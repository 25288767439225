import React, { useState, useContext, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import { get } from "../../utils/http";
import {
  MyTextInput,
  MySubmitButton,
  MySelect,
  MyCheckbox,
} from "../FormFields";
import styles from "./CreateCourse.module.css";
import { ProgramsContext } from "../../context/ProgramsContext";
import { CoursesContext } from "../../context/CoursesContext";
import { COURSES_ENDPOINT, INSTITUTIONS_LEVELS_ENDPOINT } from "../../utils/constants";
import { getInitialValues } from "../../utils/getInitialValues";

const INITIAL_VALUES = {
  program: "",
  groups: 0,
  start_date: "",
  end_date: "",
  is_curricular: "",
};

function CreateCourse() {
  const [filterText, setFilterText] = useState("");
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [isEditing, setIsEditing] = useState(false)
  const [levels, setLevels] = useState([]);
  const { programs, fetchPrograms } = useContext(ProgramsContext);
  const { createCourses, academicYears, updateCourse, fetchYears } = useContext(CoursesContext);
  const { courseId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetchPrograms()
  }, []);
  
  useEffect(() => {
    fetchYears()
  }, []);
  
  useEffect(() => {
    if (courseId) {
      setIsEditing(true)
      const fetchCourse = async () => {
        const url = `${COURSES_ENDPOINT}${courseId}/`;
        const response = await get({ url });

        if (!response.error) {
          const definedInitialValues = getInitialValues({
            values: response.data,
            base: INITIAL_VALUES,
          });
          setInitialValues(definedInitialValues);
        }
      };
      fetchCourse();
    }
  }, [courseId]);

  useEffect(() => {
      const fetchLevels = async () => {
        const url = `${INSTITUTIONS_LEVELS_ENDPOINT}`;
        const response = await get({ url });

        if (!response.error) {
          setLevels(response.data);
        }
      };
      fetchLevels();
  }, []);
  
  let filterData = levels?.filter((item) =>
    item.name.toLowerCase().includes(filterText.toLowerCase()) ||
    item.institution.toLowerCase().includes(filterText.toLowerCase()) ||
    item.stage.toLowerCase().includes(filterText.toLowerCase())
  );

  const createCoursesHandler = ({ values }) => {
    !courseId ? createCourses({ data: values }) : updateCourse({ courseId, values });
  };

  return (
    <div className={styles["create-course"]}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      ></i>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object({
          program: Yup.string().required("Campo requerido"),
          groups: Yup.number().min(0, "El número debe ser positivo."),
          start_date: Yup.date(),
          end_date: Yup.date(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            createCoursesHandler({ values });
            setSubmitting(false);
          }, 400);
          resetForm();
        }}
      >
        <Form className={styles["form__fields"]}>
          <div className={styles.form_rows}>
            <div className={styles.inputs_left}>
              <MySelect label="Programa" name="program">
                {programs?.map((program) => (
                  <MenuItem value={program.id}>{program.name} - {program.description} - {program.language}</MenuItem>
                ))}
              </MySelect>
              <MyTextInput
                label="Secciones"
                name="group_numbers"
                type="number"
                icon="fa fa-user"
              />
              <MyTextInput
                label="Fecha de inicio"
                name="start_date"
                type="date"
                icon="fa fa-key"
              />
              <MyTextInput
                label="Fecha de fin"
                name="end_date"
                type="date"
                icon="fa fa-key"
              />
              <MySelect label="Año escolar" name="year">
                {academicYears?.map((year) => (
                  <MenuItem key={year.id} value={year.id}>
                    {year.name}
                  </MenuItem>
                ))}
              </MySelect>
              <div style={{ marginTop: "20px" }}>
                <MyCheckbox label="Es curricular" name="is_curricular">
                  Es curricular
                </MyCheckbox>
              </div>
            </div>
            {!isEditing && 
            <div className={styles.inputs_right}>
              <p>Seleccione los niveles</p>
              <div className={styles["search-bar"]}>
                <input
                  id="search"
                  type="text"
                  placeholder="Buscar"
                  aria-label="Search Input"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className={styles.filter_text}
                />
              </div>
              <div className={styles.levels}>
                {filterData?.map((level) => (
                  <div className={styles.check_level}>
                    <MyCheckbox name="levels" value={level.id.toString()}>
                      {`${level.name} ${level.institution}`}
                    </MyCheckbox>
                  </div>
                ))}
              </div>
            </div>}
          </div>
          <MySubmitButton name="Crear" type="submit" />
        </Form>
      </Formik>
    </div>
  );
}

export default CreateCourse;
