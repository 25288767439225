import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const handleDownloadExcel = (exportData, title) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
  
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const currentDate = new Date();
    const dd = String(currentDate.getDate()).padStart(2, '0');
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
    const yyyy = currentDate.getFullYear();
    const formattedDate = `${dd}-${mm}-${yyyy}`;
    
    const fileName = `${title} - ${formattedDate}`;

    const blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blob, `${fileName}${fileExtension}`);
  };