import React, { useState, useRef } from "react";
import styles from "./RegistroMasivoForm.module.css";

function RegistroMasivoForm({ registerMultiple }) {
  const [showFileUploader, setShowFileUploader] = useState(true);
  const [showFileUploading, setShowFileUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState({});
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleOnChange = (e) => {
    setShowFileUploader(false);
    setFileName(e.target.files[0].name);
    setFile({ selectedFile: e.target.files[0] });
    setFileUploaded(true);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file.selectedFile);

    registerMultiple({ formData });
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {showFileUploader && (
          <form
            action="#"
            onClick={handleUploadClick}
            encType="multipart/form-data"
          >
            <input
              type="file"
              accept={".csv"}
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleOnChange}
            ></input>
            <i className="fas fa-cloud-upload-alt"></i>
            <p>{`Click para subir archivo`}</p>
          </form>
        )}
        {showFileUploading && (
          <section className={styles.progressarea}>
            <li className={styles.row}>
              <i className="fas fa-file-alt"></i>
              <div className={styles.content}>
                <div className={styles.details}>
                  <span
                    className={styles.name}
                  >{`${fileName} • Uploading`}</span>
                  <span className={styles.percent}>50%</span>
                </div>
                <div className={styles.progressbar}>
                  <div className={styles.progress}></div>
                </div>
              </div>
            </li>
          </section>
        )}
        {fileUploaded && (
          <section className={styles.uploadedarea}>
            <li className={styles.row}>
              <div className={styles.content}>
                <i className="fas fa-file-alt"></i>
                <div className={styles.details}>
                  <span
                    className={styles.name}
                  >{`${fileName} • Uploaded`}</span>
                </div>
              </div>
              <i className="fas fa-check"></i>
            </li>
            <button onClick={handleOnSubmit}>Submit</button>
          </section>
        )}
      </div>
    </div>
  );
}

export default RegistroMasivoForm;
