import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import styles from "./ListCapacitaciones.module.css";
import { QUIZ_QUESTIONS_ENDPOINT } from "../../../utils/constants";
import { PROGRAMS_ENDPOINT } from "../../../utils/constants";
import { get } from "../../../utils/http";
import Container from "../../Container";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function QuestionsListCap() {
  const [preguntas, setPreguntas] = useState([]);
  const [programs, setPrograms] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrograms = async () => {
      const url = `${PROGRAMS_ENDPOINT}`;
      const response = await get({ url });

      setPrograms(response.data);
    };
    fetchPrograms();
  }, []);

  useEffect(() => {
    const fetchPreguntas = async () => {
      const url = `${QUIZ_QUESTIONS_ENDPOINT}`;
      const response = await get({ url });
      setPreguntas(response.data);
    };
    fetchPreguntas();
  }, []);

  const columns = [
    {
      name: "Descripción",
      selector: (row) => row.content,
      sortable: true,
      cell: (cell) => {
        return(
          <div style={{display:'flex', width: '200px'}}>{cell.content}</div>
        )
      }
    },
    {
      name: "Programa",
      selector: (row) => row.program,
      sortable: true,
      cell: (cell) => {
        let programa 
        programs?.forEach((program) => {
          if (program.id === cell.program) {
            programa = program.name
          }
        });
        return (
          <div >
            <p >{programa}</p>
          </div>
        );
      },
    },
    {
      name: "Peso",
      selector: (row) => `${row.weight}`,
      sortable: true,
    },
    {
      name: "Opciones",
      selector: (row) => row.options,
      sortable: true,
      cell: (cell) => {
        return(
          <div style={{display:'flex', width: '300px', flexDirection: 'column'}}>
            {cell?.options?.map((opt) => (
              <p>{opt?.description}</p>
            ))}
          </div>
        )
      }
    },
  ];

  return (
    <Container>
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles.table}>
          <DataTable
            columns={columns}
            data={preguntas}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight="500px"
            pointerOnHover
            noDataComponent="No hay preguntas para mostrar"
          />
        </div>
      </div>
    </Container>
  )
}

export default QuestionsListCap
