import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { MyTextInput, MySubmitButton} from "../FormFields";
import Container from "../Container";
import styles from "./InstitutionTables.module.css";
import { useParams } from "react-router-dom";
import { REFERENCE_LEVELS } from "../../utils/constants/institutionsConstants";
import { useInstitutions } from "../../context/InstitutionsContext";

const institutionLevels = [
  {
    id: 1,
    name: "1er Grado",
    stage: "Primaria Menor",
    reference_level: "1er Grado",
    student_sections: 2,
    students_per_section: 20,
    institution_coordinator: null,
  },
  {
    id: 2,
    name: "1er Año",
    stage: "Bachillerato",
    reference_level: "1er Año",
    student_sections: 2,
    students_per_section: 20,
    institution_coordinator: null,
  },
];

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function InstitutionTables() {
  const [addSection, setAddSection] = useState(false);
  const { createLevel } = useInstitutions();

  const { institutionId } = useParams();

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Etapa",
      selector: (row) => row.stage,
      sortable: true,
    },
    {
      name: "Secciones",
      selector: (row) => row.student_sections,
      sortable: true,
    },
    {
      name: "Estudiantes por sección",
      selector: (row) => row.students_per_section,
      sortable: true,
    },
  ];

  const handleCreateLevel = (values) => {

    const levelData = {
      ...values,
      institution: institutionId,
    };

    for (let key in levelData) {
      if (values[key] === "") {
        delete levelData[key];
      }
    }

    createLevel({ data: levelData });
    setAddSection(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.options}>
        {!addSection && (
          <div className={styles.add} onClick={() => setAddSection(true)}>
            <i
              className="far fa-plus"
              style={{ fontSize: "24px", color: "var(--main-blue)" }}
            ></i>
          </div>
        )}
      </div>
      {addSection && (
        <div className={styles.sections}>
          <Formik
            initialValues={{
              name: "",
              reference_level: REFERENCE_LEVELS[0].name,
              student_sections: "",
              students_per_section: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .min(4, "El campo no puede estar vacío.")
                .required("Campo requerido"),
              student_sections: Yup.number().min(
                1,
                "El numero tiene que ser positivo."
              ),
              students_per_section: Yup.number().min(
                1,
                "El numero tiene que ser positivo."
              ),
            })}
            onSubmit={(values) => {
              handleCreateLevel(values);
            }}
          >
            <Form className={styles["form__fields"]}>
              <div className={styles.inputs_section}>
                <div className={styles.input_section}>
                  <MyTextInput
                    label="Nombre"
                    name="name"
                    type="text"
                    placeholder="1er Grado"
                  />
                  <div className={styles.dropdown}>
                    <label htmlFor="reference">Referencia</label>
                    <Field
                      component="select"
                      id="reference"
                      name="reference_level"
                      className={styles.select}
                    >
                      {REFERENCE_LEVELS.map((level) => (
                        <option value={level.name} key={level.key}>
                          {level.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <MyTextInput
                    label="# Secciones"
                    name="student_sections"
                    type="number"
                    placeholder=""
                  />
                  <MyTextInput
                    label="Alumnos por sección"
                    name="students_per_section"
                    type="number"
                    placeholder=""
                  />
                </div>
                <div className={styles.inputs}>
                  <MySubmitButton name="Aceptar" type="submit" />
                  <MySubmitButton
                    name="Cancelar"
                    onClick={() => setAddSection(false)}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      )}
      <Container>
        <div className={styles.section_content}>
          <div className={styles.table}>
            <DataTable
              columns={columns}
              data={institutionLevels}
              customStyles={customStyles}
              sortIcon={
                <i
                  className="far fa-arrow-up"
                  style={{ marginLeft: "5px", fontSize: "14px" }}
                ></i>
              }
              highlightOnHover
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight="500px"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default InstitutionTables;
