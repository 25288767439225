import React, {useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./CardSettings.module.css";
import { UsersContext } from "../../context/UsersProvider";

function CardSetting({ data, option, setOption, type, logoutUser }) {
  const { setUserAct } = useContext(UsersContext);
  const navigate = useNavigate();

  const onSelectHandler = () => {
    setOption(data.key);
    navigate(data.route);
  };

  const handleLogut = () => {
    setUserAct(null)
    logoutUser()
  }

  return (
    <>
      {type !== "settingsCard" ? (
        <div
          onClick={() => onSelectHandler(data)}
          className={option === data.key ? styles.option_3 : styles.option_2}
        >
          <span className={styles.icon}>{data.icon}</span>
          <span className={styles.title}>{data.name}</span>
        </div>
      ) : data.route !== "" ? (
        <>
        {data.name === "Cerrar sesión" ? 
        (<Link to={data.route} onClick={handleLogut} className={styles.link}>
          <div className={styles.option}>
            <span className={styles.icon}>{data.icon}</span>
            <span className={styles.title}>{data.name}</span>
          </div>
        </Link>) :
        (<Link to={data.route} className={styles.link}>
          <div className={styles.option}>
            <span className={styles.icon}>{data.icon}</span>
            <span className={styles.title}>{data.name}</span>
          </div>
        </Link>)}
        </>
      ) : (
        <div className={styles.placeholder}>
          <span className={styles.icon}>{data.icon}</span>
          <span className={styles.title}>{data.name}</span>
        </div>
      )}
    </>
  );
}

export default CardSetting;
