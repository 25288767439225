import React, {useContext, useEffect, useState}  from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Grid, Stack, Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/system';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { CurrentUserContext } from '../../../context/CurrentUserProvider';
import SessionsComp from './SessionsComp';
import { AnnualTrainingsContext } from '../../../context/AnnualTrainingsContext';
import AnswerQuizView from './AnswerQuizView';
import RightSideComponent from './RightSideComponent';
import ModalAnswers from './ModalAnswers';

const Title = styled('p')({
  fontSize: '1.6rem',
  fontWeight:'bold',
  marginBottom: '5px'
});
const Info = styled('p')({
  fontSize: '1.2rem',
  color: '#787878',
});
const Text = styled('p')({
  fontSize: '1.2rem',
  paddingTop: '5px'
});
const Text2 = styled('p')({
  fontSize: '1.3rem',
  paddingTop: '5px',
  color: 'var(--main-blue)',
  fontWeight:'bold',
});

function CapacitacionAnualView() {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [viewQuiz, setViewQuiz] = useState(false)
  const [openAnswers, setOpenAnswers] = useState(false)
  const [selectedQuiz, setSelectedQuiz] = useState(null)
  const {fetchSingleAnnualTrainings, userAnnualTraining, createAttendance, createQuizAnswer} = useContext(AnnualTrainingsContext)
  const { currentUser } = useContext(CurrentUserContext);
  const { trainingId } = useParams();

  useEffect(() => {
    if(trainingId){
    fetchSingleAnnualTrainings(trainingId);}
  }, [trainingId]);

  const navigate = useNavigate();

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  const programs = Array.from(new Set(userAnnualTraining?.sessions?.map(session => session?.program_name)));
  const sortedSessions = userAnnualTraining?.sessions?.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Obtener la primera y la última fecha
  const firstDate = sortedSessions && sortedSessions[0]?.date
  const lastDate = sortedSessions && sortedSessions[sortedSessions?.length - 1]?.date

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const startDate = new Date(firstDate)?.toLocaleString("es-ES", options) || "";
  const endDate = new Date(lastDate)?.toLocaleString("es-ES", options) || "";

  let type = ""
  let icon = ""
  switch (userAnnualTraining?.type) {
    case 'on-site':
      type = "Presencial"
      icon = <i class="fas fa-building" style={{backgroundColor: 'var(--main-blue)', 
        color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
      break
    case 'remote':
      type = "Remoto"
      icon = <i class="fas fa-laptop" style={{backgroundColor: 'var(--main-blue)', 
        color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
      break
    case 'hybrid':
      type = "Híbrido"
      icon = <i class="fas fa-laptop-house" style={{backgroundColor: 'var(--main-blue)', 
        color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
      break
  }

  const nextSessionsFilter = userAnnualTraining?.sessions?.filter((item) => new Date(item.date) > new Date())

  const confirm = userAnnualTraining?.sessions?.filter(session =>
    session?.teacher?.some(teacher =>
      teacher.id === currentUser.id && teacher?.attendance_status === null
    )
  );
  
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmSingleAttendance = (sessionId, teacherAttendance) => {
    const data = {
      session: sessionId,
      teacher: currentUser.id
    }
    if(!teacherAttendance){
      createAttendance(data, userAnnualTraining.id)
    }
  }

  const handleConfirmAttendance = () => {
    userAnnualTraining?.sessions.forEach((item) => {
      const teacherAttendance = item?.teacher?.find((item) => item.id === currentUser.id)
      const d = {
        session: item.id,
        teacher: currentUser.id
      }
      if(!teacherAttendance.attendance_status){
        createAttendance(d, userAnnualTraining.id)
      }
      setOpenDialog(false)
    })
  }

  const formattedTime = (time) => {
    return time.slice(0, -3);
  }

  return (
    <>
      {!viewQuiz ? 
      <>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      ></i>
      <Grid container display={"row"} rowGap={2} columnGap={2}>
        <Grid item md={8.7} xs={12}>
          <Grid container direction={"row"} columnGap={2} rowGap={2}>
            <Grid item md={5} xs={12} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Title>{userAnnualTraining?.name}</Title>
              <Info>{programs?.join(', ')}</Info>
              <Stack direction={"row"} spacing={1}
              sx={{display:'flex', alignItems:'center', marginTop: '2px'}}>
                <i class="fas fa-calendar" style={{color: "#787878", fontSize:'1.2rem'}}></i>
                <Info>{`${startDate} - ${endDate}`}</Info>
              </Stack>
            </Grid>
            <Grid item md={3} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                      <Info>Modalidad</Info>
                      <Text2>{type}</Text2>
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    {icon}
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
          {userAnnualTraining?.description &&
          <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
          borderRadius: '10px', marginTop:'15px'}}>
            <Info>Descripción</Info>
            <Text>{userAnnualTraining?.description}</Text>
          </Box>}
          {!isDesktop &&
          <Box
          sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
          borderRadius: '10px', marginTop: '20px'}}>
            <RightSideComponent 
            nextSessionsFilter={nextSessionsFilter} 
            userAnnualTraining={userAnnualTraining}
            currentUser={currentUser}
            trainingId={trainingId} userId={currentUser.id} />
          </Box>}
          <Box sx={{marginTop: '10px'}}>
            <Grid container direction={"row"}>
              <Grid item md={8.5} xs={12}>
                <Title>Sesiones</Title>
              </Grid>
              {confirm?.length > 0 &&
              <Grid item md={3.5}  xs={12}
              sx={{display:'flex', justifyContent: 'flex-end'}}>
                <Button variant="contained" fullWidth 
                onClick={() => setOpenDialog(true)}
                sx={{borderRadius: '10px'}}>
                  Confirmar asistencia a todas las sesiones
                </Button>
              </Grid>
              }
            </Grid>
            <SessionsComp sessions={userAnnualTraining?.sessions} trainingId={userAnnualTraining?.id}
            handleConfirmSingleAttendance={handleConfirmSingleAttendance} createAttendance={createAttendance}/>
          </Box>
        </Grid>
        {isDesktop && 
        <Grid item md={3} xs={12} 
        sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px', height: isDesktop && '86vh'}}>
          <RightSideComponent 
            nextSessionsFilter={nextSessionsFilter} 
            userAnnualTraining={userAnnualTraining}
            currentUser={currentUser}
            trainingId={trainingId} userId={currentUser.id} />
        </Grid>}
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle id="alert-dialog-title" style={{color: 'red', fontSize: '18px'}}>
          {"Confirmas tu asistencia a todas las sesiones?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{fontSize: '16px'}}>
            <List>
              {userAnnualTraining?.sessions?.map((item) => (
                <ListItem sx={{paddingLeft: 0}}>
                  {`${new Date(item.date)?.toLocaleString("es-ES", options)} - 
                  ${formattedTime(item?.start_time)} a ${formattedTime(item?.end_time)}`}
                </ListItem>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus 
          sx={{fontSize: '14px', borderRadius: '10px'}}>
            Cancelar
          </Button>
          <Button onClick={handleConfirmAttendance} variant='outlined' 
          autoFocus sx={{fontSize: '14px', borderRadius: '10px'}}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      </> : 
      <>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => setViewQuiz(false)}
      ></i>
      <AnswerQuizView setViewQuiz={setViewQuiz} createQuizAnswer={createQuizAnswer} trainingId={userAnnualTraining?.id}
      quiz={userAnnualTraining?.quiz?.templatequiz} quizId={userAnnualTraining?.quiz?.id}/>
      </>
      }
      {openAnswers && (
        <ModalAnswers setOpenAnswers={setOpenAnswers} 
        selectedQuiz={selectedQuiz} />
      )}
    </>
  )
}

export default CapacitacionAnualView