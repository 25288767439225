import React from "react";
import Modal from "./Modal";

function DiplomadoSTEM({ setOpenModal }) {
  return (
    <Modal modalOpen={setOpenModal} title={"Diplomado STEM IA"}>
      <div
        style={{
          width: "100%",
          maxHeight: "80vh",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <p>{`¡Nuestro nuevo Diplomado STEM IA está disponible para todos los docentes de la institución! En él aprenderán a integrar prácticas e indicadores de STEM, así como todo lo referente a la Inteligencia Artificial disponible para la educación, ¿Qué esperas para inscribirte y convertirte en el maestro del siglo XXI? Al participar, obtendrás la certificación de la Asociación Americana de Robótica y Tecnología, el cual es un certificado internacional.`}</p>
        <p>
          <span style={{ fontWeight: "bold" }}>Duración:</span> 3 meses (aprox)
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Modalidad:</span> Aprendizaje autónomo con apoyo de docentes de Kurios Education.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Requerimientos:</span> 
        </p>
        <ul style={{marginLeft: '20px'}}>
          <li>
          Tener acceso a una computadora, internet, y Whatsapp.
          </li>
          <li>
          Tiempo disponible para dedicarle al diplomado.
          </li>
          <li>
            Ser docente de nuestros colegios aliados. 
          </li>
        </ul>
        <div
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSd9T_Qz348yVHBstO2a-ayHPQLa-8ToKMCuj3nlsAb9gghBLA/viewform"
            target="_blank"
            rel="noreferrer"
            style={{
              background: "#330072",
              color: "#ffffff",
              // cursor: "not-allowed",
              borderRadius: "10px",
              padding: "10px",
              width: "fit-content",
            }}
          >
            Inscribirse
          </a>
        </div>
      </div>
    </Modal>
  );
}

export default DiplomadoSTEM;
