import React, { useContext, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { get } from "../../../utils/http";
import {
  MyTextInput,
  MySubmitButton,
  MyCheckbox
} from "../../FormFields";
import Container from "../../Container";
import { QUIZ_QUESTIONS_ENDPOINT, PROGRAMS_ENDPOINT } from "../../../utils/constants";
import styles from './CreateQuiz.module.css'
import { CapacitacionesContext } from "../../../context/CapacitacionesContext";

function CreateQuiz() {
  const [questions, setQuestions] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [filterProgram, setFilterProgram] = useState("")
  const {createQuiz} = useContext(CapacitacionesContext)

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrograms = async () => {
      const url = PROGRAMS_ENDPOINT;
      const response = await get({ url });

      if (!response.error) {
        setPrograms(response.data);
      }
    };
    fetchPrograms();
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      const url = QUIZ_QUESTIONS_ENDPOINT;
      const response = await get({ url });
      if (!response.error) {
        setQuestions(response.data);
      }
    };
    fetchQuestions();
  }, []);

  const createQuizHandler = ({ values }) => {
    const questionData = {
      description: values.description,
      questions: values.questions,
    };
    
    createQuiz({ data: questionData })
  };

  return (
    <Container>
      <div className={styles["create-question"]}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
      <div style={{ overflowY: "scroll" }}>
        <Formik
            initialValues={{
              description: ""
            }}
            enableReinitialize
            validationSchema={Yup.object({
              description: Yup.string().required("Campo requerido"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                createQuizHandler({ values });
                setSubmitting(false);
              }, 400);
              resetForm();
            }}
          >
            <Form className={styles["form__fields"]}>
              <Stack direction={"column"} spacing={2}>
                <MyTextInput
                    label="Descripción"
                    name="description"
                    type="text"
                    placeholder="Descripción"
                    icon="fa fa-user"
                    width={'100%'}
                />
                <Accordion sx={{borderRadius: '10px'}} elevation={0} defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{fontSize: '16px'}}
                  >
                    Seleccione las preguntas
                  </AccordionSummary>
                  <AccordionDetails sx={{ maxHeight: '50vh', overflow: 'scroll'}}>
                    <FormControl 
                      fullWidth 
                      variant="outlined"  size="small"
                      sx={{marginBottom: '10px',
                        "& .MuiInputBase-root": {
                          fontSize: "14px", 
                        },
                        "& .MuiMenuItem-root": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      <Select
                      value={filterProgram || ""}
                      onChange={(e) =>
                        setFilterProgram(parseInt(e.target.value))
                      }
                      size="small"
                      fullWidth
                      sx={{
                        marginTop: '3px',
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      >
                      {programs.map((license) => (
                        <MenuItem value={license.id} key={license.id}>
                          {license.name}
                        </MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                      {questions?.map((question) => {
                        return (
                          <div className={styles.check_level} key={question.id}>
                            <MyCheckbox
                              name="questions"
                              value={question.id.toString()}
                            >
                              <p
                                style={{
                                  marginTop: 0,
                                  maxWidth: "400px",
                                  justifyContent: "flex-start",
                                  textAlign: "start",
                                  display: "flex",
                                  paddingLeft: "10px",
                                }}
                              >{`${question.content}`}</p>
                            </MyCheckbox>
                          </div>
                        );
                    })}
                </AccordionDetails>
              </Accordion>
              </Stack>
              
              <MySubmitButton
                name={"Crear"}
                type="submit"
              />
            </Form>
          </Formik>
        </div>
        </div>
    </Container>
  )
}

export default CreateQuiz
