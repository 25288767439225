import React, { useEffect, useState,useContext } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Contact from "../../components/Contact/Contact";
import styles from "./Contacts.module.css";
import { useCurrentUser } from "../../context/CurrentUserProvider";
import { useConversations } from "../../context/ConversationsProvider";
import NewConversation from "../../components/NewConversation/NewConversation";
import { UsersContext } from "../../context/UsersProvider";
import { CONTACTS_ENDPOINT } from "../../utils/constants";
import { get } from "../../utils/http";

const Contacts = () => {
  const { currentUser } = useCurrentUser();
  const {  userAct } = useContext(UsersContext);
  const [textFilter, setTextFilter] = useState("")
  const [contacts, setContacts] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [isLoading, setIsLoading] =useState(false)

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
        window.removeEventListener('resize', changeWidth)
    }
  }, [])

  //GET Contacts
  useEffect(() => {
    const fetchContacts = async () => {
      setIsLoading(true)
      const url = CONTACTS_ENDPOINT;
      const response = await get({ url });

      if (!response.error) {
        setContacts(response.data);
        setIsLoading(false)
      }
    };
    fetchContacts();
  }, []);

  const {
    conversations,
    currentConversationId,
    setCurrentConversationId,
    markAsRead,
    fetchRooms,
    setCurrentConversation,
    currentConversation
  } = useConversations();

  const sortedData = conversations?.sort((a, b) => {
    return (
      new Date(b.last_message?.timestamp) - new Date(a.last_message?.timestamp)
    );
  });

  const filteredData = sortedData?.filter((item) => {
    if (textFilter === "" ) {
      return item;
    } else {
      const user = item?.users.find((us) => us.id !== currentUser.id)
      const name = `${user.first_name} ${user.last_name}`
      let nameMatch = name
      if(textFilter !== ""){
        nameMatch = name.toLowerCase().includes(textFilter.toLowerCase());
      }
      if (nameMatch) {
        return item;
      }
    }
  });

  const selectConversationHandler = (conversation) => {
    setCurrentConversationId(conversation.id);
    setCurrentConversation(conversation)
    if (conversation?.unread_messages > 0) {
      markAsRead(conversation.id);
    }
    fetchRooms()
  };

  return (
    <div className={styles.contacts} 
    style={{visibility: (screenWidth <= 750 && currentConversation) && 'hidden', 
    display: (screenWidth <= 750 && currentConversation) && 'none'}}>
      <div className={styles["user-info"]}>
        <div style={{width: '70%'}}>
          <Contact
            firstName={currentUser?.first_name}
            lastName={currentUser?.last_name}
            profilePic={userAct?.img_profile}
            status="online"
            myInfo={true}
          />
        </div>
        <div style={{width:'30%', display:'flex', alignItems:'center'}}>
        <NewConversation />
        </div>
      </div>
      <div className={styles["chat-list"]}>
        <div className={styles["search-bar"]}>
          <input
            id="search"
            type="text"
            placeholder="Buscar"
            aria-label="Search Input"
            className={styles.filter_text}
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
          />
        </div>
      {!isLoading ? 
        <>
        {filteredData?.map((conversation) => {
          //const newUsers = filteredUsers(conversation?.users, currentId)[0];
          const users = [];
          conversation?.users?.forEach((user) => {
            if (user.id !== currentUser.id) {
              users?.push(user);
            }
          });
          const filteredUsers = contacts?.filter((item) =>
            users?.includes(item.id)
          );
          const firstName = users[0]?.first_name
          const lastName =users[0]?.last_name;
          const profilePic = users[0]?.img_profile;

          return (
            <div key={conversation["id"]} className={styles["contact-list"]}>
              <div
                className={`${styles.contact} ${
                  conversation["id"] === currentConversationId
                    ? styles.active
                    : "not active"
                }`}
              >
                <Contact
                  key={conversation["id"]}
                  converId={conversation["id"]}
                  firstName={firstName}
                  lastName={lastName}
                  notifications={conversation?.unread_messages}
                  status="online"
                  typing={false}
                  lastMessage={conversation["last_message"]}
                  profilePic={profilePic}
                  selectConversationHandler={selectConversationHandler}
                  conversation={conversation}
                />
              </div>
            </div>
          );
        })}
        </> :
       <Skeleton count={2} height={'30px'}/>}
      </div> 
      
    </div>
  );
};

export default Contacts;
