import React from "react";
import styles from "./Container.module.css";

const Container = ({ direction, children, type }) => {
  const containerClass =
    direction === "column" ? "container-column" : "container-row";

  return <div className={type === 'institutions' ? styles.container_inst : styles[`${containerClass}`]}>{children}</div>;
};

export default Container;
