import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Button, DialogContent, DialogTitle, DialogActions, Dialog } from "@mui/material";
import { MONITOR_FORM_ENDPOINT } from "../../../../utils/constants";
import { useForms } from "../../../../context/MonitorFormsContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MySubmitButton, MyTextarea } from "../../../FormFields";
import { get } from "../../../../utils/http";
import Container from "../../../Container";
import styles from "./PlanillaView.module.css";
import Modal from "../../../Modal";
import { CurrentUserContext } from "../../../../context/CurrentUserProvider";
import LoadPicturesPlanilla from "./LoadPicturesPlanilla";

function PlanillaView() {
  const [planilla, setPlanilla] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { planillaId } = useParams();
  const { editMonitorForm, deleteMonitorForm } = useForms();
  const { currentUser } = useContext(CurrentUserContext);

  const navigate = useNavigate();

  const fetchPlanilla = async () => {
    const url = `${MONITOR_FORM_ENDPOINT}${planillaId}/`;
    const response = await get({ url });
    setPlanilla(response.data);
  };

  useEffect(() => {
    fetchPlanilla();
  }, [planillaId]);

  const handleAcceptFeedback = () => {
    const data = {
      reviewed: true,
    };
    editMonitorForm({ planillaId, data });
    fetchPlanilla();
  };

  const handleAcceptAlert = () => {
    const data = {
      alert: false,
    };
    editMonitorForm({ planillaId, data });
    fetchPlanilla();
  };

  const handleEditFeedback = (values) => {
    const data = values;
    editMonitorForm({ planillaId, data });
    fetchPlanilla();
    setOpenModal(false);
  };

  const handleDeleteForm = () => {
    const response = deleteMonitorForm({ planillaId });

    if (!response.error) {
      navigate(-1);
      setOpenDeleteModal(false);
    }
    setOpenDeleteModal(false);
  };

  const handleClosedDeletAlert = () => {
    setOpenDeleteModal(false);
  }

  return (
    <Container>
      <div className={styles.container}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles.formContent} >
          <div className={styles.left_side}>
            <div className={styles.left_container}>
              <div className={styles.header}>
                <p className={styles.title}>
                  {`${planilla?.teacher?.first_name} 
                  ${planilla?.teacher?.last_name} `}
                </p>
                <p className={styles.text}>
                  {`${planilla?.course_section?.level?.institution}  `}
                </p>
                <p className={styles.text}>
                  {`${planilla?.course_section?.program} - ${planilla?.course_section?.level?.name} `}
                </p>
              </div>
              <div className={styles.left_content}>
                <p className={styles.tex2}>
                  {`Nota: ${planilla?.grade?.toFixed(2)}%  `}
                </p>
                <p className={styles.tex2}>
                  {`Nota estimada: ${planilla?.estimated_grade}  `}
                </p>
                <p className={styles.tex2}>
                  {`Fecha de la visita:  ${planilla?.date}`}
                </p>

                <p className={styles.tex2}>
                  {`Tipo de docente: ${planilla?.teacher_type}  `}
                </p>
              </div>
            </div>
            <div className={styles.left_container}>
              <p className={styles.text}>
                {`Visita por: ${planilla?.monitor?.first_name} 
                            ${planilla?.monitor?.last_name}`}
              </p>
            </div>
            <Button fullWidth size="large"
            variant="outlined" color="error" 
            onClick={() => setOpenDeleteModal(true)}
            sx={{backgroundColor: '#fff', borderRadius:'10px',
            fontSize: '1.3rem'}}>
            Eliminar planilla
            </Button>
            <LoadPicturesPlanilla 
             formId={planilla.id} 
            />
            {planilla?.grade < 70 && (
              <Link
                to={`/courses/${planilla?.course_section?.course}/forum`}
                style={{ textDecoration: "none", color: "#000" }}
              >
                <div className={styles.left_zoom}>
                  <p className={styles.text}>Se requiere reunión por zoom</p>
                  <i
                    className="fas fa-exclamation-circle"
                    style={{
                      fontSize: "28px",
                      color: "var(--sec-orange)",
                      marginLeft: "auto",
                    }}
                  ></i>
                </div>
              </Link>
            )}
            {planilla?.alert &&
              currentUser.role === "monitoring_coordinator" && (
                <div className={styles.left_alert} onClick={handleAcceptAlert}>
                  <p className={styles.text3} style={{ color: "#fff" }}>
                    Desactivar alerta
                  </p>
                </div>
              )}
          </div>
          <div className={styles.right_side}>
            <p className={styles.title3} style={{ marginTop: "10px" }}>
              Comentarios
            </p>
            <div style={{ gap: "1rem" }}>
              <div className={styles.comment_content}>
                <p
                  style={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    fontWeight: "bold",
                    marginBottom: "5px",
                  }}
                >
                  Comentario interno
                </p>
                <p style={{ fontSize: "13px" }}>{planilla?.comments}</p>
              </div>
              <div
                className={styles.feedback_content}
                style={{ marginTop: "10px" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: "bold",
                      marginBottom: "5px",
                    }}
                  >
                    Feedback
                  </p>
                  <p style={{ fontSize: "13px" }}>{planilla?.feedback}</p>
                </div>
                
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {(currentUser?.role === "monitoring_coordinator" ||
                  currentUser?.role === "academic_coordination") && (
                    <>
                    {!planilla.reviewed && (
                      <p
                        className={styles.accept_feedback}
                        onClick={handleAcceptFeedback}
                      >
                        Aceptar
                      </p>
                    )}
                    </>
                    )}
                    <p
                      className={styles.edit_feedback}
                      onClick={() => setOpenModal(true)}
                    >
                      Editar
                    </p>
                  </div>
                
              </div>
            </div>
            <p className={styles.title3}>Respuestas</p>
            <div className={styles.right_content}>
              {planilla?.answers?.map((answer) => {
                return (
                  <div
                    className={
                      answer.answer ? styles.answer_true : styles.answer_false
                    }
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ fontSize: "12px", fontStyle: "italic" }}>
                        {answer.question.area}
                      </p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p
                          style={{
                            fontSize: "14px",
                            paddingTop: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          {answer?.question?.content}
                        </p>
                        {answer?.answer ||
                        (!answer?.answer &&
                          answer.question.area == "Requerimientos") ? (
                          <i
                            className="fas fa-check-circle"
                            style={{
                              fontSize: "18px",
                              color: "var(--sec-green)",
                              marginLeft: "5px",
                              marginTop: "3px",
                            }}
                          ></i>
                        ) : (
                          <>
                            {answer?.not_applies ? (
                              <p
                                style={{
                                  backgroundColor: "#989a9e",
                                  padding: "5px 10px 5px 10px",
                                  borderRadius: "20px",
                                  color: "#fff",
                                  marginLeft: "15px",
                                }}
                              >
                                No aplica
                              </p>
                            ) : (
                              <i
                                className="fas fa-times-circle"
                                style={{
                                  fontSize: "18px",
                                  color: "var(--sec-red)",
                                  marginLeft: "5px",
                                  marginTop: "3px",
                                }}
                              ></i>
                            )}
                          </>
                        )}
                      </div>
                      {answer?.comment && (
                        <p style={{ marginTop: "5px", fontSize: "12px" }}>
                          {answer?.comment}
                        </p>
                      )}
                    </div>
                    {!answer.not_applies &&
                      !answer.answer &&
                      answer?.question?.important &&
                      answer.question.area !== "Requerimientos" && (
                        <div style={{ marginLeft: "auto" }}>
                          <p className={styles.aswer_alert}>Importante</p>
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <Modal title={"Editar Feedback"} modalOpen={setOpenModal}>
          <div style={{height: '100%', overflowY: 'scroll'}}>
            <Formik
              initialValues={{
                feedback: planilla?.feedback,
              }}
              validationSchema={Yup.object({
                feedback: Yup.string(),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                  handleEditFeedback(values);
                  setSubmitting(false);
                }, 400);
                resetForm();
              }}
            >
              <Form className={styles["form__fields"]} style={{width:'60vw'}}>
                <MyTextarea label="Feedback" name="feedback" type={"feedback"}/>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <MySubmitButton name="Editar" type="submit" />
                </div>
              </Form>
            </Formik>
          </div>
        </Modal>
      )}
      <Dialog
        open={openDeleteModal}
        onClose={handleClosedDeletAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          Seguro que quieres eliminar esta planilla?
        </DialogTitle>
        <DialogActions>
          <Button size="large"
          onClick={handleClosedDeletAlert} sx={{fontSize: '1.2rem'}}
          autoFocus>
            Cancelar
          </Button>
          <Button variant="outlined"
          sx={{fontSize: '1.2rem', borderRadius:'10px'}}
          onClick={handleDeleteForm} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      
    </Container>
  );
}

export default PlanillaView;
