export const COURSES_OPTIONS = [
  {
    key: "list",
    name: "Cursos",
    icon: <i className="fad fa-users-class"></i>,
    route: "list",
  },
  {
    key: "create",
    name: "Crear curso",
    icon: <i className="fas fa-plus-square"></i>,
    route: "create",
  },
  {
    key: "upload",
    name: "Creación Masiva",
    icon: <i className="fas fa-folder-plus"></i>,
    route: "upload",
  },
];

export const TERM_OPTIONS = {
  first_term: "Primer Lapso",
  second_term: "Segundo Lapso",
  third_term: "Tercer Lapso",
};

export const TERM_OPTIONS_CHILE = {
  first_term: "Primer Semestre",
  second_term: "Segundo Semestre",
  third_term: "Tercer Semestre",
};

export const secondaryPlanHeaders = {
  curriculum: [
    { subject: "Área de Aprendizaje" },
    { generating_topic: "Tema Generador" },
    { topics: "Componente" },
    { references: "Referentes Teorico-Prácticos" },
    { keys: ["subject", "generating_topic", "topics", "references"] },
  ],
  goal: [{ "Objetivo de la Sesión": "learning_subject" }],
  activities: [
    { Inicio: "beginning" },
    { Desarrollo: "development" },
    { Cierre: "closure" },
    { keys: ["beginning", "development", "closure"] },
  ],
  //   resources: {},
  interdisciplinary: [{ "Actividades Interdisciplinarias": "main_activity" }],
};

export const Weekdays = [
  { name: "MONDAY", display: "Lunes", key: "Lunes" },
  { name: "TUESDAY", display: "Martes", key: "Martes" },
  { name: "WEDNESDAY", display: "Miercoles", key: "Miercoles" },
  { name: "THURSDAY", display: "Jueves", key: "Jueves" },
  { name: "FRIDAY", display: "Viernes", key: "Viernes" },
];
