import React, { useContext, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { get } from "../../../utils/http";
import {
  MyTextInput,
  MySubmitButton,
  MySelect,
  MyCheckbox,
  MyTextarea,
} from "../../FormFields";
import styles from "./CrearCapacitacion.module.css";
import { CapacitacionesContext } from "../../../context/CapacitacionesContext";
import { TEACHERS_ENDPOINT, PROGRAMS_ENDPOINT, CAPACITACION_ENDPOINT, QUIZ_ENDPOINT } from "../../../utils/constants";
import Container from "../../Container";
import { getInitialValues } from "../../../utils/getInitialValues";

const INITIAL_VALUES = {
  video: "",
  guide: "",
  stage: "",
  start_date: null,
  end_date: null,
  task_info: "",
  title: "",
  quiz: null,
  programs: null,
  teachers: null
};

function CrearCapacitacion() {
  const [teachers, setTeachers] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [showTeachers, setShowTeachers] = useState(false);
  const [showPrograms, setShowPrograms] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [quices, setQuices] = useState([]);
  const { createCapacitacion, updateCapacitacion } = useContext(CapacitacionesContext);
  const { trainingId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeachers = async () => {
      const url = `${TEACHERS_ENDPOINT}`;
      const response = await get({ url });

      setTeachers(response.data);
    };
    fetchTeachers();
  }, []);

  useEffect(() => {
    const fetchQuices = async () => {
      const url = `${QUIZ_ENDPOINT}`;
      const response = await get({ url });
      setQuices(response.data);
    };
    fetchQuices();
  }, []);

  useEffect(() => {
    if (trainingId) {
      const fetchCapacitacion = async () => {
        const url = `${CAPACITACION_ENDPOINT}${trainingId}/`;
        const response = await get({ url });

        if (!response.error) {
          const definedInitialValues = getInitialValues({
            values: response.data,
            base: INITIAL_VALUES,
          });
          setInitialValues(definedInitialValues);
        }
      };
      fetchCapacitacion();
    }
  }, [trainingId]);

  useEffect(() => {
    const fetchPrograms = async () => {
      const url = `${PROGRAMS_ENDPOINT}`;
      const response = await get({ url });

      setPrograms(response.data);
    };
    fetchPrograms();
  }, []);

  const handleCreate = (values) => {
    !trainingId ? createCapacitacion({ data: values }) : updateCapacitacion({ trainingId, data: values });
  };

  let filterData = teachers?.filter(
    (item) =>
      item.info.first_name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.info.last_name.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div className={styles["create-course"]}>
      <Container>
        <div className={styles.content}>
          <i
            className="fal fa-arrow-left"
            style={{
              fontSize: "12px",
              cursor: "pointer",
              marginBottom: "10px",
            }}
            onClick={() => navigate(-1)}
          ></i>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object({
              title: Yup.string().required("Campo requerido"),
              video: Yup.string(),
              guide: Yup.string(),
              stage: Yup.string(),
              task_info: Yup.string(),
              start_date: Yup.date(),
              end_date: Yup.date(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                handleCreate(values);
                setSubmitting(false);
              }, 400);
              resetForm();
            }}
          >
            <Form className={styles["form__fields"]}>
              <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
                <Grid item md={7} xs={12}>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                    <MyTextInput
                    label="Título"
                    name="title"
                    type="text"
                    icon="fa fa-user"
                  />

                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                    <MyTextInput
                    label="Video"
                    name="video"
                    type="text"
                    icon="fa fa-user"
                    />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                    <MyTextInput
                    label="Guía"
                    name="guide"
                    type="text"
                    icon="fa fa-user"
                    />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <MySelect label="Etapa" name="stage">
                        <MenuItem value="Preescolar">Preescolar</MenuItem>
                        <MenuItem value="Primaria Menor">Primaria Menor</MenuItem>
                        <MenuItem value="Primaria Mayor">Primaria Mayor</MenuItem>
                        <MenuItem value="Bachillerato">Bachillerato</MenuItem>
                      </MySelect>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <MyTextInput
                      label="Fecha de inicio"
                      name="start_date"
                      type="date"
                      icon="fa fa-key"
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <MyTextInput
                      label="Fecha de fin"
                      name="end_date"
                      type="date"
                      icon="fa fa-key"
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <MySelect label="Quiz" name="quiz">
                        {quices?.map((quiz) => (
                           <MenuItem value={quiz?.id}>{quiz?.description}</MenuItem>
                        ))}
                      </MySelect>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <MyTextarea label="Asignación" name="task_info" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={5} xs={12}>
                <Accordion sx={{borderRadius: '10px'}} elevation={0} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{fontSize: '16px'}}
                >
                  Seleccione los programas
                </AccordionSummary>
                <AccordionDetails>
                {showPrograms && (
                    <div>
                      <div className={styles.inst}>
                        {programs?.map((program) => (
                          <div className={styles.check_level} key={program.id}>
                            <MyCheckbox
                              name="programs"
                              value={program?.id.toString()}
                            >
                              {`${program?.name}`}
                            </MyCheckbox>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{borderRadius: '10px'}} elevation={0} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{fontSize: '16px'}}
                >
                  Seleccione los docentes
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                      <TextField variant="outlined" 
                        size="small" 
                        value={filterText}
                        placeholder="Buscar" fullWidth
                        onChange={(e) => setFilterText(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchRoundedIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          marginBottom: 1,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }} />
                      <div className={styles.inst}>
                        {filterData?.map((teacher) => (
                          <div className={styles.check_level} key={teacher.id}>
                            <MyCheckbox
                              name="teachers"
                              value={teacher.info.id.toString()}
                            >
                              {`${teacher?.info.first_name} ${teacher?.info.last_name}`}
                            </MyCheckbox>
                          </div>
                        ))}
                      </div>
                    </div>
                  
                </AccordionDetails>
              </Accordion>
                
                  
                  
                </Grid>
              </Grid>
              <MySubmitButton name="Crear" type="submit" />
            </Form>
          </Formik>
        </div>
      </Container>
    </div>
  );
}

export default CrearCapacitacion;
