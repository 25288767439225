import { useState, createContext, useContext, useEffect } from "react";
import { get, post } from "../utils/http";
import {
  FORUMS_ENDPOINT,
  FORUM_TOPICS_ENDPOINT,
  FORUM_MESSAGES_ENDPOINT,
} from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { AlertsContext } from "./AlertsContext";

const ForumsContext = createContext();

const ForumsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [forums, setForums] = useState([]);
  const [forumMessages, setForumMessages] = useState([]);
  const [forumTopics, setForumTopics] = useState([]);
  const [selectedForum, setSelectedForum] = useLocalStorage(
    "selectedForum",
    ""
  );
  const [selectedForumTopic, setSelectedForumTopic] = useLocalStorage(
    "selectedForumTopic",
    ""
  );
  const showSnackbar = useContext(AlertsContext);

  const navigate = useNavigate();

  const createForum = async ({ data }) => {
    const url = FORUMS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha creado el foro', 'success')
      fetchForums();
      navigate(-1);
    }
  };

  const fetchForums = async () => {
    const url = FORUMS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setForums(response.data);
    }
  };

  const createForumTopic = async ({ data }) => {
    const url = FORUM_TOPICS_ENDPOINT;
    const response = await post({ url, data });
    if(!response.error){
      showSnackbar('Se ha creado un nuevo tópico', 'success')
      return response;
    }else{
      showSnackbar('Hubo un error al crear el tópico', 'error');
    }
  };

  const fetchTopics = async () => {
    const url = FORUM_TOPICS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setForumTopics(response.data);
      return response.data;
    }
  };

  const createForumMessage = async ({ data }) => {
    const url = FORUM_MESSAGES_ENDPOINT;
    const response = await post({ url, data });
    if(!response.error){
      showSnackbar('Mensaje enviado', 'success')
    }else{
      showSnackbar('Ocurrió un error al enviar el mensaje', 'error')
    }
    return response;
  };

  const fetchSingleForum = async () => {
    const url = FORUMS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setForums(response.data);
    }
  };

  const markForumMessageRead = async ( forumId ) => {
    const url = `${FORUM_TOPICS_ENDPOINT}${forumId}/mark_read/`;
    const response = await post({ url });
    if(!response.error){
      fetchTopics()
      fetchForums()
    }
    return response;
  };

  useEffect(() => {
    //fetchForums();
    // fetchTopics();
    // fetchForumMessages();
  }, []);

  const contextData = {
    forums,
    setForums,
    selectedForum,
    setSelectedForum,
    createForum,
    fetchForums,
    setForumTopics,
    forumTopics,
    fetchTopics,
    createForumTopic,
    forumMessages,
    setForumMessages,
    // fetchForumMessages,
    createForumMessage,
    selectedForumTopic,
    setSelectedForumTopic,
    markForumMessageRead
  };

  return (
    <ForumsContext.Provider value={contextData}>
      {loading ? null : children}
    </ForumsContext.Provider>
  );
};

const useForums = () => {
  return useContext(ForumsContext);
};

export { ForumsContext, ForumsProvider, useForums };
