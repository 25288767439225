import React from 'react'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/system';

const Info = styled('p')({
    fontSize: '1.2rem',
    color: '#787878',
    textAlign: 'center'
  });
const Info2 = styled('p')({
    fontSize: '1.2rem',
    color: '#787878',
});
const Info3 = styled('p')({
    fontSize: '1.2rem',
    color: '#000',
    fontWeight:'bold'
});

function NextSessionComp({session, num}) {
    const formattedTime = (time) => {
        return time?.slice(0, -3);
    }
    const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
      };
  return (
    <Box sx={{marginTop: '10px'}}>
        <Info2>{new Date(session?.date)?.toLocaleString("es-ES", options)}</Info2>
        <Box sx={{backgroundColor: '#fff', padding: '10px 15px', 
          borderRadius: '10px', margin: '5px 0'}}>
            <Grid container direction={"row"} columnSpacing={1}>
                <Grid item md={3.5} xs={3.5} sx={{borderRight: '2px solid var(--ter-blue)', paddingRight: '5px'}}>
                    <Info>
                        {`${formattedTime(session?.start_time) || ""}`}
                    </Info>
                    <Info>
                        a
                    </Info>
                    <Info>
                    {`${formattedTime(session?.end_time) || ""}`}
                    </Info>
                </Grid>
                <Grid item md={8} xs={8.5} sx={{display:'flex', flexDirection: 'column', justifyContent:'center', gap:'5px'}}>
                    <Info3>
                        {`Sesión ${num + 1}`}
                    </Info3>
                    <Info2>
                        {session?.program_name}
                    </Info2>
                </Grid>
            </Grid>
        </Box>
    </Box>
  )
}

export default NextSessionComp