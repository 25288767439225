import React, { useState, useRef, useEffect } from "react";
import styles from "./ForumTopicView.module.css";
import Container from "../../Container";
import { dateFormatter } from "../../../utils/dateFormatter";
import { get } from "../../../utils/http";
import { FORUM_TOPICS_ENDPOINT } from "../../../utils/constants";
import ForumMessageForm from "./ForumMessageForm";

function ForumTopicView({ setShowMessages, activeTopic: topic }) {
  const [commenting, setCommenting] = useState(false);
  const [messages, setMessages] = useState([]);

  const listRef = useRef(null);

  const fetchMessages = async () => {
    const url = `${FORUM_TOPICS_ENDPOINT}${topic.id}/messages/`;
    const response = await get({ url });
    setMessages(response.data);
  };

  useEffect(() => {
    listRef.current.scrollTop = listRef.current.scrollHeight;
  }, [messages]);

  useEffect(() => {
    fetchMessages();
  }, [topic]);

  return (
    <div className={styles.topic_view}>
      <div className={styles.go_back}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => setShowMessages(false)}
        ></i>
      </div>
      <div className={styles.topic}>
        <Container>
          <div className={styles.topic_content}>
            <div className={styles.topic_left}>
              <div className={styles.left_content}>
                <i
                  className="fal fa-chevron-up"
                  style={{ fontSize: "20px", color: "#989a9e" }}
                ></i>
                <p>{`${messages.length}`}</p>
                <i
                  className="fal fa-chevron-down"
                  style={{ fontSize: "20px", color: "#989a9e" }}
                ></i>
              </div>
            </div>
            <div className={styles.topic_right}>
              <div className={styles.head}>
                <h2 style={{ textTransform: "capitalize" }}>
                  {`${topic.title}`}
                </h2>
                <div
                  className={
                    topic.status === "OPEN"
                      ? styles.status_active
                      : styles.status_closed
                  }
                ></div>
              </div>
              <p className={styles.description}>{`${topic.description}`}</p>
              <div className={styles.topic_bottom}>
                <div className={styles.mesgs_list} ref={listRef}>
                  {messages.length > 0 ? (
                    <div className={styles.msg_list}>
                      {messages.map((message) => {
                        // const sender_time = new Date(message.date).getTime();
                        let readers = [];

                        if (message?.read_by && message?.read_by != []) {
                          readers = message.read_by.reduce((readers, user) => {
                            if (readers !== "") {
                              readers += ", "; // Add a comma and a space if there are multiple names
                            }
                            readers += `${user.first_name} ${user.last_name}`;
                            return readers;
                          }, "");
                        }

                        return (
                          <div className={styles.msg_box} key={message.id}>
                            <div className={styles.header}>
                              <p className={styles.sender}>
                                {`${message?.sender?.first_name} ${message?.sender?.last_name}`}
                              </p>
                              <p className={styles.sender_date}>
                                {dateFormatter(message.date)}
                              </p>
                            </div>
                            <p className={styles.mesg}>{message.content}</p>
                            {message.attachment && (
                              <a rel="noreferrer"
                                target="_blank"
                                href={message.attachment}
                                className={styles["attachment-container"]}
                              >
                                <div className={styles.attachment}>
                                  <i className="fal fa-paperclip"></i>
                                  Descargar archivo adjunto
                                </div>
                              </a>
                            )}
                            {readers.length && (
                              <div
                                className={styles.readby}
                              >{`Leido por: ${readers}`}</div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p
                      style={{
                        marginTop: "1rem",
                        fontSize: "12px",
                        fontStyle: "italic",
                      }}
                    >
                      No hay mensajes
                    </p>
                  )}
                </div>
                {!commenting ? (
                  <div className={styles.add_comment}>
                    {topic.status === "OPEN" && (
                      <p onClick={() => setCommenting(true)}>
                        Agregar comentario
                      </p>
                    )}
                  </div>
                ) : (
                  <ForumMessageForm
                    fetchMessages={fetchMessages}
                    setCommenting={setCommenting}
                    topic={topic}
                  />
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ForumTopicView;
