import React from 'react'
import { Link } from 'react-router-dom';
import AlertTitle from '@mui/material/AlertTitle';
import { Snackbar, Alert, Stack } from '@mui/material';

function NotificationSnack({openSnackbar, handleCloseSnackbar, messageData}) {
  return (
    <Snackbar 
      open={openSnackbar} 
      autoHideDuration={7000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} icon={<i class="fas fa-comment-dots"></i>}
        sx={{ width: '100%', borderRadius: '10px', boxShadow: 5, fontSize: '12px' }} severity="info">
            <Link to="/chats">
            <AlertTitle sx={{fontWeight: 'bold', fontSize: '13px'}}>Nuevo Mensaje</AlertTitle>
            {messageData.firstName} {messageData.lastName}
            
            </Link>
        </Alert>
      </Snackbar>
  )
}

export default NotificationSnack
