import React from "react";
import Conversation from "../containers/Conversation/Conversation";
import styles from "./chatPage.module.css";
import Contacts from "../containers/Contacts/Contacts";

const ChatPage = () => {

  return (
    <div>
      <div className={styles.content}>
        <Contacts />
        <Conversation />
      </div>
    </div>
  );
};

export default ChatPage;
