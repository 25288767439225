import { useState, createContext, useContext, useEffect } from "react";
import { get, patch, post } from "../utils/http";
import useLocalStorage from "../hooks/useLocalStorage";
import {
  ANNOUNCEMENTS_ENDPOINT,
  USER_ANNOUNCEMENTS_ENDPOINT,
} from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "./CurrentUserProvider";
import { AlertsContext } from "./AlertsContext";

const AnnouncementsContext = createContext();

const AnnouncementsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [userAnnouncements, setUserAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useLocalStorage(
    "selectedAnnouncement",
    ""
  );
  const { currentUser } = useCurrentUser();
  const showSnackbar = useContext(AlertsContext);

  const navigate = useNavigate();

  const createAnnouncement = async (data) => {
    const url = ANNOUNCEMENTS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha creado el anuncio', 'success')
      fetchAnnouncements();
    }else{
      showSnackbar('Hubo un error al crear el anuncio', 'error');
    }
  };

  const fetchAnnouncements = async () => {
    const url = ANNOUNCEMENTS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setAnnouncements(response.data);
    }
  };

  const fetchUserAnnouncements = async () => {
    const url = USER_ANNOUNCEMENTS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setUserAnnouncements(response.data);
    }
  };

  const fetchSingleAnnouncement = async () => {
    const url = ANNOUNCEMENTS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setAnnouncements(response.data);
    }
  };

  const markAnnouncementAsRead = async (userAnnouncementId) => {
    const url = `${USER_ANNOUNCEMENTS_ENDPOINT}${userAnnouncementId}/`;
    const response = await patch({ url });
    if (!response.error) {
      showSnackbar('Anuncio aceptado', 'success')
      fetchUserAnnouncements();
    }else{
      showSnackbar('Hubo un error al aceptar el anuncio', 'error');
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchUserAnnouncements();
    }
  }, [currentUser]);

  const contextData = {
    announcements,
    setAnnouncements,
    userAnnouncements,
    setUserAnnouncements,
    selectedAnnouncement,
    setSelectedAnnouncement,
    createAnnouncement,
    fetchAnnouncements,
    markAnnouncementAsRead,
    fetchUserAnnouncements,
  };

  return (
    <AnnouncementsContext.Provider value={contextData}>
      {loading ? null : children}
    </AnnouncementsContext.Provider>
  );
};

const useAnnouncements = () => {
  return useContext(AnnouncementsContext);
};

export { AnnouncementsContext, AnnouncementsProvider, useAnnouncements };
