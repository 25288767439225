import React, { useState, useEffect, useContext } from "react";
import 'react-toastify/dist/ReactToastify.css';
import {
  useParams,
  useOutletContext,
} from "react-router-dom";
import { Formik, Form } from "formik";
import { get } from "../../../utils/http";
import * as Yup from "yup";
import { MyTextInput, MySubmitButton, MyTextarea } from "../../FormFields";
import Container from "../../Container";
import styles from "./CourseForum.module.css";
import Modal from "../../Modal";
import { useForums } from "../../../context/ForumContext";
import ForumTopicView from "./ForumTopicView";
import { COURSES_ENDPOINT } from "../../../utils/constants";
import { CoursesContext } from "../../../context/CoursesContext";

function ForumView() {
  const [modalOpen, setModalOpen] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [forumTopics, setForumTopics] = useState([]);
  const { courseId } = useParams();
  const [activeTopic, setActiveTopic] = useState({});
  const {fetchCourses} =useContext(CoursesContext)

  const { createForumTopic, markForumMessageRead } =
    useForums();

  const {
    forum: [forum],
  } = useOutletContext();

  const fetchCourseTopics = async () => {
    const url = `${COURSES_ENDPOINT}${courseId}/forum/`;
    const response = await get({ url });
    setForumTopics(response.data.topics);
  };

  const createHandler = async (values) => {
    const registerData = {
      title: values.title,
      description: values.description,
      forum: forum.forum.id,
    };

    const response = await createForumTopic({ data: registerData });

    if (!response.error) {
      setModalOpen(false);
      fetchCourseTopics();
    }
  };

  const handleClickTopic = (topic) => {
    setActiveTopic(topic);
    markForumMessageRead(topic.id)
    fetchCourses()
    setShowMessages(true);
  };

  useEffect(() => {
    fetchCourseTopics();
  }, [courseId]);

  forumTopics?.sort((a, b) => {
    if (a.status !== "OPEN") {
      return 1;
    } else if (b.status !== "OPEN") {
      return -1;
    } else {
      return new Date(b.last_interaction) - new Date(a.last_interaction);
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div
          className={styles.header_content}
          onClick={() => setModalOpen(true)}
        >
          <p>Nuevo tópico</p>
          <i
            className="fas fa-plus-square"
            style={{ marginLeft: "auto", fontSize: "35px", color: "var(--sec-purple)" }}
          ></i>
        </div>
      </div>
      {!showMessages ? (
        <div className={styles.topics_list}>
          {forumTopics?.map((topic) => {
            return (
              <div className={styles.topic} key={topic.id}>
                <Container>
                  <div className={styles.topic_content}>
                    <div className={styles.topic_left}>
                      <div className={styles.left_content}>
                        <i
                          className="fal fa-chevron-up"
                          style={{ fontSize: "20px", color: "#989a9e" }}
                        ></i>
                        <p>{`${topic.message_count}`}</p>
                        <i
                          className="fal fa-chevron-down"
                          style={{ fontSize: "20px", color: "#989a9e" }}
                        ></i>
                      </div>
                    </div>
                    <div className={styles.topic_right}>
                      <div className={styles.head}>
                        <h2 style={{ textTransform: "capitalize" }}>
                          {`${topic.title}`}
                        </h2>
                        {topic.unread_messages && (
                          <div
                          className={styles.unread_messages}
                        ></div>
                        )}
                        <div
                          className={
                            topic.status === "OPEN"
                              ? styles.status_active
                              : styles.status_closed
                          }
                        >{topic.status === "OPEN" ? "Activo" : "Cerrado"}</div>
                      </div>
                      <p className={styles.description}>
                        {`${topic.description}`}
                      </p>
                      <div className={styles.topic_bottom}>
                        <p
                          className={styles.button}
                          onClick={() => handleClickTopic(topic)}
                        >
                          Ver respuestas
                        </p>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            );
          })}
        </div>
      ) : (
        <ForumTopicView
          setShowMessages={setShowMessages}
          activeTopic={activeTopic}
        />
      )}
      {modalOpen && (
        <Modal title={"Nuevo tópico"} modalOpen={setModalOpen}>
          <div>
            <Formik
              initialValues={{
                title: "",
                description: "",
              }}
              validationSchema={Yup.object({
                title: Yup.string()
                  .min(1, "El campo no puede estar vacío.")
                  .required(),
                description: Yup.string().min(
                  2,
                  "Debe contener más de 2 caracteres."
                ),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                  createHandler(values);
                  setSubmitting(false);
                }, 400);
                resetForm();
              }}
            >
              <Form className={styles["form__fields"]}>
                <MyTextInput
                  label="Título"
                  name="title"
                  type="text"
                  placeholder="Título"
                  icon="fa fa-user"
                />
                <MyTextarea label="Descripción" name="description" />
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <MySubmitButton name="Crear" type="submit" />
                </div>
              </Form>
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ForumView;
