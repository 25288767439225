import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import {
  MyTextInput,
  MySubmitButton,
  MySelect,
  MyCheckbox,
} from "../../FormFields";
import { useForms } from "../../../context/FormsContext";
import { getInitialValues } from "../../../utils/getInitialValues";
import { get } from "../../../utils/http";
import {
  FORM_TEMPLATE_ENDPOINT,
  FORM_QUESTION_ENDPOINT,
  LICENSES_ENDPOINT,
} from "../../../utils/constants";
import Container from "../../Container";
import styles from "./CreateTemplate.module.css";

const INITIAL_VALUES = {
  name: "",
  form_type: "",
  start_date: new Date(),
  end_date: new Date(),
  questions: "",
};

function CreateTemplate() {
  const { createFormTemplate, updateTemplate } = useForms();
  const [licenses, setLicenses] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectedLicense, setSelectedLicense] = useState("");
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  const navigate = useNavigate();

  const { templateId } = useParams();

  useEffect(() => {
    const fetchQuestions = async () => {
      const url = FORM_QUESTION_ENDPOINT;
      const response = await get({ url });
      if (!response.error) {
        setQuestions(response.data);
      }
    };
    fetchQuestions();
  }, []);

  useEffect(() => {
    const fetchPrograms = async () => {
      const url = LICENSES_ENDPOINT;
      const response = await get({ url });

      if (!response.error) {
        setLicenses(response.data);
      }
    };
    fetchPrograms();
  }, []);

  useEffect(() => {
    if (Number.isInteger(parseInt(templateId))) {
      const fetchTemplate = async () => {
        const url = `${FORM_TEMPLATE_ENDPOINT}${templateId}/`;
        const response = await get({ url });
        const end_date = new Date(response.data.end_date);
        response.data.end_date = end_date.toISOString().slice(0, 10);
        const start_date = new Date(response.data.start_date);
        response.data.start_date = start_date.toISOString().slice(0, 10);

        if (!response.error) {
          const questions = response.data.questions.map((q) =>
            q.question.id.toString()
          );
          const definedInitialValues = getInitialValues({
            values: { ...response.data, questions },
            base: INITIAL_VALUES,
          });
          setInitialValues(definedInitialValues);
        }
      };
      fetchTemplate();
    }
  }, [templateId]);

  let filterData = questions.filter((item) =>
    item.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const createTemplateHandler = ({ values }) => {
    const questionData = {
      name: values.name,
      form_type: values.form_type,
      start_date: values.start_date,
      end_date: values.end_date,
      form_questions: values.questions,
    };
    !templateId
      ? createFormTemplate({ data: questionData })
      : updateTemplate({ templateId, data: questionData });

    navigate("/encuestas");
  };

  return (
    <Container>
      <div className={styles["create-question"]}>
        {!Number.isInteger(parseInt(templateId)) && (
          <i
            className="fal fa-arrow-left"
            style={{
              fontSize: "12px",
              cursor: "pointer",
              marginBottom: "10px",
            }}
            onClick={() => navigate(-1)}
          ></i>
        )}
        <div style={{ overflowY: "scroll" }}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object({
              name: Yup.string().required("Campo requerido"),
              form_type: Yup.string(),
              start_date: Yup.date(),
              end_date: Yup.date(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                createTemplateHandler({ values });
                setSubmitting(false);
              }, 400);
              resetForm();
            }}
          >
            <Form className={styles["form__fields"]}>
              <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
                <Grid item md={7} sm={12} xs={12} sx={{display:'flex', justifyContent:'center'}}>
                  <Stack direction={"column"} spacing={2} sx={{width: isDesktop ? '60%' : '100%'}}>
                  <MyTextInput
                    label="Nombre"
                    name="name"
                    type="text"
                    placeholder="Nombre"
                    icon="fa fa-user"
                  />
                   <MySelect label="Tipo" name="form_type">
                    <MenuItem value="">---</MenuItem>
                    <MenuItem value="Teacher">Docentes</MenuItem>
                    <MenuItem value="Employee">Empleados</MenuItem>
                  </MySelect>
                  <MyTextInput
                    label="Fecha de inicio"
                    name="start_date"
                    type="date"
                    icon="fa fa-key"
                    min={new Date()}
                  />
                  <MyTextInput
                    label="Fecha de fin"
                    name="end_date"
                    type="date"
                    icon="fa fa-key"
                  />{" "}
                  </Stack>
                </Grid>
                <Grid item md={5} sm={12} xs={12}>
                <Accordion sx={{borderRadius: '10px'}} elevation={0} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{fontSize: '16px'}}
                >
                  Seleccione las preguntas
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction={"row"} sx={{width:'100%', marginBottom: 1}} spacing={1}>
                
                  <TextField variant="outlined" 
                  size="small" 
                  placeholder="Buscar" fullWidth
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRoundedIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginBottom: 1,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }} />
                  <FormControl 
                    fullWidth 
                    variant="outlined"  size="small"
                  >
                    <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      value={selectedLicense || ""}
                      onChange={(e) =>
                        setSelectedLicense(parseInt(e.target.value))
                      }
                    >
                      <MenuItem value="">---</MenuItem>
                      {licenses.map((license) => (
                        <MenuItem value={license.id} key={license.id}>
                          {license.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Stack>
                  <div className={styles.levels}>
                    {filterData.map((question) => {
                      if (
                        question.program === selectedLicense ||
                        !selectedLicense
                      ) {
                        return (
                          <div className={styles.check_level} key={question.id}>
                            <MyCheckbox
                              name="questions"
                              value={question.id.toString()}
                            >
                              <p
                                style={{
                                  marginTop: 0,
                                  maxWidth: "400px",
                                  justifyContent: "flex-start",
                                  textAlign: "start",
                                  display: "flex",
                                  paddingLeft: "10px",
                                }}
                              >{`${question.title}`}</p>
                            </MyCheckbox>
                          </div>
                        );
                      }
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
                </Grid>
              </Grid>
              <MySubmitButton
                name={
                  Number.isInteger(parseInt(templateId))
                    ? "Actualizar"
                    : "Crear"
                }
                type="submit"
              />
            </Form>
          </Formik>
        </div>
      </div>
    </Container>
  );
}

export default CreateTemplate;
