import React, {useState, useEffect, useCallback} from 'react'
import * as Survey from "survey-react";
import "survey-react/survey.css";

export const themeJson = {
    "cssVariables": {},
    "isPanelless": false
}
  
Survey.StylesManager.applyTheme("default");
  
let defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
defaultThemeColors["$main-color"] = "var(--main-blue)";
defaultThemeColors["$main-hover-color"] = "var(--ter-blue)";

function isImageUrl(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

function AnswerQuizView({quiz, setViewQuiz, quizId, createQuizAnswer, trainingId}) {
    const [jsonQuiz, setJsonQuiz] = useState({})

    useEffect(() => {
        const json = {
            elements: [],
        };
        quiz?.forEach((question) => {
          const isImageLink = question?.question?.options?.some(opt => isImageUrl(opt.file));
          const question_type = isImageLink ? "imagepicker" : "radiogroup";
          const options = question?.question?.options?.map(opt => {
              return isImageLink ? { value: opt.file, imageLink: opt.file } : opt.file || opt.description;
          });
          if(question.question.file){
            const qst = {
              type: "html",
              html: question.question.file && `<div><img src="${question.question.file}" style="max-width:350px;height:auto;margin-bottom:10px;" /></div>`,
            };
            json?.elements?.push(qst);
          }
      
          const qst = {
            type: question_type,
            name: question.question.id.toString(),
            title: question.question.content,
            choices: options,
            isRequired: true,
            requiredErrorText: "Value cannot be empty",
          };
      
          if (isImageLink) {
            qst.choices = question?.question?.options?.map(opt => {
                return { value: opt.file, imageLink: opt.file };
            });
            qst.imageHeight = 150; 
            qst.imageWidth = 250; 
          }
      
          json?.elements?.push(qst);
        });
        setJsonQuiz(json)
    }, [quiz]);

    const survey = new Survey.Model(jsonQuiz);

    survey.completedHtml = "<p>¡Gracias por completar la encuesta!</p>";

    const alertResults = useCallback((sender) => {
        const results = sender.data;
        const answersData = {
            quiz: parseInt(quizId),
            answers: [],
        };
        Object.entries(results).map(([key, value]) => {
          quiz?.forEach((preg) => {
            if(preg.question.id === parseInt(key)){
              preg.question.options.forEach((opt) => {
                if(value === opt.description || value === opt.file){
                  const data = {
                      question: key,
                      answer_option: opt.id,
                  };
                  answersData?.answers?.push(data);
                }
              })
            }
          })
        });
        createQuizAnswer({ data: answersData, trainingId }).then((response) => {
              if (!response.error) {
                setViewQuiz(false)
              } else {
              }
          });
      }, []);
      survey.onComplete.add(alertResults);
  
  return (
    <Survey.Survey model={survey} />
  )
}

export default AnswerQuizView
