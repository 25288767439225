import React from 'react'
import { Page, View, Text, Document, 
  StyleSheet, 
  PDFViewer, 
  Image, 
  Font  } from '@react-pdf/renderer';
import { jsPDF } from 'jspdf';
import SohoGothicPro from '../../../fonts/SohoGothic/SohoGothicPro-Bold.otf'
import OpenSans from '../../../fonts/OpenSans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: OpenSans });

Font.register({ family: 'Soho Gothic', src: SohoGothicPro });

const customStyles = StyleSheet.create({
  page: {
  },
  container: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 10
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    margin: 15
  },
  section2: {
    flexGrow: 1,
    margin: 15
  },
  title: {
    fontFamily: 'Soho Gothic',
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#330072',
    marginTop: 12
  },
  topic: {
    fontFamily: 'Open Sans',
    color: '#330072',
    fontSize: '14px'
  },
  container2: {
    marginTop: 110,
    display:'flex',
    justifyContent: 'center',
    width: '100%',

  },
  box: {
    border: '1px solid #000',
    borderRadius: '5px',
    marginTop: 15,
    marginRight: 30,
    marginLeft: 30,
    marginBottom: 10
  },
  box2: {
    border: '1px solid #000',
    borderRadius: '5px',
    marginRight: 30,
    marginLeft: 30,
    marginTop: 40
  },
  box3: {
    border: '1px solid #000',
    borderRadius: '5px',
    marginRight: 30,
    marginLeft: 30,
    marginTop: 5
  },
  boxTitle: {
    backgroundColor:'#330072',
    color: '#fff',
    width: '100%',
    fontSize: '12px',
    fontFamily: 'Open Sans',
    padding: '6px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  boxTitle2: {
    backgroundColor:'#330072',
    color: '#fff',
    width: '100%',
    fontSize: '12px',
    fontFamily: 'Open Sans',
    padding: '6px',
    marginTop: '3px'
  },
  boxText: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    padding: '5px',
    width: '85%'
  },
  boxText2: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    padding: '10px',
  },
  answerBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  answ: {
    width: '15%',
    textAlign: 'center',
    fontSize: '12px',
    fontFamily: 'Open Sans',

  }
})

const splitTextIntoBlocks = (text, maxLength) => {
  const words = text.split(' ');
  const blocks = [];
  let currentBlock = '';

  words.forEach(word => {
    if ((currentBlock + word).length > maxLength) {
      blocks.push(currentBlock.trim());
      currentBlock = '';
    }
    currentBlock += `${word} `;
  });

  if (currentBlock.length) {
    blocks.push(currentBlock.trim());
  }

  return blocks;
};

function ReportePDF({institution, teachersFeedback}) {
  

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
      {teachersFeedback?.map((feedback) => {
        const { comentariosGenerales, ...otherCategories } = feedback.formData;
        let isFirstCategory = true;
        // const uniqueStages = [...new Set(feedback?.teacherInfo?.stages)];
        const stagesList = feedback?.teacherInfo?.stages;
        const textComments = splitTextIntoBlocks(comentariosGenerales, 1500)
        return(
          <>
        <Page style={customStyles.page}>
        <View style={customStyles.container}>
        <View style={customStyles.contentContainer}>
          <View style={customStyles.section}>
            <Text 
              style={customStyles.topic}>
                {`${institution?.name}`}
            </Text>
            <Text 
              style={customStyles.topic}>
                {`Docente: ${feedback?.teacherInfo?.firstName} ${feedback?.teacherInfo?.lastName}`}
            </Text>
            {feedback?.teacherInfo?.stages?.length > 0 &&
            <Text 
              style={customStyles.topic}>
                {`Etapas: ${stagesList}`}
            </Text>}

            <Text 
              style={customStyles.topic}>
                {`Elaborado por: ${feedback?.teacherInfo?.elaborado}`}
            </Text>
          </View>
        <View style={customStyles.section2}>
          <div 
          style={{width: '100%', display: 'flex',
          justifyContent: 'flex-end', alignItems: 'flex-end',}}>
          <Image
            style={{
              width: '100px',
            }}
            src="/LogoKurios.webp"
            alt="Logo"
          />
          {/*<Image
            style={{
              width: '50px',
            }}
            src={institution?.logo}
            alt="Logo"
          />*/}
          </div>
        </View>
      </View>
      <View style={customStyles.container2}>
        <Text style={customStyles.title}>
          Reporte Trimestral
        </Text>
      </View>
      <View style={customStyles.box}>
        {Object.entries(otherCategories)?.map(([category, answers], index) => {
          const categoryStyle = isFirstCategory ? customStyles.boxTitle : customStyles.boxTitle2
          isFirstCategory = false;
        return(
        <>
          <Text style={categoryStyle}>
          {category}
          </Text>
          {Object.entries(answers)?.map(([question, response]) => (
            <View style={customStyles.answerBox}>
            <Text style={customStyles.boxText}>
              {`${question}`}
            </Text>
            <Text style={customStyles.answ}>
              {`${response}`}
            </Text>
            </View>
          ))}
        </>
        )})}
      </View>
      {textComments?.length === 1 &&
            <View style={customStyles.box3}>
              <Text style={customStyles.boxTitle}>
                Comentarios Generales
              </Text>
              <Text style={customStyles.boxText2}>
                {comentariosGenerales}
              </Text>
            </View>
        }
      </View>
        </Page>
        {textComments?.length > 1 &&
          <Page>
            <View style={customStyles.box2}>
              <Text style={customStyles.boxTitle}>
                Comentarios Generales
              </Text>
              <Text style={customStyles.boxText2}>
                {comentariosGenerales}
              </Text>
            </View>
          </Page>
        }
        </>)})}
    </Document>
  </PDFViewer>
  )
}

export default ReportePDF
