import React from 'react'
import { useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import EventViewList from './EventViewList';
import IncidencesViewList from './IndicencesViewList';
import RequestViewList from './RequestViewList';
import Container from '../Container';
import MyEventViewList from './Admin/MyEventList';
import InstitutionsEventViewList from './Admin/InstitutionEventList';

function EventsMain() {
    const [value, setValue] = React.useState('1');
    const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container>
      <i
      className="fal fa-arrow-left"
      style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
      onClick={() => navigate(-1)}
      ></i>
        <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs onChange={handleChange} aria-label="visible arrows tabs example" variant="scrollable"
         value={value} >
            <Tab label="Mis Eventos" value="1" sx={{fontSize: '12px'}}/>
            <Tab label="Eventos por Instituciones" value="2"  sx={{fontSize: '12px'}}/>
            {/* <Tab label="Incidencias" value="3" sx={{fontSize: '12px'}}/> */}
            {/* <Tab label="Todos los eventos" value="4" sx={{fontSize: '12px'}}/> */}
          </Tabs>
        </Box>
        <TabPanel value="1" sx={{borderRadius: '10px', padding: "10px 2px"}}>
            <MyEventViewList />
        </TabPanel>
        <TabPanel value="2" sx={{borderRadius: '10px', padding: "10px 2px"}}>
            <InstitutionsEventViewList />
        </TabPanel>
        {/* <TabPanel value="3" sx={{borderRadius: '10px', padding: "10px 2px"}}>
            <IncidencesViewList />
        </TabPanel> */}
        {/* <TabPanel value="4" sx={{borderRadius: '10px', padding: "10px 2px"}}>
            <AllViewList />
        </TabPanel> */}
        
    </TabContext>
    </div>
    </Container>
  )
}

export default EventsMain
