import React, { useState, useContext, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputLabel from '@mui/material/InputLabel';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { MyTextInput, MySubmitButton, MySelect } from "../FormFields";
import styles from "./CreateProgram.module.css";
import { ProgramsContext } from "../../context/ProgramsContext";
import { getInitialValues } from "../../utils/getInitialValues";
import { get } from "../../utils/http";
import { PROGRAMS_ENDPOINT,  
  LESSONS_ENDPOINT, 
  LICENSES_ENDPOINT } from "../../utils/constants";

const INITIAL_VALUES = {
  name: "",
  licenss: "",
  distributor: "",
  course_outline: "",
  planification_term1: "",
  planification_term2: "",
  planification_term3: "",
};

function CreateProgram() {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const { createProgram, updateProgram } = useContext(ProgramsContext);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [lessons, setLessons] = useState([]);
  const [licens, setLicens] = useState([]);
  const [addLessons, setAddLessons] = useState(false)
  const [lessonsProgram, setLessonsProgram] = useState([]);
  const [lessonInfo, setLessonInfo] = useState({ term: 0, id: '', lesson_number: 0 })
  const { programId } = useParams();

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  useEffect(() => {
    if (programId) {
      const fetchProgram = async () => {
        const url = `${PROGRAMS_ENDPOINT}${programId}/`;
        const response = await get({ url });

        if (!response.error) {
          const definedInitialValues = getInitialValues({
            values: response.data,
            base: INITIAL_VALUES,
          });
          setInitialValues(definedInitialValues);
        }
      };
      fetchProgram();
    }
  }, [programId]);

  useEffect(() => {
    const fetchLessons = async () => {
      const url = LESSONS_ENDPOINT;
      const response = await get({ url });
  
      if (!response.error) {
        setLessons(response.data);
      }
    };
    fetchLessons();
}, []);

  useEffect(() => {
    const fetchLessons = async () => {
      const url = LICENSES_ENDPOINT;
      const response = await get({ url });

      if (!response.error) {
        setLicens(response.data);
      }
    };
    fetchLessons();
  }, []);

  const deleteLesson = (index) => {
    const newLesson = [...lessonsProgram];
    newLesson.splice(index, 1);
    setLessonsProgram(newLesson);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLessonInfo((prevOpcion) => ({ ...prevOpcion, [name]: value }));
  };
  const handleSelectChange = (e) => {
    setLessonInfo((prevOpcion) => ({ ...prevOpcion, term: e.target.value }));
};
  
  const addLesson = () => {
    if (lessonInfo?.id !== '' && lessonInfo?.term !== 0
    && lessonInfo?.lesson_number !== 0 ) {
      setLessonsProgram([...lessonsProgram, lessonInfo]);
      setLessonInfo({ term: 0, id: '', lesson_number: 0 });
      setFilterText("")
    }
  };

  const submitHandler = async (values) => {
    const newData = {
      lessons: lessonsProgram,
      ...values,
    };

    !programId ? createProgram(newData) : updateProgram({ programId, values });

    navigate("/programs");
  };

  let filterData = lessons.filter((item) =>
    item?.licens?.name?.toLowerCase().includes(filterText?.toLowerCase())
  );

  return (
    <div className={styles["create-program"]}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      ></i>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string()
            .min(1, "El campo no puede estar vacío.")
            .required("Campo requerido"),
            licenss: Yup.string()
            .min(1, "El campo no puede estar vacío.")
            .required("Campo requerido"),
          course_outline: Yup.string()
            .matches(/((https?):\/\/)?(www.?)?[a-z0-9]?$/, "Ingrese un url!")
            .required("Campo requerido"),
          planification_term1: Yup.string()
            .matches(/((https?):\/\/)?(www.?)?[a-z0-9]?$/, "Ingrese un url!")
            .required("Campo requerido"),
          planification_term2: Yup.string()
            .matches(/((https?):\/\/)?(www.)?[a-z0-9]?$/, "Ingrese un url!")
            .required("Campo requerido"),
          planification_term3: Yup.string()
            .matches(/((https?):\/\/)?(www.)?[a-z0-9]?$/, "Ingrese un url!")
            .required("Campo requerido"),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            submitHandler(values);
            setSubmitting(false);
          }, 400);
          resetForm();
        }}
      >
        <Form className={styles["form__fields"]}>
          <Grid container direction={"row"} 
          rowSpacing={2} columnSpacing={2} sx={{width: isDesktop ? '50%' : '100%'}}>
            <Grid item md={6} sm={12} xs={12}>
              <MyTextInput
                label="Nombre"
                name="name"
                type="text"
                icon="fa fa-user"
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <MySelect label="Licencia" name="licenss">
                {licens?.map((lic) => (
                  <MenuItem value={lic.id} key={lic.id}>
                   {lic.name}
                  </MenuItem>
                ))}
              </MySelect>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <MyTextInput
                label="Distribuidor"
                name="distributor"
                type="text"
                icon="fa fa-key"
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <MyTextInput
                label="Tabla de contenido"
                name="course_outline"
                type="text"
                icon="fa fa-user"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Button variant="outlined" onClick={() => setAddLessons(!addLessons)}
              endIcon={addLessons ? <RemoveCircleOutlineIcon/> : <AddCircleOutlineIcon/>}
              size="large" sx={{borderRadius: '10px'}}>
                Agregar Clases
              </Button>
            </Grid>
            {addLessons && 
            <>
            <Grid item xs={12}>
              {lessonsProgram?.map((lesson, index) => (
                  <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <p style={{color: '#000', fontSize: '1.5rem'}}>
                    {lesson.term} - {lesson.id} - Número: {lesson.lesson_number}
                    </p>
                    <IconButton onClick={()=>deleteLesson(index)} size="small"
                    sx={{height: 'fit-content', marginTop: 3, marginLeft: .7}}>
                      <RemoveCircleOutlineRoundedIcon fontSize="large"
                      sx={{color: '#cf142b', fontSize: '22px'}} />
                    </IconButton>
                  </div>
              ))}
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <FormControl  fullWidth 
                variant="outlined"  size="small"
                sx={{"& .MuiInputBase-root": {
                fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                },
                "& .MuiMenuItem-root": {
                  fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                },}}
              >
                <InputLabel id="demo-simple-select-label">Lapso</InputLabel>
                <Select 
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lessonInfo?.term}
                onChange={handleSelectChange} 
                size="small"
                label="Lapso"
                fullWidth
                sx={{
                  marginTop: '3px',
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                  
                }}>
                  <MenuItem value={1}>
                    1er Lapso
                  </MenuItem>
                  <MenuItem value={2}>
                    2do Lapso
                  </MenuItem>
                  <MenuItem value={3}>
                    3er Lapso
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
              <FormControl  fullWidth 
                variant="outlined"  size="small"
                sx={{"& .MuiInputBase-root": {
                fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                },
                "& .MuiMenuItem-root": {
                  fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                },}}
              >
                <InputLabel id="demo-simple-select-label1">Licencia</InputLabel>
                <Select 
                labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                size="small"
                label="Licencia"
                name="licens"
                fullWidth
                sx={{
                  marginTop: '3px',
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                  
                }}>
                  {licens?.map((lic) => (
                    <MenuItem value={lic.name} key={lic.id}>
                     {lic.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl  fullWidth 
                variant="outlined"  size="small"
                sx={{"& .MuiInputBase-root": {
                fontSize: "14px", 
                },
                "& .MuiMenuItem-root": {
                  fontSize: "14px", 
                },
                marginTop: '10px'}}
              >
                <InputLabel id="demo-simple-select-label1">Clase</InputLabel>
                <Select 
                labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                value={lessonInfo?.lesson}
                    onChange={handleInputChange}
                size="small"
                label="Clase"
                name="lesson_number"
                fullWidth
                sx={{
                  marginTop: '3px',
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                }}>
                  {filterData?.map((lesson) => (
                    <MenuItem value={lesson.id} key={lesson.id}>
                     {lesson.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </div>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <TextField 
              variant="outlined" size="small"
              fullWidth
              type="number"
              placeholder="0"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
                "& .MuiInputBase-root": {
                fontSize: "16px", 
              },
              "& .MuiInputLabel-root": {
                fontSize: "16px", 
              },
              "& .MuiFormHelperText-root": {
                fontSize: "14px",
              },
              }}
              />
            </Grid>
            <Grid item xs={12}>
              <IconButton onClick={addLesson} size="small"
              sx={{marginTop: .2}}>
                <AddCircleOutlineRoundedIcon fontSize="large"
                sx={{color: '#5FDBA7', fontSize: '22px'}} />
              </IconButton>
            </Grid>
            </>
              }
          </Grid>
          <div className={styles.inputs_left}>
            <MySubmitButton
              name={"Regresar"}
              type="button"
              onClick={() => {
                navigate("/programs");
              }}
            />
            <MySubmitButton
              name={programId ? "Actualizar" : "Crear"}
              type="submit"
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default CreateProgram;
