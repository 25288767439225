import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Stack, Button, TextField } from '@mui/material';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/system';
import AttendanceList from './AttendanceList';

const CardText = styled('p')({
    fontSize: '1.2rem',
    color: 'var(--ter-blue)',
    cursor:'pointer'
});
const Info = styled('p')({
    fontSize: '1.2rem',
    color: '#787878',
});
const Text = styled('p')({
    fontSize: '1.2rem',
    paddingTop: '5px'
});

const EditingSession = ({session, startDate, handleSetEdit, handleEditSession}) => {
    const initialValues = {
        location: session.location || "",
        meeting_id: session.meeting_id || "",
        passcode: session.passcode || "",
        url_zoom: session.url_zoom || "",
        recording_url: session.recording_url || "",
        map_url: session?.map_url || "",
        classroom: session?.classroom || ""
    };
    const formattedTime = (time) => {
        return time.slice(0, -3);
    }
    return(
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object({
                location: Yup.string(),
                meeting_id: Yup.string(),
                passcode: Yup.string(),
                url_zoom: Yup.string(),
                recording_url: Yup.string(),
                map_url: Yup.string(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                    handleEditSession(session.id, values);
                    setSubmitting(false);
                }, 400);
                resetForm();
            }}
        >
            {({ handleChange, values }) => (
                <Form>
                    <Grid container direction={"row"} rowGap={1} columnGap={1}>
                        <Grid item md={2.3} xs={12}
                            sx={{ backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px' }}>
                            <Grid
                                container
                                direction={"row"}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    height: '100%'
                                }}>
                                <Grid
                                    item
                                    xs={8}
                                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                                    <Stack direction={"column"}>
                                        <Info>Fecha y Hora</Info>
                                        <CardText>
                                            {startDate}
                                        </CardText>
                                        <CardText>
                                            {`${formattedTime(session?.start_time)} - ${formattedTime(session?.end_time)}`}
                                        </CardText>
                                    </Stack>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <i class="fas fa-calendar-alt" style={{
                                        backgroundColor: 'var(--ter-blue)',
                                        color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'
                                    }}></i>
                                </Grid>
                            </Grid>
                        </Grid>
                        {session?.instructor?.length > 0 &&
                            <Grid item md={2.3} xs={5.85}
                                sx={{ backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px' }}>
                                <Grid
                                    container
                                    direction={"row"}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        height: '100%'
                                    }}>
                                    <Grid
                                        item
                                        xs={8}
                                        sx={{ display: "flex", justifyContent: "flex-start" }}>
                                        <Stack direction={"column"}>
                                            <Info>Instructor</Info>
                                            <Stack direction={"column"}>
                                                {session?.instructor?.map((instructor, index) => {
                                                    return (
                                                        <CardText key={index}>
                                                            {`${instructor?.first_name} ${instructor?.last_name}`}
                                                        </CardText>
                                                    );
                                                })}
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                        <i class="fas fa-chalkboard-teacher" style={{
                                            backgroundColor: 'var(--ter-blue)',
                                            color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'
                                        }}></i>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item md={2.3} xs={5.85}
                            sx={{ backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px' }}>
                            <Grid
                                container
                                direction={"row"}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    height: '100%'
                                }}>
                                <Grid
                                    item
                                    xs={8}
                                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                                    <Stack direction={"column"}>
                                        <Info>Ubicación</Info>
                                        <Field
                                            name="location"
                                            as={TextField}
                                            label="Ubicación"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            onChange={handleChange}
                                            value={values.location}
                                            sx={{borderRadius: '10px', marginTop: '5px',
                                                '& .MuiOutlinedInput-root': { borderRadius: '10px' }}}
                                        />
                                        <Field
                                            name="map_url"
                                            as={TextField}
                                            label="Link Mapa"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            onChange={handleChange}
                                            value={values.map_url}
                                            sx={{borderRadius: '10px', marginTop: '10px',
                                            '& .MuiOutlinedInput-root': { borderRadius: '10px' }}}
                                        />
                                        <Field
                                            name="classroom"
                                            as={TextField}
                                            label="Salón"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            onChange={handleChange}
                                            value={values.map_url}
                                            sx={{borderRadius: '10px', marginTop: '10px',
                                            '& .MuiOutlinedInput-root': { borderRadius: '10px' }}}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Link to={session?.map_url} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>
                                        <i class="fas fa-map-marker-alt" style={{
                                            backgroundColor: 'var(--ter-blue)',
                                            color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'
                                        }}></i>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={2.3} xs={5.85}
                            sx={{ backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px' }}>
                            <Grid
                                container
                                direction={"row"}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    height: '100%'
                                }}>
                                <Grid
                                    item
                                    xs={8}
                                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                                    <Stack direction={"column"}>
                                        <Info>Zoom Info</Info>
                                        <Field
                                            name="meeting_id"
                                            as={TextField}
                                            label="Meeting ID"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            onChange={handleChange}
                                            value={values.meeting_id}
                                            sx={{borderRadius: '10px', marginTop: '5px',
                                                '& .MuiOutlinedInput-root': { borderRadius: '10px' }}}
                                        />
                                        <Field
                                            name="passcode"
                                            as={TextField}
                                            label="Meeting Passcode"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            onChange={handleChange}
                                            value={values.passcode}
                                            sx={{borderRadius: '10px', marginTop: '10px',
                                                '& .MuiOutlinedInput-root': { borderRadius: '10px' }}}
                                        />
                                        <Field
                                            name="url_zoom"
                                            as={TextField}
                                            label="Zoom URL"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            onChange={handleChange}
                                            value={values.url_zoom}
                                            sx={{borderRadius: '10px', marginTop: '10px',
                                                '& .MuiOutlinedInput-root': { borderRadius: '10px' }}}
                                        />
                                        <Info>Grabación</Info>
                                        <Field
                                            name="recording_url"
                                            as={TextField}
                                            label="URL Grabación"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            onChange={handleChange}
                                            value={values.recording_url}
                                            sx={{borderRadius: '10px', marginTop: '5px',
                                                '& .MuiOutlinedInput-root': { borderRadius: '10px' }}}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Link to={session?.url_zoom} style={{ textDecoration: 'none' }} rel="noreferrer" target='_blank'>
                                        <i class="fas fa-video" style={{
                                            backgroundColor: 'var(--ter-blue)',
                                            color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'
                                        }}></i>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', gap: '10px', width: '100%', justifyContent: 'center', marginTop: '10px' }}>
                        <Button variant='outlined' size='large' sx={{ borderRadius: '10px' }} onClick={handleSetEdit}>
                            Cancelar
                        </Button>
                        <Button type="submit" variant="contained" size="large" sx={{ borderRadius: '10px' }}>
                            Aceptar
                        </Button>
                        
                    </Box>
                </Form>
            )}
        </Formik>
    )
}

function SessionsAdminView({sessions, isEditing, handleEditSession, handleSetEdit}) {
    const [value, setValue] = React.useState(0);
    const [viewAttendance, setViewAttendance] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const formattedTime = (time) => {
    return time.slice(0, -3);
  }

  const onOpenAttendance = () => {
    setViewAttendance(!viewAttendance)
  }

  return (
    <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
        borderRadius: '10px'}}>
    <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs onChange={handleChange} 
          aria-label="visible arrows tabs example" variant="scrollable"
         value={value}>
            {sessions?.map((session, index) => {
                return(
                    <Tab disabled={isEditing} 
                    label={`${session?.name} - ${session?.session_code}`} 
                    value={index} 
                    sx={{fontSize: '12px'}}/>
                )
            })}
          </Tabs>
        </Box>
        {sessions?.map((session, index) => {
            const startDate = new Date(session?.date).toLocaleString("es-ES", options);
            return(
                <TabPanel value={index} 
                sx={{borderRadius: '10px', padding: "10px 2px"}}>
                {!isEditing ? <Grid container direction={"row"} rowGap={1} columnGap={1}>
                    <Grid item md={2.3} xs={12}
                    sx={{backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px'}}>
                        <Grid
                        container
                        direction={"row"}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height:'100%'
                        }}>
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Stack direction={"column"}>
                                    <Info>Fecha y Hora</Info>
                                    <CardText>
                                        {startDate}
                                    </CardText>
                                    <CardText>
                                        {`${formattedTime(session?.start_time)} - ${formattedTime(session?.end_time)}`}
                                    </CardText>
                                </Stack>
                            </Grid>
                            <Grid item sx={{display:'flex', alignItems:'center'}}>
                                <i class="fas fa-calendar-alt" style={{backgroundColor: 'var(--ter-blue)', 
                              color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                              
                            </Grid>
                        </Grid>
                    </Grid>
                    {session?.instructor?.length > 0 && 
                    <Grid item md={2.3} xs={5.85}
                    sx={{backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px'}}>
                        <Grid
                        container
                        direction={"row"}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height:'100%'
                        }}>
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Stack direction={"column"}>
                                <Info>Instructor</Info>
                                <Stack direction={"column"}>
                                    {session?.instructor?.map((instructor) => {
                                        return(
                                            <CardText>
                                                {`${instructor?.first_name} ${instructor?.last_name}`}
                                            </CardText>
                                        )
                                    })}
                                </Stack>
                                </Stack>
                            </Grid>
                            <Grid item sx={{display:'flex', alignItems:'center'}}>
                                <i class="fas fa-chalkboard-teacher" 
                                style={{backgroundColor: 'var(--ter-blue)', 
                                color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                              
                            </Grid>
                        </Grid>
                    </Grid>}
                    {session?.location && 
                    <Grid item md={2.3} xs={5.85}
                    sx={{backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px'}}>
                        <Grid
                        container
                        direction={"row"}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height:'100%'
                        }}>
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Stack direction={"column"}>
                                    <Info>Ubicación</Info>
                                    <Link to={session?.map_url} target='_blank' rel="noreferrer" style={{textDecoration:'none'}}>
                                        <CardText>
                                            {session?.location}
                                        </CardText>
                                    </Link>
                                    <CardText>
                                        {session?.classroom}
                                    </CardText>
                                </Stack>
                            </Grid>
                            <Grid item sx={{display:'flex', alignItems:'center'}}>
                                <Link to={session?.map_url} target='_blank' rel="noreferrer" style={{textDecoration:'none'}}>
                                    <i class="fas fa-map-marked-alt" style={{backgroundColor: 'var(--ter-blue)', 
                                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>}
                    {(session?.url_zoom || session?.recording_url) &&
                    <Grid item md={2.3} xs={5.85}
                    sx={{backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px'}}>
                        <Grid
                        container
                        direction={"row"}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height:'100%'
                        }}>
                            {!session?.recording_url ? 
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Stack direction={"column"}>
                                <Info>Zoom Info</Info>
                                {session?.meeting_id &&
                                    <div style={{display:'flex'}}>
                                        <Info>ID: </Info> <CardText>{session?.meeting_id}</CardText>
                                    </div>
                                }
                                {session?.passcode &&
                                    <div style={{display:'flex'}}>
                                        <Info>Clave: </Info> <CardText>{session?.passcode}</CardText>
                                    </div>
                                }
                                <Link to={session?.url_zoom} 
                                style={{textDecoration:'none'}}>
                                    <Button variant='outlined' size='small'
                                    sx={{borderRadius: '10px', marginTop: '5px'}}
                                    endIcon={<ArrowForwardIcon />}>
                                        {`Ingresar`}
                                    </Button>
                                </Link>
                                </Stack>
                            </Grid> : 
                            <Grid
                            item
                            xs={8}
                            sx={{ display: "flex", justifyContent: "flex-start" }}>
                              <Stack direction={"column"}>
                              <Info>Grabación</Info>
                              {session?.recording_url &&
                                    <Link to={session?.recording_url} style={{textDecoration:'none'}}>
                                        <Button variant='outlined' size='small'
                                    sx={{borderRadius: '10px', marginTop: '5px'}}
                                    endIcon={<ArrowForwardIcon />}>
                                            {`Ver Sesión`}
                                        </Button>
                                    </Link>
                              }
                              </Stack>
                          </Grid>}
                            <Grid item sx={{display:'flex', alignItems:'center'}}>
                                <Link to={session?.url_zoom} style={{textDecoration:'none'}} rel="noreferrer" target='_blank'>
                                    <i class="fas fa-video" style={{backgroundColor: 'var(--ter-blue)', 
                                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i> 
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>}
                    <Grid item md={2.3} xs={5.85} onClick={onOpenAttendance}
                    sx={{backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px', 
                    border: viewAttendance && '1px solid var(--ter-blue)', 
                    boxShadow: viewAttendance && 3, cursor: 'pointer', '&:hover': {
                    backgroundColor: "#E7E7E7",
                    opacity: 1,
                        }}}>
                        <Grid
                        container
                        direction={"row"}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height:'100%'
                        }}>
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Stack direction={"column"}>
                                <Info>Asistencias</Info>
                                <CardText>
                                    Ver Docentes y Asistencia
                                </CardText>
                                </Stack>
                            </Grid>
                            <Grid item sx={{display:'flex', alignItems:'center'}}>
                                <i class="fas fa-clipboard" style={{backgroundColor: 'var(--ter-blue)', 
                                color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                            </Grid>
                        </Grid>
                    </Grid>                
                </Grid> : 
                <EditingSession session={session} handleEditSession={handleEditSession}
                handleSetEdit={handleSetEdit}
                startDate={startDate} />}
                {viewAttendance ? 
                <AttendanceList
                teachers={session?.teacher} sessionId={session?.id} /> : 
                <>
                    {session.description &&
                    <Box sx={{backgroundColor: '#fff', padding: '10px 15px', 
                        borderRadius: '10px', marginTop: '15px'}}>
                        <Info>Descripción</Info>
                        <Text>{session?.description}</Text>
                    </Box>}
                    {session.materials &&
                    <Box sx={{backgroundColor: '#fff', padding: '10px 15px', 
                        borderRadius: '10px', marginTop: '15px'}}>
                        <Info>Material Complementario</Info>
                    </Box>}
                </>
                }
                </TabPanel>
                )
            })}
    </TabContext>
    </Box>
  )
}

export default SessionsAdminView
