import React, { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import {
  MyTextInput,
  MySubmitButton,
  MyTextarea,
  MySelect,
} from "../../../FormFields";
import Container from "../../../Container";
import {
  INSTITUTIONS_ENDPOINT,
  INSTITUTIONS_LIST_ENDPOINT,
  TEMPLATE_FORM_ENDPOINT
} from "../../../../utils/constants";
import { get } from "../../../../utils/http";
import { MonitorFormsContext } from "../../../../context/MonitorFormsContext";
import styles from "./Planillas.module.css";
import LoadingComponent from "../../../LoadingComponent";
import { useLoading } from "../../../../context/LoadingContext";
import FillQuestions from "./FillQuestions";
import LoadPictures from "../../../MonitoringPictures/LoadPictures";

function Planillas() {
  const [institutions, setInstitutions] = useState([]);
  const { sendMonitorForms } = useContext(MonitorFormsContext);
  const [teachers, setTeachers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedInstitutionId, setSelectedInstitutionId] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedTeacher2, setSelectedTeacher2] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [userResponses, setUserResponses] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState('')
  const [openLoadPicturesModal, setOpenLoadPicturesModal] = useState(false)
  const [monitorinfFormId, setMonitoringFormId] = useState(null)
  const { showLoading, hideLoading, isLoading } = useLoading();

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  const navigate = useNavigate();

  //Get Institutions list
  useEffect(() => {
    const fetchInstitutions = async () => {
      showLoading();
      const url = INSTITUTIONS_LIST_ENDPOINT;
      const response = await get({ url });
      if (!response.error) {
        setInstitutions(response.data);
        hideLoading();
      } else if (response.error === 401) {
        hideLoading();
      }
    };
    fetchInstitutions();
  }, []);

  // GET templates forms 
  useEffect(() => {
    const fetchTemplates = async () => {
      showLoading();
      const url = TEMPLATE_FORM_ENDPOINT;
      const response = await get({ url });
      if (!response.error) {
        setTemplates(response.data);
        hideLoading();
      } else if (response.error === 401) {
        hideLoading();
      }
    };
    fetchTemplates();
  }, []);

  //Get Cursos de una institucion 
  const fetchCourses = async (institutionId) => {showLoading();
    try {
      const url = `${INSTITUTIONS_ENDPOINT}${institutionId}/coursesforspreadsheet/`;
      const response = await get({ url });
      setFilteredCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    } finally {
    }
  };  

  //Filtrar las preguntas correspondientes dependiendo de la planilla seleccionada
  useEffect(() => {
    if (selectedTemplate) {
      const filterQuestions = templates?.filter(
        (item) => item.id === parseInt(selectedTemplate)
      );
      const questionsF = filterQuestions[0].questions;
      setQuestions(questionsF);
    }
  }, [selectedTemplate, templates]);

  //GET docentes de una institucion 
  const fetchTeachers = async (institutionId) => {
    const url = `${INSTITUTIONS_ENDPOINT}${institutionId}/teachers/`;
    const response = await get({ url });
    if(!response.error){
      setTeachers(response.data);
    }
  };

  const handleTeacherChange = (e) => {
    showLoading();
    teachers?.forEach((teacher) => {
      if (teacher.id === parseInt(e.target.value)) {
        setSelectedTeacher(teacher);
      }
    });
    hideLoading();
  };

  const handleTeacherChange2 = (e) => {
    showLoading();
    teachers?.forEach((teacher) => {
      if (teacher.id === parseInt(e.target.value)) {
        setSelectedTeacher2(teacher);
      }
    });
    hideLoading();
  };

  const sortedInstitutions =
    institutions &&
    institutions?.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  const sortedQuestions =
    questions &&
    questions?.sort((a, b) => {
      return a.area.localeCompare(b.area);
    });

  const groupedData = sortedQuestions && sortedQuestions?.reduce((acc, item) => {
      if (!acc[item.area]) {
        acc[item.area] = [];
      }
      acc[item.area].push(item);
      return acc;
    }, {});

  // Seleccionar la institucion y llamar al get de los cursos y teachers para esa insitucion 
  const handleInstitution = async(e) => {
    showLoading();
    const instId = parseInt(e.target.value);
    setSelectedInstitutionId(instId);
    // para que espere la respuesta de fetchCourses y fetchTeachers
    try {
      await Promise.all([fetchCourses(instId), fetchTeachers(instId)]);
    } catch (error) {
      console.error('Error fetching data:', error);
      hideLoading();
    } finally {
      hideLoading();
    }
  };
  
  const handleTeachers = (e) => {
    showLoading();
    const course = filteredCourses?.filter(
      (item) => item.id === parseInt(e.target.value)
    );
    setSelectedCourse(course[0]);
    hideLoading();
  };

  const handleClosedAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const handleSendResponse = async (values) => {
    showLoading();
    const missingFields = [];

    if (!values.course_section) {
      missingFields.push("Sección del curso");
    }

    if (!selectedTeacher) {
      missingFields.push("Docente seleccionado");
    }

    if (!values.feedback) {
      missingFields.push("Feedback");
    }

    if (!values.comments) {
      missingFields.push("Comentarios");
    }

    if (!selectedClass) {
      missingFields.push("Clase seleccionada");
    }

    if (!values.estimated_grade || values.estimated_grade <= 0) {
      missingFields.push("Nota estimada válida");
    }

    if (!values.route || values.route <= 0) {
      missingFields.push("Ruta válida");
    }

    if (!selectedTemplate) {
      missingFields.push("Planilla seleccionada");
    }

    if (selectedTeacher2 && !values.feedback2) {
      missingFields.push("Feedback del segundo docente");
    }

    if (missingFields.length > 0) {
      const alertMessage = `Faltan los siguientes campos por llenar: ${missingFields.join(", ")}`;
      setAlertMessage(alertMessage);
      setOpenAlertDialog(true);
      hideLoading();
      return { success: false };  // Detener el envío
    }

    // Verificar si la cantidad de respuestas coincide con la cantidad de preguntas
    const totalQuestions = questions.length;  // Asume que tienes una lista `questions`
    const totalResponses = userResponses.length;
  
    if (totalResponses !== totalQuestions) {
      setAlertMessage(`Faltan respuestas por completar. Respuestas completas: ${totalResponses} / ${totalQuestions}`)
      setOpenAlertDialog(true)
      hideLoading();
      return { success: false };  // Detener el envío
    }
    
    const payload = {
      course_section: parseInt(values.course_section),
      teachers: [
        {
          id: selectedTeacher?.info?.id,
          feedback: values?.feedback,
        },
        {
          id: selectedTeacher2?.info?.id,
          feedback: values?.feedback2,
        },
      ],
      comments: values.comments,
      teacher_type: selectedTeacher.type,
      date: values.date,
      estimated_grade: values.estimated_grade,
      lesson: selectedClass,
      route: values.route,
      answers: userResponses.map((response) => ({
        question: response.question,
        answer: response.answer,
        comment: response.comment,
        not_applies: response.applies,
      })),
      form_template: selectedTemplate
    };
  
    if (!selectedTeacher2) {
      payload.teachers.pop();
    }
  
    try {
      // Si `sendMonitorForms` es una promesa, espera su resolución
      const response = await sendMonitorForms({ data: payload });
      const monitoringFormId = response?.data?.form[0]?.monitoring_form_id; 
      setMonitoringFormId(monitoringFormId)
      hideLoading();
      setOpenLoadPicturesModal(true)
      return { success: true };  // Indica que fue exitoso
    } catch (error) {
      console.error("Error sending form:", error);
      hideLoading();
      return { success: false, error };  // Indica que falló
    }
  };

  const validationSchema = Yup.object().shape({
    comments: Yup.string().required("Este campo es obligatorio"),
    course_section: Yup.number().required("Selecciona una sección"),
    date: Yup.date().required("La fecha es obligatoria"),
    feedback: Yup.string().required("Este campo es obligatorio"),
    estimated_grade: Yup.number().required("La nota subjetiva es obligatoria"),
    route: Yup.number().required("La ruta es obligatoria"),
  });

  return (
    <Container>
      <div className={styles["create-question"]}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <Formik
          initialValues={{
            comments: "",
            course_section: 0,
            date: new Date(),
            feedback: "",
            estimated_grade: 0,
            lesson: "",
            route: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm, validateForm }) => {
            setSubmitting(true);  

            const result = await handleSendResponse(values);
            
            if (result.success) {
              // Si fue exitoso, resetea el formulario
              resetForm();
            } else {
              console.error("Form submission failed", result.error);
            }
            setSubmitting(false);
            }
          }
        >
          <Form className={styles["form__fields"]}>
            <Grid container direction={"row"} rowSpacing={2} columnSpacing={2} sx={{width: isDesktop ? '60%' : '100%'}}>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" 
                  sx={{"& .MuiMenuItem-root": {
                      fontSize: "16px",
                    },}}
                >
                  <label className={styles.select_input}>Institución</label>
                  <Select
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                    }}
                    value={selectedInstitutionId}
                    onChange={handleInstitution}
                  >
                    {sortedInstitutions?.map((institution) => (
                    <MenuItem value={institution.id} key={institution.id}
                    sx={{
                      fontSize: "16px" 
                    }}>
                      {`${institution?.name}`}
                    </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "16px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "16px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Curso</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={handleTeachers}
                      value={selectedCourse?.id}
                  >
                    {filteredCourses.length>0 &&
                      filteredCourses?.map((course) => (
                        <MenuItem sx={{
                          fontSize: "16px" 
                        }}
                        value={course.id} key={course.id}>
                          {`${course?.institution_level} ${course?.program}`}
                        </MenuItem>
                      ))}
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
              <MySelect name="course_section" label={"Seccion"}>
              {selectedCourse && selectedCourse?.sections && selectedCourse?.sections?.map((section) => (
                <MenuItem sx={{
                  fontSize: "16px" 
                }}
                value={section.id} key={section.id}>
                  {section.name}
                </MenuItem>
              ))}
              </MySelect>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Lapso</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => setSelectedTerm(e.target.value)}
                  >
                  {selectedCourse?.lessons &&
                    Object.keys(selectedCourse?.lessons).map((term) => {
                      let terms = {
                        first_term: "1er Lapso",
                        second_term: "2do Lapso",
                        third_term: "3er Lapso",
                      };

                      return (
                        <MenuItem sx={{
                          fontSize: "16px" 
                        }}
                        value={term} key={term}>
                          {`${terms[term]}`}
                        </MenuItem>
                      );
                    })}
                      
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Clase</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => setSelectedClass(e.target.value)}
                  >
                    {selectedCourse?.lessons[selectedTerm] &&
                      selectedCourse?.lessons[selectedTerm].map((clase) => (
                        <MenuItem sx={{
                          fontSize: "16px" 
                        }}
                        value={clase.id} key={clase.id}>
                          {`${clase?.title}`}
                        </MenuItem>
                    ))} 
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Lapso</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => setSelectedTerm(e.target.value)}
                  >
                  {selectedCourse?.lessons &&
                    Object.keys(selectedCourse?.lessons).map((term) => {
                      let terms = {
                        first_term: "1er Lapso",
                        second_term: "2do Lapso",
                        third_term: "3er Lapso",
                      };

                      return (
                        <MenuItem sx={{
                          fontSize: "16px" 
                        }}
                        value={term} key={term}>
                          {`${terms[term]}`}
                        </MenuItem>
                      );
                    })}
                      
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Clase</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => setSelectedClass(e.target.value)}
                  >
                    {selectedCourse?.lessons[selectedTerm] &&
                      selectedCourse?.lessons[selectedTerm].map((clase) => (
                        <MenuItem sx={{
                          fontSize: "16px" 
                        }}
                        value={clase.id} key={clase.id}>
                          {`${clase?.title}`}
                        </MenuItem>
                    ))} 
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "16px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "16px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Planilla</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => setSelectedTemplate(e.target.value)}
                  >
                  {templates?.map((template) => (
                    <MenuItem sx={{
                      fontSize: "16px" 
                    }}
                    value={template.id} key={template.id}>
                      {`${template?.name}`}
                    </MenuItem>
                  ))}
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "16px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "16px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Docente</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => handleTeacherChange(e)}
                  >
                  {teachers && teachers?.map((teacher) => (
                    <MenuItem sx={{
                      fontSize: "16px" 
                    }}
                    value={teacher.id} key={teacher?.info?.id}>
                      {`${teacher?.info?.first_name} ${teacher?.info?.last_name}`}
                    </MenuItem>
                  ))}
                      
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "16px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "16px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Docente 2 (si aplica)</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => handleTeacherChange2(e)}
                  >
                  {teachers?.map((teacher) => (
                    <MenuItem sx={{
                      fontSize: "16px" 
                    }}
                    value={teacher.id} key={teacher?.info?.id}>
                      {`${teacher?.info?.first_name} ${teacher?.info?.last_name}`}
                    </MenuItem>
                  ))}
                      
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
              <MyTextInput
                label="Fecha"
                name="date"
                type="date"
                placeholder="Date"
                icon="fa fa-user"
              />

              </Grid>
              <Grid item md={6} sm={12} xs={12}>
              <MyTextInput
                label="Nota subjetiva"
                name="estimated_grade"
                type="number"
                placeholder="Nota"
                icon="fa fa-user"
              />
              </Grid>
              
              <Grid item md={6} sm={12} xs={12}>
                <MyTextInput
                  label="Ruta"
                  name="route"
                  type="number"
                  placeholder="Ruta"
                  icon="fa fa-user"
                />
              </Grid>
              {selectedTeacher && (
                <FillQuestions groupedData={groupedData}
                userResponses={userResponses}
                setUserResponses={setUserResponses} />
              )}
              <Grid item md={12} sm={12} xs={12}>
                <MyTextarea label="Comentarios generales" name="comments" />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <MyTextarea label="Feedback para el docente" name="feedback" />
              </Grid>
              {selectedTeacher2 && (
                <Grid item md={12} sm={12} xs={12}>
                <MyTextarea
                  label="Feedback para el docente 2"
                  name="feedback2"
                />
                </Grid>
              )}
            </Grid>
            <MySubmitButton name="Llenar" type="submit" />
          </Form>
        </Formik>
      </div>
      {isLoading && <LoadingComponent />}
      <Dialog
        open={openAlertDialog}
        onClose={handleClosedAlertDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alertMessage}
        </DialogTitle>
        <DialogContent>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosedAlertDialog} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      {openLoadPicturesModal && monitorinfFormId && (
        <LoadPictures 
          institutions={sortedInstitutions}
          type={"monitoringTeacher"}
          formId={monitorinfFormId} 
          setOpenModal={setOpenLoadPicturesModal} 
        />
      )}
    </Container>
  );
}

export default Planillas;
