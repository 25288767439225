import React, {useState, useEffect} from 'react'
import DataTable from "react-data-table-component";
import { ANNOUNCEMENTS_ENDPOINT } from '../../utils/constants';
import { get } from '../../utils/http';
import { dateFormatter } from '../../utils/dateFormatter';
import Modal from '../Modal';

const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#000",
        fontSize: "13px",
        fontWeight: "bold",
        borderBottom: "1px solid #E0E0E0",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f5f5f5",
        borderBottomColor: "#FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
};

function AllAnnouncementComponent() {
    const [announcements, setAnnouncements] = useState([])
    const [showContent, setShowContent] = useState(false)
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null)
    const [announcementText, setAnnouncementText] = useState(null)

    useEffect(() => {
        const fetchAnnouncements = async () => {
            const url = `${ANNOUNCEMENTS_ENDPOINT}`;
            const response = await get({ url });
            setAnnouncements(response.data);
        }
        fetchAnnouncements()
      }, []);

    const columns = [
        {
          name: "Título",
          selector: (row) => row.title,
          sortable: true,
        },
        {
          name: "Creado por",
          selector: (row) => row.created_by,
          sortable: true,
        },
        {
          name: "Fecha de inicio",
          selector: (row) => row.start_date,
          cell: (cell) =>(
            <p>{dateFormatter(new Date(cell.start_date))}</p>
          )
        },
        {
          name: "Fecha de expiración",
          selector: (row) => row.expiration_date,
          cell: (cell) =>(
            <p>{dateFormatter(new Date(cell.expiration_date))}</p>
          )
        },
        {
            name: "Contenido",
            cell: (cell) => {
              return (
                <div>
                  <p
                    style={{
                      color: "var(--main-blue)",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSelectedAnnouncement(cell)}
                  >Ver contenido</p>
                </div>
              );
            },
        },
    ];

    const handleSelectedAnnouncement = (row) => {
        setSelectedAnnouncement(row);
        const contenidoSinComillas = row?.content?.replace(/^"|"$/g, '');
        setAnnouncementText(contenidoSinComillas)
        setShowContent(true)
    };

    const sortedData = announcements && announcements?.sort((a, b) => {
        return new Date(b?.start_date) - new Date(a?.start_date);
    });

  return (
    <div style={{width:'100%', borderRadius:'10px'}}>
      <DataTable
        columns={columns}
        data={sortedData}
        customStyles={customStyles}
        sortIcon={
          <i
            className="far fa-arrow-up"
            style={{ marginLeft: "5px", fontSize: "14px" }}
          ></i>
        }
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="500px"
        />
        {showContent &&
        <Modal
            title={selectedAnnouncement?.title}
            modalOpen={setShowContent}
            type={"announcement"}
        >
            <div dangerouslySetInnerHTML={{ __html: announcementText }} />
        </Modal>}
    </div>
  )
}

export default AllAnnouncementComponent
