import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { INSTITUTIONS_OPTIONS, INSTITUTIONS_OPTIONS_MONITOR_OR_DEVELOPMENT } from "../utils/constants/institutionsConstants";
import styles from "./InstitutionsPage.module.css";
import SettingsBar from "../components/Navbars/SettingsBar";
import {CurrentUserContext} from "../context/CurrentUserProvider";
const InstitutionsPage = () => {
  const [institutionsOption, setInstitutionOption] = useState("list");
  const { currentUser } = useContext(CurrentUserContext);

  // console.log("cuurentUser", currentUser);
  // if (currentUser?.role === "monitoring_coordinator" || currentUser?.role === "monitoring_teacher" || currentUser?.role === "development_teacher") {
  //   setInstitutionOption("list");
  // };

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.split("/")[2]) {
      navigate("list", { replace: true });
    } else {
      setInstitutionOption(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  return (
    <div className={styles.content}>
      {(currentUser?.role === 'monitoring_coordinator' || 
      currentUser?.role === 'monitoring_teacher' || 
      currentUser?.role === 'monitoring_teacher_3' ||
      currentUser?.role === 'external_coordinator' ||
      currentUser?.role === 'academic_coordinator' ||
      currentUser?.role === 'development_teacher') ? (
        <SettingsBar
          optionsList={INSTITUTIONS_OPTIONS_MONITOR_OR_DEVELOPMENT}
          option={institutionsOption}
          setOption={setInstitutionOption}
        />
      ) : (
        <>
        {(institutionsOption === "list" ||
          institutionsOption === "create" ||
          institutionsOption === "upload") && (
          <SettingsBar
            optionsList={INSTITUTIONS_OPTIONS}
            option={institutionsOption}
            setOption={setInstitutionOption}
          />
        )}
        </>
        
        
      )}
      <section className={styles.leftside}>
          <Outlet />
        </section>

    </div>
  );
};

export default InstitutionsPage;
