import React, {useState, useEffect, useContext} from 'react'
import Paper from "@mui/material/Paper"
import Chip from '@mui/material/Chip';
import Button from "@mui/material/Button";
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Checkbox from "@mui/material/Checkbox";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Skeleton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import { AnnualTrainingsContext } from '../../../context/AnnualTrainingsContext';

const options = ["Docente", "Teléfono", "Institución", "Asistencia", "Marcar Asistencia"]

const TableColumns = () => {
  return options.map((column) => {
    if (column === "Docente" ) {
      return (
        <TableCell
          key={column}
          sx={{
            color: "#8C8C8C",
            fontSize: "10.4px",
            fontWeight: "bold",
            background: "#ffff",
            textTransform: 'uppercase'
          }}>
          {column}
        </TableCell>
      )
    }
    return (
      <TableCell
        key={column}
        align="center"
        sx={{
          color: "#8C8C8C",
          fontSize: "10.4px",
          fontWeight: "bold",
          background: "#ffff",
          textTransform: 'uppercase'
        }}>
        {column}
      </TableCell>
    )
  })
}

function AttendanceOverview({ sessionId, trainingId}) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(true)
    const [filterText, setFilterText] = useState("");
  const [markAttendance, setMarkAttendance] = useState(false)
  const [selectedSession, setSelectedSession] = useState(null)
  const [teacherSelected, setTeacherSelected] = useState({})
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [filterStatus, setFilterStatus] = useState("ALL");
  const {fetchSingleAnnualTrainings, userAnnualTraining, createAttendance,
    createBulkAttendance} = useContext(AnnualTrainingsContext)

  useEffect(() => {
    if(trainingId){
        fetchSingleAnnualTrainings(trainingId);
    }
  }, [trainingId]);

  useEffect(() => {
    fetchSingleAnnualTrainings().finally(() => {
        const session = userAnnualTraining?.sessions?.find((item) => item.id === parseInt(sessionId))
        setSelectedSession(session);
        setLoading(false)
    });
  }, [userAnnualTraining, sessionId]);

  useEffect(() => {
    if(selectedSession){
        const attendedRows = selectedSession?.teacher
      .filter(item => item.attendance_status === 'attended')
      .map(item => ({ teacher: item.id, status: 'attended', session: sessionId }));
    setSelectedRows(attendedRows);
    }
    
  }, [selectedSession]);

  const handleSelectRow = (id) => {
    setSelectedRows((prev) => {
      const isSelected = prev.some((row) => row.teacher === id);
      if (isSelected) {
        return prev.map((row) =>
          row.teacher === id ? { ...row, status: 'absent' } : row
        );
      } else {
        return [...prev, { teacher: id, status: 'attended', session: sessionId }];
      }
    });
  };

  const handleSendSelected = () => {
    createBulkAttendance(selectedRows, trainingId)
    handleCancelMarkAttendance()
  };

  const handleMarkAttendance = () => {
    setMarkAttendance(true)
  }

  const handleCancelMarkAttendance = () => {
    setMarkAttendance(false)
  }

  const handleSelectTeacher = (teacher) => {
    setTeacherSelected(teacher)
    setOpenConfirmDialog(!openConfirmDialog)
  }

  const filteredData = selectedSession?.teacher?.filter(item => {
    const name =`${item?.first_name} ${item?.last_name}`
    const isNameMatch = name?.toLowerCase().includes(filterText?.toLowerCase());
    const isInstitutionMatch = item?.institutions[0]?.toLowerCase().includes(filterText?.toLowerCase());

    const isStatusMatch = filterStatus === "ALL" || item?.attendance_status === filterStatus;

    return (isNameMatch || isInstitutionMatch) && isStatusMatch
  });

  const handleConfirmSingleAttendance = () => {
    const data = {
      session: sessionId,
      teacher: teacherSelected.id
    }
    createAttendance(data, trainingId)
    setOpenConfirmDialog(false)
  }

  const handleClose = () => {
    setOpenConfirmDialog(false);
  };
  const opt = [
    {
      label: "Todos",
      value: "ALL",
    },
    {
      label: "Confirmado",
      value: "pending",
    },
    {
      label: "Pendiente",
      value: null,
    },
    {
      label: "Inasistente",
      value: "absent",
    },
    {
      label: "Asistente",
      value: "attended",
    },
    {
      label: "Asistente por Video",
      value: "video",
    },
  ]

  return (
    <Box sx={{width:'100%'}}>
        <Box sx={{display: 'flex'}}>
          <h2>Asistencia</h2>
          {!markAttendance &&
          <Box sx={{ marginLeft: 'auto'}}>
            <Button variant='contained' size='large'
            onClick={handleMarkAttendance}
            sx={{borderRadius: '10px'}}>
              Marcar Asistencia
            </Button>
          </Box>
        }
        </Box>
        <Box sx={{width: '100%', justifyContent:'center', display:'flex', marginBottom: '10px'}}>
        <TextField id="outlined-basic" 
          placeholder="Buscar por nombre del docente o institución"
          variant="outlined" size='small'
          sx={{width:'50%', 
            '& .MuiOutlinedInput-root': { borderRadius: '10px', 
              backgroundColor: '#fff' }}}
          onChange={(e) => setFilterText(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon />
              </InputAdornment>
            ),
          }} />
          <FormControl 
            variant="outlined" 
            size="small" 
            sx={{
              width:'200px',
              marginLeft: '10px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                backgroundColor: '#fff',
              },
            }}
          >
            <InputLabel id="filter-status-label">Filtro por Asistencia</InputLabel>
            <Select
              labelId="filter-status-label"
              label="Estado"
              name="status"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ccc',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#999',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#333',
                },
              }}
            >
              {opt?.map((option, i) => (
                <MenuItem key={i} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Box>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ overflow: "scroll", maxHeight: "500px", width: '100%' }}>
          <Table size="small" aria-label="sales order table" stickyHeader>
            <TableHead sx={{ border: "none", height: '45px'}}>
              <TableRow sx={{ border: "none" }}>
                <TableColumns />
              </TableRow>
            </TableHead>
            <TableBody>
                {loading ? 
                Array.from(new Array(5)).map((_, index) => (
                    <TableRow key={index} sx={{ height: '50px' }}>
                      {options?.map((_, i) => (
                        <TableCell key={i}>
                          <Skeleton variant="rectangular" height={20} sx={{borderRadius: '5px'}} />
                        </TableCell>
                      ))}
                    </TableRow>
                  )) : (<>
                  
              {filteredData?.map((item, i) => {
                let color = ''
                let att = ''
                switch (item?.attendance_status) {
                  case 'pending':
                    att = "Confirmado"
                    color = '#00a3e0'
                    break
                  case null:
                    att = "Pendiente"
                    color = '#fcb514'
                    break
                  case 'absent':
                    att = "Inasistente"
                    color = '#d30019'
                    break
                  case 'attended':
                    att = "Asistente"
                    color = '#43b02a'
                    break
                  case 'video':
                      att = "Asistente por video"
                      color = '#43b02a'
                      break
                }
                const isChecked = !!selectedRows?.find(row => row.teacher === item.id && row.status === 'attended');
                return(
                  <TableRow
                  key={i}>
                    <TableCell component="th" scope="row" sx={{fontSize: '14px'}}>
                      {`${item?.first_name} ${item?.last_name}`}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '14px'}}>
                      {item?.phone}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '14px'}}>
                      {item?.institutions?.map((item) => (
                        <p>{item}</p>
                      ))}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '14px'}}>
                      {markAttendance ?
                        <Checkbox
                        checked={isChecked}
                        onChange={() => handleSelectRow(item.id)}
                        /> : 
                        <Chip label={att} sx={{backgroundColor: color, 
                          fontSize: '12px', color: '#fff', fontWeight: 'bold'}} />
                      }
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '14px'}}>
                      {att === "Pendiente" &&
                        <Button variant='contained' onClick={() => handleSelectTeacher(item)}
                        sx={{borderRadius:'10px'}}>
                          Confirmar Asistencia
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                )
              })}</>) }
            </TableBody>
          </Table>
      </TableContainer>
      {markAttendance && 
        <Box sx={{width:'100%', display:'flex', 
        justifyContent:'center', gap: '10px', marginTop: 1}}>
          <Button variant='outlined' size='large'
          onClick={handleCancelMarkAttendance}
          sx={{borderRadius: '10px'}}>
            Cancelar
          </Button>
          <Button variant='contained' size='large'
          onClick={handleSendSelected}
          sx={{borderRadius: '10px'}}>
            Guardar
          </Button>
        </Box>
      }
      <Dialog
        open={openConfirmDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle id="alert-dialog-title" style={{color: 'var(--main-blue)', fontSize: '18px', textAlign: 'center'}}>
          {`Seguro que deseas confirmar la asistencia de: ${teacherSelected?.first_name} ${teacherSelected?.last_name}?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} autoFocus 
          sx={{fontSize: '14px', borderRadius: '10px'}}>
            Cancelar
          </Button>
          <Button variant='contained' onClick={handleConfirmSingleAttendance}
          autoFocus sx={{fontSize: '14px', borderRadius: '10px'}}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
  )
}

export default AttendanceOverview
