import React, { useContext, useState, useEffect, useCallback } from "react";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { InstitutionsContext } from "../../../context/InstitutionsContext";
import { TEACHERS_ENDPOINT } from "../../../utils/constants";
import styles from "./SendSurvey.module.css";
import { get } from "../../../utils/http";
import { FormsContext } from "../../../context/FormsContext";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function SendSurvey() {
  const { selectedFormTemplate, sendUserForms } = useContext(FormsContext);
  const { institutions, fetchInstitutions } = useContext(InstitutionsContext);
  const [filterText, setFilterText] = useState("");
  const [filterStage, setFilterStagel] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    fetchInstitutions()
  }, []);

  const columns = [
    {
      name: "Docente",
      selector: (row) => row.info.first_name,
      sortable: true,
      cell: (cell) => <p>{`${cell.info.first_name} ${cell.info.last_name}`}</p>,
    },
    {
      name: "Institución",
      selector: (row) => row.institution,
      sortable: true,
      // cell: (cell) => {
      //   return (
      //     <>
      //       {cell.institution.map((institu) => {
      //         {
      //           return institutions.map((inst) => (
      //             <div style={{ display: "flex" }} key={inst.id}>
      //               {institu === inst.id && (
      //                 <p
      //                   style={{ marginRight: "1rem" }}
      //                 >{` ${inst.short_name.toUpperCase()}`}</p>
      //               )}
      //             </div>
      //           ));
      //         }
      //       })}
      //     </>
      //   );
      // },
    },
  ];

  const navigate = useNavigate();

  const url = `${TEACHERS_ENDPOINT}`;

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  useEffect(() => {
    const fetchTeachers = async () => {
      const response = await get({ url });

      const teachersData = response.data.map((teacher) => {
        const aux = [];
        teacher.institution.forEach((institution) => {
          aux.push(institution.short_name.toUpperCase());
        });
        return { ...teacher, institution: aux.join(" - ") };
      });
      setTeachers(teachersData);
    };
    fetchTeachers();
  }, [url, institutions]);

  let filteredData = teachers.filter(
    (item) =>
      (item.info.first_name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.info.last_name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.institution.includes(filterText.toUpperCase())) &&
      (filterStage === "" || item.stages.includes(filterStage))
  );

  const sendTemplateHandler = () => {
    const selectedTeachers = [];
    selectedRows?.forEach((teacher) => {
      selectedTeachers.push(teacher.info.id.toString());
    });
    const templateData = {
      template: selectedFormTemplate.id,
      users: selectedTeachers,
    };
    sendUserForms({ data: templateData });
    navigate("/encuestas/list");
  };

  const handleChangeFilterText = (e) => {
    setFilterText(e.target.value);
  };

  return (
    <div className={styles.data_table} style={{ width: "100%" }}>
      <div className={styles["search-bar"]}>
        <input
          id="search"
          type="text"
          placeholder="Filtrar"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => handleChangeFilterText(e)}
          className={styles.filter_text}
        />
        <select
          value={filterStage || ""}
          onChange={(e) => setFilterStagel(e.target.value)}
          className={styles.filter_text}
        >
          <option value="">Filtrar por etapa</option>
          <option value="Preescolar">Preescolar</option>
          <option value="Primaria Menor">Primaria Menor</option>
          <option value="Primaria Mayor">Primaria Mayor</option>
          <option value="Bachillerato">Bachillerato</option>
        </select>
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        customStyles={customStyles}
        sortIcon={
          <i
            className="far fa-arrow-up"
            style={{ marginLeft: "5px", fontSize: "14px" }}
          ></i>
        }
        highlightOnHover
        pointerOnHover
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        onSelectedRowsChange={handleRowSelected}
      />
      <button className={styles.send_button} onClick={sendTemplateHandler}>
        Enviar
      </button>
    </div>
  );
}

export default SendSurvey;
