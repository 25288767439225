import React from "react";
import { useNavigate } from "react-router-dom";
import RegistroMasivoForm from "../Users/RegistroMasivoForm";

function CreateMasiveCourses() {
  const navigate = useNavigate();
  return (
    <div style={{ width: "100%" }}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      ></i>
      <RegistroMasivoForm />
    </div>
  );
}

export default CreateMasiveCourses;
