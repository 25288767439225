import React, {useContext} from "react";
import styles from "./AnnouncementsList.module.css";
import AnnouncementComp from "./AnnouncementComp";
import { useAnnouncements } from "../../context/AnnouncementsContext";
import { CurrentUserContext } from "../../context/CurrentUserProvider";

function AnnouncementsList() {
  const { userAnnouncements } = useAnnouncements();
  let { currentUser } = useContext(CurrentUserContext);

  const userPermission = (currentUser?.role === "IT" || 
  currentUser?.role === "monitoring_coordinator" || 
  currentUser?.role === "monitoring_teacher" ||  
  currentUser?.role === "post_sales" ||
  currentUser?.role === "academy_coordination" ||
  currentUser?.role === "academic_coordination" ||
  currentUser?.role === "monitoring_teacher_3")

  const sortedData = userAnnouncements.sort((a, b) => {
    return new Date(b.data.creation_date) - new Date(a.data.creation_date);
  });

  if(!userPermission){
    sortedData.sort((a, b) => {
      if (a.read_at) {
        return 1;
      } else if (b.read_at) {
        return -1;
      } else {
        return 0;
      }
    })
  }

  const announcementsFilter = userAnnouncements?.filter(evento => {
    const fechaActual = new Date();
    const fechaEvento = new Date(evento.data.start_date);
    const fechFin = new Date(evento.data.expiration_date);
    return (fechaEvento <= fechaActual && fechFin >= fechaActual)
  });

  return (
    <div className={styles.announcements}>
      {announcementsFilter?.map((item) => (
        <AnnouncementComp 
        item={item} 
        key={item.id}
        userPermission={userPermission} />
      ))}
    </div>
  );
}

export default AnnouncementsList;
