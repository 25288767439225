import { useState, useEffect } from 'react';
import { eventMapOptions } from '../constants';
export const  useEditComponent = (initialType) => {
  const [selectedType, setSelectedType] = useState(initialType);
  const [options, setOptions] = useState(eventMapOptions[initialType] || []);

  useEffect(() => {
    setOptions(eventMapOptions[selectedType] || []);
  }, [selectedType]);

  return { selectedType, setSelectedType, options };
}
