import React, { useContext } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { CoursesContext } from "../../context/CoursesContext";
import Card from "../Card/Card";
import styles from "./CourseList.module.css";

const CourseList = () => {
  const { setSelectedCourse } = useContext(CoursesContext);
  const {
    filteredData: [filteredData],
    filterText: [filterText, setFilterText],
    currentPage: [currentPage],
    totalPages: [totalPages],
    handlePageChange: [handlePageChange],
  } = useOutletContext();

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
  };

  const renderPaginationNumbers = () => {
    const paginationNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      if (i === currentPage || i === currentPage - 1 || i === currentPage + 1) {
        paginationNumbers.push(
          <button
            key={i}
            className={i === currentPage ? styles.active : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      } else if (i === 1 && currentPage > 3) {
        paginationNumbers.push(<span key="ellipsis-start">...</span>);
      } else if (i === totalPages && currentPage < totalPages - 2) {
        paginationNumbers.push(<span key="ellipsis-end">...</span>);
      }
    }
    return paginationNumbers;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>Mis cursos</div>
        <input
          type="text"
          className={styles.search}
          placeholder="Search..."
          onChange={(e) => setFilterText(e.target.value)}
        />
      </div>
      <div className={styles.courses}>
        {filteredData &&
          filteredData?.map((course) => (
            <Link
              to={`/courses/${course.id}`}
              onClick={() => handleCourseClick(course)}
              key={course.id}
            >
              <div className={styles.course}>
                <Card type="course" data={course} />
              </div>
            </Link>
          ))}
        <div className={styles.pagination}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i class="fas fa-chevron-left"></i>
          </button>
          {renderPaginationNumbers()}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default CourseList;
