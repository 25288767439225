import React, { useState, useEffect, useContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./SurveysPage.module.css";
import SettingsBar from "../components/Navbars/SettingsBar";
import { CurrentUserContext } from "../context/CurrentUserProvider";

export const FORMS_OPTIONS = [
  {
    key: "list",
    name: "Encuestas",
    icon: <i className="fas fa-poll-h"></i>,
    route: "list",
  },
  {
    key: "create-template",
    name: "Crear template",
    icon: <i className="fas fa-file-plus"></i>,
    route: "create-template",
  },
  {
    key: "create-question",
    name: "Crear pregunta",
    icon: <i className="fas fa-plus-square"></i>,
    route: "create-question",
  },
  {
    key: "questions",
    name: "Preguntas",
    icon: <i className="far fa-list-ul"></i>,
    route: "questions",
  },
];

function SurveysPage() {
  const [formsOption, setFormsOption] = useState("list");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const userPermission =
    currentUser.role === "IT" ||
    currentUser.role === "monitoring_coordinator" ||
    currentUser.role === "monitoring_teacher" ||
    currentUser?.role === "monitoring_teacher_3";

  useEffect(() => {
    if (!location.pathname.split("/")[2]) {
      navigate("list", { replace: true });
    } else {
      setFormsOption(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
        window.removeEventListener('resize', changeWidth)
    }
  }, [])

  return (
    <div className={styles.content}>
      {(userPermission) && (
        <>
        {(formsOption === "list" ||
          formsOption === "create-template" ||
          formsOption === "create-question" || 
          formsOption === "questions") && (
          <SettingsBar
            optionsList={FORMS_OPTIONS}
            option={formsOption}
            setOption={setFormsOption}
          />
        )}
      </>
      )}
      <section className={styles.leftside}>
        <Outlet />
      </section>
    </div>
  );
}

export default SurveysPage;
