import React, { useState } from "react";
import { Link } from "react-router-dom";
import linkifyHtml from 'linkify-html';
import styles from "./AnnouncementComp.module.css";
import { useAnnouncements } from "../../context/AnnouncementsContext";
import YoutubeEmbed from "../../utils/YoutubePreview";
import extractVideoId from "../../utils/extractVideoId";

function AnnouncementComp({ item, userPermission }) {
  const [expand, setExpand] = useState(false);
  const { markAnnouncementAsRead } = useAnnouncements();

  const handleAcceptAnnouncement = () => {
    markAnnouncementAsRead(item.id);
  };

  const contenidoSinComillas = item?.data?.content?.replace(/^"|"$/g, '');

  return (
    <div key={item.data.id}>
      <div className={styles.announcement}>
        <div className={styles.header} onClick={() => setExpand(!expand)}>
          <h2>{item.data.title}</h2>
          {!expand ? (
            <div className={styles.more}>
              <div>Ver mas</div>
              <i className="fal fa-angle-down"></i>
            </div>
          ) : (
            <div className={styles.more}>
              <div>Ver menos</div>
              <i className="fal fa-angle-up"></i>
            </div>
          )}
        </div>
        {expand && (
          <div className={styles.content}>
            <div dangerouslySetInnerHTML={{
                        __html: linkifyHtml(contenidoSinComillas, {
                          attributes: {
                            target: "_blank",
                          },
                        }),
                      }}
                />
            {item?.data?.image &&
                <img src={item?.data?.image} alt="announcement" width={400} style={{marginTop: '20px'}}/> }
            {item.data.url && 
            <>
            {item.data.url.includes("youtu") ? (
              <YoutubeEmbed
                url={item.data.url}
                embedId={extractVideoId(item.data.url)}
              />
            ) : (
              <div style={{display: 'flex', marginTop: '20px'}}>
                <p style={{fontWeight: 'bold', fontSize: '14px'}}>Accede al enlace aquí: </p>
                <Link target="_blank" rel="noreferrer" to={item.data.url} style={{fontSize: '14px'}} >
                      {item.data.url}
                </Link>
              </div>
            )}</>
            }
            <br></br>
            {!userPermission && (
              <>
                {!item.read_at && (
                  <button
                    className={styles.button_accept}
                    onClick={handleAcceptAnnouncement}
                  >
                    {`Aceptar`}
                  </button>
                )}
              </>
            )}
            <p className={styles.date}>
              {new Date(item.data.start_date).toDateString("en-EN")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AnnouncementComp;
