import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./SurveysPage.module.css";
import SettingsBar from "../components/Navbars/SettingsBar";


export const FORMS_OPTIONS = [
  {
    key: "crear",
    name: "Crear Feedback",
    icon: <i className="fas fa-poll-h"></i>,
    route: "crear",
  },
  {
    key: "list",
    name: "Feedbacks",
    icon: <i className="fas fa-list-alt"></i>,
    route: "list",
  },
];

function ReportesPage() {
  const [formsOption, setFormsOption] = useState("llenar");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.split("/")[3]) {
      navigate("crear", { replace: true });
    } else {
      setFormsOption(location.pathname.split("/")[3]);
    }
  }, [location.pathname]);

  return (
    <div className={styles.content}>
      {(formsOption === "llenar" ||
        formsOption === "crear" ||
        formsOption === "list" ) && (
        <SettingsBar
          optionsList={FORMS_OPTIONS}
          option={formsOption}
          setOption={setFormsOption}
        />
      )}
      <section className={styles.leftside}>
        <Outlet />
      </section>
    </div>
  )

}

export default ReportesPage