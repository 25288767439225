import React, {useContext, useEffect, useState} from 'react'
import * as XLSX from 'xlsx';
import { useParams, useNavigate, Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Skeleton from '@mui/material/Skeleton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import PollRoundedIcon from '@mui/icons-material/PollRounded';
import { Grid, Stack, Box, Button, IconButton, Typography, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import { AnnualTrainingsContext } from '../../../context/AnnualTrainingsContext'
import SessionsAdminView from './SessionsAdminView';
import CertificatesDataView from './CertificatesDataView';
import QuizView from './QuizView';

const Title = styled('p')({
  fontSize: '1.6rem',
  fontWeight:'bold',
  marginBottom: '5px'
});
const Info = styled('p')({
  fontSize: '1.2rem',
  color: '#787878',
});
const Text = styled('p')({
  fontSize: '1.2rem',
  paddingTop: '5px'
});
const Text2 = styled('p')({
  fontSize: '1.3rem',
  paddingTop: '5px',
  color: 'var(--main-blue)',
  fontWeight:'bold',
});
const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  fontSize: '12px', 
  backgroundColor: 'var(--background-gray)', borderRadius: '10px',
  margin: '5px',
  '&:hover': {
    backgroundColor: "#E7E7E7",
    opacity: 1,
    borderRadius: '10px',
  },
  '&.Mui-selected': {
    boxShadow: '0 0 6px -1px var(--main-gray)',
    border: 'none',
  },
}));

function exportToExcel(userAnnualTraining, trainingCertificates) {
  // Crear un nuevo libro de trabajo
  const workbook = XLSX.utils.book_new();

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  // Página 1: Resumen
  const summaryData = [['Sesión', 'Fecha', 'Cantidad de Docentes', '% Asistencias', '% Inasistencias']];
  
  userAnnualTraining?.sessions?.forEach((session, index) => {
    const totalTeachers = session?.teacher?.length;
    const attendedCount = session?.teacher?.filter(teacher => teacher?.attendance_status === 'attended')?.length;
    const absentCount = session?.teacher?.filter(teacher => teacher?.attendance_status === 'absent')?.length;
    
    const attendedPercentage = (totalTeachers === 0) ? 0 : (attendedCount / totalTeachers) * 100;
    const absentPercentage = (totalTeachers === 0) ? 0 : (absentCount / totalTeachers) * 100;

    summaryData?.push([
      `Session ${index + 1}`,
      new Date(session?.date)?.toLocaleString("es-ES", options),
      totalTeachers,
      attendedPercentage.toFixed(2),
      absentPercentage.toFixed(2),
    ]);
  });

  const summarySheet = XLSX.utils.aoa_to_sheet(summaryData);
  XLSX.utils.book_append_sheet(workbook, summarySheet, 'Summary');

  // Páginas por cada sesión
  userAnnualTraining?.sessions?.forEach((session, index) => {
    const sessionData = [['Teacher', 'Attendance Status', 'Nota Quiz', 'Certificado']];
    
    session?.teacher?.forEach(teacher => {
      const certificate = trainingCertificates?.find((item) => item.user.id === teacher.id)
      let status = ""
      switch (teacher?.attendance_status) {
        case 'attended':
          status = "Asistente"
          break
        case 'absent':
          status = "Inasistente"
          break
        case 'pending':
          status = "Confirmado"
          break
        default: 
          status = ""
          break
      }
      let certificateStatus = ""
      switch (certificate?.status) {
        case 'pending':
          certificateStatus = "Pendiente aprobación"
          break
        case 'pending attended':
          certificateStatus = "Pendiente aprobación asistencia"
          break
        case 'pending quiz':
          certificateStatus = "Pendiente aprobación quiz"
          break
        case 'approved':
          certificateStatus = 'Por descargar'
          break
        case 'downloaded':
          certificateStatus = 'Descargado'
          break
        default: 
          certificateStatus = "Pendiente Quiz"
          break
      }
      
      sessionData?.push([`${teacher?.first_name} ${teacher?.last_name}` || 'Unknown', status, teacher?.quiz_grade || "Sin Responder", certificateStatus]);
    });

    const sessionSheet = XLSX.utils.aoa_to_sheet(sessionData);
    XLSX.utils.book_append_sheet(workbook, sessionSheet, `Session ${index + 1}`);
  });

  // Escribir el archivo Excel
  XLSX.writeFile(workbook, `${userAnnualTraining?.name}.xlsx`);
}

const EditingTraining = ({userAnnualTraining, handleSetTrainingEdit, handleEditTraining}) => {
  const initialValues = {
    quiz_url: userAnnualTraining.quiz_url || "",
  };

  return(
      <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            quiz_url: Yup.string(), 
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                  handleEditTraining(userAnnualTraining?.id, values);
                  setSubmitting(false);
              }, 400);
              resetForm();
          }}
      >
          {({ handleChange, values }) => (
              <Form>
                <Field
                  name="quiz_url"
                  as={TextField}
                  label="Link Quiz"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={handleChange}
                  value={values.quiz_url}
                  sx={{borderRadius: '10px', marginTop: '10px', width: '100%',
                  '& .MuiOutlinedInput-root': { borderRadius: '10px' }}}
                  />
                  <Box sx={{ display: 'flex', gap: '10px', width: '100%', justifyContent: 'center', marginTop: '10px' }}>
                      <Button variant='outlined' size='small' sx={{ borderRadius: '10px' }} 
                      onClick={handleSetTrainingEdit}>
                          Cancelar
                      </Button>
                      <Button type="submit" variant="contained" size="small" sx={{ borderRadius: '10px' }}>
                          Aceptar
                      </Button>
                      
                  </Box>
              </Form>
          )}
      </Formik>
  )
}

function AdminCapacitacionView() {
  const [value, setValue] = React.useState('1');
  const [isEditing, setIsEditing] = useState(false)
  const [isTrainingEditing, setIsTrainingEditing]= useState(false)
  const [openDialog, setOpenDialog] = React.useState(false);
  const [nextSession, setNextSession] = useState(null)
  const {fetchSingleAnnualTrainings, 
    userAnnualTraining, 
    updateSession, 
    sendReminderEmail, 
    fetchAnnualTrainingCertificates, 
    trainingCertificates, updateAnnualTraining} = useContext(AnnualTrainingsContext)
  const { trainingId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(trainingId){
    fetchSingleAnnualTrainings(trainingId);}
  }, [trainingId]);

  useEffect(() => {
    if(trainingId){
      fetchAnnualTrainingCertificates(trainingId);
    }
  }, [trainingId]);

  useEffect(() => {
    const nextSessionsFilter = userAnnualTraining?.sessions?.filter((item) => new Date(item?.date) > new Date())
    if(nextSessionsFilter?.length > 0){
      setNextSession(nextSessionsFilter[0])
    }else{
      setNextSession(null)
    }
  }, [userAnnualTraining]);

  const programs = Array.from(new Set(userAnnualTraining?.sessions?.map(session => session?.program_name)));
  const sortedSessions = userAnnualTraining?.sessions?.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Obtener la primera y la última fecha
  const firstDate = sortedSessions && (sortedSessions[0]?.date || "")
  const lastDate = sortedSessions && (sortedSessions[sortedSessions?.length - 1]?.date || "")

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const navigate = useNavigate();

  const startDate = new Date(firstDate)?.toLocaleString("es-ES", options) || "";
  const endDate = new Date(lastDate)?.toLocaleString("es-ES", options) || "";

  let type = ""
  let icon = ""
  switch (userAnnualTraining?.type) {
    case 'on-site':
      type = "Presencial"
      icon = <i class="fas fa-building" style={{backgroundColor: 'var(--main-blue)', 
        color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
      break
    case 'remote':
      type = "Remoto"
      icon = <i class="fas fa-laptop" style={{backgroundColor: 'var(--main-blue)', 
        color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
      break
    case 'hybrid':
      type = "Híbrido"
      icon = <i class="fas fa-laptop-house" style={{backgroundColor: 'var(--main-blue)', 
        color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
      break
  }

  const handleSetEdit = () => {
    setIsEditing(!isEditing)
  }

  const handleSetTrainingEdit = () => {
    setIsTrainingEditing(!isTrainingEditing)
  }

  const handleEditSession = (sessionId, data) => {
    const trainingId = userAnnualTraining.id
    updateSession({sessionId, data, trainingId})
    setIsEditing(false)
  }

  const handleEditTraining = (trainingId, data) => {
    updateAnnualTraining({trainingId, data})
    setIsTrainingEditing(false)
  }

  const handleSendRemindEmail = (sessionId) => {
    sendReminderEmail(sessionId)
    setOpenDialog(false);
  }

  const handleCheckEmailSend = () => {
    setOpenDialog(true)
    
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const isLoading = Array.isArray(userAnnualTraining) || (typeof userAnnualTraining === 'object' && Object.keys(userAnnualTraining).length === 0);

  if (isLoading ) {
    return (
      <Stack direction="column" sx={{width: '100%', padding: '20px'}}>
        <Skeleton variant="rectangular" width="100%" height={100} sx={{marginTop: '10px', borderRadius: '10px'}} />
        <Skeleton variant="rectangular" width="100%" height={300} sx={{marginTop: '20px', borderRadius: '10px'}} />
      </Stack>
    );
  }

  return (
    <Stack direction={"column"} sx={{width: '100%'}}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      ></i>
      <Grid container direction={"row"} columnGap={2} rowGap={2}>
        <Grid item md={3} xs={12} 
        sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
          <Title>{userAnnualTraining?.name}</Title>
          <Info>{programs.join(', ')}</Info>
          <Stack direction={"row"} spacing={1}
          sx={{display:'flex', alignItems:'center', marginTop: '2px'}}>
            <i class="fas fa-calendar" style={{color: "#787878", fontSize:'1.2rem'}}></i>
            <Info>{`${startDate} - ${endDate}`}</Info>
          </Stack>
        </Grid>
        <Grid item md={2} xs={5.6} 
        sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
          <Grid
            container
            direction={"row"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height:'100%'
          }}>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Stack direction={"column"}>
              <Info>Modalidad</Info>
              <Text2>{type}</Text2>
              </Stack>
            </Grid>
            <Grid item sx={{display:'flex', alignItems:'center'}}>
              {icon}
            </Grid>
          </Grid>
        </Grid>
        {nextSession &&
        <Grid item md={2} xs={5.6}  onClick={handleCheckEmailSend}
        sx={{backgroundColor: 'var(--background-gray)', 
          padding: '10px 15px', 
        borderRadius: '10px', cursor:'pointer', 
        '&:hover': {
          backgroundColor: "#E7E7E7",
          opacity: 1,
        },}}>
          <Grid
            container
            direction={"row"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height:'100%'
            }}>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Stack direction={"column"}>
                <Info>Recordatorio</Info>
                <Text2>Enviar Recordatorios</Text2>
                </Stack>
            </Grid>
            <Grid item sx={{display:'flex', alignItems:'center'}}>
              <i class="fas fa-comment-dots" style={{backgroundColor: 'var(--main-blue)', 
              color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
            </Grid>
          </Grid>
        </Grid>}
        <Grid item md={2} xs={5.6}
        sx={{backgroundColor: 'var(--background-gray)', 
          padding: '10px 15px', 
        borderRadius: '10px',
        }}>
          <Grid
            container
            direction={"row"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height:'100%'
          }}>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Stack direction={"column"}>
              <Info>Quiz</Info>
              {!isTrainingEditing ? 
              <Stack direction={"row"} sx={{display:'flex', alignItems:'center'}}>
                {userAnnualTraining?.quiz_url ? 
                <Link to={userAnnualTraining?.quiz_url} target='_blank'>
                  <Button variant='outlined' size='small' sx={{borderRadius: '10px'}}>
                    Ver Quiz
                  </Button>
                </Link> : 
                <p>No hay ningun quiz</p>}
                <Tooltip title={
                  <Typography sx={{ fontSize: '11px' }}>
                    Editar Link
                  </Typography>
                }>
                <IconButton size='small' onClick={() => handleSetTrainingEdit()}>
                  <EditIcon sx={{color: 'var(--main-blue)'}}/>
                </IconButton>
                </Tooltip>
              </Stack> : 
              <EditingTraining userAnnualTraining={userAnnualTraining} handleEditTraining={handleEditTraining}
              handleSetTrainingEdit={handleSetTrainingEdit}/>}
              </Stack>
            </Grid>
            {!isTrainingEditing &&
            <Grid item sx={{display:'flex', alignItems:'center'}}>
              <PollRoundedIcon sx={{backgroundColor: 'var(--main-blue)', 
              color: '#fff', padding: '5px', borderRadius: '7px', fontSize: '30px'}} />
            </Grid>}
          </Grid>
        </Grid>
        <Grid item md={2} xs={5.6} onClick={() => exportToExcel(userAnnualTraining, trainingCertificates)}
        sx={{backgroundColor: 'var(--background-gray)', 
          padding: '10px 15px', 
        borderRadius: '10px', cursor:'pointer', 
        '&:hover': {
          backgroundColor: "#E7E7E7",
          opacity: 1,
        },}}>
          <Grid
            container
            direction={"row"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height:'100%'
          }}>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Stack direction={"column"}>
              <Info>Datos</Info>
              <Text2>Descargar Tabla</Text2>
              </Stack>
            </Grid>
            <Grid item sx={{display:'flex', alignItems:'center'}}>
              <DownloadForOfflineIcon sx={{backgroundColor: 'var(--main-blue)', 
              color: '#fff', padding: '5px', borderRadius: '7px', fontSize: '30px'}} />
              
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
      borderRadius: '10px', marginTop:'15px'}}>
        <Info>Descripción</Info>
        <Text>{userAnnualTraining?.description}</Text>
      </Box>
      
      <Box sx={{marginTop: '10px'}}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider',  border: 'none', width:'100%', display:'flex'}}>
            <Tabs onChange={handleChange} 
            aria-label="visible arrows tabs example" 
            variant="scrollable" 
            sx={{width:'100%',
              '& .MuiTabs-indicator': {
              display: 'none',
            },}}
            value={value} >
              <AntTab label="Sesiones" value="1" />
              <AntTab label="Quiz" value="2"  />
              <AntTab label="Certificados" value="3" />
              {!isEditing && value==="1" &&
              <Button variant='outlined' disabled={isTrainingEditing}
              size='medium' onClick={handleSetEdit}
              sx={{borderRadius:'10px', marginLeft: 'auto', marginRight: 1}}
              startIcon={<EditIcon />}>
                Editar Sesión
              </Button>}
            </Tabs>
          </Box>
          <TabPanel value="1" sx={{borderRadius: '10px', padding: "10px 2px"}}>
            <SessionsAdminView sessions={userAnnualTraining?.sessions} handleEditSession={handleEditSession}
            isEditing={isEditing} setIsEditing={setIsEditing} handleSetEdit={handleSetEdit} />
          </TabPanel>
          <TabPanel value="2" sx={{borderRadius: '10px', padding: "10px 2px"}}>
            <QuizView userAnnualTraining={userAnnualTraining} />
          </TabPanel>
          <TabPanel value="3" sx={{borderRadius: '10px', padding: "10px 2px"}}>
            <CertificatesDataView trainingId={userAnnualTraining?.id} />
          </TabPanel>
        </TabContext>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle id="alert-dialog-title" style={{color: 'var(--main-blue)', fontSize: '18px'}}>
          {"Selecciona la Sesión a la que deseas enviar el recordatorio"}
        </DialogTitle>
        <DialogContent>
        <Stack spacing={2}>
            {userAnnualTraining?.sessions?.map((item, i) => {
              const lastEmailSent = item?.last_email_sent_date ? new Date(item?.last_email_sent_date).toLocaleString("es-ES", options) : "N/A"
              return(
                <Stack direction={"row"} key={i}>
                  <Stack direction={"column"}>
                  <Typography variant='h6'>
                    {`${item?.name} - ${item?.session_code}`}
                  </Typography>
                  <Typography>{`Último recordatorio: ${lastEmailSent}`}</Typography>
                  </Stack>
                  <Button variant='outlined' onClick={() => handleSendRemindEmail(item.id)}
                   sx={{borderRadius: '10px', marginLeft: 'auto'}}>
                    Seleccionar 
                  </Button>
                </Stack>
              )
            })}
            </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  )
}

export default AdminCapacitacionView
