export const SETTINGS_OPTIONS = [
  {
    name: "Ajustes",
    icon: <i className="fad fa-cog"></i>,
    route: "",
  },
  {
    name: "Usuarios",
    icon: <i className="fad fa-users"></i>,
    route: "/users",
  },
  {
    name: "Instituciones",
    icon: <i className="fad fa-school"></i>,
    route: "/institutions",
  },
  {
    name: "Programas",
    icon: <i className="fad fa-robot"></i>,
    route: "/programs",
  },
  {
    name: "Docentes",
    icon: <i className="fad fa-chalkboard-teacher"></i>,
    route: "/teachers",
  },
  {
    name: "Cursos",
    icon: <i className="fas fa-chalkboard"></i>,
    route: "/courses-admin",
  },
  {
    name: "Cerrar sesión",
    icon:  <i className="far fa-sign-out"></i>,
    route: "/",
  },

];

export const SETTINGS_OPTIONS2 = [
  {
    name: "Ajustes",
    icon: <i className="fad fa-cog"></i>,
    route: "",
  },
  {
    name: "Docentes",
    icon: <i className="fad fa-chalkboard-teacher"></i>,
    route: "/teachers",
  },
];