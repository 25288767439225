import React, {useState, useEffect} from 'react'
import Modal from '../../Modal'

function UserAnswersModal({selectedUser, 
    setOpenModal, 
    questions, 
    selectedUserName}) {
    const [answInfo, setAnswInfo] = useState([])

    useEffect(() => {
        const filteredData = selectedUser?.filter((item) => 
        item.institution === selectedUserName?.institutionId)
    
        const info = []

        filteredData[0]?.answers?.forEach((answer) => {
            questions?.forEach((question) => {
                if(answer?.question === question?.question.id){
                    let answerData = answer?.content
                    if(answer?.content?.includes("[")){
                        const letters = JSON.parse(answer?.content?.replaceAll("'", '"'));
                        answerData = letters
                    }

                    if (answer?.answer_file !== null) {
                        answerData = answer?.answer_file
                    }

                    const user_data = {
                        question: question?.question.description,
                        answer: answerData,
                        question_type: question?.question?.options_type,
                    }
                    info?.push(user_data)
                }
            })
        })
        setAnswInfo(info)
    }, [selectedUser, selectedUserName]);

  return (
    <Modal 
    modalOpen={setOpenModal}
    title={`${selectedUserName?.userName} - ${selectedUserName?.institution}`}>
        <div style={{height:'100%', overflowY: 'scroll'}}>
            {answInfo?.map((data) => {
                return(
                    <div 
                    style={{backgroundColor: '#f5f5f5', 
                    marginBottom: '2rem', 
                    padding: '1.5rem', 
                    borderRadius: '10px', minWidth: '360px'}}>
                        <p 
                        style={{fontWeight: 'bold', 
                        fontStyle: 'italic', 
                        marginBottom: '5px', fontSize: '16px'}}>
                            {data.question}
                        </p>
                        {data?.question_type === 2 ?
                        <>
                            {data?.answer?.map((dat) => (
                                <li>
                                {dat}
                                </li>
                            ))}
                        </>
                         : 
                        <p>
                            {data.answer}
                        </p>
                        } 
                    </div>
                )
            })}

        </div>
    </Modal>
  )
}

export default UserAnswersModal
