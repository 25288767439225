import { useState, createContext, useContext, useEffect } from "react";
import { get, post,patch } from "../utils/http";
import { CLASS_JOURNAL_ENDPOINT } from "../utils/constants";
import { AlertsContext } from "./AlertsContext";

const ClassJournalContext = createContext();

const ClassJournalProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [classJournal, setClassJournal] = useState([]);
  const showSnackbar = useContext(AlertsContext);

  const fillClassJournal = async ({ data }) => {
    const url = CLASS_JOURNAL_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Clase agregada al día día', 'success')
      fetchClassJournal();
    }else{
      showSnackbar('Ha ocurrido un error', 'error')
    }
  };

  const updateClassJournal = async ({journalId, data }) => {
    const url = `${CLASS_JOURNAL_ENDPOINT}${journalId}/`;
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar('Día día editado', 'success')
      fetchClassJournal();
    }else{
      showSnackbar('Ha ocurrido un error', 'error')
    }
  };

  const fillMultipleClassJournal = async ({ data }) => {
    const url = `${CLASS_JOURNAL_ENDPOINT}add_multiple/`;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Clases agregadas al día día', 'success')
      fetchClassJournal();
    }else{
      showSnackbar('Ha ocurrido un error', 'error')
    }
  };

  const fetchClassJournal = async () => {
    const url = CLASS_JOURNAL_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setClassJournal(response.data);
    }
  };


  useEffect(() => {
    // fetchClassJournal();
  }, []);

  const contextData = {
    classJournal,
    setClassJournal,
    fillClassJournal,
    fetchClassJournal,
    fillMultipleClassJournal,
    updateClassJournal
  };

  return (
    <ClassJournalContext.Provider value={contextData}>
      {loading ? null : children}
    </ClassJournalContext.Provider>
  );
};

const useClassJournal = () => {
  return useContext(ClassJournalContext);
};

export { ClassJournalContext, ClassJournalProvider, useClassJournal };
