import { useState, createContext, useContext, useEffect } from "react";
import { get, post } from "../utils/http";
import {
  INSTITUTIONS_ENDPOINT,
  INSTITUTIONS_LEVELS_ENDPOINT,
} from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";
import { AlertsContext } from "./AlertsContext";
import { useLoading } from "./LoadingContext";
const InstitutionsContext = createContext();

const InstitutionsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const [selectedInstitution, setSelectedInstitution] = useLocalStorage(
    "selectedInstitution",
    ""
  );
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useLocalStorage(
    "selectedLevel",
    ""
  );
  const showSnackbar = useContext(AlertsContext);

  const createInstitution = async ({ data }) => {
    const url = INSTITUTIONS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha creado una institución', 'success')
      fetchInstitutions();
    }else{
      showSnackbar('Hubo un error al crear la institución', 'error');
    }
  };

  const fetchInstitutions = async () => {
    const url = INSTITUTIONS_ENDPOINT;
    showLoading();
    const response = await get({ url });
    if (!response.error) {
      hideLoading();
      setInstitutions(response.data);
    } else if (response.error === 401) {
      hideLoading();
    }
  };

  const createLevel = async ({ data }) => {
    const url = INSTITUTIONS_LEVELS_ENDPOINT;
    const response = await post({ url, data });
  };

  const fetchLevels = async () => {
    const url = INSTITUTIONS_LEVELS_ENDPOINT;
    const response = await get({ url });

    if (!response.error) {
      setLevels(response.data);
    }
  };

  const contextData = {
    institutions,
    setInstitutions,
    selectedInstitution,
    setSelectedInstitution,
    fetchInstitutions,
    levels,
    setLevels,
    selectedLevel,
    setSelectedLevel,
    fetchLevels,
    createLevel,
    createInstitution,
  };

  return (
    <InstitutionsContext.Provider value={contextData}>
      {loading ? null : children}
    </InstitutionsContext.Provider>
  );
};

const useInstitutions = () => {
  return useContext(InstitutionsContext);
};

export { InstitutionsContext, InstitutionsProvider, useInstitutions };
