import React, {useContext, useEffect, useState} from 'react'
import DataTable from "react-data-table-component";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import { MyTextInput, MySubmitButton, MySelect } from "../FormFields";
import { INSTITUTIONS_ENDPOINT } from "../../utils/constants";
import { InstitutionsContext } from "../../context/InstitutionsContext";
import styles from './InstitutionsLevels.module.css'
import Container from '../Container';
import { get } from '../../utils/http';
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import Modal from '../Modal';
import { InstitutionsLevelContext } from '../../context/Institutions_LevelsContext';

const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#000",
        fontSize: "13px",
        fontWeight: "bold",
        borderBottom: "1px solid #E0E0E0",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f5f5f5",
        borderBottomColor: "#FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
};

const columns_levels = [
    {
      name: "Nivel",
      selector: (row) => row.name,
    },
    {
      name: "Secciones",
      selector: (row) => row.student_sections,
    },
    {
      name: "Alumnos por secciones",
      selector: (row) => row.students_per_section,
    },
];

let INITIAL_VALUES = {
  name: "",
  stage: "",
  student_sections: 0,
  students_per_section: 0,
};

function InstitutionsLevels() {
    const [levels, setLevels] = useState([])
    const [addLevel, setAddLevel] = useState(false);
    const [filterLevel, setFilterLevel] = useState("");
    const [isEditing, setIsEditing] = useState(false)
    const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
    const [levelId, setLevelId] = useState(0)
    const { selectedInstitution: institution } = useContext(InstitutionsContext);
    const {createInstitutionLevel, updateLevel} = useContext(InstitutionsLevelContext);
    const { currentUser } = useContext(CurrentUserContext);

    const userPermission =
    currentUser.role === "IT" 

    let filteredLevels = levels?.filter((item) =>
    item.name.toLowerCase().includes(filterLevel.toLowerCase())
    );

    useEffect(() => {
      const fetchLevels = async () => {
        const url = `${INSTITUTIONS_ENDPOINT}${institution.id}/levels/`;
        const response = await get({ url });
    
        if (!response.error) {
          setLevels(response.data);
        }
      };
      fetchLevels();
    }, [institution]);

    const handleChangeFilterLevels = (e) => {
        setFilterLevel(e.target.value);
    };

    const handleRowClick = (row) => {
      setLevelId(row.id)
      setIsEditing(true)
      INITIAL_VALUES = {
        name: row.name,
        stage: row.stage,
        student_sections: row.student_sections,
        students_per_section: row.students_per_section,
      };
      setInitialValues(INITIAL_VALUES)
      setAddLevel(true)
    };

    const createHandler = async (values) => {
      const levelsData = {
        name: values.name,
        stage: values.stage,
        student_sections: values.student_sections,
        students_per_section: values.students_per_section,
      };

      !isEditing ? createInstitutionLevel({ data: levelsData }) : updateLevel({ levelId, values });
      setAddLevel(false);
      setIsEditing(false)
      window.location.reload();
    };

  return (
    <Container direction={"column"}>
      <div className={styles.levels_header}>
        {!addLevel && userPermission && (
          <div className={styles.add} onClick={() => setAddLevel(true)}>
            <i
              className="far fa-plus"
              style={{ fontSize: "24px", color: "var(--main-blue)" }}
            ></i>
          </div>
        )}
        <div className={styles["search-bar"]}>
          <input
            id="search"
            type="text"
            placeholder="Buscar por nivel"
            aria-label="Search Input"
            value={filterLevel}
            onChange={(e) => handleChangeFilterLevels(e)}
            className={styles.filter_text}
          />
        </div>
      </div>
      <div className={styles.data_table}>
          <DataTable
            columns={columns_levels}
            data={filteredLevels}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            pointerOnHover
            fixedHeader
            fixedHeaderScrollHeight="500px"
            onRowClicked={(row) => handleRowClick(row)}
          />
      </div>
      {addLevel && userPermission && (
        <Modal title={isEditing ? "Editar Grado":"Nuevo Grado"} 
        modalOpen={setAddLevel}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              name: Yup.string()
                .min(1, "El campo no puede estar vacío.")
                .required(),
                stage: Yup.string().required(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                createHandler(values);
                setSubmitting(false);
              }, 400);
              resetForm();
            }}
          >
            <Form className={styles["form__fields"]}>
              <div className={styles.inputs}>
              <MyTextInput
                label="Nombre"
                name="name"
                type="text"
                placeholder="Name"
                icon="fa fa-user"
              />
              <MySelect label="Stage" name="stage">
                  <MenuItem value="Bachillerato">
                    Bachillerato
                  </MenuItem>
                  <MenuItem value="Primaria Menor">
                    Primaria Menor
                  </MenuItem>
                  <MenuItem value="Primaria Mayor">
                    Primaria Mayor
                  </MenuItem>
                  <MenuItem value="Preescolar">
                    Preescolar
                  </MenuItem>
              </MySelect>
              </div>
              <div className={styles.inputs}>
                <MyTextInput
                  label="Secciones por grado"
                  name="student_sections"
                  type="number"
                  placeholder="Secciones por nivel"
                  icon="fa fa-user"
                />
                <MyTextInput
                  label="Alumnos por sección"
                  name="students_per_section"
                  type="number"
                  placeholder="Alumnos por sección"
                  icon="fa fa-user"
                />
              </div>
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: '10px'
                }}
              >
                <MySubmitButton name={isEditing ? "Editar" : "Crear"} type="submit" />
              </div>
            </Form>
          </Formik>
        </Modal>
      )}
    </Container>
  )
}

export default InstitutionsLevels