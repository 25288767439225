import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Container from "../components/Container";
import { get } from "../utils/http";
import styles from "./TeachersPage.module.css";
import { TEACHERS_ENDPOINT, PROGRAMS_ENDPOINT } from "../utils/constants";
import { timestampFormatter } from "../utils/timestampFormatter";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function TeachersPage() {
  const [filterText, setFilterText] = useState("");
  const [institutionFilter, setInstitutionFilter] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [programs, setPrograms] = useState([])
  const [stageFilter, setStageFilter] = useState("");
  const [programFilter, setProgramFilter] = useState(0)
  const navigate = useNavigate();
  let usersdata = [];

  useEffect(() => {
    const fetchPrograms = async () => {
      const url = PROGRAMS_ENDPOINT;
      const response = await get({ url });
  
      if (!response.error) {
        setPrograms(response.data);
      }
    };
    fetchPrograms();
  }, []);

  const url = `${TEACHERS_ENDPOINT}`;

  useEffect(() => {
    const fetchTeachers = async () => {
      const response = await get({ url });

      setTeachers(response.data);
    };
    fetchTeachers();
  }, [url]);

  if(teachers){
  teachers?.forEach((teacher) => {
    const username = `${teacher?.info?.first_name} ${teacher?.info?.last_name}`;

    const userdata = {
      id: teacher?.info.id,
      name: username,
      last_login: teacher?.info?.last_login,
      type: teacher.type,
      institution_name: teacher?.institution_name,
      programs: teacher?.programs,
      stages: teacher?.stages,
      level: teacher?.level
    };
    usersdata.push(userdata);
  });}

  const filteredData = usersdata?.filter((item) => {
    if (institutionFilter === "" && stageFilter === "" && parseInt(programFilter) === 0 && filterText === "") {
      return item;
    } else {
      
      let nameMatch = item

      if(filterText !== ""){
        nameMatch = item.name.toLowerCase().includes(filterText.toLowerCase());
      }

      let institutionMatch = item

      if(institutionFilter !== ""){
        institutionMatch = item.institution_name.some((institution) =>
        institution.toLowerCase().includes(institutionFilter.toLowerCase())
      );
      }

      let programMatch = item

      if(parseInt(programFilter) !== 0){
        programMatch = item.programs.some((program) =>
        program.id === parseInt(programFilter)
        );
      }

      let stagesMatch = item

      if(stageFilter !== ""){
        stagesMatch =
        item.stages.some((stage) =>
          stage.toLowerCase().includes(stageFilter.toLowerCase())
        ) 
      }

      if (nameMatch && institutionMatch && programMatch && stagesMatch) {
        return item;
      }
    }
  });

  const handleRowClick = (row) => {
    navigate(`/profile/${row.id}`);
  };

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.name,
      sortable: true,
      
    },
    {
      name: "Tipo",
      selector: (row) => row.type,
      sortable: true,
      cell: (cell) => {
        let tipo 
        if(cell.type === "External"){
          tipo = "Externo"
        }else{
          let nivel
          if(cell.level === 1){
            nivel = "I"
          }else if(cell.level === 2){
            nivel = "II"
          }else if(cell.level === 3){
            nivel = "III"
          }
          tipo = `Interno - Nivel ${nivel}`
        }
        return(
          <p>{tipo}</p>
        )
      }
    },
    {
      name: "Etapas",
      selector: (row) => row.stages,
      sortable: true,
      cell: (cell) => {
        return(
        <div style={{display:'flex', flexDirection: 'column'}}>
        {cell?.stages?.map((stage) => {
          return(
          <p>{stage}</p>
          )
        })}
        </div>
      )}
    },
    {
      name: "Colegio",
      selector: (row) => row.institution_name,
      sortable: true,
      cell: (cell) => {
        return(
        <div style={{display:'flex', flexDirection: 'column'}}>
        {cell?.institution_name?.map((institution) => {
          return(
          <p>{institution}</p>
          )
        })}
        </div>
      )}
    },
    {
      name: "Última conexión",
      selector: (row) => row.last_login,
      sortable: true,
      cell: (cell) => (
        <p>{timestampFormatter(new Date(cell.last_login))}</p>
      )
    },
  ];

  const handleChangeFilterText = (e) => {
    setFilterText(e.target.value);
  };

  return (
    <Container direction={"column"}>
      <div className={styles.teachers_info}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles.content}>
          <div className={styles.filter}>
          <input
            id="search"
            type="text"
            placeholder="Filtrar por nombre"
            aria-label="Search Input"
            value={filterText}
            onChange={(e) => handleChangeFilterText(e)}
            className={styles.filter_text}
          />
          <input
            id="search"
            type="text"
            placeholder="Filtrar por colegio"
            aria-label="Search Input"
            value={institutionFilter}
            onChange={(e) => setInstitutionFilter(e.target.value)}
            className={styles.filter_text}
            style={{marginLeft: '5px'}}
          />
          </div>
          <div className={styles.filter}>
          <select value={programFilter} className={styles.select}
          onChange={(e) => setProgramFilter(e.target.value)}>
            <option value={0}>Filtrar por programa</option>
            {programs.map((program) => (
              <option value={program.id}>{program.name}</option>
            ))}
          </select>
          <select value={stageFilter} className={styles.select}
          onChange={(e) => setStageFilter(e.target.value)}>
            <option value={""}>Filtrar por etapa</option>
            <option value={"Preescolar"}>Preescolar</option>
            <option value={"Primaria Menor"}>Primaria Menor</option>
            <option value={"Primaria Mayor"}>Primaria Mayor</option>
            <option value={"Bachillerato"}>Bachillerato</option>
          </select>
          </div>
        </div>
        <div className={styles.data_table}>
          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            pointerOnHover
            fixedHeader
            fixedHeaderScrollHeight="515px"
            onRowClicked={(row) => handleRowClick(row)}
          />
        </div>
      </div>
    </Container>
  );
}

export default TeachersPage;
