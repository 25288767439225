import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, FormControlLabel, Typography } from '@mui/material';
import Modal from '../Modal';
import './InnovativeTeacherModal.css';
import { CurrentUserContext } from '../../context/CurrentUserProvider';
import { useLoading } from '../../context/LoadingContext'; 
import LoadingComponent from '../LoadingComponent';

const InnovativeTeacherModal = ({ setOpenInnovative }) => {
  const [imageContent, setImageContent] = useState(null);
  const [checked, setChecked] = useState(false); 
  const { currentUser, acceptAgreement } = useContext(CurrentUserContext);
  const { showLoading, hideLoading } = useLoading();
  const hasFetched = useRef(false); // Control de carga


  useEffect(() => {

    const fetchHTML = async () => {
      if (hasFetched.current) 
        return;
      
      try {
        showLoading();    
        const response = await fetch('https://kuriosedu.s3.us-east-2.amazonaws.com/app/Pop+up+-+Docente+Innovador+(1).png');
        if (response.ok) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          setImageContent(imageUrl);
          hasFetched.current = true;
        }
      } catch (error) {
        console.error('Error fetching Image file:', error);
        hideLoading();
      }
      finally {
        hideLoading();
      }
    };

    fetchHTML();
  }, []);

  const handleCancel = () => {
    setOpenInnovative(false);
  };

  return (
    <Modal title={"Docente Innovador"} modalOpen={setOpenInnovative}>
      <div className="reward-container" style={{ textAlign: 'center' }}>
                <Typography variant="body2" style={{ fontSize: '18px', fontWeight: 'bold', color: 'var(--main-blue)' }}>
                ¡Sigue las instrucciones y participa por el premio a docente innovador!
                </Typography>
      </div>
     <div className="image-container">
        {imageContent ? (
          <img src={imageContent} alt="Docente Innovador" style={{ width: '50%', margin: '0 auto' , alignContent: 'center'}} />
        ) : (
          <LoadingComponent />
        )}
      </div>      
        <div className="instructions-container" style={{ marginTop: '10px', textAlign: 'center' }}>
            <Typography variant="body2" >
             <a href="http://info.kuriosedu.com/books/uvtg/" target="_blank" rel="noopener noreferrer">Encuentra las instrucciones <span class="color-aqui">aquí</span> </a>
            </Typography>
           
      </div>
    </Modal>
  );
};

export default InnovativeTeacherModal;
