// import { notification } from "antd";
import axios from "axios";
import { isArray } from "lodash";
import {
  BASE_URL,
  CONFIRM_PASSWORD_ENDPOINT,
  LOGIN_ENDPOINT,
  LS_USER_KEY,
  REGISTER_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
} from "./constants";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

const noAuthEndpoints = [
  LOGIN_ENDPOINT,
  REGISTER_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  CONFIRM_PASSWORD_ENDPOINT,
];

const instance = axios.create({ baseURL: `${BASE_URL}` });

instance.interceptors.request.use(async (config) => {
  const user = JSON.parse(localStorage.getItem(LS_USER_KEY));
  if (!noAuthEndpoints.includes(config.url || "")) {
    let accessToken = "";
    if (config.headers.Authorization) {
      accessToken = config.headers.Authorization.split(" ")[1];
    } else {
      accessToken = user.tokens.access;
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    // if (!config.headers["Content-Type"]) {
    //   config.headers["Content-Type"] = "application/json";
    // }

    const exp = jwt_decode(accessToken).exp;

    const isExpired = dayjs.unix(exp).diff(dayjs()) < 1;

    if (!isExpired) {
      return config;
    }
  }
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data


    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // catchError(error);
    return Promise.reject(error);
  }
);

const catchError = (err) => {
  let statusCode = 500;
  let message = err.message;

  if (err.response) {
    statusCode = err.response.status;

    if (statusCode === 401 || statusCode === 403) {
      //localStorage.removeItem(LS_USER_KEY);
      //localStorage.clear();
      //window.location.href = '/login'; 

    }

    if (!!err.response.data) {
      let description = {};
      Object.entries(err.response.data).forEach(([key, value]) => {
        if (isArray(value)) {
          description[key] = value[0];
        }

        description[key] = value;
      });
      message = description;
    }
  }

  //   notification.open({
  //     type: "error",
  //     message: statusCode,
  //     description: message || err.message,
  //   });

  return {
    error: statusCode,
    data: message,
  };
};

const respAdapter = (resp, successMessage, callback) => {
  //   if (successMessage) {
  //     notification.open({
  //       type: "success",
  //       message: successMessage,
  //     });
  //   }

  return {
    error: null,
    data: resp.data,
  };
};

const get = ({ url, config = {}, successMessage }) =>
  instance
    .get(url, config)
    .then((resp) => respAdapter(resp, successMessage))
    .catch(catchError);

const post = ({ url, data = {}, successMessage, config }) =>
  instance
    .post(url, data, successMessage, config)
    .then((resp) => respAdapter(resp, successMessage))
    .catch(catchError);

const patch = ({ url, data = {}, successMessage, config }) =>
  instance
    .patch(url, data, config)
    .then((resp) => respAdapter(resp, successMessage))
    .catch(catchError);

const put = ({ url, data = {}, successMessage, config }) =>
  axios
    .put(url, data, config)
    .then((resp) => respAdapter(resp, successMessage))
    .catch(catchError);

const deleted = ({ url, successMessage }) =>
  instance
    .delete(url)
    .then((resp) => respAdapter(resp, successMessage))
    .catch(catchError);

export { get, post, put, deleted, patch };
