import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { COURSES_OPTIONS } from "../utils/constants/coursesConstants";
import styles from "./InstitutionsPage.module.css";
import Container from "../components/Container";
import SettingsBar from "../components/Navbars/SettingsBar";

const CoursesAdmin = () => {
  const [coursesOption, setCoursesOption] = useState("list");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
        window.removeEventListener('resize', changeWidth)
    }
  }, [])

  useEffect(() => {
    if (!location.pathname.split("/")[2]) {
      navigate("list", { replace: true });
    } else {
      setCoursesOption(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  return (
    <div className={styles.content}>
      <SettingsBar
        optionsList={COURSES_OPTIONS}
        option={coursesOption}
        setOption={setCoursesOption}
      />
      <section className={styles.leftside}>
        <Container direction={"row"}>
          <Outlet />
        </Container>
      </section>
    </div>
  );
};

export default CoursesAdmin;
