import React from "react";
import { useOutletContext } from "react-router-dom";
import {
  BASIC_INFO,
  EMPLOYEE_INFO,
  DATE_FIELDS,
} from "../../utils/constants/profileConstants";
import { dateFormatter } from "../../utils/dateFormatter";
import styles from "./UserInfo.module.css";

const UserInfo = () => {

  const {
    selectedUser: [selectedUser],
  } = useOutletContext();

  const isEmployee = selectedUser.employee_id;

  return (
    <>
      <h2>Información básica</h2>
      <div className={styles["basic-info"]}>
        {BASIC_INFO.map((item) => {
          const displayName = item["value"];
          let value = selectedUser[`${item["key"]}`];

          if (DATE_FIELDS.includes(item.key)) {
            value = dateFormatter(value);
          }

          return (
            <div key={item.key}>
              <div className={styles["info-item"]}>
                <div className={styles["info-item__label"]}>{displayName}</div>
                <div className={styles["info-item__value"]}>{value}</div>
              </div>
            </div>
          );
        })}
       <div className={styles["info-item"]}>
          <div className={styles["info-item__label"]}
          style={{cursor: 'pointer'}} >Diplomado STEAM</div>
        </div>
      </div>

      {isEmployee && (
        <>
          <h2>Información de empleado</h2>
          <div className={styles["basic-info"]}>
            {EMPLOYEE_INFO.map((item) => {
              return (
                <>
                  <div className={styles["info-item"]} key={item.key}>
                    <div className={styles["info-item__label"]}>
                      {item["value"]}
                    </div>
                    <div className={styles["info-item__value"]}>
                      {selectedUser[`${item["key"]}`]}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default UserInfo;
