import React from 'react'

function StudentsResources() {
  return (
    <div>
        <h1>Recursos</h1>
    </div>
  )
}

export default StudentsResources
