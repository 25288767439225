import React, {useState, useEffect, useContext} from 'react'
import ReactDOM from "react-dom";
import styles from './ModalEdit.module.css'
import { REPORTS_ENDPOINT } from '../../../utils/constants';
import { CurrentUserContext } from '../../../context/CurrentUserProvider';
import { get , patch} from '../../../utils/http';
import { useLoading } from "../../../context/LoadingContext";
import LoadingComponent from '../../LoadingComponent';
import ReportePDF from './ReportePDF';
import { dateFormatter } from '../../../utils/dateFormatter';

function ReportEdit({report, onUpdateReportStatus}) {
    const [remainingCharacters, setRemainingCharacters] = useState(5000); 
    const [status, setStatus] = useState(report.status);
    const [ authorized, setAuthorized ] = useState(false);
    const {currentUser} = useContext(CurrentUserContext);
    const [openModal, setOpenModal] = useState(true);
    const { showLoading, hideLoading, isLoading } = useLoading();
    const [teachersFeedback, setTeachersFeedback] = useState([]);
    const [reportReady, setReportReady] = useState(false);
    const [questions , setQuestions] = useState([]);
    const [answers, setAnswers] = useState({
        comentariosGenerales: ""
      })

    const institution = {
        name: report.institution_name,}
      useEffect(() => {
        if (currentUser?.role === "IT" || currentUser?.role === "monitoring_coordinator" || 
        currentUser?.role === "monitoring_teacher_3") {
            setAuthorized(true);
        }
      }, [currentUser]);
      useEffect(() => {
        if (report) {
         showLoading();
          const fetchFeedbacks = async () => {
            const url = `${REPORTS_ENDPOINT}${report.id}/`;
            const response = await get({ url });
            if (!response.error) {
                setQuestions(response.data.feedback_questions || []);
                const initialAnswers = { comentariosGenerales: response.data.comments || "" };
                Object.entries(response.data.feedback_questions || {}).forEach(([section, questionsInSection]) => {
                  initialAnswers[section] = {};
                  questionsInSection.forEach((pregunta) => {
                    initialAnswers[section][pregunta.question_id] = pregunta.answer;
                  });
                });
                setAnswers(initialAnswers);
                setRemainingCharacters(5000 - (report.comments || "").length);
                hideLoading();
          }
         
        }
        fetchFeedbacks();
      };
   
    }, [report]);
    
      const formatAnswers = (answers, questions) => {
        const newFormData = {};
        newFormData.comentariosGenerales = answers.comentariosGenerales ? answers.comentariosGenerales : "";
      
        Object.entries(answers).forEach(([section, sectionData]) => {
          if (section !== "comentariosGenerales") {
            const formattedSection = {};
            Object.entries(sectionData).forEach(([questionId, respuesta]) => {
              if (respuesta !== null && respuesta !== "") { // Verifica que la respuesta no sea null o ""
                // Encuentra la pregunta correspondiente en el array de preguntas
                const preguntaTexto = questions[section].find(p => p.question_id == questionId)?.question;
                formattedSection[preguntaTexto] = respuesta === true ? "Sí" : respuesta === false ? "No" : "No aplica";
              }
            });
            if (Object.keys(formattedSection).length > 0) {
              newFormData[section] = formattedSection;
            }
          }
        });
      
        return newFormData;
      };

      const formatAnswersBackend = (answers) => {
        const formattedAnswers = {};
      
        Object.entries(answers).forEach(([section, sectionAnswers]) => {
          if (section !== "comentariosGenerales") {
            Object.entries(sectionAnswers).forEach(([questionId, value]) => {
              formattedAnswers[`answer_${questionId}`] = value;
            });
          }
        });
      
        return formattedAnswers;
      };

      const showOpenReport = (result) => {  
        const newWindow = window.open("", "_blank");
        newWindow.document.title = "Reporte trimestral";
        newWindow.document.body.innerHTML = `<div id="reporte-container"></div>`;
        ReactDOM.render(
          <ReportePDF teachersFeedback={teachersFeedback}
           institution={institution}/>,
          newWindow.document.getElementById("reporte-container")
        );
      };
    const handleChange = (section, questionId, value) => {
        setAnswers((prevAnswers) => ({
          ...prevAnswers,
          [section]: {
            ...prevAnswers[section],
            [questionId]: value,
          },
        }));
      };

    const handleCommentChange = (event) => {
        const comment = event.target.value;
        const remaining = 5000 - comment.length; // Adjust the limit as needed
        setRemainingCharacters(remaining);
        setAnswers((prevAnswers) => ({
          ...prevAnswers,
          comentariosGenerales: event.target.value,
        }));

      };

      

      const handleAcceptEdit = (formData) => {
        const formattedAnswers = formatAnswersBackend(answers);
        // para el PDF
        const teacherNameParts = report.teacher_name ? report.teacher_name.split(" ") : [];
         const teacherInfo = {
          firstName: report.teacher_name.split(" ")[0],
          lastName: `${teacherNameParts[1] || ""} ${teacherNameParts[2] || ""} ${teacherNameParts[3] || ""} ${teacherNameParts[4] || ""}`.trim(),
          stages: report.stage,
          elaborado: `${report.created_by} `,
        };
        const newFormData = formatAnswers(answers, questions);
        const feedbackWithTeacher = {
          teacherInfo,
          formData: newFormData,
          // Para el backend
          ...formattedAnswers,
          comments: answers.comentariosGenerales, 
          teacher : report?.teacher,
          status: "Completado",

        };
        const fetchReports = async () => {
          showLoading();
          try {
            const url = `${REPORTS_ENDPOINT}${report.id}/`;
            const response = await patch({url, data: feedbackWithTeacher});            
            if (!response.error) {
                setReportReady(true);
                onUpdateReportStatus(report.id, "Completado");// Llamar a la función onUpdateReportStatus para actualizar el estado de los reportes
                hideLoading();
                
            }
           
          } catch (error) {
            console.error(error);
            setReportReady(false);
            hideLoading();
          }
        };
        fetchReports();
        setTeachersFeedback([feedbackWithTeacher]);
        setOpenModal(false);
          }

        // const reportStatus = someCondition ? "Completado" : "Revisado";

        const handleAprobado = (formData) => {
        const formattedAnswers = formatAnswersBackend(answers);
        // para el PDF
        const teacherNameParts = report.teacher_name ? report.teacher_name.split(" ") : [];
        const teacherInfo = {
          firstName: report.teacher_name.split(" ")[0],
          lastName: `${teacherNameParts[1] || ""} ${teacherNameParts[2] || ""} ${teacherNameParts[3] || ""} ${teacherNameParts[4] || ""}`.trim(),
          stages: report.stage,
          elaborado: `${report.created_by} `,
        };
        const newFormData = formatAnswers(answers, questions);
        const feedbackWithTeacher = {
          teacherInfo,
          formData: newFormData,
          // Para el backend
          ...formattedAnswers,
          comments: answers.comentariosGenerales,
          status: "Revisado",
        };
        const fetchReports = async () => {
          showLoading();
          try {
            const url = `${REPORTS_ENDPOINT}${report.id}/`;
            const response = await patch({url, data: feedbackWithTeacher});
            if (!response.error) {
                setReportReady(true);

                onUpdateReportStatus(report.id, "Revisado");// Llamar a la función onUpdateReportStatus para actualizar el estado de los reportes
                hideLoading();
            }
          } catch (error) {
            console.error(error);
            setReportReady(false);
            hideLoading();
          }
        };
        fetchReports();
        setTeachersFeedback([feedbackWithTeacher]);

      };


    
  useEffect(() => {
    if (reportReady && teachersFeedback.length > 0) {
      showOpenReport();
      setReportReady(false);
    }
  }, [reportReady,teachersFeedback]);

  return (
    
    <div style={{ height: '100%', overflowY: 'scroll' }}>
        <div>
        {isLoading && <LoadingComponent />}
        <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '10px' }}>
        {report.teacher_name}
        </div>
        {Object.entries(questions).map(([section, questionsInSection]) => (
        <div key={section}>
            <h3 style={{ margin: 0, paddingTop: '5px' }}>{section}</h3>
            {questionsInSection.map((pregunta) => (
            <div key={pregunta.question_id}>
                <label style={{ textAlign: 'start', paddingTop: '5px' }}>
                {pregunta.question}
                </label>
                <input
                type="radio"
                name={`${section}-${pregunta.question_id}`}
                value="true"
                checked={answers[section]?.[pregunta.question_id] === true}
                onChange={() =>
                    handleChange(section, pregunta.question_id, true)
                }
                />{' '}
                Si
                <input
                type="radio"
                name={`${section}-${pregunta.question_id}`}
                value="false"
                checked={answers[section]?.[pregunta.question_id] === false}
                onChange={() =>
                    handleChange(section, pregunta.question_id, false)
                }
                style={{ marginLeft: '7px' }}
                />{' '}
                No
                <input
                type="radio"
                name={`${section}-${pregunta.question_id}`}
                value="No aplica"
                checked={answers[section]?.[pregunta.question_id] === null}
                onChange={() => handleChange(section, pregunta.question_id, null)}
                style={{ marginLeft: '7px' }}
                />{' '}
                No aplica
            </div>
            ))}
        </div>
        ))}

        <div style={{ position: 'relative', paddingTop: '10px' }}>
        <label>Comentarios Generales:</label>
        <textarea
            value={answers.comentariosGenerales}
            onChange={handleCommentChange}
            style={{ width: '100%', borderRadius: '10px', padding: '5px' }}
            maxLength={5000}
            rows={6}
        />
        <div style={{
            position: 'absolute',
            bottom: '5px',
            right: '10px',
            fontSize: '12px',
            color: remainingCharacters < 0 ? 'red' : 'black'
        }}>
            {remainingCharacters} / 5000 caracteres
        </div>
        </div>  
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: '20px' }}>
              {authorized &&  report.status!=='Revisado' &&(
                <button onClick={() => handleAprobado(answers)} className={styles.button2}>Aprobado</button>
              )}
              <button onClick={() => handleAcceptEdit(answers)} className={styles.button}>Aceptar</button>
            </div>     
            </div>     
  </div>
)
}

export default ReportEdit;