import React, { useState, useEffect, useCallback, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import styles from './CreateTemplatePlanilla.module.css'
import { MyTextInput,
  MySubmitButton, MySelect } from '../../FormFields';
import { useForms } from "../../../context/FormsContext";
import { MonitorFormsContext } from "../../../context/MonitorFormsContext";
import { QUESTIONS_FORM_ENDPOINT, ACADEMIC_TERMS } from "../../../utils/constants";
import Container from "../../Container";
import { get } from "../../../utils/http";
import CreatePlanilla from "./CreatePlanilla";

const INITIAL_VALUES = {
  name: "",
  term: "",
  questions: "",
};

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function CreateTemplatePlanilla() {
  const { createFormTemplate } = useForms();
  const { createPlanilla } = useContext(MonitorFormsContext);
  const [questions, setQuestions] = useState([]);
  const [terms, setTerms] = useState([])
  const [filterText, setFilterText] = useState("");
  const [filterArea, setFilterArea] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      const url = QUESTIONS_FORM_ENDPOINT;
      const response = await get({ url });
      if (!response.error) {
        setQuestions(response.data);
      }
    };
    fetchQuestions();
  }, []);

  useEffect(() => {
    const fetchTerms = async () => {
      const url = ACADEMIC_TERMS;
      const response = await get({ url });
      if (!response.error) {
        setTerms(response.data);
      }
    };
    fetchTerms();
  }, []);

  let filterData = questions?.filter((item) => {
    if (filterText === "" && filterArea === "" ) {
      return item;
    } else {
      let contentMatch = true;
      if (filterText !== "") {
        contentMatch = item.content
          ?.toLowerCase()
          .includes(filterText.toLowerCase());
      }

      let areaMatch = true;
      if (filterArea !== "") {
        areaMatch = item?.area === filterArea;
      }

      if (areaMatch && contentMatch ) {
        return item;
      }
    }
  });

  const columns = [
    {
      name: "Area",
      selector: (row) => row.area,
      sortable: true,
      cell: (cell) => <p>{`${cell.area}`}</p>,
    },
    {
      name: "Pregunta",
      selector: (row) => row.content,
      sortable: true,
      cell: (cell) => <p>{`${cell.content}`}</p>,
    },
    {
      name: "Peso",
      selector: (row) => row.weight,
      sortable: true,
    },
    {
      name: "Importante",
      selector: (row) => row.important,
      sortable: true,
      cell: (cell) => <p>{`${cell.important ? "Si" : "No"}`}</p>,
    },
    {
      name: "Comentario Obligatorio",
      selector: (row) => row.mandatory_comment,
      sortable: true,
      cell: (cell) => <p>{`${cell.mandatory_comment ? "Si" : "No"}`}</p>,
    },
    
  ];

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const createTemplateHandler = ({ values }) => {
    const selectedQuestions = [];
    selectedRows?.forEach((question) => {
      selectedQuestions.push(question.id);
    });
    const questionData = {
      name: values.name,
      term: values.term,
      questions: selectedQuestions,
    };
    createPlanilla({ data: questionData })

    navigate("/planillas");
  };

  return (
    <Container>
      <i
      className="fal fa-arrow-left"
      style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
      onClick={() => navigate(-1)}
      ></i>
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={Yup.object({
        term: Yup.number().required("Campo requerido"),
        name: Yup.string().required("Campo requerido"),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        createTemplateHandler({ values });
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
        resetForm();
      }}
    >
      <Form className={styles["form__fields"]}>
        <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
          <Grid item md={6} sm={6} xs={12}>
          <MyTextInput
            label="Nombre"
            name="name"
            type="text"
            placeholder="Nombre"
            icon="fa fa-user"
            width={"100%"}
          />

          </Grid>
          <Grid item md={6} sm={6} xs={12}>
          <MySelect name="term" label={"Lapso"}>
            {terms?.map((term) => (
              <MenuItem value={term.id} key={term.id}>
                {`${term.name}`}
              </MenuItem>
            ))}
          </MySelect>
            
          </Grid>
        </Grid>
        <p style={{display: 'flex', width:'100%', color: '#000', fontWeight:'bold'}}>
          Seleccione las preguntas</p>
        <div className={styles.data_table} style={{ width: "100%" }}>
        
      <div className={styles["search-bar"]}>
        <input
          id="search"
          type="text"
          placeholder="Buscar pregunta"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          className={styles.filter_text}
        />
        <select
          value={filterArea}
          onChange={(e) => setFilterArea(e.target.value)}
          className={styles.filter_text}
        >
          <option value="">{`Filtrar por área`}</option>
          <option value="Docente">Docente</option>
          <option value="Estudiante">Estudiante</option>
          <option value="Materiales">Materiales</option>
          <option value="Requerimientos">Requerimientos</option>
        </select>
      </div>
      <DataTable
        columns={columns}
        data={filterData}
        customStyles={customStyles}
        sortIcon={
          <i
            className="far fa-arrow-up"
            style={{ marginLeft: "5px", fontSize: "14px" }}
          ></i>
        }
        highlightOnHover
        pointerOnHover
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        onSelectedRowsChange={handleRowSelected}
      />
      </div>
        <MySubmitButton name="Crear" type="submit" />
      </Form>
    </Formik>
  </Container>
  )
}

export default CreateTemplatePlanilla
