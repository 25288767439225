import React, { useState, useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Container from "../Container";
import styles from "./TeachersList.module.css";
import { TEACHERS_ENDPOINT, PROGRAMS_ENDPOINT } from "../../utils/constants";
import { get } from "../../utils/http";
import { timestampFormatter } from "../../utils/timestampFormatter";
import LoadingComponent from "../LoadingComponent";
import { useLoading } from "../../context/LoadingContext";
import { dateFormatter } from "../../utils/dateFormatter";
import { handleDownloadExcel } from "../../utils/downloadToExcel";
import { CurrentUserContext } from "../../context/CurrentUserProvider";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function TeachersList() {
  const [teachers, setTeachers] = useState([]);
  const [programs, setPrograms] = useState([])
  const [ordenAscendente, setOrdenAscendente] = useState(true);
  const [institutionFilter, setInstitutionFilter] = useState("");
  const [stageFilter, setStageFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("")
  const [programFilter, setProgramFilter] = useState(0)
  const [monitorTeacher, setMonitorTeacher] = useState(null)
  const [internalTeachers, setInternalTeachers] = useState([])
  const [teachersNoLog, setTeachersNoLog] = useState(0)
  const [finalTeachers, setFinalTeachers] = useState([])
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    const internalT = teachers?.filter((item) => item.type !== "External")
    setInternalTeachers(internalT)
  }, [teachers]);

  useEffect(() => {
    if(currentUser?.role === "academic_coordination" || 
    currentUser?.role === "academy_coordination"){
      setFinalTeachers(internalTeachers)
    }else{
      setFinalTeachers(teachers)
    }
  }, [currentUser, teachers, internalTeachers]);

  const navigate = useNavigate();

  const url = `${TEACHERS_ENDPOINT}`;

  useEffect(() => {
    showLoading()
    const fetchTeachers = async () => {
      const response = await get({ url });
      setTeachers(response.data);
      hideLoading()
    };
    fetchTeachers();
  }, [url]);

  useEffect(() => {
    const fetchPrograms = async () => {
      const url = PROGRAMS_ENDPOINT;
      const response = await get({ url });
  
      if (!response.error) {
        setPrograms(response.data);
      }
    };
    fetchPrograms();
  }, []);

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.info.first_name,
      sortable: true,
      cell: (cell) => <p>{`${cell?.info?.first_name} ${cell?.info?.last_name}`}</p>,
    },
    {
      name: "Tipo",
      selector: (row) => row.type,
      sortable: true,
      cell: (cell) => {
        let tipo;
        if (cell.type === "External") {
          tipo = "Externo";
        } else {
          let nivel;
          if (cell.level === 1) {
            nivel = "I";
          } else if (cell.level === 2) {
            nivel = "II";
          } else if (cell.level === 3) {
            nivel = "III";
          }
          tipo = `Interno - Nivel ${nivel}`;
        }
        return <p>{tipo}</p>;
      },
    },
    {
      name: "Colegio",
      selector: (row) => row.institution_name,
      sortable: true,
      cell: (cell) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {cell?.institution_name?.map((institution) => {
              return <p>{institution}</p>;
            })}
          </div>
        );
      },
    },
    {
      name: "Última visita",
      selector: (row) => (row.last_monitoring ? row.last_monitoring : "Nunca"),
      sortable: true,
    },
    {
      name: "Capacitaciones",
      sortable: true,
      selector: (row) => {
        let completed = row.completed_trainings ? row.completed_trainings : 0;
        let total = row.total_trainings ? row.total_trainings : 0;

        return `${completed}/${total}`;
      },
    },
    {
      name: "Última conexión",
      selector: (row) => row.info.last_login,
      cell: (cell) => {
        let lastLogin
        if(cell?.info?.last_login){
          const userLastLogin = new Date(cell?.info?.last_login)
          lastLogin = timestampFormatter(userLastLogin)
        }else{
          lastLogin = "Nunca"
        }
        return(
        <p>{lastLogin}</p>)
        },
    },
  ];

  const filteredData = finalTeachers && finalTeachers?.filter((item) => {
    if (institutionFilter === "" && stageFilter === "" && parseInt(programFilter) === 0 && roleFilter === "" && monitorTeacher === null ) {
      return item;
    } else {

      let institutionMatch = true
      if(institutionFilter !== ""){
       institutionMatch = item.institution_name.some((institution) =>
        institution.toLowerCase().includes(institutionFilter.toLowerCase())
      );}

      let programMatch = true

      if(parseInt(programFilter) !== 0){
        programMatch = item.programs.some((program) =>
        program.id === parseInt(programFilter)
        );
      }

      let stagesMatch = true

      if(stageFilter !== ""){
        stagesMatch =
        item.stages.some((stage) =>
          stage.toLowerCase().includes(stageFilter.toLowerCase())
        ) 
      }

      let roleMatch = true
      if(roleFilter !== ""){
        roleMatch = item?.role === roleFilter;
      }

      let monitoringMatch = true
      if(monitorTeacher !== null) {
        monitoringMatch = item?.monitor === parseInt(monitorTeacher)
      }

      if (institutionMatch && roleMatch && programMatch && stagesMatch && monitoringMatch ) {
        return item;
      }
    }
  });

  const usuariosOrdenados = filteredData?.sort((a, b) => {
    if (a.info.last_login === null && b.info.last_login === null) {
      return 0; 
    } else if (a.info.last_login === null) {
      return ordenAscendente ? 1 : -1; 
    } else if (b.info.last_login === null) {
      return ordenAscendente ? -1 : 1;
    }

    return ordenAscendente ? (new Date(b.info.last_login) - new Date(a.info.last_login)) : (new Date(a.info.last_login) - new Date(b.info.last_login));
  });

  useEffect(() => {
    let teachersNo = 0
    finalTeachers?.forEach((teacher) => {
      if(!teacher?.info?.last_login){
        teachersNo += 1
      }
    })
    setTeachersNoLog(teachersNo)
  }, [finalTeachers]);

  const exportData = usuariosOrdenados?.map(item => ({
    Nombre: `${item.info.first_name} ${item.info.last_name}`,
    Tipo: item.type === "External" ? "Externo" : `Interno - Nivel ${item.level}`,
    Colegio: item.institution_name.join('\n'), // Concatena los nombres de colegios en una sola cadena
    'Última visita': item.last_monitoring || "Nunca",
    Capacitaciones: `${item.completed_trainings || 0}/${item.total_trainings || 0}`,
    'Última conexión': item.info.last_login ? dateFormatter(new Date(item.info.last_login)) : "Nunca",
  }));

  return (
    <Container direction={"column"}>
      <div className={styles.teachers_info}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles.content}>
          <div className={styles.input_box} >
          <input
            id="search"
            type="text"
            placeholder="Filtrar por colegio"
            aria-label="Search Input"
            value={institutionFilter}
            onChange={(e) => setInstitutionFilter(e.target.value)}
            className={styles.filter_text}
            style={{ marginLeft: "5px" }}
          />
          <select value={programFilter} className={styles.select}
          onChange={(e) => setProgramFilter(e.target.value)}>
            <option value={0}>Filtrar por programa</option>
            {programs?.map((program) => (
              <option value={program.id}>{program.name}</option>
            ))}
          </select>
          </div>
          <div className={styles.input_box}>
          <select value={stageFilter} className={styles.select}
          onChange={(e) => setStageFilter(e.target.value)}>
            <option value={""}>Filtrar por etapa</option>
            <option value={"Preescolar"}>Preescolar</option>
            <option value={"Primaria Menor"}>Primaria Menor</option>
            <option value={"Primaria Mayor"}>Primaria Mayor</option>
            <option value={"Bachillerato"}>Bachillerato</option>
          </select>
          <select value={monitorTeacher} className={styles.select}
          onChange={(e) => setMonitorTeacher(e.target.value)}>
            <option value={null}>Filtrar por docente de seguimiento</option>
            {finalTeachers?.map((teacher) => (
              <>
              {(teacher.role === "monitoring_teacher" || teacher.role === "monitoring_coordinator" ||
    currentUser?.role === "monitoring_teacher_3") &&
                <option value={teacher?.info?.id}>{`${teacher?.info?.first_name} ${teacher?.info?.last_name}`}</option>
              }
              </>
            ))}
          </select>
          </div>
          {/*<select value={roleFilter} className={styles.select}
          onChange={(e) => setRoleFilter(e.target.value)}>
            <option value={""}>Filtrar por tipo</option>
            <option value={"monitoring_teacher"}>Docente seguimiento</option>
            <option value={"monitoring_coordinator"}>Seguimiento coordinación</option>
            <option value={"external_teacher"}>Docente externo</option>
            <option value={"internal_teacher"}>Docente interno</option>
            </select>*/}
        </div>
        <div className={styles.content}>
          <div style={{display: 'flex', gap: '5px', alignItems: 'center', cursor: 'pointer',}}
          onClick={() => setOrdenAscendente(!ordenAscendente)}>
          <p style={{fontSize: '12px'}}>Filtrar por última conexión</p>
          {ordenAscendente ? 
          <i class="fas fa-arrow-down" style={{fontSize: '12px'}}></i> : 
          <i class="fas fa-arrow-up" style={{fontSize: '12px'}}></i>}
          </div>
        </div>
        <div className={styles.data_table}>
        <button onClick={() => handleDownloadExcel(exportData, 'Tabla de docentes')} className={styles.download}>Descargar Tabla</button>
          <DataTable
            columns={columns}
            data={usuariosOrdenados}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            pointerOnHover
            fixedHeader
            fixedHeaderScrollHeight="470px"
          />
        </div>
        <div className={styles.questions_total}>
          <div style={{display: 'flex', flexDirection: 'row', 
            alignItems: 'center'}}>
            <p style={{fontWeight: 'bold', fontSize: '12px'}}>
              Cantidad de docentes sin ingresar:
            </p>
            <p style={{textAlign: 'center', fontSize: '14px', paddingLeft: '5px'}}>
              {`${teachersNoLog}/${finalTeachers?.length}`}
            </p>
          </div>
        </div>
      </div>
      {isLoading && <LoadingComponent />}
    </Container>
  );
}

export default TeachersList;
