import React, { useContext, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import styles from "./ListCapacitaciones.module.css";
import { CapacitacionesContext } from "../../../context/CapacitacionesContext";
import { CAPACITACION_ENDPOINT } from "../../../utils/constants";
import { PROGRAMS_ENDPOINT } from "../../../utils/constants";
import { get } from "../../../utils/http";
import { handleDownloadExcel } from "../../../utils/downloadToExcel";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function ListCapacitaciones() {
  const [capacitaciones, setCapacitaciones] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [filterTerm, setFilterTerm] = useState("")
  const [filterTitle, setFilterTitle] = useState("")
  const { setSelectedCapacitacion } = useContext(CapacitacionesContext);

  useEffect(() => {
    const fetchPrograms = async () => {
      const url = `${PROGRAMS_ENDPOINT}`;
      const response = await get({ url });

      setPrograms(response.data);
    };
    fetchPrograms();
  }, []);

  useEffect(() => {
    const fetchCapacitaciones = async () => {
      const url = `${CAPACITACION_ENDPOINT}`;
      const response = await get({ url });

      setCapacitaciones(response.data);
    };
    fetchCapacitaciones();
  }, []);

  const navigate = useNavigate();

  const columns = [
    {
      name: "Título",
      selector: (row) => row.title,
      sortable: true,
      cell: (cell) => (
        <div>{cell.title}</div>
      )
    },
    {
      name: "Programas",
      selector: (row) => row.programs,
      sortable: true,
      cell: (cell) => {
        const programas = [];
        cell.programs?.forEach((program) => {
          programs?.forEach((pr) => {
            if (program === pr.id) {
              programas.push(pr.name);
            }
          });
        });
        return (
          <div style={{ marginTop: "5px" }}>
            {programas.map((pp) => (
              <p style={{ marginBottom: "5px" }}>{pp}</p>
            ))}
          </div>
        );
      },
    },
    {
      name: "Fecha de inicio",
      selector: (row) => row.start_date,
      sortable: true,
    },
    {
      name: "Fecha de cierre",
      selector: (row) => row.end_date,
      sortable: true,
    },
    {
      name: "Lapso",
      selector: (row) => row.term.name,
      sortable: true,
    },
    {
      name: "Entregas",
      selector: (row) => row.tasks,
      sortable: true,
      cell: (cell) => {
        return <p>{cell.quiz ? cell.completed_quiz_number : cell?.tasks_number || 0}/{cell?.teachers?.length}</p>;
      },
    },
    {
      name: "Editar",
      sortable: true,
      cell: (cell) => {
        return <i class="fas fa-edit" 
        onClick={() => navigate(`/seguimiento/capacitaciones/agregar-capacitacion/${cell.id}`)}
        style={{color: 'var(--main-blue)', fontSize: '15px', cursor: 'pointer'}}></i>;
      },
    },
  ];

  const handleRowClick = (row) => {
    setSelectedCapacitacion(row);
    navigate(`/seguimiento/capacitaciones/${row.id}`, { relative: false });
  };

  const exportData = capacitaciones?.map((item) => ({
    "Título": item?.title,
    "Etapa": item?.stage,
    "Fecha de inicio": item?.start_date,
    "Fecha de fin": item?.end_date,
    "Lapso": item?.term?.name,
    "Quiz": `${item?.quiz ? "Si" : "No"}`,
    "Tarea de entrega": `${item?.task_info ? "Si" : "No"}`,
    "Cantidad de docentes": item?.teachers?.length,
    "# de entregas": item?.quiz ? item?.completed_quiz_number : item?.tasks_number,
  }));

  let filterData = capacitaciones?.filter(
    (item) =>{
      if (filterTerm === "" && filterTitle === "") {
        return item;
      }else{

        let termMatch = true;
        if (filterTerm !== "") {
          termMatch = item?.term?.name === filterTerm
        }

        let titleMatch = true;
        if (filterTitle !== "") {
          titleMatch = item.title.toLowerCase().includes(filterTitle.toLowerCase())
        }

        if (termMatch && titleMatch) {
          return item;
        }
      }
    }
  );

  return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles.head_content}>
        <button
          onClick={() =>
            handleDownloadExcel(exportData, "Planillas de seguimiento")
          }
          className={styles.download}
        >
          Descargar Tabla
        </button>
        <div className={styles.cont}>
        <div className={styles["search-bar"]}>
        <select
          value={filterTerm}
          className={styles.filter_text}
          onChange={(e) => setFilterTerm(e.target.value)}
        >
          <option value={''}>Filtrar por lapso</option>
          <option value={'I'}>I Lapso</option>
          <option value={'II'}>II Lapso</option>
          <option value={'III'}>III Lapso</option>
        </select>
        </div>
        <div className={styles["search-bar2"]}>
        <input
          id="search"
          type="text"
          placeholder="Buscar por nombre"
          aria-label="Search Input"
          value={filterTitle}
          onChange={(e) => setFilterTitle(e.target.value)}
          className={styles.filter_text}
        />
        </div>
        </div>
        </div>
        <div className={styles.table}>
          <DataTable
            columns={columns}
            data={filterData}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight="580px"
            pointerOnHover
            onRowClicked={(row) => handleRowClick(row)}
            noDataComponent="No hay capacitaciones para mostrar"
          />
        </div>
      </div>
  );
}

export default ListCapacitaciones;
