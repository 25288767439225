import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.css";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import { UsersContext } from "../../context/UsersProvider";
import { DRAWER_OPTIONS,
  DRAWER_TEACHER_COORDINATOR,
  DRAWER_OPTIONS_TEACHERS,
  DRAWER_OPTIONS_TEACHER_INTERNAL,
  DRAWER_OPTIONS_SEGUIMIENTO,
  DRAWER_OPTIONS_STUDENTS, 
  DRAWER_OPTIONS_DEVELOPMENT_TEACHER_INTERNAL,
  DRAWER_OPTIONS_TEACHERS_CHILE, DRAWER_TEACHER_COORDINATOR_CHILE } from "./constants";

function Sidebar() {
  const [activeLinkIndex, setActiveLinkIndex] = useState("home");
  const { setUserAct } = useContext(UsersContext);
  const [userDrawerOpt, setUserDrawerOpt] = useState([])
  const { logoutUser, currentUser } = useContext(CurrentUserContext);
  const location = useLocation();

  useEffect(() => {
    if (currentUser.role === "IT") {
      setUserDrawerOpt(DRAWER_OPTIONS)
    } else if (
      currentUser.role === "monitoring_coordinator" ||
      currentUser.role === "monitoring_teacher" || currentUser.role === "post_sales" || currentUser.role === "monitoring_teacher_3"
    ) {
      setUserDrawerOpt(DRAWER_OPTIONS_SEGUIMIENTO)
    } else if (currentUser.role === "external_coordinator" ) {
      if(currentUser.branch === "VES"){
        setUserDrawerOpt(DRAWER_TEACHER_COORDINATOR)
      }else{
        setUserDrawerOpt(DRAWER_TEACHER_COORDINATOR_CHILE)
      }
    } else if (currentUser.role === "internal_teacher" ){
      setUserDrawerOpt(DRAWER_OPTIONS_TEACHER_INTERNAL)
    }else if(currentUser.role === "academic_coordination" || currentUser.role === "academy_coordination"){
      setUserDrawerOpt(DRAWER_OPTIONS_SEGUIMIENTO)
    }
    else if(currentUser.role === "development_teacher"){
      setUserDrawerOpt(DRAWER_OPTIONS_DEVELOPMENT_TEACHER_INTERNAL)
    }
    else if(currentUser.role === "student"){
      setUserDrawerOpt(DRAWER_OPTIONS_STUDENTS)
    }
    else {
      if(currentUser.branch === "VES"){
        setUserDrawerOpt(DRAWER_OPTIONS_TEACHERS)
      }else{
        setUserDrawerOpt(DRAWER_OPTIONS_TEACHERS_CHILE)
      }
     
    }
  }, [currentUser]);

  useEffect(() => {
    const pathParts = location.pathname.split('/')
    const primeraParte = pathParts[1] || ''
    if(location.pathname === "/"){
      setActiveLinkIndex("home")
    }else{
      userDrawerOpt?.forEach((option) => {
      
      if (option.route.includes(primeraParte)) {
        setActiveLinkIndex(option.name.toLowerCase());
      }
    })}
  }, [location.pathname]);

  const handleLinkClick = (index) => {
    setActiveLinkIndex(index.toLowerCase());
  };

  const handleLogut = () => {
    setUserAct(null)
    logoutUser()
  }

  return (
    <div className={styles.sidebar}>
      <div className={styles.navigation}>
        <ul className={styles.options}>
          <li>
            <a href="#"></a>
          </li>
          {userDrawerOpt?.map((op) => (
            <li
              className={
                activeLinkIndex?.toLowerCase() === op.name.toLowerCase()
                  ? styles.hovered
                  : ""
              }
              onClick={() => handleLinkClick(op.name)}
              key={op.name}
            >
              <Link to={op.route}>
                <span className={styles.icon}>{op.icon}</span>
                <span className={styles.title} 
                style={{ lineHeight: op.name === "Panel de actividades" && 1.5,
                  whiteSpace: op.name === "Panel de actividades" &&'normal',
                  wordWrap: op.name === "Panel de actividades" &&'break-word',
                  flexGrow:op.name === "Panel de actividades" && 1}}>{op.name}</span>
              </Link>
            </li>
          ))}
        </ul>
        {currentUser.role !== "IT" && 
        <div className={styles.lastset}>
          <ul>
            <li></li>
            <li>
              <Link to="/" onClick={handleLogut}>
                <span className={styles.icon}>
                  <i className="far fa-sign-out"></i>
                </span>
                <span className={styles.title}>{`Salir`}</span>
              </Link>
            </li>
          </ul>
        </div>}
      </div>
    </div>
  );
}

export default Sidebar;
