export const capacitacionesIniciales = [
    {
        id: 26,
        title: "Kurios Competition 2024 Preview",
        url: "https://youtube.com/shorts/YhCfd98pfeY"
    },
    {
        id: 25,
        title: "Acuerdo de Confidencialidad docentes Aliados Interior",
        url: "https://drive.google.com/file/d/1Hy42GwYQvnF9O6CY6n9GDsE1UugrgOu5/view?usp=sharing"
    },
    {
        id: 1,
        title: "Capacitación Inicial Kurios Education Parte 1",
        url: "https://www.youtube.com/watch?v=aoeZUM4Zlxg"
    },
    {
        id: 2,
        title: "Capacitación Inicial Kurios Education Parte 2",
        url: "https://www.youtube.com/watch?v=DjRNazCxfQw"
    },
    {
        id: 3,
        title: "Capacitación Septiembre E-Board I",
        url: "https://youtu.be/DWwUpHIRqYg"
    },
    {
        id: 4,
        title: "Capacitación Septiembre Makerlab I",
        url: "https://youtu.be/C6CJJXKaOhM"
    },
    {
        id: 5,
        title: "Capacitación Septiembre Play Block I",
        url: "https://www.youtube.com/watch?v=RSsr3zxqY_I"
    },
    {
        id: 6,
        title: "Capacitación Septiembre Play Block II",
        url: "https://www.youtube.com/watch?v=4W5CYdd-MBY"
    },
    {
        id: 7,
        title: "Capacitación Septiembre Makerlab II (Scratch)",
        url: "https://youtu.be/bgh-S317IPY"
    },
    {
        id: 8,
        title: "Capacitación Septiembre B-Block",
        url: "https://youtu.be/0-JklSL_6TQ"
    },
    {
        id: 9,
        title: "Capacitación Virtual Python Parte I",
        url: "https://youtu.be/gp9pOJO5xzA"
    },
    {
        id: 10,
        title: "Capacitación Virtual Python Parte II",
        url: "https://youtu.be/M6L3_kwDYbw"
    },
    {
        id: 11,
        title: "Capacitación Virtual Python Parte III",
        url: "https://youtu.be/7cJaosjC7UI"
    },
    {
        id: 12,
        title: "Capacitación Virtual Python Parte IV",
        url: "https://youtu.be/YKwcOXctfSo"
    },
    {
        id: 21,
        title: "Capacitación Virtual Python Parte V",
        url: "https://youtu.be/CqeVKV3J6w8"
    },
    {
        id: 13,
        title: "Capacitación Septiembre Makerlab II (Arduino Blocks)",
        url: "https://youtu.be/WmX3yGsb8hs"
    },
    {
        id: 14,
        title: "Capacitación Septiembre E-Board I Parte II",
        url: "https://youtu.be/e_JNk86lrsQ"
    },
    {
        id: 15,
        title: "Capacitación Septiembre Eboard II",
        url: "https://youtu.be/R_KLAtKxEk8"
    },
    {
        id: 16,
        title: "Capacitación Septiembre Makerlab II (Photon AI)",
        url: "https://youtu.be/blIbFga30Jc"
    },
    {
        id: 17,
        title: "Capacitación Septiembre Makerlab II (Knex)",
        url: "https://youtu.be/DwSuKA1zr90"
    },
    {
        id: 18,
        title: "Capacitación Septiembre Makerlab III",
        url: "https://youtu.be/dHr8vgSfD14"
    },
    {
        id: 19,
        title: "Capacitación Septiembre Makerlab II (Arduino Blocks)",
        url: "https://youtu.be/1UZrBVCB3Mw"
    },
    {
        id: 20,
        title: "Capacitación Septiembre Makerlab III",
        url: "https://youtu.be/dHr8vgSfD14"
    },
    {
        id: 24,
        title: "Capacitación Marketplace día 1",
        url: "https://www.youtube.com/watch?v=hFicqBrP8sg"
    },
    {
        id: 22,
        title: "Capacitación Digital Skills I día 1",
        url: "https://youtu.be/VK_knyv7whM"
    },
    {
        id: 23,
        title: "Capacitación Digital Skills I día 2",
        url: "https://youtu.be/gKhAwxN9xKs"
    },


]
export const tutoriales = [
    {
        id: 1,
        title: "Tutorial carga de horarios para mis cursos",
        url: "https://app.tango.us/app/workflow/Paso-a-paso--Cargar-los-horarios-de-los-cursos-en-la-plataforma-bcecda2eaf60423cb59f9fcb276d5c6e"
    },
    {
        id: 2,
        title: "Tabla Programas",
        url: "https://online.fliphtml5.com/zjfpz/yjhf/"
    },
    {
        id: 3,
        title: "Tutorial para llenar día día",
        url: "https://app.tango.us/app/workflow/Paso-a-paso-llenar-d-a-d-a-de-clases-8ede094689554b50a8aac542cf8d154e"
    },
    {
        id: 4,
        title: "Tutorial para cargar resgistro fotográfico",
        url: "https://app.tango.us/app/workflow/Paso-a-paso---Registro-fotogr-fico-bd8c91bcaa8849a799f7110182c90a69"
    },
    {
        id: 5,
        title: "Tutorial para compartir resgistro fotográfico vía Google Drive",
        url: "https://app.tango.us/app/workflow/Paso-a-Paso-para-compartir-la-carpeta-de-registro-fotogr-fico-v-a-Google-Drive-17948e827a0f4f1ebbf6bebd0fbdbc1d"
    }
]

export const instalaciones = [
    {
        id: 1,
        title: "Instalación Python",
        url: "https://docs.google.com/presentation/d/e/2PACX-1vSDJPesZp-CYH2IyNkrG7XHgeLwC43RMF5vDg2vW_xen3xg8m8oMCmaVw0nZMlhMZEPQhuL44tMVaOP/pub?start=false&loop=false&delayms=3000"
    },
    {
        id: 2,
        title: "Instalación Arduino",
        url: "https://docs.google.com/presentation/d/e/2PACX-1vSZrG9IdhqxTufIp34AUvYrh7dvGBcHND5PwTRMjwjo1U4MQe5IvWiIRR-8-cBT13jjZ_M10XXX1YQH/pub?start=false&loop=false&delayms=3000"
    },
    {
        id: 3,
        title: "Instalación Scratch",
        url: "https://docs.google.com/presentation/d/e/2PACX-1vSaDaQ__0r8gHledQLukTHQX4Q02IlM6SpiPXEnhJWRhobHVtT7bbR-vcpXnAUqzz-B1uqeku7vtm2X/pub?start=false&loop=false&delayms=3000"
    }

]