function extractVideoId(url) {
  const longFormatRegex = /[?&]v=([^?&]+)/;
  const shortFormatRegex = /youtu\.be\/([^?&]+)/;
  const shortsFormatRegex = /youtube\.com\/shorts\/([^?&]+)/;

  const longFormatMatch = url?.match(longFormatRegex);
  const shortFormatMatch = url?.match(shortFormatRegex);
  const shortsFormatMatch = url?.match(shortsFormatRegex);
 
  if (longFormatMatch && longFormatMatch[1]) {
    if(longFormatMatch[1].includes('/pub')){
      const format =longFormatMatch[1].slice(0,-4)
      return format
    }else{
      return longFormatMatch[1];
    }
  } else if (shortFormatMatch && shortFormatMatch[1]) {
    return shortFormatMatch[1];
  } else if (shortsFormatMatch && shortsFormatMatch[1]) {
    return shortsFormatMatch[1];
  }else {
    return null;
  }
}

export default extractVideoId;
