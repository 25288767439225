import React, { useContext, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, Link } from "react-router-dom";
import { ProgramsContext } from "../../context/ProgramsContext";
import { PROGRAMS_ENDPOINT } from "../../utils/constants";
import { get } from "../../utils/http";
import styles from "./ProgramView.module.css";
import EditLesson from "./EditLesson";

let INITIAL_VALUES = {
  title: "",
  description: "",
  learning_subject: "",
  learning_topic:"",
  lesson_goal: "",
  learning_outcomes: "",
  generating_topic: "",
  theoric_references: "",
  curriculum_content: "",
  beginning: "",
  development: "",
  closure: "",
  script: "",
  slides: "",
  achievement_indicators: "",
  main_activities: "",
  reference_number: 0
};

const columns = [
  { 
    name: "ID",
    selector: (row) => row.lesson?.lesson_id,
    sortable: true,
    cell: (cell) => {
      return (
        <p>
          {cell.lesson?.lesson_id}
        </p>
      );
    },

  },
  {
    name: "Título",
    selector: (row) => row.lesson?.title,
    sortable: true,
    cell: (cell) => {
      return <p style={{ width: "300px" }}>{cell.lesson?.title}</p>;
    },
  },
  {
    name: "Versión",
    selector: (row) => row.version,
    sortable: true,
    cell: (cell) => {
      return (
        <p style={{ width: "35px", display: "flex", justifyContent: "center" }}>
          {cell.version}
        </p>
      );
    },
  },
  {
    name: "Clase #",
    selector: (row) => row.lesson_number,
    sortable: true,
    cell: (cell) => {
      return (
        <p style={{ width: "35px", display: "flex", justifyContent: "center" }}>
          {cell.lesson_number}
        </p>
      );
    },
  },
  {
    name: "Lapso",
    selector: (row) => row.term,
    sortable: true,
    cell: (cell) => {
      let term;
      if (cell.term === 1) {
        term = "I";
      } else if (cell.term === 2) {
        term = "II";
      } else if (cell.term === 3) {
        term = "III";
      }
      return (
        <p style={{ width: "35px", display: "flex", justifyContent: "center" }}>
          {term}
        </p>
      );
    },
  },
];

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

export const headerTerms = [
  {
    key: 1,
    name: "I Lapso",
  },
  {
    key: 2,
    name: "II Lapso",
  },
  {
    key: 3,
    name: "III Lapso",
  },
];

function ProgramView() {
  const { selectedProgram } = useContext(ProgramsContext);
  const [selectedTerm, setSelectedTerm] = useState(1);
  const [lessons, setLessons] = useState([]);
  const [lessonsTerm, setLessonsTerm] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [lessonId, setLessonId] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLessons = async () => {
      const url = `${PROGRAMS_ENDPOINT}${selectedProgram.id}/lessons/`;
      const response = await get({ url });

      if (!response.error) {
        setLessons(response.data);
      }
    };
    fetchLessons();
  }, []);

  useEffect(() => {
    if (selectedTerm === 1) {
      const dt = lessons?.filter((item) => item?.term === 1);
      setLessonsTerm(dt);
    } else if (selectedTerm === 2) {
      const dt = lessons?.filter((item) => item?.term === 2);
      setLessonsTerm(dt);
    } else if (selectedTerm === 3) {
      const dt = lessons?.filter((item) => item?.term === 3);
      setLessonsTerm(dt);
    }
  }, [selectedTerm, lessons]);

  let filteredData = lessonsTerm.filter((item) =>
    item?.lesson?.title?.toLowerCase().includes(filterText?.toLowerCase())
  );

  const onSelectHandler = (option) => {
    setSelectedTerm(option.key);
  };

  const handleOpenModal = (row) => {
    setLessonId(row.id);
    INITIAL_VALUES = {
      title: row.title,
      description: row.description,
      lerning_subject: row.lerning_subject,
      learning_topic:row.learning_topic,
      lesson_goal: row.lesson_goal,
      learning_outcomes: row.learning_outcomes,
      generating_topic: row.generating_topic,
      theoric_references: row.theoric_references,
      curriculum_content: row.curriculum_content,
      beginning: row.beginning,
      development: row.development,
      closure: row.closure,
      script: row.script,
      slides: row.slides,
      achievement_indicators: row.achievement_indicators,
      main_activities: row.main_activities,
      reference_number: row.reference_number
    };
    setInitialValues(INITIAL_VALUES);
    setModalOpen(true);
  };

  return (
    <div style={{ width: "100%" }}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer" }}
        onClick={() => navigate(-1)}
      ></i>
      <div className={styles.program_info}>
        <div className={styles.right_side}>
          <div className={styles.header_content}>
            <div className={styles.edit}>
              <p className={styles.title}>{selectedProgram?.name} - {selectedProgram?.version} - {selectedProgram?.language}</p>
              <Link to={`edit`} style={{ marginLeft: "auto" }}>
                <i
                  className="fas fa-pen"
                  style={{
                    marginLeft: "auto",
                    fontSize: "16px",
                    color: "var(--main-blue)",
                    cursor: "pointer",
                  }}
                ></i>
              </Link>
            </div>
            <p
              className={styles.header_text}
            >{`Distribuidor: ${selectedProgram?.distributor}`}</p>
            <p
              className={styles.header_text}
            >{`Licencia: ${selectedProgram?.licenss?.name}`}</p>
            <p
              className={styles.header_text}
            >{`# Clases: ${lessons?.length}`}</p>
          </div>
          
        </div>
        <div className={styles.left_side}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Clases
          </p>
          <div className={styles.headers_options}>
            {headerTerms.map((option) => {
              return (
                <p
                  className={
                    option.key === selectedTerm
                      ? styles.option_selected
                      : styles.option
                  }
                  onClick={() => onSelectHandler(option)}
                  key={option.key}
                >
                  {option.name}
                </p>
              );
            })}
          </div>
          <div className={styles.filters}>
            <div className={styles["search-bar"]}>
              <input
                id="search"
                type="text"
                placeholder="Buscar..."
                aria-label="Search Input"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className={styles.filter_text}
              />
            </div>
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            pointerOnHover
            fixedHeader
            fixedHeaderScrollHeight="450px"
            onRowClicked={(row) => handleOpenModal(row?.lesson)}
          />
        </div>
      </div>
      {modalOpen && (
        <EditLesson
          setModalOpen={setModalOpen}
          initialValues={initialValues}
          lessonId={lessonId}
        />
      )}
    </div>
  );
}

export default ProgramView;
