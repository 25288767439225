import React from "react";
import { useNavigate } from "react-router-dom";
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/system';

const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  fontSize: '13px', 
  backgroundColor: 'var(--background-gray)', borderRadius: '10px',
  margin: '5px',
  minHeight: '40px', // Establece una altura mínima
  height: '40px',
  '&:hover': {
    backgroundColor: "#E7E7E7",
    opacity: 1,
    borderRadius: '10px',
  },
  '&.Mui-selected': {
    boxShadow: '0 0 6px -1px var(--main-gray)',
    border: 'none',
  },
}));


const SettingsBar = ({ optionsList, option, setOption }) => {
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    const value = optionsList.find((item)=>item.key === newValue)
    setOption(value.key);
    navigate(value.route);
  };

  return (
      <Box sx={{backgroundColor: 'var(--background-gray)', marginBottom: '5px', 
      borderRadius: '10px', padding: '5px'}}>
        <TabContext value={option}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider',  border: 'none', }}>
          <Tabs onChange={handleChange} aria-label="visible arrows tabs example" 
            variant="scrollable" 
            sx={{
              '& .MuiTabs-indicator': {
              display: 'none',
            },}}
            value={option} >
              {optionsList.map((item) => (
                  <AntTab label={item.name} value={item.key} icon={item.icon} iconPosition="start" />
              ))}
            </Tabs>
        </Box>
        </TabContext>
      </Box>
  );
};

export default SettingsBar;