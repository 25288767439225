import React, { useState, useEffect } from "react";
import { useNavigate, Outlet, useLocation, useParams } from "react-router-dom";
import * as Survey from "survey-react";
import { useForms } from "../../../context/FormsContext";
import Container from "../../Container";
import styles from "./SurveyAdminView.module.css";

Survey.StylesManager.applyTheme("default");

let defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
defaultThemeColors["$main-color"] = "var(--main-blue)";
defaultThemeColors["$main-hover-color"] = "var(--ter-blue";

export const headerOpts = [
  {
    key: "enviar",
    name: "Enviar",
    route: "enviar",
  },
  {
    key: "editar",
    name: "Editar",
    route: "editar",
  },
  {
    key: "vista-previa",
    name: "Vista previa",
    route: "vista-previa",
  },
];
export const headerOpts2 = [
  {
    key: "answers",
    name: "Ver respuestas",
    route: "answers",
  },
  {
    key: "vista-previa",
    name: "Vista previa",
    route: "vista-previa",
  },
];

function SurveyAdminView() {
  const { selectedFormTemplate, deleteTemplate } = useForms();
  const [selectedOption, setSelectedOption] = useState(
    selectedFormTemplate.status === "Enviado" ? "answers" : "enviar"
  );

  const { templateId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const handleDeleteTemplate = () => {
    const response = deleteTemplate({ templateId });

    if (!response.error) {
      navigate("/encuestas/list");
    }
  };

  const onSelectHandler = (option) => {
    setSelectedOption(option.route);
    navigate(option.route);
  };

  useEffect(() => {
    if (!location.pathname.split("/")[4]) {
      selectedFormTemplate.status === "Enviado"
        ? navigate("answers", { replace: true })
        : navigate("enviar", { replace: true });
    } else {
      setSelectedOption(location.pathname.split("/")[4]);
    }
  }, [location.pathname]);

  return (
    <Container>
      <div className={styles.container}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate("/encuestas")}
        ></i>
        <div className={styles.header_options}>
          {selectedFormTemplate.status === "Enviado" ? (
            <>
              {headerOpts2.map((option) => {
                return (
                  <p
                    className={
                      option.route === selectedOption
                        ? styles.option_selected
                        : styles.option
                    }
                    onClick={() => onSelectHandler(option)}
                    key={option.name}
                  >
                    {option.name}
                  </p>
                );
              })}
            </>
          ) : (
            <>
              {headerOpts.map((option) => {
                return (
                  <p
                    className={
                      option.route === selectedOption
                        ? styles.option_selected
                        : styles.option
                    }
                    onClick={() => onSelectHandler(option)}
                    key={option.route}
                  >
                    {option.name}
                  </p>
                );
              })}
              <div
                className={styles["delete-survey"]}
                onClick={handleDeleteTemplate}
              >
                Eliminar
              </div>
            </>
          )}
        </div>
        <div className={styles.option_content}>
          <Outlet />
        </div>
      </div>
    </Container>
  );
}

export default SurveyAdminView;
