import { useState, createContext, useContext, useEffect } from "react";
import { get } from "../utils/http";
import { GROUPS_ENDPOINT } from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";

const GroupsContext = createContext();

const GroupsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useLocalStorage(
    "selectedGroup",
    ""
  );

  const fetcGroups = async () => {
    const url = GROUPS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setGroups(response.data);
    }
  };

  useEffect(() => {
    // fetcGroups();
  }, []);

  const contextData = {
    groups,
    setGroups,
    selectedGroup,
    setSelectedGroup,
    fetcGroups,
  };

  return (
    <GroupsContext.Provider value={contextData}>
      {loading ? null : children}
    </GroupsContext.Provider>
  );
};

const useGroups = () => {
  return useContext(GroupsContext);
};

export { GroupsContext, GroupsProvider, useGroups };
