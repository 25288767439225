import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Modal from '../../Modal';
import { get } from '../../../utils/http';
import { USER_QUIZ_ENDPOINT } from '../../../utils/constants';

const quicesOptions = ["Docente", "Status", "Nota", ""]

const TableColumns = () => {
  return quicesOptions.map((column) => {
    if (column === "Docente" ) {
      return (
        <TableCell
          key={column}
          sx={{
            color: "#565656",
            fontSize: "13px",
            fontWeight: "bold",
          }}>
          {column}
        </TableCell>
      )
    }
    return (
      <TableCell
        key={column}
        align="center"
        sx={{
          color: "#565656",
          fontSize: "13px",
          fontWeight: "bold",
        }}>
        {column}
      </TableCell>
    )
  })
}

function QuizView({userAnnualTraining}) {
  const [loading, setLoading] = useState(true)
  const [quicesAnswers, setQuicesAnswers] = useState([])
  const [openAnswers, setOpenAnswers] = useState(false)
  const [selectedQuiz, setSelectedQuiz] = useState([])
  const [totalWeight, setTotalWeight] = useState(0)

  useEffect(() => {
    let total = 0
    userAnnualTraining?.quiz?.templatequiz?.forEach((question) => {
      total += question?.question?.weight
    });
    setTotalWeight(total)
  }, [userAnnualTraining]);

  const getQuizAnswers = async () => {
    setLoading(true);
    const url = `${USER_QUIZ_ENDPOINT}?quiz=${parseInt(userAnnualTraining?.quiz?.id)}`;
    const response = await get({ url });
    setQuicesAnswers(response.data);
    setLoading(false);
  };

  const getUserQuizAnswers = async (user) => {
    const url = `${USER_QUIZ_ENDPOINT}?quiz=${parseInt(userAnnualTraining?.quiz?.id)}&user=${user} `;
    const response = await get({ url });
    setSelectedQuiz(response.data);
    setOpenAnswers(true)
  };

  useEffect(() => {
    if(userAnnualTraining?.quiz){
      getQuizAnswers();
    }
  }, [userAnnualTraining]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Timeout de 3 segundos para manejar casos donde ambos arrays estén vacíos

    return () => clearTimeout(timer);
  }, []);

  const combinedTeachers = userAnnualTraining?.sessions?.reduce((acc, session) => {
    session?.teacher.forEach(teacher => {
      const userQuiz = quicesAnswers?.users_quiz?.find(quiz => quiz?.user?.id === teacher?.id);
      acc.push({
        ...teacher,
        status: userQuiz ? "Entregado" : "Sin Responder",
        grade: userQuiz ? userQuiz?.grade : null
      });
    });
    return acc;
  }, []);

  const handleOpenAnswers = (e, userId) => {
    e.stopPropagation();
    getUserQuizAnswers(userId)
  }

  return (
    <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
      borderRadius: '10px'}}>
        <TableContainer
        component={Paper}
        elevation={0}
        sx={{ overflow: "scroll", maxHeight: "500px", width: '100%', borderRadius: '10px' }}>
          <Table size="small" aria-labelledby="tableTitle" stickyHeader>
            <TableHead sx={{ border: "none", height: '45px'}}>
              <TableRow sx={{ border: "none", backgroundColor:'#F7F8FA' }}>
                <TableColumns  />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? 
              Array.from(new Array(5)).map((_, index) => (
                <TableRow key={index} sx={{ height: '50px' }}>
                  {quicesOptions?.map((_, i) => (
                    <TableCell key={i}>
                      <Skeleton variant="rectangular" height={20} sx={{borderRadius: '5px'}} />
                    </TableCell>
                  ))}
                </TableRow>
              )) : (
                combinedTeachers?.length > 0 ?(
              <>
              {combinedTeachers?.map((teacher, i) => {
                let chipColor = ""
                let textColor = ""
                switch (teacher?.status) {
                  case 'Sin Responder':
                    chipColor = "#FFEDEA"
                    textColor = "#F0032B"
                    break
                  case 'Entregado':
                    chipColor = "#E8FFED"
                    textColor = "#55BB5A"
                    break
                }
                return(
                  <TableRow hover sx={{height: '50px', cursor: 'pointer'}}
                  key={i}>
                    <TableCell component="th" scope="row" sx={{fontSize: '13px'}}>
                      {`${teacher?.first_name} ${teacher?.last_name}`}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                    <Chip
                        label={teacher?.status}
                        sx={{
                          backgroundColor:chipColor,
                          textTransform: "capitalize",
                          width: "fit-content",
                          margin: 0,
                          height: 25,
                          borderRadius: "10px",
                          fontWeight: "bold",
                          color: textColor,
                          fontSize: '11px',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {teacher?.grade ? `${teacher?.grade} / ${totalWeight}` : ""}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {teacher?.status === "Entregado" ? 
                      <Button variant='outlined' sx={{borderRadius: '10px'}}
                      onClick={(e) => handleOpenAnswers(e, teacher.id)}>
                        Ver Respuestas
                      </Button>
                      : ""}
                    </TableCell>
                  </TableRow>
                )
              })}
              </>):
              (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Typography variant="body1" color="textSecondary">No hay datos disponibles</Typography>
                  </TableCell>
                </TableRow>
              )
            )}
            </TableBody>
          </Table>
        </TableContainer>
        {openAnswers && (
        <Modal title={"Respuestas"} modalOpen={setOpenAnswers}>
          <div style={{height: '100%', overflowY: 'scroll'}}>
          {selectedQuiz?.answers?.map((quiz) => {
            const answer = quiz?.question?.options?.filter((item) => item?.correct_answer)
            return(
              <Stack direction={"column"} > 
                <p style={{fontWeight: 'bold'}}>{quiz?.question?.content}</p>
                <Stack direction={"row"} gap={1} sx={{display: 'flex', alignItems:'center'}}>
                  <p>{quiz?.answer_option?.description}</p>
                  {quiz?.answer_option?.correct_answer ? 
                  <i class="fas fa-check" style={{color: 'var(--sec-green)'}}></i> : 
                  <i class="fas fa-times" style={{color: 'var(--sec-red)'}}></i>
                  }
                </Stack>
                {!quiz?.answer_option?.correct_answer &&
                  <p>{`Respuesta Correcta: ${answer[0]?.description}`}</p>
                }
              </Stack>
            )
          })}
          </div>
        </Modal>
      )}
    </Box>
  )
}

export default QuizView
