import React, { useState, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import styles from "./CourseSections.module.css";
import { useSections } from "../../context/SectionsContext";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import { TEACHERS_ENDPOINT } from "../../utils/constants";
import { get } from "../../utils/http";
import SectionsOptions from "./Sections/SectionsOptions";
import { useCourses } from "../../context/CoursesContext";
import { useLoading } from "../../context/LoadingContext";
import ClassJournal from "./Sections/ClassJournal";
import ClassSchedule from "./Sections/ClassSchedule";

function CourseSections() {
  const [teachers, setTeachers] = useState([]);
  const [sections, setSections] = useState([]);
  const [currentSection, setCurrentSection] = useState(null);
  const { fetchSingleCourse } = useCourses();
  const [courseTeachers, setCourseTeacers] = useState([]);
  const [journalEntries, setJournalEntries] = useState([]);
  const { showLoading, hideLoading } = useLoading();
  const [value, setValue] = React.useState('dia');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  sections?.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (currentSection === undefined || currentSection === null) {
      setCurrentSection(sections[0]);
      setJournalEntries(sections[0]?.class_journal);
    }
  }, [sections]);

  const getCourse = async () => {
    const course = await fetchSingleCourse(courseId);
    setCourseTeacers(course?.course_teachers);
  };

  const { currentUser } = useContext(CurrentUserContext);
  const {
    createSection,
    fetchSections,
    createSchedule,
    deleteSection,
    deleteSchedule,
    updateSchedule,
  } = useSections();

  useEffect(() => {
    if (
      currentUser.role === "IT" ||
      currentUser.role === "academic_coordination" ||
      currentUser.role === "academy_coordination"
    ) {
      const fetchTeachers = async () => {
        const url = `${TEACHERS_ENDPOINT}`;
        const response = await get({ url });

        setTeachers(response.data);
      };
      fetchTeachers();
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      currentUser.role === "IT" ||
      currentUser.role === "academic_coordination"
    ) {
      getCourse();
    }
  }, [currentUser]);

  const filteredTeachers = teachers?.filter((item) =>
    courseTeachers.includes(item.id)
  );

  const { courseId } = useParams();

  const userPermission =
    currentUser.role === "IT" ||
    currentUser.role === "monitoring_coordinator" ||
    currentUser.role === "monitoring_teacher" || 
    currentUser.role === "internal_teacher" ||
    currentUser.role === "development_teacher" ;

  const getSections = async () => {
    try {
      const response = await fetchSections(courseId);
      setSections(response.data); 
    } catch (error) {
        console.error("Error fetching sections: ", error);
    }
  };

  const handleChangeCurrentSection = (section) => {
    setCurrentSection(section);
    setJournalEntries(section.class_journal);
  };

  useEffect(() => {
    getSections();
  }, [fetchSections, courseId]);

  return (
    <div className={styles.container}>
      <SectionsOptions
        userPermission={userPermission}
        createSection={createSection}
        sections={sections}
        handleChangeCurrentSection={handleChangeCurrentSection}
        currentSection={currentSection}
        getSections={getSections}
        courseId={courseId}
        deleteSection={deleteSection}
      />
      <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs onChange={handleChange} aria-label="visible arrows tabs example" variant="scrollable"
         value={value} >
            <Tab label="Día Día" value="dia"  sx={{fontSize: '14px'}}/>
            <Tab label="Horario" value="horario" sx={{fontSize: '14px'}}/>
          </Tabs>
        </Box>
        <TabPanel value="dia" sx={{borderRadius: '10px', padding: "10px 2px"}}>
          <ClassJournal currentSection={currentSection} courseId={courseId} journalEntries={journalEntries} />
        </TabPanel>
        <TabPanel value="horario" sx={{borderRadius: '10px', padding: "10px 2px"}}>
        <ClassSchedule
        createSchedule={createSchedule}
        currentUser={currentUser}
        filteredTeachers={filteredTeachers}
        sections={sections}
        currentSection={currentSection}
        deleteSchedule={deleteSchedule}
        showLoading={showLoading}
        hideLoading={hideLoading}
        updateSchedule={updateSchedule}
        fetchSections={fetchSections}
        setSections={setSections}
        setCurrentSection={setCurrentSection}
        courseId={courseId} />
        </TabPanel>
      </TabContext>
      
    </div>
  );
}

export default CourseSections;
