import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Survey from "survey-react";
import { FormsContext } from "../../../context/FormsContext";
import Container from "../../Container";
import styles from "./PreviusViewSurvey.module.css";

Survey.StylesManager.applyTheme("default");

let defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
defaultThemeColors["$main-color"] = "var(--main-blue)";
defaultThemeColors["$main-hover-color"] = "var(--ter-blue";

function PreviusViewSurvey() {
  const { selectedFormTemplate } = useContext(FormsContext);

  const questions = selectedFormTemplate?.questions;

  const { templateId } = useParams();

  const navigate = useNavigate();

  const json = {
    elements: [],
  };
  questions?.forEach((question) => {
    let questionType;
    const options = [];
    if (question.options_type === null || question.options_type === 0) {
      questionType = {"type" :"text"};

    } else if (question.options_type === 3) {
      questionType = {
                      "type" : "file",
                      "allowImagesPreview" : false
                    };
    
    } else {
      if (question.options_type === 1) {
        questionType = {"type" : "radiogroup"};
      } else {
        questionType = {"type" :"checkbox"};
      }
      question?.question_options?.forEach((opt) => {
        options?.push(opt.content);
      });
    }

    const qst =
    questionType.type === "text"
        ? {
            ...questionType,
            name: question.question.id.toString(),
            title: question.question.description,
            isRequired: true,
            requiredErrorText: "Value cannot be empty",
          }
        : {
            ...questionType,
            name: question.question.id.toString(),
            title: question.question.description,
            choices: options,
            isRequired: true,
            requiredErrorText: "Value cannot be empty",
          };
    json?.elements?.push(qst);
  });
  const survey = new Survey.Model(json);

  const alertResults = useCallback((sender) => {
    const results = sender.data;
    const answersData = {
      teacher_form_id: templateId,
      answers: [],
    };
    Object.entries(results).map(([key, value]) => {
      const data = {
        question_id: key,
        content: value,
      };
      answersData?.answers?.push(data);
    });
  }, []);
  survey.onComplete.add(alertResults);

  return (
    <Container>
      <div className={styles.survey_content}>
        <div style={{ width: "100%" }}>
          <p style={{ fontSize: "16px", whiteSpace: "pre-line", padding: "0px 5px 5px 10px" }}>{selectedFormTemplate?.description}</p>
          <Survey.Survey model={survey} />
        </div>
      </div>
    </Container>
  );
}

export default PreviusViewSurvey;
