import React, {useContext, useEffect} from 'react'
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {Box} from '@mui/material';
import { dateFormatter } from '../../utils/dateFormatter';
import { EventsContext } from '../../context/EventsProvider';
import Container from '../Container';
import styles from './Events.module.css';
import { priority, priorityChoices, priorityChoicesColor, 
    customStyles, getStatusIcon, getStatusIconList,
     areaResponsible, statusChoices, areaChoices, typeChoices, 
     handleRowClick, typeOfChoices} from './constants';

function AllViewList() {
  const {fetchEvents, events, fetchNextPage, fetchPreviousPage, currentPage, 
    totalPages, fetchForSearch, dataEvent } = useContext(EventsContext);
  const [filterInstitution, setFilterInstitution] = React.useState('');
  const [filterStatus, setFilterStatus] = React.useState('');
  const [filterType, setFilterType] = React.useState('');
  const [filterArea, setFilterArea] = React.useState('');

  useEffect(() => {
    fetchEvents();
  }, []);

  const navigate = useNavigate();

  const columns = [
    {
      name: "Reportado por",
      selector: (row) => row.report_person?.first_name + " " + row.report_person?.last_name,
      sortable: true,
      cell: (cell) => {
        const fullName = `${cell.report_person?.first_name || ''} ${cell.report_person?.last_name || ''}`.trim();
        return fullName ? <p>{fullName}</p> : 'Por asignar';
      }
    },
    {
      name: "Institución",
      selector: (row) => row.institution?.name,
      sortable: true,
      cell: (cell) => <div>{cell.institution?.name}</div>
  },
  {
      name: "Estado",
      selector: (row) => row.status,
      sortable: true,
      cell: (cell) => getStatusIconList(cell.status)       

    },
    {
      name: "Tipo ",
      selector: (row) => row.type_event,
      sortable: true,
      cell: (cell) => <div>{typeOfChoices(cell.type_event)}</div>
    },
    {
      name: "Prioridad",
      selector: (row) => row.priority,
      sortable: true,
      cell: (cell) => <div>{priority(cell.priority)}</div>
    },
    // {
    //     name: "Detalles",
    //     selector: (row) => row.incident_type,
    //     sortable: true,
    //     cell: (cell) => <div>{typeofEvents(cell.incident_type)}</div>
    // },
    {
      name: "Programa",
      selector: (row) => row.program?.name,
      sortable:true,
      cell: (cell) => <div>{cell.program?.name}</div>

    },
    {
      name: "Area Responsable",
      selector: (row) => row.area_responsible,
      sortable: true,
      cell: (cell) => 
      {
        const area = areaResponsible(cell.area_responsible)
        return <div>{area}</div>
      }
      
    },
    {
      name: "Fecha",
      selector: (row) => row.event_date,
      cell: (cell) =>(
        <p>{dateFormatter(new Date(cell.event_date))}</p>
      )
    },
  ];

  const handleSearchType = (event) => {
    setFilterType(event.target.value);
  };

  const handleSearchInstitution = (event) => {
    setFilterInstitution(event.target.value);
  };

  const handleSearchStatus = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleSearchArea = (event) => {
    setFilterArea(event.target.value);
  };
  const handleAllSearch = () => {

    const params  = []
    if (filterInstitution) {
      params.push(`institution=${filterInstitution}`);
    }
    if (filterType) {
      params.push(`type=${filterType}`);
    }
    if (filterStatus) {
      params.push(`status=${filterStatus}`);
    }
    if (filterArea) {
      params.push(`area=${filterArea}`);
    }
    const url = params.join('&');
    fetchForSearch(url);
  }

  useEffect(() => {
    handleAllSearch();
  }, [filterInstitution,filterType, filterStatus, filterArea]);

  return (
    <Container >
        <i
      className="fal fa-arrow-left"
      style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
      onClick={() => navigate(-1)}
      ></i>
      <div className={styles.table}>
        <div className={styles["pagination"]} style={{ marginTop: "12px" }}>
          <i className="fas fa-arrow-left"
            style={{ fontSize: "16px", cursor: "pointer", marginLeft: "5px", marginRight: "5px", color: "var(--main-blue)" }}
            onClick={fetchPreviousPage}></i>
          <span>{`Página ${currentPage}  de ${totalPages}`}</span>
          <i className="fas fa-arrow-right"
            style={{ fontSize: "16px", cursor: "pointer", marginLeft: "5px", marginRight: "5px", color: "var(--main-blue)" }}
            onClick={fetchNextPage}></i>
        </div>
        <div className={styles.cont2}>
          <div className={styles["search-bar"]}>
              <select
                value={filterType}
                className={styles.filter_text}
                onChange={handleSearchType}
              >
                <option value={''}>Filtrar por tipo de evento</option>
                {typeChoices.map((type) => (
                  <option key={type.value} value={type.value}>{type.label}</option>
                ))}
              </select>
        </div>
        <div className={styles["search-barthree"]}>
              <select
                value={filterStatus}
                className={styles.filter_text}
                onChange={handleSearchStatus}
              >
                <option value={''}>Filtrar por estado</option>
                {statusChoices.map((status) => (
                  <option key={status.value} value={status.value}>
                    {status.label}</option>
                ))}
              </select>
        </div>
        <div className={styles["search-barfour"]}>
              <select
                value={filterArea}
                className={styles.filter_text}
                onChange={handleSearchArea}
              >
                <option value={''}>Filtrar por Área</option>
                {areaChoices.map((area) => (
                  <option key={area.value} value={area.value}>
                    {area.label}</option>
                ))}
              </select>
        </div>
          <div className={styles["search-bartwo"]}>
            <input 
              type="text"
              placeholder="Filtrar por institución"
              value={filterInstitution}
              onChange={handleSearchInstitution}
              className={styles.filter_text}
            />
          </div>
        </div>
        <Box  sx={{ width: '100%', marginRight: '10px' }}>
          <div style={{borderRadius: '10px'}}>
          <DataTable
              columns={columns}
              data={events}
              customStyles={customStyles}
              sortIcon={
              <i
                  className="far fa-arrow-up"
                  style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
              }
              highlightOnHover
              fixedHeader
              pointerOnHover
              onRowClicked={(row) => handleRowClick(row, navigate)}
              noDataComponent="No hay eventos"
              fixedHeaderScrollHeight="500px"
          />
          </div>
          <div className={styles["text"]}>
          <p>
            <span className={styles.pending}>
              {` Pendientes: ${dataEvent?.status_count?.pending ?? "0"}`}
            </span>
            {dataEvent?.status_count?.process && (
              <span className={styles.process}>
                {` - En Proceso: ${dataEvent?.status_count?.process ?? "0"}`}
              </span>
            )}
            {dataEvent?.status_count?.completed && (
              <span className={styles.completed}>
                {` - Resueltos: ${dataEvent?.status_count?.completed ?? "0"}`}
              </span>
            )}
            {dataEvent?.status_count?.closed && (
              <span className={styles.closed}>
                {` - Cerrados: ${dataEvent?.status_count?.closed ?? "0"}`}
              </span>
            )}

            {dataEvent?.count && (
              <span className={styles.total}>
                {` - Total de eventos: ${dataEvent?.count ?? "0"}`}
              </span>
            )}
          </p>

            
          </div>
      </Box>
    </div>
  </Container>
  )
}

export default AllViewList
