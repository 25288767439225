import React, { useContext, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { get } from "../../../utils/http";
import {
  MyTextInput,
  MySubmitButton,
  MySelect,
  MyTextarea,
  MyRadio,
} from "../../FormFields";
import { FormsContext } from "../../../context/FormsContext";
import styles from "./CreateQuestion.module.css";
import Container from "../../Container";
import { LICENSES_ENDPOINT } from "../../../utils/constants";
import { wordCapitalize } from "../../../utils/capitalize";

function CreateQuestion() {
  const [programs, setPrograms] = useState([]);
  const { createQuestion } = useContext(FormsContext);
  const [hasSingleOptions, setHasSingleOptions] = useState(false);
  const [hasMultipleOptions, setHasMultipleOptions] = useState(false);
  const [options, setOptions] = useState([""]);

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  useEffect(() => {
    if (!hasSingleOptions && !hasMultipleOptions) {
      setOptions([""]);
    }
  }, [hasSingleOptions, hasMultipleOptions]);

  useEffect(() => {
    const fetchLicenses = async () => {
      const url = LICENSES_ENDPOINT;
      const response = await get({ url });

      if (!response.error) {
        setPrograms(response.data);
      }
    };
    fetchLicenses();
  }, []);

  const navigate = useNavigate();

  const handleChange = (index, event) => {
    const newOptions = [...options];
    newOptions[index] = event.target.value;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    const newOptions = [...options, ""];
    setOptions(newOptions);
  };

  const handleRemoveOption = (index) => () => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const createQuestionHandler = ({ values }) => {
    const questionData = {
      title: wordCapitalize(values.title),
      description: wordCapitalize(values.description),
      licenss: parseInt(values.program),
      options: options,
      options_type: hasSingleOptions ? 1 : hasMultipleOptions ? 2 : 0,
    };

    if (questionData.program === "") {
      delete questionData["program"];
    }

    createQuestion({ data: questionData });
  };

  const handleChangeSingleValue = (e) => {
    setHasMultipleOptions(false);
    setHasSingleOptions(!hasSingleOptions);
  };

  const handleChangeMultipleValue = (e) => {
    setHasSingleOptions(false);
    setHasMultipleOptions(!hasMultipleOptions);
  };

  return (
    <Container>
      <div className={styles["create-question"]}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div style={{ overflowY: "scroll" }}>
          <Formik
            initialValues={{
              title: "",
              description: "",
              program: "",
              options: 0,
            }}
            validationSchema={Yup.object({
              title: Yup.string().required("Campo requerido"),
              description: Yup.string().required("Campo requerido"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                createQuestionHandler({ values });
                setSubmitting(false);
              }, 400);
              resetForm();
            }}
          >
            <Form className={styles["form__fields"]}>
            <Grid container direction={"row"} 
            rowSpacing={2} columnSpacing={2}>
              <Grid item md={(hasSingleOptions || hasMultipleOptions) ? 7 : 12} sm={12} xs={12}
              sx={{display:'flex', justifyContent:'center'}}>
                <Grid container 
                direction={"row"} 
                rowSpacing={2} columnSpacing={2} 
                sx={{width: isDesktop ? ((hasSingleOptions || hasMultipleOptions) ? '100%' : '50%') : '100%'}}>
                  <Grid item md={6} sm={12} xs={12}>
                  <MyTextInput
                      label={`Título`}
                      name="title"
                      type="text"
                      icon="fa fa-user"
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12} >
                    <MySelect label="Programa" name="program">
                      <MenuItem value="">--</MenuItem>
                      {programs.map((program) => (
                        <MenuItem value={program.id} key={program.id}>
                          {program.name}
                        </MenuItem>
                      ))}
                    </MySelect>
                  </Grid>
                  <Grid item  sm={12} xs={12} >
                    <MyTextarea label={`Descripción`} name="description" />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12} >
                    <MyRadio
                      label="Es de seleccion"
                      name="options"
                      checked={hasSingleOptions}
                      onClick={(e) => handleChangeSingleValue(e)}
                    >
                      {`Selección simple`}
                    </MyRadio>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12} >
                    <MyRadio
                      label="Es de seleccion"
                      name="options"
                      checked={hasMultipleOptions}
                      onClick={(e) => handleChangeMultipleValue(e)}
                    >
                      {`Selección múltiple`}
                    </MyRadio>
                  </Grid>
                </Grid>
              </Grid>
              {(hasSingleOptions || hasMultipleOptions) && (
                <Grid item md={5} sm={12} xs={12}>
                  <Stack direction={"column"} spacing={1}>
                    {options?.map((option, index) => (
                    <Stack direction={"row"} sx={{width:'100%'}}>
                      
                        <MyTextInput
                          name="option"
                          type="text"
                          value={option}
                          onChange={(event) => handleChange(index, event)}
                          icon="fa fa-user"
                          label={`Opcion ${index + 1}:`}
                          width={"100%"}
                        />
                        {options?.length > 1 && (
                           <IconButton onClick={handleRemoveOption(index)} size="small"
                           sx={{height: 'fit-content', marginTop: 3, marginLeft: .7}}>
                            <RemoveCircleOutlineRoundedIcon fontSize="large"
                            sx={{color: '#cf142b', fontSize: '22px'}} />
                          </IconButton>
                      )}

                    </Stack>
                    ))}
                    </Stack>
                    <IconButton onClick={handleAddOption} size="small"
                     sx={{marginTop: .2}}>
                      <AddCircleOutlineRoundedIcon fontSize="large"
                      sx={{color: '#5FDBA7', fontSize: '22px'}} />
                    </IconButton>
                </Grid>
                  )}
                
              </Grid>
              <MySubmitButton name="Crear" type="submit" />
            </Form>
          </Formik>
        </div>
      </div>
    </Container>
  );
}

export default CreateQuestion;
