import React, { useContext, useEffect, useState } from "react";
import styles from "./CoursesPage.module.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { CoursesContext } from "../context/CoursesContext";
import { BASE_URL } from "../utils/constants";
import { get } from "../utils/http";
import { CurrentUserContext } from "../context/CurrentUserProvider";

function CoursesPage() {
  const [filterText, setFilterText] = useState("");
  const { currentUser } = useContext(CurrentUserContext);
  const { courses } = useContext(CoursesContext);
  const [pagCourses, setPagCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchCourses(currentPage);
  }, [currentPage]);

  const fetchCourses = async (page) => {
    const url = `${BASE_URL}/course_list/?page=${page}&page_size=10/`;
    const response = await get({ url });
    if (!response.error) {
      setPagCourses(response.data);
    }
  };

  const totalPages = Math.ceil(pagCourses.count / 10);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.split("/")[2]) {
      navigate("list", { replace: true });
    }
  }, [location.pathname]);

  // const coursesInfo = currentUser.role === "IT" ? pagCourses.results : courses
  const coursesInfo = pagCourses.results;

  let filteredData =
    coursesInfo?.length > 0 &&
    coursesInfo?.filter((item) =>
      item.program.toLowerCase().includes(filterText.toLowerCase())
    );

  return (
    <div className={styles.container}>
      <section className={styles.leftside}>
        <Outlet
          context={{
            filteredData: [filteredData],
            filterText: [filterText, setFilterText],
            currentPage: [currentPage],
            totalPages: [totalPages],
            handlePageChange: [handlePageChange],
            currentUser: [currentUser],
          }}
        />
      </section>
    </div>
  );
}

export default CoursesPage;
