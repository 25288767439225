import React from 'react'
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

function HomeCardComponentModal({ color, title, link, tab, setOpenAgreement }) {
  return (
    <Card sx={{ display: 'flex', borderRadius: '10px', backgroundColor: 'var(--background-gray)', 
        borderLeft: `10px solid ${color}`, height: '54px'  }} elevation={0}>
      <CardContent sx={{ flex: '1 0 auto', display: 'flex', alignItems: 'center' }}>
        <p style={{ fontSize: '1.4rem' }}>
          {title}
        </p>
      </CardContent>
      <CardActions>
        {link ? (
          <Link
            to={link}
            target={tab && "_blank"} rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "var(--main-gray)",
            }}
          >
            <Button size="small" sx={{ fontSize: '12px', borderRadius: '10px' }}>
              Ver acuerdo
            </Button>
          </Link>
        ) : (
          <Button size="small" sx={{ fontSize: '12px', borderRadius: '10px' }} onClick={() => setOpenAgreement(true)}>
            Ver acuerdo
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

export default HomeCardComponentModal;
