import { useState, createContext, useContext, useEffect } from "react";
import { get, post, patch } from "../utils/http";
import { INSTITUTIONS_LEVELS_ENDPOINT } from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";
import { AlertsContext } from "./AlertsContext";

const InstitutionsLevelContext = createContext();

const InstitutionsLevelProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useLocalStorage(
    "selectedLevel",
    ""
  );
  const showSnackbar = useContext(AlertsContext);

  const createInstitutionLevel = async ({ data }) => {
    const url = INSTITUTIONS_LEVELS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha creado un nuevo grado', 'success')
      fetchLevels();
    }else{
      showSnackbar('Hubo un error al crear un grado', 'error');
    }
  };

  const fetchLevels = async () => {
    const url = INSTITUTIONS_LEVELS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setLevels(response.data);
    }
  };

  const updateLevel = async ({ levelId, values }) => {
    const url = `${INSTITUTIONS_LEVELS_ENDPOINT}${levelId}/`;
    const data = { ...values };
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar('Se ha actualizado el grado', 'success')
      fetchLevels();
    }else{
      showSnackbar('Hubo un error al actualizar el grado', 'error');
    }
  };

  useEffect(() => {
    // fetchLevels();
  }, []);

  const contextData = {
    levels,
    setLevels,
    selectedLevel,
    setSelectedLevel,
    fetchLevels,
    createInstitutionLevel,
    updateLevel
  };

  return (
    <InstitutionsLevelContext.Provider value={contextData}>
      {loading ? null : children}
    </InstitutionsLevelContext.Provider>
  );
};

const useLevels = () => {
  return useContext(InstitutionsLevelContext);
};

export { InstitutionsLevelContext, InstitutionsLevelProvider, useLevels };
