import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Survey from "survey-react";
import DataTable from "react-data-table-component";
import "survey-react/survey.css";
import Container from "../Container";
import styles from "./SurveysList.module.css";
import { FormsContext } from "../../context/FormsContext";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import { BASE_URL } from "../../utils/constants";
import { get } from "../../utils/http";

const myCustomThemeColors = Survey.StylesManager.ThemeColors.default;
myCustomThemeColors["$main-color"] = "var(--main-blue)";
myCustomThemeColors["$main-hover-color"] = "var(--ter-blue)";

Survey.StylesManager.applyTheme("myCustomTheme", {
  theme: "modern",
  themeColor: "myCustomThemeColors",
});

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

const columnsAdmin = [
  {
    name: "Título",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Tipo",
    selector: (row) => row.form_type,
    sortable: true,
  },
  {
    name: "Lapso",
    selector: (row) => row?.term?.name,
    sortable: true,
  },
  {
    name: "Fecha de inicio",
    selector: (row) =>
      new Date(row.start_date).toLocaleString("es-ES", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
    sortable: true,
  },
  {
    name: "Fecha de cierre",
    selector: (row) =>
      new Date(row.end_date).toLocaleString("es-ES", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    cell: (cell) => {
      let status = cell.status;
      return (
        <div
          style={{
            backgroundColor: status === "Borrador" ? "#FFCC02" : "#22B532",
            padding: "0.8rem",
            borderRadius: "20px",
          }}
        >
          {cell.status}
        </div>
      );
    },
  },
];

const columnsTeacher = [
  {
    name: "Titulo",
    selector: (row) => row.template.name,
    sortable: true,
  },
  {
    name: "Intitución",
    selector: (row) => row.institution?.name,
    sortable: true,
  },
  {
    name: "Fecha de cierre",
    selector: (row) =>
      new Date(row.template.end_date).toLocaleString("es-ES", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.completed,
    sortable: true,
    cell: (cell) => {
      let status;
      if (cell.answered_at !== null) {
        status = "Completado";
      } else {
        status = "Pendiente";
      }
      return (
        <div
          style={{
            backgroundColor: cell.answered_at === null ? "#FFCC02" : "#22B532",
            padding: "0.8rem",
            borderRadius: "20px",
          }}
        >
          {status}
        </div>
      );
    },
  },
];

function SurveysList() {
  const { formTemplate, setSelectedFormTemplate, fetchFormTemplates } =
    useContext(FormsContext);
  const { currentUser } = useContext(CurrentUserContext);
  const [userForms, setUserForms] = useState([]);
  const [filterTerm, setFilterTerm] = useState('')

  useEffect(() => {
    const fetchForms = async () => {
      const url = `${BASE_URL}/accounts/forms/`;
      const response = await get({ url });

      if (!response.error) {
        setUserForms(response.data);
      }
    };
    fetchForms();
  }, []);

  const userPermission =
    currentUser.role === "IT" ||
    currentUser.role === "monitoring_coordinator" ||
    currentUser.role === "monitoring_teacher" ||
    currentUser?.role === "monitoring_teacher_3";

  const navigate = useNavigate();

  const handleAdminRowClick = (row) => {
    if (userPermission) {
      setSelectedFormTemplate(row);
      navigate(`/encuestas/admin/${row.id}`, { relative: false });
    } else {
      if (row.answered_at === null) {
        navigate(`/encuestas/${row.id}`, { relative: false });
      }
    }
  };

  let filterData = formTemplate?.filter(
    (item) =>{
      if ( filterTerm === "") {
        return item;
      }else{

        let termMatch = true;
        if (filterTerm !== "") {
          termMatch = item?.term?.name === filterTerm
        }

        if (termMatch) {
          return item;
        }
      }
    }
  );

  useEffect(() => {
    if (userPermission) {
      fetchFormTemplates();
    }
  }, [userPermission]);

  const currentDatetime = new Date();

  const filteredUserForms = userForms?.filter((item) => {
    const startDate = new Date(item.template.start_date);
    const endDate = new Date(item.template.end_date);
    return startDate <= currentDatetime && currentDatetime <= endDate;
  });

  return (
    <Container>
      <div className={styles.container}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles["search-bar2"]}>
        <select
          value={filterTerm}
          className={styles.filter_text}
          onChange={(e) => setFilterTerm(e.target.value)}
        >
          <option value={''}>Filtrar por lapso</option>
          <option value={'I'}>I Lapso</option>
          <option value={'II'}>II Lapso</option>
          <option value={'III'}>III Lapso</option>
        </select>
        </div>
        <DataTable
          columns={userPermission ? columnsAdmin : columnsTeacher}
          data={userPermission ? filterData : filteredUserForms}
          customStyles={customStyles}
          sortIcon={
            <i
              className="far fa-arrow-up"
              style={{ marginLeft: "5px", fontSize: "14px" }}
            ></i>
          }
          highlightOnHover
          pointerOnHover
          fixedHeader
          fixedHeaderScrollHeight="500px"
          onRowClicked={(row) => handleAdminRowClick(row)}
          noDataComponent="No tienes encuestas por responder actualmente"
        />
      </div>
    </Container>
  );
}

export default SurveysList;
