import React, { useState, useEffect } from "react";
import styles from "./NewConversation.module.css";
import { useConversations } from "../../context/ConversationsProvider";
import { useCurrentUser } from "../../context/CurrentUserProvider";
import Modal from "../../components/Modal";
import { get } from "../../utils/http";
import { CONTACTS_ENDPOINT, CHAT_ROOM_ENDPOINT } from "../../utils/constants";
import { post } from "../../utils/http";

const NewConversation = () => {
  const [contacts, setContacts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [textFilter, setTextFilter] = useState("")

  const { conversations, setCurrentConversationId, fetchRooms, setCurrentConversation } = useConversations();

  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const fetchContacts = async () => {
      const url = CONTACTS_ENDPOINT;
      const response = await get({ url });

      if (!response.error) {
        setContacts(response.data);
      }
    };
    fetchContacts();
  }, []);

  const newChatHandler = () => {
    setModalOpen(true);
  };

  const createChat = async ({ data }) => {
    const url = CHAT_ROOM_ENDPOINT;
    const response = await post({ url, data });
    if(!response.error){
      fetchRooms()
      setCurrentConversationId(response.data.id)
    }
    
  };

  const newConversationHandler = (targetId) => {
    let existingConversation;

    conversations?.forEach((conversation) => {
      conversation?.users.forEach((user) => {
        if (user?.id === targetId) {
          existingConversation = conversation;
        }
      });
    });

    if (existingConversation) {
      setModalOpen(false);
      setCurrentConversationId(existingConversation?.id);
      setCurrentConversation(existingConversation)
    } else {
      const dataInfo = { users: [targetId] };
      createChat({ data: dataInfo });
      setModalOpen(false);
    }
  }

  const filteredData = contacts?.filter((item) => {
    if (textFilter === "" ) {
      return item;
    } else {
      const name = `${item.first_name} ${item.last_name}`
      let nameMatch = name
      if(textFilter !== ""){
        nameMatch = name.toLowerCase().includes(textFilter.toLowerCase());
      }
      if (nameMatch) {
        return item;
      }
    }
  });

  filteredData?.sort((a, b) => {
    const fullNameA = `${a.first_name} ${a.last_name}`;
    const fullNameB = `${b.first_name} ${b.last_name}`;
    return fullNameA.localeCompare(fullNameB);
  })

  return (
    <div className={styles["new-chat"]}>
      {modalOpen && (
        <Modal title="Nueva Conversación" modalOpen={() => setModalOpen(false)}>
          <input
            id="search"
            type="text"
            placeholder="Buscar por nombre"
            aria-label="Search Input"
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
            className={styles.filter_text}
          />
          <div className={styles["user-list"]}>
            {contacts && filteredData
              .filter((user) => user["id"] !== currentUser["id"])
              .map((user) => {
                const firstName =
                  user?.first_name?.charAt(0).toUpperCase() +
                  user?.first_name?.slice(1);
                const lastName =
                  user?.last_name?.charAt(0).toUpperCase() +
                  user?.last_name?.slice(1);

                return (
                  <>
                  {(firstName && lastName) &&
                  <div
                    className={styles["user"]}
                    onClick={() => newConversationHandler(user["id"])}
                    key={user["id"]}
                  >{`${firstName} ${lastName}`}
                  {(user?.role !== "external_teacher" && user?.role !== "external_coordinator") && 
                  <img src="/IsotipoK.png" width={'22px'} style={{marginLeft: '5px'}} alt="kurios"/>}
                  </div>}
                  </>
                );
              })}
          </div>
        </Modal>
      )}
      <div style={{marginLeft: 'auto', width:'fit-content'}}>
        <i onClick={newChatHandler} class="fas fa-plus-circle"></i>
      </div>
    </div>
  );
};

export default NewConversation;
