import React, {useState, useContext} from 'react'
import { Formik, Form } from "formik";
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import { MyTextInput,
    MySubmitButton, MyRadio, MySelect } from '../../FormFields';
import Container from '../../Container';
import styles from './CreatePlanilla.module.css'
import {MonitorFormsContext} from '../../../context/MonitorFormsContext'

function CreatePlanilla() {
  const { createQuestion } = useContext(MonitorFormsContext);
    const [isImportant, setIsImportant] = useState(false);
    const [hasComment, setHasComment] = useState(false);

    const navigate = useNavigate();

    const createQuestionHandler = (values) => {
      const questionData = {
        content: values.content,
        weight: values.weight,
        type: values.type,
        area: values.area,
        important: isImportant,
        mandatory_comment: hasComment
      }
      createQuestion({data: questionData})
      setIsImportant(false)
      setHasComment(false)
    }

  return (
    <Container>
      <div className={styles["create-planilla"]}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div style={{ overflowY: "scroll" }}>
          <Formik
            initialValues={{
              content: "",
              weight: 0,
              type: '',
              area: '',
              important: false,
              comment: false
            }}
            validationSchema={Yup.object({
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                createQuestionHandler(values)
                setSubmitting(false);
              }, 400);
              resetForm();
            }}
          >
            <Form className={styles["form__fields"]}>
            <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
              <Grid item md={6} sm={6} xs={12}>
              <MyTextInput
                    label="Pregunta"
                    name="content"
                    type="text"
                    icon="fa fa-user"
                  />

              </Grid>
              <Grid item md={6} sm={6} xs={12}>
              <MyTextInput
                    label="Peso (%)"
                    name="weight"
                    type="number"
                    icon="fa fa-user"
                  />
                
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
              <MySelect label="Tipo" name="type">
                    <MenuItem value="Internal">Docente interno</MenuItem>
                    <MenuItem value="External">Docente externo</MenuItem>
                  </MySelect>
                
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
              <MySelect label="Area" name="area">
                    <MenuItem value="Docente">Docente</MenuItem>
                    <MenuItem value="Estudiante">Estudiantes</MenuItem>
                    <MenuItem value="Materiales">Materiales</MenuItem>
                    <MenuItem value="Requerimientos">Requerimientos</MenuItem>
                    <MenuItem value="Internos">Internos</MenuItem>
                  </MySelect>
                
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
              <MyRadio
                    label="Es importante"
                    name="important"
                    checked={isImportant}
                    onClick={() => setIsImportant(!isImportant)}
                  >
                    {`Es importante`}
                  </MyRadio>

              </Grid>
              <Grid item md={6} sm={6} xs={12}>
              <MyRadio
                    label="Lleva comentario"
                    name="comment"
                    checked={hasComment}
                    onClick={() => setHasComment(!hasComment)}
                  >
                    {`Lleva comentario`}
                  </MyRadio>

              </Grid>
            </Grid>
              <div style={{marginTop: '2rem'}}>
                <MySubmitButton name="Crear" type="submit" />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </Container>
  )
}

export default CreatePlanilla
