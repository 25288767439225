import { useState, createContext, useContext, useEffect } from "react";
import { get, post } from "../utils/http";
import {
    ACADEMIC_TERMS,
    ACADEMIC_YEAR,
} from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { AlertsContext } from "./AlertsContext";

const YearTermsContext = createContext();

const YearTermsProvider = ({ children }) => {
    const [yearActive, setYearActive] = useState();
    const [terms, setTerms] = useState([]);

    const navigate = useNavigate();
    const { showSnackbar } = useContext(AlertsContext);

    const fetchTerms = async () => {
        const url = ACADEMIC_TERMS;
        const response = await get({ url });
        if (!response.error) {
            setTerms(response.data);
        return response.data;
        }
    };

    const fetchYear = async () => {
        const url = ACADEMIC_YEAR;
        const response = await get({ url });
        if (!response.error) {
            setYearActive(response.data);
        return response.data;
        }
    };

    const contextData = {
        yearActive,
        setYearActive,
        terms,
        setTerms,
        fetchTerms,
        fetchYear,
    };

    return (
        <YearTermsContext.Provider value={contextData}>
            {children}
        </YearTermsContext.Provider>
    );
};

const useYearTerms = () => {
    return useContext(YearTermsContext);

};

export { YearTermsContext, YearTermsProvider, useYearTerms };