import { useState, useContext } from "react";
import { Divider } from "@mui/material";
import { CurrentUserContext } from "../context/CurrentUserProvider";
import LoginForm from "../components/LoginForm";
import styles from "./LoginPage.module.css";
import MoonLoader from "react-spinners/MoonLoader";
import StudentsCertificates from "../components/Certificates/StudentsCertificatesModal";

const LoginPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const { loginUser, resetPassword } = useContext(CurrentUserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [openCertificatedModal, setOpenCertificatedModal] = useState(false)

  const handleLogin = async (values) => {
    setIsLoading(true);

    const response = await loginUser({
      username: values.username,
      password: values.password,
    });
    if (response) {
      setIsLoading(false);
    }
  };

  const resetHandler = async (values) => {
    resetPassword(values);
    setOpenModal(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.login}>
        <div className={styles.header}>
          <img src="LogoKurios.webp" alt="Logo-TK-Borde-Delgado" border="0" />
        </div>
        <div className={styles.content}>
          {!isLoading ? (
            <div style={{margin: '15px 0'}}>
            <LoginForm
              onSubmitFunction={handleLogin}
              resetHandler={resetHandler}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
            <Divider sx={{margin: '10px 0'}} textAlign="center">ó</Divider>
            {/*<div className={styles.marketplace}>
              <a
                href="https://www.ponteenlinea.net/educacionstem/login/index.php"
                target="_blank" rel="noreferrer"
              >
                Acceso Simulador Marketplace
              </a>
            </div>*/}
            <div className={styles.marketplace} style={{margin: '10px 0'}} onClick={() => setOpenCertificatedModal(true)}>
              Certificados alumnos
            </div>
            </div>
          ) : (
            <MoonLoader color="rgba(51, 0, 114, 1)" speedMultiplier={0.5} />
          )}
        </div>
      </div>
      {openCertificatedModal &&
      <StudentsCertificates openCertificatedModal={openCertificatedModal} 
      setOpenCertificatedModal={setOpenCertificatedModal} />
      }
      
    </div>
  );
};

export default LoginPage;
