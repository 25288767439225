import React, { useState } from "react";
import { useAnnouncements } from "../context/AnnouncementsContext";

const TestUpload = () => {
  const { createAnnouncement } = useAnnouncements();
  const [file, setFile] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("image", file);

    // Display formData values

    createAnnouncement(formData);
  };

  const inputFileHandler = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div>
      <p>Form</p>
      <form onSubmit={submitHandler}>
        <input type="file" name="image" onChange={(e) => inputFileHandler(e)} />
        <input
          type="datetime"
          name="start_date"
          onChange={(e) => inputFileHandler(e)}
        />
        <input label="Título" name="title" type="text" placeholder="Título" />
        <input label="Fecha de inicio" name="start_date" type="date" />

        <input
          label="Fecha de expiración"
          name="expiration_date"
          type="date"
          icon="fa fa-user"
        />
        <input type="submit" />
      </form>
    </div>
  );
};

export default TestUpload;
