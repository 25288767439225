import React from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Modal from '../../Modal'

const CurriculumReferenceTable = ({ curriculumReferences }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="learning outcomes table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: "12px", fontWeight: "bold" }}>
              ÁREA APRENDIZAJE
            </TableCell>
            <TableCell sx={{ fontSize: "12px", fontWeight: "bold", textAlign: 'center' }}>
            TEMA GENERADOR
            </TableCell>
            <TableCell sx={{ fontSize: "12px", fontWeight: "bold", textAlign: 'center' }}>
            TEJIDO TEMÁTICO
            </TableCell>
            <TableCell sx={{ fontSize: "12px", fontWeight: "bold", textAlign: 'center' }}>
            REFERENTES TEÓRICO-PRÁCTICOS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {curriculumReferences?.map((item, index) => (
            <TableRow key={index}>
              <TableCell sx={{ fontSize: "12px" }}>
                {item.topic?.generating_topic?.learning_subject?.content || "N/A"}
              </TableCell>
              <TableCell sx={{ fontSize: "12px" }}>
                {item.topic?.generating_topic?.content || "N/A"}
              </TableCell>
              <TableCell sx={{ fontSize: "12px" }}>
                {item.topic?.content || "N/A"}
              </TableCell>
              <TableCell sx={{ fontSize: "12px" }}>{item.content}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const LearningOutcomesTable = ({ learningOutcomes }) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="learning outcomes table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "12px", fontWeight: "bold" }}>
                ÁREA APRENDIZAJE
              </TableCell>
              <TableCell sx={{ fontSize: "12px", fontWeight: "bold", textAlign: 'center' }}>
                COMPONENTE
              </TableCell>
              <TableCell sx={{ fontSize: "12px", fontWeight: "bold", textAlign: 'center' }}>
                OBJETIVO
              </TableCell>
              <TableCell sx={{ fontSize: "12px", fontWeight: "bold", textAlign: 'center' }}>
                APRENDIZAJE ESPERADO
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {learningOutcomes?.map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontSize: "12px" }}>
                  {item.goal?.component?.learning_subject?.content || "N/A"}
                </TableCell>
                <TableCell sx={{ fontSize: "12px" }}>
                  {item.goal?.component?.content || "N/A"}
                </TableCell>
                <TableCell sx={{ fontSize: "12px" }}>
                  {item.goal?.content || "N/A"}
                </TableCell>
                <TableCell sx={{ fontSize: "12px" }}>{item.content}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
};

const CurriculumTable = ({ curriculumContent }) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="curriculum table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "12px", fontWeight: "bold" }}>
                ÁREA APRENDIZAJE
                </TableCell>
              <TableCell sx={{textAlign: 'center', fontSize: "12px", fontWeight: "bold"}}>COMPONENTES</TableCell>
              <TableCell sx={{textAlign: 'center', fontSize: "12px", fontWeight: "bold"}}>CONTENIDOS CURRICULARES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {curriculumContent?.map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{fontSize: '12px'}}>
                  {item.component?.learning_subject?.content || "N/A"}
                </TableCell>
                <TableCell sx={{fontSize: '12px'}}>{item.component?.content || "N/A"}</TableCell>
                <TableCell sx={{fontSize: '12px'}}>{item?.content}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
};

function CurricularContentModal({setShowCurricularContent, activeLesson, stage}) {
  return (
    <Modal width={'80%'}
    modalOpen={setShowCurricularContent} 
    title={"Malla Curricular"}>
        <div
        style={{
          width: "100%",
          maxHeight: "80vh",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
        >
          {stage?.includes("Primaria")&&
          <CurriculumTable curriculumContent={activeLesson?.curriculum_content} />
          }
          {stage === "Preescolar" &&
          <LearningOutcomesTable learningOutcomes={activeLesson?.learning_outcomes} />
          }
          {stage === "Bachillerato" &&
          <>
          {activeLesson?.curriculum_references?.length > 0 ?
            <CurriculumReferenceTable curriculumReferences={activeLesson?.curriculum_references} />
            :
            <p style={{textAlign: 'center', padding: '10px 0'}}>
              La malla curricular para este programa no se encuentra disponible. Muy pronto podrás acceder a ella
            </p>
          }
          </>
          
          }
        </div>
    </Modal>
  )
}

export default CurricularContentModal
