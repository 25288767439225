import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./EventPage.module.css";
import SettingsBar from "../components/Navbars/SettingsBar";

export const FORMS_OPTIONS = [
    {
      key: "agregar-registro",
      name: "Cargar registro",
      icon: <i className="fas fa-file-plus"></i>,
      route: "create",
    },
    {
      key: "all",
      name: "Todos los eventos",
      icon: <i class="fas fa-list-alt"></i>,
      route: "list",
    },
];


function EventsPageExt() {
  const [formsOption, setFormsOption] = useState("list");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
      if (!location.pathname.split("/")[2]) {
        navigate("list", { replace: true });
      } else {
        setFormsOption(location.pathname.split("/")[2]);
      }
  }, [location.pathname]);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
        window.removeEventListener('resize', changeWidth)
    }
  }, [])

return (
  <div className={styles.content}>
    <SettingsBar
        optionsList={FORMS_OPTIONS}
        option={formsOption}
        setOption={setFormsOption}
      />
       <section className={styles.leftside}>
      <Outlet />
    </section>
  </div>
)
}


export default EventsPageExt
