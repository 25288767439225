export const ANNOUNCEMENTS_OPTIONS = [
  {
    key: "list",
    name: "Mis Anuncios",
    icon: <i className="fad fa-bullhorn"></i>,
    route: "list",
  },
  {
    key: "create",
    name: "Crear anuncio",
    icon: <i className="fas fa-plus-square"></i>,
    route: "create",
  },
  {
    key: "all",
    name: "Todos los Anuncios",
    icon: <i class="far fa-newspaper"></i>,
    route: "all",
  },
];
