import React, { useState, useEffect, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate, Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Stack } from "@mui/material";
import { INSTITUTIONS_ENDPOINT } from "../utils/constants";
import { get } from "../utils/http";
import styles from "./SchedulesPage.module.css";
import { CurrentUserContext } from "../context/CurrentUserProvider";
import LoadingComponent from "../components/LoadingComponent";
import { useLoading } from "../context/LoadingContext";

function SchedulesPage() {
  const [institutionLevels, setInstitutionLevels] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [currentInstitution, setCurrentInstitution] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showRightSide, setShowRightSide] = useState(false);
  const [institutionId, setInstitutionId] = useState(null);

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  const { showLoading, hideLoading, isLoading } = useLoading();
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();

  const institutionId1 = currentUser && currentUser.institution[0];

  const fetchLevels = async () => {
    setLoading(true);
    const institutionIds = currentUser?.institution.map((i) => i.id) || [];
    const institutionLevelsPromises = institutionIds.map(
      async (institutionId1) => {
        let url = institutionId1 && `${INSTITUTIONS_ENDPOINT}${institutionId1}/`;
        const response1 = await get({ url });
        const institution = response1?.data;
        url =
          institutionId1 && `${INSTITUTIONS_ENDPOINT}${institutionId1}/levels/`;
        const response = await get({ url });
        return {
          institution: institution.short_name,
          idInstitution: institution.id,
          levels: response.data,
        };
      }
    );

    const levelsData = await Promise.all(institutionLevelsPromises);
    setLoading(false);
    setInstitutionLevels(levelsData);
  };

  useEffect(() => {
    fetchLevels();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.institution.length === 1) {
      // Si el usuario tiene solo una institución
      setInstitutionId(currentUser?.institution[0].id);
    }
    if (currentLevel === undefined || currentLevel === null) {
      setCurrentLevel(
        institutionLevels?.levels && institutionLevels?.levels[0]
      );
    }
  }, [institutionLevels]);

  const handleChangeCurrentLevel = (institutionLevel, institution, idInstitution) => {
    setCurrentLevel(institutionLevel);
    setCurrentInstitution(institution);
    setInstitutionId(idInstitution);
    navigate(`/schedules/${institutionLevel.id}`);

    if (!isDesktop) {
      setShowRightSide(true);
    }
  };

  return (
    <div className={styles.container}>
      {(isDesktop || !showRightSide) && (
        <div className={styles.left_side}>
          <Stack direction={"column"} spacing={1}>
            <i
              className="fal fa-arrow-left"
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            ></i>
            <p className={styles.title}>Seleccione el nivel</p>
            {!loading ? (
              <>
                {institutionLevels?.map((institutionData, index) => {
                  return (
                    <>
                      {institutionData?.levels?.map((level, levelIndex) => (
                        <div
                          onClick={() =>
                            handleChangeCurrentLevel(
                              level,
                              institutionData?.institution,
                              institutionData?.idInstitution
                            )
                          }
                          className={
                            currentLevel?.id === level.id
                              ? styles.option2
                              : styles.option
                          }
                          key={levelIndex}
                        >
                          {`${level?.name} - ${institutionData?.institution}`}
                        </div>
                      ))}
                    </>
                  );
                })}
              </>
            ) : (
              <Skeleton count={20} height={"20px"} />
            )}
          </Stack>
        </div>
      )}

      {(isDesktop || showRightSide) && (
        <div className={styles.right_side}>
          <Outlet
            context={{
              institutionLevels: [institutionLevels, setInstitutionLevels],
              institutionId: [institutionId, setInstitutionId],
              currentLevel: [currentLevel, setCurrentLevel],
              currentInstitution: [currentInstitution, setCurrentInstitution],
              showRightSide: [showRightSide, setShowRightSide]
            }}
          />
        </div>
      )}
      {isLoading && <LoadingComponent />}
    </div>
  );
}

export default SchedulesPage;
