import React from 'react';
import { FormControlLabel, RadioGroup, Radio, FormHelperText } from "@mui/material";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Field, ErrorMessage } from 'formik';
import FormControl from '@mui/material/FormControl';
import styles from "./Planillas.module.css";

function FillQuestions({ groupedData, userResponses, setUserResponses }) {

  const handleResponseChange = (questionId, answer, comment, applies) => {
    const existingResponse = userResponses.find(
      (response) => response.question === questionId
    );

    if (existingResponse) {
      const updatedResponses = userResponses.map((response) =>
        response.question === questionId
          ? {
              ...response,
              answer,
              comment: comment !== undefined ? comment : response.comment,
              applies,
            }
          : response
      );
      setUserResponses(updatedResponses);
    } else {
      const newResponse = { question: questionId, answer, applies, comment: "" };
      if (comment !== undefined) {
        newResponse.comment = comment;
      }
      setUserResponses([...userResponses, newResponse]);
    }
  };

  return (
    <Grid item md={12} sm={12} xs={12}>
      {Object.entries(groupedData)?.map(([area, items]) => (
        <Box key={area}>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            {area}
          </p>
          {items?.map((question) => (
            <div key={question.id}>
              <p className={styles.text_question}>{question.content}</p>
              
              <FormControl component="fieldset" fullWidth error={!!ErrorMessage}>
                {/* Field para RadioGroup */}
                <Field name={`answer-${question.id}`}>
                  {({ field, form }) => (
                    <>
                      <RadioGroup
                        row
                        {...field}
                        value={form.values[`answer-${question.id}`] || ""}
                        onChange={(event) => {
                          const value = event.target.value === "true";
                          const applies = event.target.value === "null";
                          handleResponseChange(question.id, value, form.values[`comment-${question.id}`], applies);
                          form.setFieldValue(`answer-${question.id}`, event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Sí"
                          sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.4rem' } }}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                          sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.4rem' } }}
                        />
                        <FormControlLabel
                          value="null"
                          control={<Radio />}
                          label="No aplica"
                          sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.4rem' } }}
                        />
                      </RadioGroup>
                      {/* Mostrar mensaje de error */}
                      <ErrorMessage name={`answer-${question.id}`}>
                        {(msg) => <FormHelperText error>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </>
                  )}
                </Field>
              </FormControl>

              {/* Comentarios */}
              <div className={styles.inputs} style={{ marginTop: "5px" }}>
                <div className={styles["text_area"]}>
                  <label htmlFor={`comment-${question.id}`}>
                    {`Comentario ${question?.mandatory_comment ? "(obligatorio)" : ""}`}
                  </label>
                  <Field
                    as="textarea"
                    rows={"2"}
                    className={styles["textarea"]}
                    name={`comment-${question.id}`}
                  />
                  {/* Mostrar mensaje de error para los comentarios */}
                  {question?.mandatory_comment && (
                    <ErrorMessage name={`comment-${question.id}`}>
                      {(msg) => <FormHelperText error>{msg}</FormHelperText>}
                    </ErrorMessage>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Box>
      ))}
    </Grid>
  );
}

export default FillQuestions;
