import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, styled, Paper, MenuItem, Autocomplete } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { InstitutionsContext } from '../../context/InstitutionsContext';
import { EventsContext } from '../../context/EventsProvider';
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import { MySubmitButton } from '../FormFields';
import Tooltip from '@mui/material/Tooltip';
import { typeChoices, statusChoices,
  incidentTypeChoices,
  typeofEventChoices,
  typeofInformationChoices,
  typeInformationChoices,
  typeEventChoices,
  typeofRequest,
  areaChoices,
  stageChoices,
  priorityChoices,
  getTooltipMessage,
  priority
} from './constants';

import HelpIcon from '@mui/icons-material/Help';


const Container = styled(Paper)({
  width: '100%',
  margin: '0px 20px 0px 0px',
  backgroundColor: 'var(--background-gray)',
  padding: '20px',
  borderRadius: '10px',
});

const FormWrapper = styled('div')({
  backgroundColor: 'var(--main-white)',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
});

const Title2 = styled('p')({
  fontSize: '1.4rem',
  fontWeight: 'bold',
  marginBottom: '5px',
});

const Text2 = styled('p')({
  fontSize: '1.3rem',
  paddingTop: '5px',
  color: 'var(--main-blue)',
  fontWeight: 'bold',
});

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    borderRadius: '15px',
    fontSize: '1.2rem',
    width: '100%',
  },
  '& .MuiInputLabel-root': {
    fontSize: '1.2rem',
  },
  '& .MuiMenuItem-root': {
    fontSize: '1.2rem',
  },
  fontSize: '1.2rem',
});

const AutocompleteStyled = styled(Autocomplete)({
  '& .MuiInputBase-root': {
    borderRadius: '15px',
    fontSize: '1.2rem',
    width: '100%',
  },
 '& .MuiInputBase-input': {
    width: '100%', 
  },
  '& .MuiAutocomplete-inputRoot': {
    fontSize: '1.2rem',
    minWidth: '100%',
  },
  '& .MuiInputBase-input': {
    width: '100%',
  },
  fontSize: '1.2rem',
});

const MenuItemStyled = styled(MenuItem)({
  fontSize: '1.2rem',
  '&:hover': {
    backgroundColor: 'var(--main-gray)',
  }
});

const IconHelpStyled = styled(HelpIcon)({
  fontSize: '2rem', 
  color: 'var(--main-blue)',
  cursor: 'pointer', 
  '&:hover': {
    color: 'var(--sec-blue)',
  },
});
const TooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: '1.2rem',  
    backgroundColor: 'var(--main-gray)',  
    color: '#fff',  
    padding: '10px',  
    borderRadius: '10px',  
  },
  [`& .MuiTooltip-arrow`]: {
    color: 'var(--main-gray)', 
  },
});



const validationSchema = Yup.object().shape({
  type_event: Yup.string().required('Required')
    .oneOf(['Request', 'Incident', 'Event', 'Information'], 'Invalid type event'),
  institution: Yup.string(),
  stage: Yup.string(),
  description: Yup.string().required('Required')
    .min(5, 'Debe tener al menos 5 caracteres')
    .max(3000, 'El texto no debe exceder los 3000 caracteres'),
  status: Yup.string().required('Required'),
  incident_type: Yup.string().when('type_event', {
    is: 'Incident',
    then: schema => schema.required('Required'),
    otherwise: schema => schema,
  }),
  area_responsible: Yup.string(),
  estimated_resolution_time: Yup.number()
  .integer('Debe ser un número entero') 
  .nullable(), 
  priority: Yup.number().nullable(),
  program: Yup.string(),
  term: Yup.string(),
  event_date: Yup.date().required('Required'),
  designated_person: Yup.string().when('status', {
    is: 'En Proceso',
    then: schema => schema.required('Required'),
    otherwise: schema => schema,    
  }),
  text_solution: Yup.string().when('status', {
    is: 'Resuelto',
    then: schema => schema.required('Required').max(3000, 'El texto no debe exceder los 3000 caracteres'),
    otherwise: schema => schema.max(3000, 'El texto no debe exceder los 3000 caracteres'),
  }),
});
function CreateActivity() {
  const { fetchInstitutions, institutions } = useContext(InstitutionsContext);
  const { createEvent, fetchForUpdate, dataUpdate } = useContext(EventsContext);
  const { currentUser } = useContext(CurrentUserContext);

  const handleSubmit = (values) => {
    const valores = {
      ...values,
      estimated_resolution_time: values.estimated_resolution_time ? parseInt(values.estimated_resolution_time, 10) : null,
      priority : values.priority ? parseInt(values.priority, 10) : null,
      report_person: currentUser.id,
    };
    createEvent({ data: valores });
  };

  useEffect(() => {
    fetchForUpdate();
    fetchInstitutions();
  }, []);

  const navigate = useNavigate();
  const fullAccessRoles = ['IT', 'post_sales', 'monitoring_teacher', 'monitoring_coordinator',
     'monitoring_teacher_3', 'purchases_and_inventory'];
  const externalAccessRoles = ['external_teacher', 'external_coordinator', 'student'];

  const userITAccess = ['IT']
  const UserFullAccess = fullAccessRoles.includes(currentUser.role);
  const UserExternalAccess = externalAccessRoles.includes(currentUser.role);

  return (
    <Container>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: '12px', cursor: 'pointer', marginBottom: '10px' }}
        onClick={() => navigate(-1)}
      ></i>
      <FormWrapper>
        <Formik
          initialValues={{
            type_event: '',
            institution: currentUser?.institution[0]?.id || '',
            stage: '',
            description: '',
            status: 'Pendiente',
            incident_type: '',
            area_responsible: '',
            estimated_resolution_time: '',
            program: '',
            external_teacher: '',
            priority: '',
            term: '',
            event_date: '',
            designated_person: '',
            requirements: '',
            text_solution: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              handleSubmit(values);
              setSubmitting(false);
            }, 400);
            resetForm();
          }}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Title2>Crear Actividad</Title2>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Text2>Tipo de Evento</Text2>
                  <CustomTextField 
                    select
                    name="type_event"
                    value={values.type_event}
                    onChange={handleChange}
                    fullWidth
                    required
                  >
                    {typeChoices.map((option) => (
                      <MenuItemStyled key={option.value} value={option.value}>
                        {option.label}
                      </MenuItemStyled>
                    ))}
                  </CustomTextField>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Text2>Institución</Text2>
                  <CustomTextField
                    select
                    name="institution"
                    value={values.institution}
                    onChange={handleChange}
                    fullWidth
                    disabled={UserExternalAccess}
                  >
                    {institutions && institutions.map((institution) => (
                      <MenuItemStyled key={institution.id} value={institution.id}>
                        {institution.name}
                      </MenuItemStyled>
                    ))}
                  </CustomTextField>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Text2>Etapa</Text2>
                  <CustomTextField
                    select
                    name="stage"
                    value={values.stage}
                    onChange={handleChange}
                    fullWidth
                  >
                    {stageChoices.map((option) => (
                      <MenuItemStyled key={option.value} value={option.value}>
                        {option.label}
                      </MenuItemStyled>
                    ))}
                  </CustomTextField>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Text2>Estado</Text2>
                  <CustomTextField
                    select
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    fullWidth
                    required
                    disabled={UserExternalAccess}
                  >
                    {statusChoices.map((option) => (
                      <MenuItemStyled key={option.value} value={option.value}>
                        {option.label}
                      </MenuItemStyled>
                    ))}
                  </CustomTextField>
                </Grid>
                {(values.type_event === 'Incident' || values.type_event === 'Event' ||
                 values.type_event === 'Information' || values.type_event === 'Request') && (
                  <Grid item xs={6} sm={3}>
                    <Text2>{values.type_event === 'Incident' ? 'Tipo de Incidente' 
                    : values.type_event === 'Information'? 'Tipo de Información':  
                    values.type_event === 'Request'? 'Tipo de Solicitud': 'Tipo de Evento'}</Text2>
                    <CustomTextField
                      select
                      name="incident_type"
                      value={values.incident_type}
                      onChange={handleChange}
                      fullWidth
                      required
                    >
                      {(values.type_event === 'Incident' ? incidentTypeChoices :
                          values.type_event === 'Event' ? typeEventChoices :
                          values.type_event === 'Information' ? typeInformationChoices :typeofRequest).map((option) => (
                        <MenuItemStyled key={option.value} value={option.value}>
                          {option.label}
                        </MenuItemStyled>
                      ))}
                    </CustomTextField>
                  </Grid>
                )}
                
                {UserFullAccess && <Grid item xs={6} sm={3}>
                  <Text2>Área Responsable</Text2>
                  <CustomTextField
                    select
                    name="area_responsible"
                    value={values.area_responsible}
                    onChange={handleChange}
                    fullWidth
                  >
                    {areaChoices.map((option) => (
                      <MenuItemStyled key={option.value} value={option.value}>
                        {option.label}
                      </MenuItemStyled>
                    ))}
                  </CustomTextField>
                </Grid>
                }
                {UserFullAccess && (
                  <Grid item xs={6} sm={3}>
                    <Text2>Persona Asignada</Text2>
                    <CustomTextField
                      select
                      name="designated_person"
                      value={values.designated_person}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <TooltipStyled title="Selecciona a la persona para solucionar el reporte." arrow> 
                              <IconHelpStyled />
                            </TooltipStyled>
                          </InputAdornment>
                        ),
                      }}
                    >
                      {dataUpdate?.internal_users && dataUpdate.internal_users.map((person) => (
                        <MenuItemStyled key={person.id} value={person.id}>
                          {person.first_name} {person.last_name}
                        </MenuItemStyled>
                      ))}
                    </CustomTextField>
                  </Grid>
                )}
                {UserFullAccess && (
                  <Grid item xs={6} sm={3}>
                    <Text2>Persona Afectada</Text2>
                    <AutocompleteStyled
                      options={dataUpdate?.users || []}
                      getOptionLabel={(option) => {
                        const firstName = option.first_name || "";
                        const lastName = option.last_name || "";
                        return `${firstName} ${lastName}`;
                      }}
                      value={dataUpdate?.users?.find(user => user.id === values.external_teacher) || null}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          label="Buscar Persona"
                          name="external_teacher"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end" style={{ marginRight: '-16px' }}>
                                <TooltipStyled title={getTooltipMessage(values?.type_event)} arrow>
                                  <IconHelpStyled />
                                </TooltipStyled>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.first_name} {option.last_name}
                        </li>
                      )}
                      ListboxProps={{
                        style: {
                          maxHeight: '30vh',
                        },
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={6} sm={3}>
                  <Text2>Fecha del Evento</Text2>
                  <CustomTextField
                    name="event_date"
                    value={values.event_date}
                    onChange={handleChange}
                    type="datetime-local"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
                {UserFullAccess && (
                  <Grid item xs={6} sm={3}>
                    <Text2>Prioridad</Text2>
                    <CustomTextField 
                      select
                      name="priority"
                      value={values.priority}
                      onChange={handleChange}
                      fullWidth
                    >
                      {priorityChoices.map((option) => (
                        <MenuItemStyled key={option.value} value={option.value}>
                          {option.label}
                        </MenuItemStyled>
                      ))}
                    </CustomTextField>
                  </Grid>
                )}
                <Grid item xs={6} sm={3}>
                  <Text2>Programa</Text2>
                  <CustomTextField
                    select
                    name="program"
                    value={values.program}
                    onChange={handleChange}
                    fullWidth
                  >
                    <MenuItemStyled key="none" value="">
                      {"Ninguno"}
                    </MenuItemStyled>
                    {dataUpdate?.programs && dataUpdate?.programs?.map((option) => (
                      <MenuItemStyled key={option.id} value={option.id}>
                        {`${option?.year} - ${option?.name} - ${option?.version} - ${option?.language}`}
                      </MenuItemStyled>
                    ))}
                  </CustomTextField>                
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Text2>Lapso</Text2>
                  <CustomTextField
                    select
                    name="term"
                    value={values.term}
                    onChange={handleChange}
                    fullWidth
                  >
                    <MenuItemStyled key="none" value="">
                      {"Ninguno"}
                    </MenuItemStyled>
                    {dataUpdate?.terms && dataUpdate.terms.map((option) => (
                      <MenuItemStyled key={option.id} value={option.id}>
                        {option.name}
                      </MenuItemStyled>
                    ))}
                  </CustomTextField>
                </Grid>

                 {UserFullAccess && (
                  <Grid item xs={6} sm={3}>
                    <Text2>Tiempo de Solución</Text2>
                    <CustomTextField
                      label="Días estimados"
                      name="estimated_resolution_time"
                      value={values.estimated_resolution_time}
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      inputProps={{ min: 1, max: 60 }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Text2>Descripción</Text2>
                  <CustomTextField
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                    error={errors.description && touched.description}
                    helperText={touched.description && errors.description}
                    FormHelperTextProps={{ sx: { fontSize: '1rem', }, }}
                    required
                  />
                </Grid>
                {UserFullAccess && (
                  <Grid item xs={12}>
                    <Text2>Solución</Text2>
                    <CustomTextField
                      name="text_solution"
                      value={values.text_solution}
                      onChange={handleChange}
                      multiline
                      rows={4}
                      error={errors.text_solution && touched.text_solution}
                      helperText={touched.text_solution && errors.text_solution}
                      FormHelperTextProps={{ sx: { fontSize: '1rem', }, }}
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12} >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <MySubmitButton name="Crear Actividad" type="submit" />
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
}

export default CreateActivity;
