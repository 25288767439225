import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { USERS_OPTIONS } from "../utils/constants/usersConstants";
import Container from "../components/Container";
import SettingsBar from "../components/Navbars/SettingsBar";
import styles from "./UsersPage.module.css";

function UsersPage() {
  const [usersOption, setUsersOption] = useState("users");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
        window.removeEventListener('resize', changeWidth)
    }
  }, [])

  useEffect(() => {
    if (!location.pathname.split("/")[2]) {
      navigate("list", { replace: true });
    } else {
      setUsersOption(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  return (
    <div className={styles.content}>
      <SettingsBar
        optionsList={USERS_OPTIONS}
        option={usersOption}
        setOption={setUsersOption}
      />
      <section className={styles.leftside}>
        <Container direction={"row"}>
          <Outlet />
        </Container>
      </section>
    </div>
  );
}

export default UsersPage;
