import React, {useState} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';

const YoutubeEmbed = ({ embedId, url }) => {
  const [loading, setLoading] = useState(true);
  const handleLoad = () => {
    setLoading(false);
  };
  return(
  <div style={{overflow: "hidden",
    position: "relative"}}>
      {loading && (
        <Skeleton
          variant="rectangular"
          width={250}
          height={150}
          animation="wave"
          style={{ borderRadius: '10px' }}
        />
      )}
    <iframe
        width="250px"
        height="150px"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        style={{
          borderRadius: '10px',
          display: loading ? 'none' : 'block'
        }}
        onLoad={handleLoad}
      />
    <Link to={url} style={{textDecoration: 'none'}} target="_blank" rel="noreferrer">
      <Button size="small" sx={{fontSize: '12px', borderRadius: '10px'}} >Abrir en Youtube</Button>
    </Link>
  </div>
)};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;