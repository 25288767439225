export const handleDownload = (fileUrl) => {
    const filename = fileUrl?.substring(fileUrl?.lastIndexOf('/') + 1);

    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // Nombre del archivo para descargar
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    });
};