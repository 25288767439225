import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MyIconTextInput, MySubmitButton } from "../components/FormFields";
import styles from "./ResetPasswordPage.module.css";
import { useSearchParams } from "react-router-dom";
import { useCurrentUser } from "../context/CurrentUserProvider";

function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { confirmPasswordChange } = useCurrentUser();

  return (
    <div className={styles.wrapper}>
      <div className={styles.login}>
        <div className={styles.header}>
          <img src="LogoKurios.webp" alt="Logo-TK-Borde-Delgado" border="0" />
        </div>
        <p className={styles.title}>Ingresa tu nueva contraseña</p>
        <div className={styles.content}>
          <div className={styles["login-form"]}>
            <Formik
              initialValues={{
                password: "",
                confirm_password: "",
              }}
              validationSchema={Yup.object({
                password: Yup.string()
                  .required("Introduzca una contraseña")
                  // check minimum characters
                  .min(4, "La contraseña debe tener 4 caracteres"),
                confirm_password: Yup.string()
                  .required(
                    "Por favor introduzca la verificación de contraseña"
                  )
                  .oneOf([Yup.ref("password")], "Las contraseñas no coinciden"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                confirmPasswordChange({ password: values.password, token });
              }}
            >
              <Form className={styles["login-form__fields"]}>
                <MyIconTextInput
                  label="Contraseña"
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                  icon="fa fa-key"
                />

                <MyIconTextInput
                  label="Confirmar contraseña"
                  name="confirm_password"
                  type="password"
                  placeholder="Confirmar contraseña"
                  icon="fa fa-key"
                />
                <MySubmitButton name="Confirmar" type="submit" />
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
