import React, { useEffect, useRef, useCallback, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import { Box, Stack, IconButton, Button, Typography, Avatar } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { MyTextarea, MySubmitButton } from "../../components/FormFields";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import styles from "./Conversation.module.css";
import { useConversations } from "../../context/ConversationsProvider";
import { useCurrentUser } from "../../context/CurrentUserProvider";
import Contact from "../../components/Contact/Contact";
import Modal from '../../components/Modal'
import { timeFormatter } from "../../utils/timestampFormatter";
import { EventsContext } from "../../context/EventsProvider";
import { UsersContext } from "../../context/UsersProvider";
import { CONTACTS_ENDPOINT } from "../../utils/constants";
import { get } from "../../utils/http";
import { incidentTypeChoices , typeChoices, typeofRequest, 
  typeEventChoices, typeInformationChoices} from "../../components/Events/constants";

const Conversation = () => {
  const { currentUser } = useCurrentUser();
  const { userAct } = useContext(UsersContext);
  const [message, setMessage] = useState("");
  const [currentMessageId, setCurrentMessageId] = useState(null)
  const [reportModal, setReportModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [attachment, setAttachment] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [eventType, setEventType] = useState(null)
  const [incidentType, setIncidentType] = useState(null)
  const [isContact, setIsContacts] = useState([]);
  const {createEvent} = useContext(EventsContext)

  const {
    conversations,
    currentConversationId,
    currentConversation,
    sendMessage,
    firstUnreadIndex,
    fetchRooms,
    setCurrentConversationId,
    setCurrentConversation,
    deleteMessage,
    fetchMessages,
    messages
  } = useConversations();

  useEffect(() => {
    const fetchContacts = async () => {
      setIsLoading(true)
      const url = CONTACTS_ENDPOINT;
      const response = await get({ url });

      if (!response.error) {
        const user = currentConversation?.users?.find((user) =>(user?.id !== currentUser?.id)) 
        const contact = response.data?.find((item) => user?.id === item?.id)
        const isContact = contact ? true : false
        setIsContacts(isContact);
        setIsLoading(false)
      }
    };
    fetchContacts();
  }, [currentConversation]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const token = currentUser.token;
  
  const textareaRef = useRef(null);

  //Calcular el tamano de la pantalla
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
        window.removeEventListener('resize', changeWidth)
    }
  }, [])

  const deleteMessageHandler = (messageId) => {
    deleteMessage({messageId})
    fetchRooms()
    fetchMessages()
    handleClose2()
    handleClose2()
  };

  const sendMessageHandler = async () => {
    if (message.trim() === '' && !attachment) {
      // Si no hay mensaje ni archivo, simplemente retornar sin hacer nada
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("room", currentConversationId);
    formData.append("message", message);
    formData.append("file", attachment);
    try {
      await sendMessage({ data: formData }); 
      await fetchMessages();
    }catch{
    }
    setIsLoading(false);
    setMessage("");
    setAttachment(null);
  };

  //GET menssages
  useEffect(() => {
    if(currentConversationId){fetchMessages();}
  }, [currentConversationId]);

  const setRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);

  //enviar mensaje al darle enter
  const keyDownHandler = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessageHandler();
    }
  };

  messages?.messages?.sort((a, b) => {
    return new Date(a?.timestamp) - new Date(b?.timestamp);
  });

  //Marcar conversacion como leida
  useEffect(() => {
    const readConversationHandler = () => {
      const roomOpen =
        conversations?.filter(
          (conversation) => conversation["id"] === currentConversationId
        ).length > 0;
      if (roomOpen) {
        if (currentConversationId && (currentConversation?.unread_messages > 0)) {
          axios
            .put(
              `endpoint/${currentConversationId}/mark-read`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(
              (response) => {
                if (response.status === 200) {
                }
              },
              (error) => {}
            );
        }
      }
    };
    readConversationHandler();
  }, [currentConversation?.messages]);


  const user = currentConversation?.users?.filter((item) => (
    item?.id !== currentUser?.id
  ))

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "1em"; 
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [message]);

  const handleGoBackChats = () => {
    setCurrentConversationId(null)
    setCurrentConversation(null)
  }

  const handleOpenModalReport = (message) => {
    setCurrentMessageId(message)
    setReportModal(true)
    setAnchorEl(false)
    setAnchorEl2(false)
  }

  const handleChangeType = (e) => {
    setEventType(e.target.value)
  }
  
  const handleChangeIncidentType = (e) => {
    setIncidentType(e.target.value)
  }

  return (
    <div className={ styles.conversation} 
    style={{visibility: (screenWidth <= 750 && !currentConversation) && 'hidden', display: (screenWidth <= 750 && !currentConversation) && 'none'}}>
      {currentConversation &&
      <div style={{display: 'flex', alignItems: 'center', gap: '1rem',
       paddingBottom: '1rem', borderBottom: '1px solid #f0f0f0', 
       marginBottom:'auto', width:'100%'}}>
        {screenWidth <= 750 && <i class="fas fa-arrow-left" onClick={() => handleGoBackChats()} 
        style={{cursor: 'pointer', fontSize: '14px'}}></i>}
        <Contact
          firstName={user && user[0]?.first_name}
          lastName={user && user[0]?.last_name}
          institution={user[0]?.institutions?.length > 0 && user[0]?.institutions[0]}
          status="online"
          myInfo={true}
          profilePic={user[0]?.img_profile}
          type="conversation"
        />
      </div>}
      <Stack direction={"column"} gap={2.5} sx={{overflow:'auto', marginTop: '2px'}}>
        {currentConversationId &&
          messages?.messages?.map((message, index) => {
            let user_name = `${message?.created_by.first_name} ${message?.created_by.last_name}`;
            const namePREFIX = message?.created_by.id;

            const name =
              namePREFIX === currentUser["id"] ? "You" : `${user_name}`;

            const text = message?.message;

            const messageDate = new Date(message?.timestamp);

            const time = timeFormatter(messageDate);

            const lastMessage = messages?.messages?.length - 1 === index;

            return (
                <Stack key={index} direction={"column"} sx={{width:'100%'}}
                ref={lastMessage ? setRef : null}>
                  <Stack direction={"row"} gap={.5} 
                  sx={{display:'flex', width:'100%', justifyContent: name === "You" && 'flex-end', alignItems:'center'}}>
                    {name !== "You" &&
                      <Avatar
                      src={user[0]?.img_profile || null}
                      aria-label="recipe"
                      sx={{ width: 30, height: 30 }}
                      />}
                    {name === "You" && (currentUser?.role !== "external_teacher" && 
                    currentUser?.role !== "internal_teacher" && currentUser?.role !== "development_teacher")  && 
                    <>
                      <IconButton id="basic-button2"  onClick={handleClick2}
                      size="small" sx={{height: '26.8px'}}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="basic-menu2"
                        anchorEl={anchorEl2}
                        open={open2}
                        onClose={handleClose2}
                        elevation={1}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {(name === "You")&&
                        <MenuItem onClick={() => deleteMessageHandler(message.id)}>
                          Eliminar mensaje
                        </MenuItem>
                        }
                        <MenuItem onClick={() => handleOpenModalReport(message)}>
                          Marcar mensaje como incidencia
                        </MenuItem>
                      </Menu>
                    </>}
                    <Box sx={{backgroundColor: name === "You" ? "#007AFF" : "#E9E9EB",
                      borderRadius: '20px', 
                      padding: '0.5rem 1rem 0.5rem 1rem',
                      maxWidth: '70%'
                    }}>
                      <Typography variant="h5" sx={{color: name === "You" && "#fff"}}>
                        {text}
                      </Typography>
                      {message?.attachment &&
                      <Link to={message?.attachment} target="__blank" rel="noreferrer">
                        <Button sx={{borderRadius: '20px', color: name === "You" && '#fff'}}
                        startIcon={<AttachFileIcon />}>
                          Ver Archivo adjunto
                        </Button>
                      </Link>
                      }
                      
                    </Box>
                    {name === "You" &&
                      <Avatar
                      src={userAct?.img_profile || null}
                      aria-label="recipe"
                      sx={{ width: 30, height: 30 }}
                    />}
                    {name !== "You" && <>
                      <IconButton id="basic-button"  onClick={handleClick}
                      size="small" sx={{height: '26.8px'}}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                       elevation={1}
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={() => handleOpenModalReport(message)}>Marcar mensaje como incidencia</MenuItem>
                      </Menu>
                    </>}
                  </Stack>
                  <Stack direction={"row"} gap={1}
                  sx={{display:'flex', marginLeft:  'auto', marginTop: '2px',  
                  marginRight: '5px',  marginLeft: name==="You" ? "auto" : '2px'}}>
                    
                    <Typography >
                      {`${time ? time : "ahora"}`}
                    </Typography>
                    {name === "You" &&
                    (currentUser?.role !== "external_teacher" && 
                    currentUser?.role !== "internal_teacher" && currentUser?.role !== "development_teacher") &&
                      <DoneAllIcon
                      sx={{color: message?.read_by?.length > 0 ? '#4DAA57' : 
                      '#A2A2A2'}} />
                    }
                  </Stack>
                  
                </Stack>
                
            );
          })}
        {currentConversation?.messages?.length === 0 && (
          <div className={styles["no-messages"]}>
            No messages, start the conversation!
          </div>
        )}
      </Stack>
      {currentConversation && 
      <>
      {isContact ? 
      <>
      <div className={styles["new-message"]}>
        <textarea
          ref={textareaRef}
          placeholder="Start typing here"
          onKeyDown={keyDownHandler}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          style={{color: '#000', fontSize:'16px', }}
        ></textarea>
        {!isLoading ? 
        <>
        <button onClick={sendMessageHandler}><i class="fas fa-paper-plane"></i></button>
        <div className={styles.attachment}>
          <label htmlFor="file-input">
            <i className="fal fa-paperclip"></i>
          </label>
          <input
            id="file-input"
            type="file"
            onChange={(e) => setAttachment(e.target.files[0])}
          />
        </div> </> : 
        <MoonLoader color="rgba(51, 0, 114, 1)" speedMultiplier={0.5} size={30} /> }
      </div>
      {screenWidth > 750 &&
        <p style={{marginLeft: 'auto', marginTop:'5px', color: '#858585', marginRight: '10px'}}>
          Salto de línea: Shift + Enter</p>}
      </>:
      <div className={styles["new-message"]}>
        <p style={{textAlign: 'center', width:'100%'}}>No puedes enviar mensajes a este chat</p>
      </div>}
      </> 
      }
      {attachment && <p>{`Archivo: ${attachment?.name}`}</p>}
      {reportModal && 
      <Modal title={"Reportar problema"} 
      modalOpen={setReportModal}>
          <div style={{width: '100%', maxHeight: '80vh', overflowY: 'scroll', display: 'flex', 
          flexDirection: 'column', gap: '15px', justifyContent:'center'}}>
            <Formik
              initialValues={{
                message: message?.id,
                date: new Date(),
                description: "",
              }}
              validationSchema={Yup.object({
                description: Yup.string().required("Campo requerido"),
              })}
                onSubmit={(values, { setSubmitting }) => {
                  const institution = user[0]?.institutions?.length > 0 && user[0]?.institutions[0]
                  const data = {
                    "type_event":eventType,
                    "event_date": new Date(),
                    "description":values.description,
                    "incident_type":incidentType,
                    "message": currentMessageId.id,
                    "report_person": currentMessageId.created_by.id,
                    "institution": institution.id
                  }
                  createEvent({data})
                  setCurrentMessageId(null)
                  setReportModal(false)
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 400);
                }}
              >
                <Form className={styles["form__fields"]}>
                <div style={{display:'flex', gap: '10px'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                  <label for="type_event" style={{marginBottom: '3px'}}>
                  Tipo de Reporte
                  </label>
                  <select id="type_event" name="type_event" onChange={(e) => handleChangeType(e)}>
                    <option value={null}>{"---"}</option>
                    {typeChoices.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
                {eventType === "Incident" && (
                  <div style={{display:'flex', flexDirection:'column'}}>
                    <label for="incident_type" style={{marginBottom: '3px'}}>
                    Tipo de Incidencia
                    </label>
                    <select id="incident_type" name="type_event" onChange={(e) => handleChangeIncidentType(e)}>
                      <option value={null}>{"---"}</option>
                      {incidentTypeChoices.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                      
                    </select>
                  </div>
                )}
                  {eventType === "Request" 
                  && (<div style={{display:'flex', flexDirection:'column'}}>
                    <label for="incident_type" style={{marginBottom: '3px'}}>
                    Tipo de Solicitud
                    </label>
                    <select id="incident_type" name="type_event" onChange={(e) => handleChangeIncidentType(e)}>
                      <option value={null}>{"---"}</option>
                      {typeofRequest.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                      
                    </select>
                  </div>
                  )}
                      
                  {eventType === "Event" && (
                  <div style={{display:'flex', flexDirection:'column'}}>
                    <label for="incident_type" style={{marginBottom: '3px'}}>
                    Tipo de Evento
                    </label>
                    <select id="incident_type" name="type_event" onChange={(e) => handleChangeIncidentType(e)}>
                      <option value={null}>{"---"}</option>
                      {typeEventChoices.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                      
                    </select>
                  </div>
                  
                )}
                {eventType === "Information" && (
                <div style={{display:'flex', flexDirection:'column'}}>
                  <label for="incident_type" style={{marginBottom: '3px'}}>
                  Tipo de Información
                  </label>
                  <select id="incident_type" name="type_event" onChange={(e) => handleChangeIncidentType(e)}>
                    <option value={null}>{"---"}</option>
                    {typeInformationChoices.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                    
                  </select>
                </div>)}
                
                </div>
                  <MyTextarea label="Descripción del problema" name="description" />
                  <MySubmitButton name="Reportar" type="submit" />
                </Form>
            </Formik>
          </div>
      </Modal>}
    </div>
  );
};

export default Conversation;
