import { useState, createContext, useContext, useEffect } from "react";
import { get, post, patch, deleted } from "../utils/http";
import {
  FORM_QUESTION_ENDPOINT,
  FORM_TEMPLATE_ENDPOINT,
  USER_FORM_ENDPOINT,
  ANSWERS_FORM_ENDPOINT,
  SUBMIT_ANSWERS_ENDPOINT,
  BASE_URL
} from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { AlertsContext } from "./AlertsContext";

const FormsContext = createContext();

const FormsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [formTemplate, setFormTemplate] = useState([]);
  const [userForms, setUserForms] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useLocalStorage(
    "selectedQuestion",
    ""
  );
  const [selectedFormTemplate, setSelectedFormTemplate] = useLocalStorage(
    "selectedFormTemplate",
    ""
  );
  const showSnackbar = useContext(AlertsContext);

  const navigate = useNavigate();

  const createQuestion = async ({ data }) => {
    const url = FORM_QUESTION_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha creado la pregunta", "success");
      fetchQuestions();
    } else {
      showSnackbar("Hubo un error al crear la pregunta", "error");
    }
  };

  const fetchQuestions = async () => {
    const url = FORM_QUESTION_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setQuestions(response.data);
    }
  };

  const createFormTemplate = async ({ data }) => {
    const url = FORM_TEMPLATE_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Encuesta creada", "success");
      fetchFormTemplates();
    } else {
      showSnackbar("Hubo un error al crear la encuesta", "error");
    }
  };

  const fetchFormTemplates = async () => {
    const url = FORM_TEMPLATE_ENDPOINT;
    const response = await get({ url });

    const newFormTemplates = response.data;

    newFormTemplates?.sort((a, b) => {
      if (a.status !== "Borrador") {
        return 1;
      } else if (b.status !== "Borrador") {
        return -1;
      } else {
        return 0;
      }
    });

    if (!response.error) {
      setFormTemplate(newFormTemplates);
    }
  };

  const updateTemplate = async ({ templateId, data }) => {
    const url = `${FORM_TEMPLATE_ENDPOINT}${templateId}/`;

    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar("Se ha editado la encuestas", "success");
      fetchFormTemplates();
    } else {
      showSnackbar("Hubo un error al editar la encuesta", "error");
    }
  };

  const deleteTemplate = async ({ templateId }) => {
    const url = `${FORM_TEMPLATE_ENDPOINT}${templateId}/`;
    const response = await deleted({ url });
    if (!response.error) {
      showSnackbar("Encuesta eliminada", "success");
      fetchFormTemplates();
    } else {
      showSnackbar("Hubo un error al eliminar la encuesta", "error");
    }

    return response;
  };

  const fetchUserForms = async () => {
    const url = `${BASE_URL}/accounts/forms/`;
    const response = await get({ url });

    const newUserForms = response.data;

    newUserForms?.sort((a, b) => {
      if (a.completed === true) {
        return 1;
      } else if (b.completed === true) {
        return -1;
      } else {
        return 0;
      }
    });

    if (!response.error) {
      setUserForms(newUserForms);
    }
  };

  const sendUserForms = async ({ data }) => {
    // Verificar si el form es de tipo teacher o empleado
    const url = USER_FORM_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Encuesta enviada satisfactoriamente", "success");
      fetchUserForms();
    } else {
      showSnackbar("Hubo un error al enviar la encuesta", "error");
    }
  };

  const fetchTeacherAnswers = async () => {
    const url = ANSWERS_FORM_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setAnswers(response.data);
    }
  };

  const submitAswers = async ({ data }) => {
    // Verificar si el form es de tipo teacher o empleado
    const url = SUBMIT_ANSWERS_ENDPOINT;
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar("Respuestas guardadas!", "success");
      navigate(-1);
    } else {
      showSnackbar("Hubo un error al guardar la respuestas", "error");
    }
  };

  useEffect(() => {
    
  }, []);

  const contextData = {
    questions,
    setQuestions,
    selectedFormTemplate,
    setFormTemplate,
    formTemplate,
    setSelectedFormTemplate,
    selectedQuestion,
    setSelectedQuestion,
    fetchFormTemplates,
    fetchQuestions,
    createFormTemplate,
    createQuestion,
    updateTemplate,
    deleteTemplate,
    userForms,
    setUserForms,
    fetchUserForms,
    sendUserForms,
    answers,
    setAnswers,
    fetchTeacherAnswers,
    submitAswers,
  };

  return (
    <FormsContext.Provider value={contextData}>
      {loading ? null : children}
    </FormsContext.Provider>
  );
};

const useForms = () => {
  return useContext(FormsContext);
};

export { FormsContext, FormsProvider, useForms };
