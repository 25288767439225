import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import { Button, Box } from "@mui/material";
import { MONITOR_FORM_ENDPOINT } from "../../../utils/constants";
import { get } from "../../../utils/http";
import Container from "../../Container";
import styles from "./PlanillasList.module.css";
import { MonitorFormsContext } from "../../../context/MonitorFormsContext";
import { handleDownloadExcel } from "../../../utils/downloadToExcel";
import { CurrentUserContext } from "../../../context/CurrentUserProvider";
import { dateFormatter } from "../../../utils/dateFormatter";
const options = ["Seguimiento", "Docente", "Institución", "Tipo de docente", "Fecha", "Ruta", "Nota", "Alerta", "Feedback"]

const TableColumns = () => {
  return options.map((column) => {
    if (column === "Seguimiento" || column === "Docente" || column === "Institución" ) {
      return (
        <TableCell
          key={column}
          sx={{
            color: "#8C8C8C",
            fontSize: "10.4px",
            fontWeight: "bold",
            background: "#ffff",
            textTransform: 'uppercase'
          }}>
          {column}
        </TableCell>
      )
    }
    return (
      <TableCell
        key={column}
        align="center"
        sx={{
          color: "#8C8C8C",
          fontSize: "10.4px",
          fontWeight: "bold",
          background: "#ffff",
          textTransform: 'uppercase'
        }}>
        {column}
      </TableCell>
    )
  })
}

function PlanillasList() {
  const [planillas, setPlanillas] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextSeguimiento, setSearchTextSeguimiento] = useState("");
  const [filterTerm, setFilterTerm] = useState("ALL");
  const [filterRoute, setFilterRoute] = useState("");
  const { setSelectedMonitorForm } = useContext(MonitorFormsContext);

  const { currentUser } = useContext(CurrentUserContext);

  planillas?.sort((a,b) => (new Date(b.date) - new Date(a.date)))

  const internalTeachers = planillas?.filter(
    (item) => item.teacher_type !== "External"
  );

  const finalPlanillas =
    currentUser?.role === "academic_coordination" ||
    currentUser?.role === "academy_coordination"
      ? internalTeachers
      : planillas;

  useEffect(() => {
    const fetchPlanillas = async () => {
      const url = `${MONITOR_FORM_ENDPOINT}`;
      const response = await get({ url });
      setPlanillas(response.data);
    };
    fetchPlanillas();
  }, []);

  let filterData = finalPlanillas?.filter((item) => {
    if (searchText === "" && filterTerm === "" && filterRoute === "" && searchTextSeguimiento === "") {
      return item;
    } else {
      let teacherMatch = true;
      let monitorMatch = true;
      const fullNameA = `${item?.teacher?.first_name} ${item?.teacher?.last_name}`;
      const full_name_monitor = `${item?.monitor?.first_name} ${item?.monitor?.last_name}`;
      if (searchText !== "" ) {
        teacherMatch = fullNameA
          ?.toLowerCase()
          .includes(searchText.toLowerCase());
      }
      if (searchTextSeguimiento !== "" ) {
        monitorMatch = full_name_monitor
          ?.toLowerCase()
          .includes(searchTextSeguimiento.toLowerCase());
      }

      let termMatch = true;
      if (filterTerm !== "") {
        termMatch = filterTerm === "ALL" ||  item?.term?.name === filterTerm;
      }

      let routeMatch = true;
      if (filterRoute !== "") {
        routeMatch = item?.route === parseInt(filterRoute);
      }

      if (termMatch && teacherMatch && routeMatch && monitorMatch) {
        return item;
      }
    }
  });

  const exportData = filterData?.map((item) => ({
    "Fecha de creación": dateFormatter(item?.creation_date),
    "Fecha de visita": dateFormatter(item?.date),
    Revisado: dateFormatter(item?.reviewed_date), 
    Lapso: item?.term && `${item?.term?.year?.name} - ${item?.term?.name} `,
    "Docente de seguimiento": `${item?.monitor?.first_name} ${item?.monitor?.last_name}`,
    Docente: `${item?.teacher?.first_name} ${item?.teacher?.last_name}`,
    Tipo: item?.teacher_type === "External" ? "Externo" : `Interno`,
    Colegio: item?.course_section?.level?.institution,
    Grado: item?.course_section?.level?.name,
    Seccion: item?.course_section?.name,
    Nota: ((item?.grade)/100).toFixed(2),
    "Nota subjetiva": ((item?.estimated_grade)/100).toFixed(2),
    Comentario: item?.comments,
    Feedback: item?.feedback,
    "Registros fotográficos": item?.pictures_count,
  }));

  const navigate = useNavigate();

  const handleRowClick = (row) => {
    setSelectedMonitorForm(row);
    navigate(`/seguimiento/planilla/${row.id}`, { relative: false });
  };

  const opt = [
    {
      label: "Todos",
      value: "ALL",
    },
    {
      label: "I Lapso",
      value: "pending",
    },
    {
      label: "II Lapso",
      value: "II",
    },
    {
      label: "III Lapso",
      value: "III",
    },
  ]

  return (
    <Container>
      <div className={styles.table}>
        <Box sx={{width:'100%', display:'flex', marginBottom:'10px'}}>
        <Button variant="contained" sx={{borderRadius: '10px', marginLeft: 'auto'}}
        onClick={() =>
              handleDownloadExcel(exportData, "Planillas de seguimiento")
            }>
          Descargar Tabla
        </Button>
        </Box>
        <Grid container direction="row" rowSpacing={2} columnSpacing={2}>
        <Grid item md={3} sm={6} xs={6}>
            <TextField variant="outlined" 
                  size="small" 
                  value={searchTextSeguimiento}
                  placeholder="Buscar por docente de seguimiento" fullWidth
                  onChange={(e) => setSearchTextSeguimiento(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRoundedIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginBottom: 1,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      backgroundColor:'#fff'
                    },
              }} />
          </Grid>
          <Grid item md={3} sm={6} xs={6}>
            <TextField variant="outlined" 
                  size="small" 
                  value={searchText}
                  placeholder="Buscar por docente" fullWidth
                  onChange={(e) => setSearchText(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRoundedIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginBottom: 1,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      backgroundColor:'#fff'
                    },
              }} />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
          <FormControl 
            variant="outlined" 
            size="small" 
            sx={{
              width:'100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                backgroundColor: '#fff',
              },
            }}
          >
            <InputLabel id="filter-status-label">Filtro por Asistencia</InputLabel>
            <Select
              labelId="filter-status-label"
              label="Estado"
              name="status"
              value={filterTerm}
              onChange={(e) => setFilterTerm(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ccc',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#999',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#333',
                },
              }}
            >
              {opt?.map((option, i) => (
                <MenuItem key={i} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
          <TextField variant="outlined" 
                  size="small" 
                  value={filterRoute}
                  placeholder="Buscar por ruta" fullWidth
                  onChange={(e) => setFilterRoute(e.target.value)}
                  sx={{
                    marginBottom: 1,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      backgroundColor:'#fff'
                    },
              }} />
          </Grid>
        </Grid>
        <TableContainer
        component={Paper}
        elevation={0}
        sx={{ overflow: "scroll", maxHeight: "500px", width: '100%' }}>
          <Table size="small" aria-label="sales order table" stickyHeader>
          <TableHead sx={{ border: "none", height: '45px'}}>
            <TableRow sx={{ border: "none" }}>
              <TableColumns />
            </TableRow>
          </TableHead>
          <TableBody>
            {filterData?.map((item, i) => {
              let status
              let textColor = ""
              if (item?.is_read_feedback) {
                status = "Leído"
                textColor = "var( --sec-blue )"
              }else if(item.reviewed){
                status ="Enviado"
                textColor = "#43b02a"
              }else{
                status ="Por Enviar"
                textColor = "#fcb514"
              }
              return(
              <TableRow onClick={() => handleRowClick(item)} sx={{cursor:'pointer'}}
                  key={i}>
                  <TableCell component="th" scope="row" sx={{fontSize: '14px'}}>
                    {item?.monitor?.first_name}
                  </TableCell>
                  <TableCell align="start" sx={{fontSize: '14px'}}>
                    {`${item?.teacher?.first_name} ${item?.teacher?.last_name}`}
                  </TableCell>
                  <TableCell align="start" sx={{fontSize: '14px', maxWidth: '220px'}}>
                    {item?.course_section?.level?.institution}
                  </TableCell>
                  <TableCell align="center" sx={{fontSize: '14px'}}>
                    {item.teacher_type}
                  </TableCell>
                  <TableCell align="center" sx={{fontSize: '14px'}}>
                    {item.date}
                  </TableCell>
                  <TableCell align="center" sx={{fontSize: '14px'}}>
                    {item.route}
                  </TableCell>
                  <TableCell align="center" sx={{fontSize: '14px'}}>
                    {`${item?.grade?.toFixed(2)}%`}
                  </TableCell>
                  <TableCell align="center">
                    {(item?.alert || item?.grade < 70) && (
                      <Chip label={"Revisar"} 
                      sx={{backgroundColor: "#d30019", 
                        width: "fit-content",
                          margin: 0,
                          height: 25,
                          borderRadius: "10px",
                          fontWeight: "bold",
                          color: '#fff',
                          fontSize: '11px',}} 
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={status}
                      sx={{
                        backgroundColor:textColor,
                        textTransform: "capitalize",
                        width: "fit-content",
                        margin: 0,
                        height: 25,
                        borderRadius: "10px",
                        fontWeight: "bold",
                        color: "#fff",
                        fontSize: '11px',
                      }}
                    />
                  </TableCell>
                </TableRow>
            )})}
          </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.questions_total}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: "12px" }}>
              Total de planillas:
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
            >
              {`${filterData.length}`}
            </p>
            <p style={{ fontWeight: "bold", fontSize: "12px" }}>
              Planillas por enviar:
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
            >
              {`${filterData?.filter((item) => !item.reviewed)?.length}`}
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default PlanillasList;