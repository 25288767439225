import React, { useState, useContext, useEffect, useCallback } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Container from "../components/Container";
import SettingsBar from "../components/Navbars/SettingsBar";
import { ANNOUNCEMENTS_OPTIONS } from "../utils/constants/announcementsConstants";
import styles from "./AnnouncementsPage.module.css";
import { CurrentUserContext } from '../context/CurrentUserProvider';

function AnnouncementsPage() {
  const [announcementsOption, setAnnouncementsOption] = useState("list");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { currentUser } = useContext(CurrentUserContext);
  const userPermission = (currentUser.role === "IT" || 
    currentUser.role === "monitoring_coordinator" || 
    currentUser.role === "monitoring_teacher" ||
    currentUser?.role === "monitoring_teacher_3")
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
        window.removeEventListener('resize', changeWidth)
    }
  }, [])

  useEffect(() => {
    if (!location.pathname.split("/")[2]) {
      navigate("list", { replace: true });
    } 
  }, [location.pathname]);
  
  return (
    <div className={styles.content}>
      {(userPermission) && (
        <SettingsBar
          optionsList={ANNOUNCEMENTS_OPTIONS}
          option={announcementsOption}
          setOption={setAnnouncementsOption}
        />
      )}
      <section className={styles.leftside}>
        <Container direction={"row"}>
          <Outlet />
        </Container>
      </section>
    </div>
  );
}

export default AnnouncementsPage;
