import React from "react";
import { Formik, Form } from "formik";
import {
  MyIconTextInput,
  MySubmitButton,
  MyEmailTextInput,
} from "./FormFields";
import * as Yup from "yup";
import styles from "./LoginForm.module.css";
import Modal from "./Modal";

const LoginForm = ({
  onSubmitFunction,
  openModal,
  setOpenModal,
  resetHandler,
}) => {

  return (
    <>
      <div className={styles["login-form"]}>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={Yup.object({
            username: Yup.string()
              .min(1, "El campo no puede estar vacío.")
              .required("Campo requerido"),
            password: Yup.string()
              .min(1, "Debe contener mas de 8 caracteres.")
              .required("Campo requerido"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              onSubmitFunction(values);
              setSubmitting(false);
            }, 400);
          }}
        >
          <Form className={styles["login-form__fields"]}>
            <MyIconTextInput
              label="Usuario"
              name="username"
              type="text"
              placeholder="Usuario"
              icon="fa fa-user"
            />

            <MyIconTextInput
              label="Contraseña"
              name="password"
              type="password"
              placeholder="Contraseña"
              icon="fa fa-key"
            />
            <p
              onClick={() => setOpenModal(true)}
              style={{
                marginTop: 0,
                fontSize: "12px",
                cursor: "pointer",
                paddingBottom: "5px",
                marginLeft:'auto',
              }}
            >
              Recuperar Contraseña
            </p>
            <div style={{width:'100%'}}>
              <MySubmitButton name="Ingresar" type="submit" />
            </div>
            
          </Form>
        </Formik>
      </div>
      {openModal && (
        <Modal title={"Recuperar contraseña"} modalOpen={setOpenModal}>
          <div>
            <p
              style={{
                width: "350px",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              Ingresa tu correo para enviarte las instrucciones de recuperación
            </p>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .min(1, "El campo no puede estar vacío.")
                  .required(),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                  resetHandler(values);
                  setSubmitting(false);
                }, 400);
                resetForm();
              }}
            >
              <Form className={styles["form__fields"]}>
                <MyEmailTextInput
                  label="Email"
                  name="email"
                  type="text"
                  placeholder="Email"
                  icon="fa fa-user"
                />
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <MySubmitButton name="Recuperar" type="submit" />
                </div>
              </Form>
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LoginForm;
