import React, { useState, useEffect, useContext } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { PROGRAMS_OPTIONS } from "../utils/constants/programsConstants";
import Container from "../components/Container";
import SettingsBar from "../components/Navbars/SettingsBar";
import styles from "./ProgramsPage.module.css";
import { CurrentUserContext } from "../context/CurrentUserProvider";

function ProgramsPage() {
  const [programsOption, setProgramsOption] = useState("list");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const {currentUser} = useContext(CurrentUserContext)

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.split("/")[2]) {
      navigate("list", { replace: true });
    } else {
      setProgramsOption(location.pathname.split("/")[2]);
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
        window.removeEventListener('resize', changeWidth)
    }
  }, [])

  return (
    <div className={styles.content}>
      {(currentUser.role === 'IT') && (
        <SettingsBar
        optionsList={PROGRAMS_OPTIONS}
        option={programsOption}
        setOption={setProgramsOption}
      />
      )}
      <section className={styles.leftside}>
        <Container direction={"row"}>
          <Outlet />
        </Container>
      </section>
    </div>
  );
}

export default ProgramsPage;
