import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import styles from "./RegisterPage.module.css";
import {
  MyCheckbox,
  MySubmitButton,
  MyTextInput,
  MySelect,
  MyRadio,
} from "../components/FormFields";
import { UsersContext } from "../context/UsersProvider";
import { InstitutionsContext } from "../context/InstitutionsContext";
import { CoursesContext } from "../context/CoursesContext";

function RegisterPage() {
  const { registerUser } = useContext(UsersContext);
  const { institutions, fetchInstitutions } = useContext(InstitutionsContext);
  const { courses } = useContext(CoursesContext);
  const [isEmployee, setIsEmployee] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isTeacherAndEmployee, setIsTeacherAndEmployee] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [showInstitutions, setShowInstitutions] = useState(true);
  const [showCourses, setShowCourses] = useState(false);

  const navigate = useNavigate();
  
  useEffect(() => {
    fetchInstitutions()
  }, []);

  useEffect(() => {
    if (!isEmployee) {
      setIsTeacherAndEmployee(false);
    }
  }, [setIsTeacherAndEmployee, isEmployee]);

  const registerHandler = async (values) => {
    const registerData = {
      username: values.username,
      password: values.password,
      password2: values.password2,
    };
    registerUser(registerData);
  };

  const handleChangeEmployeeValue = (e) => {
    setIsTeacher(false);
    setIsEmployee(!isEmployee);
  };

  const handleChangeTeacherValue = (e) => {
    setIsEmployee(false);
    setIsTeacher(!isTeacher);
  };

  let filterData = institutions.filter(
    (item) =>
      item.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.short_name.toLowerCase().includes(filterText.toLowerCase())
  );

  let filterCourseData = courses.filter(
    (item) =>
      item.program.toLowerCase().includes(filterText.toLowerCase()) ||
      item.institution.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.institution.short_name
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      item.institution_level.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleShowInst = () => {
    setShowCourses(false);
    setFilterText("");
    setShowInstitutions(!showInstitutions);
  };
  const handleShowCourses = () => {
    setShowInstitutions(false);
    setFilterText("");
    setShowCourses(!showCourses);
  };

  return (
    <div style={{ width: "100%" }}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      ></i>
      <div className={styles["register-form"]}>
        <Formik
          initialValues={{
            username: "",
            email: "",
            first_name: "",
            last_name: "",
            password1: "",
            password2: "",
          }}
          validationSchema={Yup.object({
            username: Yup.string()
              .min(2, "Debe contener al menos 2 caracteres.")
              .required("Campo requerido"),
            email: Yup.string()
              .min(2, "Debe ingresar un email valido.")
              .email()
              .required("Campo requerido"),
            first_name: Yup.string()
              .min(2, "Debe contener al menos 2 caracteres.")
              .required("Campo requerido"),
            last_name: Yup.string()
              .min(3, "Debe contener mas de 3 caracteres.")
              .required("Campo requerido"),
            password: Yup.string()
              .min(8, "Debe contener al menos 8 caracteres.")
              .required("Campo requerido"),
            password2: Yup.string()
              .min(8, "Debe contener al menos 8 caracteres.")
              .oneOf(
                [Yup.ref("password"), null],
                "Las contraseñas deben coincidir"
              )
              .required("Campo requerido"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              registerHandler(values);
              setSubmitting(false);
            }, 400);
            resetForm();
          }}
        >
          <Form className={styles["form__fields"]}>
            <div className={styles.form__fields_employee}>
              <div className={styles.form_inputs}>
                <div className={styles.inputs}>
                  <MyTextInput
                    label="Nombre de usuario"
                    name="username"
                    type="text"
                    placeholder="Nombre de usuario"
                  />
                  <MyTextInput
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className={styles.inputs}>
                  <MyTextInput
                    label="Nombre"
                    name="first_name"
                    type="text"
                    placeholder="Nombre"
                  />
                  <MyTextInput
                    label="Apellido"
                    name="last_name"
                    type="text"
                    placeholder="Apellido"
                  />
                </div>
                <div className={styles.inputs}>
                  <MyTextInput
                    label="Contraseña"
                    name="password"
                    type="password"
                    placeholder="Contraseña"
                  />
                  <MyTextInput
                    label="Confirmar contraseña"
                    name="password2"
                    type="password"
                    placeholder="Confirmar contraseña"
                  />
                </div>
                <div className={styles.check_inputs}>
                  <MyRadio
                    label="Empleado"
                    name="isEmployee"
                    checked={isEmployee}
                    onClick={(e) => handleChangeEmployeeValue(e)}
                  >
                    Empleado
                  </MyRadio>
                  <MyRadio
                    label="Docente"
                    name="isTeacher"
                    checked={isTeacher}
                    onClick={(e) => handleChangeTeacherValue(e)}
                  >
                    Externo
                  </MyRadio>
                </div>
                {isEmployee && (
                  <>
                    <div className={styles.inputs}>
                      <MyTextInput
                        label="Teléfono"
                        name="phone"
                        type="text"
                        placeholder="Número de teléfono"
                      />
                      <MyTextInput
                        label="Dirección"
                        name="address"
                        type="text"
                        placeholder="Dirección"
                      />
                    </div>
                    <div className={styles.inputs}>
                      <MySelect
                        label="Rol"
                        name="role"
                        setType={setIsTeacherAndEmployee}
                      >
                        <MenuItem value="ACADEMIC_COORDINATION">
                          Coordinación Académica
                        </MenuItem>
                        <MenuItem value="ACADEMY_COORDINATION">
                          Coordinación de Academia
                        </MenuItem>
                        <MenuItem value="DIRECTORS">Dirección</MenuItem>
                        <MenuItem value="INTERNAL_TEACHER">
                          Docente Interno
                        </MenuItem>
                        <MenuItem value="DEVELOPMENT_TEACHER">
                          Docente de Desarrollo
                        </MenuItem>
                        <MenuItem value="IT">IT</MenuItem>
                        <MenuItem value="MONITORING_COORDINATOR">
                          Coordinador de Seguimiento
                        </MenuItem>
                        <MenuItem value="MONITORING_TEACHER">
                          Docente de Seguimiento
                        </MenuItem>
                        <MenuItem value="POST_SALES">Post Ventas</MenuItem>
                        <MenuItem value="PURCHASES_AND_INVENTORY">
                          Procura y Administracion
                        </MenuItem>
                      </MySelect>
                      <MySelect label="Sede" name="branch">
                        <MenuItem value="caracas">Caracas</MenuItem>
                      </MySelect>
                    </div>
                  </>
                )}
              </div>
              {(isTeacher || isTeacherAndEmployee) && (
                <div className={styles.inputs_right}>
                  <div className={styles.expand} onClick={handleShowInst}>
                    <p>Seleccione las instituciones</p>
                    {!showInstitutions ? (
                      <i
                        class="fal fa-chevron-down"
                        style={{
                          fontSize: "13px",
                          marginLeft: "1rem",
                          marginTop: "1.5rem",
                          color: "#000",
                        }}
                      ></i>
                    ) : (
                      <i
                        class="fal fa-chevron-up"
                        style={{
                          fontSize: "13px",
                          marginLeft: "1rem",
                          marginTop: "1.5rem",
                          color: "#000",
                        }}
                      ></i>
                    )}
                  </div>
                  {showInstitutions && (
                    <div>
                      <div className={styles["search-bar"]}>
                        <input
                          id="search"
                          type="text"
                          placeholder="Buscar"
                          aria-label="Search Input"
                          value={filterText}
                          onChange={(e) => setFilterText(e.target.value)}
                          className={styles.filter_text}
                        />
                      </div>
                      <div className={styles.inst}>
                        {filterData.map((inst) => (
                          <div className={styles.check_level}>
                            <MyCheckbox
                              name="institutions"
                              value={inst.id.toString()}
                            >
                              {`${inst.name}`}
                            </MyCheckbox>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className={styles.expand} onClick={handleShowCourses}>
                    <p>Seleccione los cursos</p>
                    {!showCourses ? (
                      <i
                        class="fal fa-chevron-down"
                        style={{
                          fontSize: "13px",
                          marginLeft: "1rem",
                          marginTop: "1.5rem",
                          color: "#000",
                        }}
                      ></i>
                    ) : (
                      <i
                        class="fal fa-chevron-up"
                        style={{
                          fontSize: "13px",
                          marginLeft: "1rem",
                          marginTop: "1.5rem",
                          color: "#000",
                        }}
                      ></i>
                    )}
                  </div>
                  {showCourses && (
                    <div>
                      <div className={styles["search-bar"]}>
                        <input
                          id="search"
                          type="text"
                          placeholder="Buscar"
                          aria-label="Search Input"
                          value={filterText}
                          onChange={(e) => setFilterText(e.target.value)}
                          className={styles.filter_text}
                        />
                      </div>
                      <div className={styles.inst}>
                        {filterCourseData.map((course) => (
                          <div className={styles.check_level}>
                            <MyCheckbox name="courses" value={course.id}>
                              {`${course.institution_level} ${course.institution.short_name} | ${course.program}`}
                            </MyCheckbox>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <MySubmitButton name="Registrar" type="submit" />
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default RegisterPage;
