/**
 * Componente Certificate
 *
 * Este componente genera un certificado en formato PDF que muestra el nombre de la persona, el programa, 
 * el colegio y un mensaje personalizado sobre el logro de los objetivos del programa de robótica.
 * Utiliza el paquete `@react-pdf/renderer` para generar el PDF.
 *
 * @param {Object} props - Propiedades del componente
 * @param {Object} props.person - Información de la persona (nombre, apellido, colegio, programa y atributo UCAB)
 * @param {string} props.person.name - Nombre de la persona
 * @param {string} props.person.lastName - Apellido de la persona
 * @param {string} props.person.programa - Nombre del programa de robótica
 * @param {string} props.person.colegio - Nombre del colegio
 * @param {string} props.person.UCAB - Indicador si la plantilla es de UCAB o no ("TRUE" o "FALSE")
 * @returns {React.Component|null} Componente PDFViewer que contiene el documento PDF del certificado, o `null` si no se proporciona `person`
 */

import React from 'react';
import { 
    Document, 
    Page, 
    Text, 
    StyleSheet, 
    PDFViewer, 
    View, 
    Image, 
    Font 
} from '@react-pdf/renderer';
import OpenSansRegular from '../../fonts/OpenSans/OpenSans-Regular.ttf'
import { capitalizeWords } from '../../utils/capitalize';

// Registro de fuente para uso en el PDF
Font.register({ family: 'OpenSans', src: OpenSansRegular });

// Estilos para los elementos del PDF
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      position: 'relative',
    },
    backgroundImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
    },
    contentContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    title: {
        textAlign:'center', 
        marginTop: 210, 
        fontSize: '28px', 
        color: '#412b88',
        fontFamily: 'OpenSans'
    },
    text:{
        fontSize: '14px', 
        textAlign: 'center', 
        marginTop: 30, width: '70%', 
        color: '#58595b',
        fontFamily: 'OpenSans'
    },
    text2:{
        fontSize: '14px', 
        textAlign: 'center', 
        marginTop: 15, width: '70%', 
        color: '#58595b',
        fontFamily: 'OpenSans'
    }
});


/**
 * Función principal del componente
 * @function Certificate
 * @param {Object} props - Propiedades que incluyen la información de la persona
 */
function Certificate({ person }) {
    // Si no se proporciona una persona, el componente no muestra nada
    if (!person) return null;

    // Texto personalizado del certificado que incluye el programa y el colegio de la persona
    let text = `Por cumplir con éxito los objetivos del programa de robótica ${person.programa} del año escolar 2023 - 2024, en el colegio ${capitalizeWords(person.colegio)}. Trabajando la creatividad, la resolución de problemas y el pensamiento crítico a lo largo de las clases.`

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.contentContainer}>
            <Image src={person.UCAB === "FALSE" ? "/template2.png" : "/template.png"} style={styles.backgroundImage} />
          <View style={styles.section}>
            <Text 
            style={styles.title}>
                {`${capitalizeWords(person.name)} ${capitalizeWords(person.lastName)}`}
            </Text>
            <div style={{width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center'}}>
            <Text 
            style={styles.text}>
                {`${text}`}
            </Text>
            <Text 
            style={styles.text2}>
                {`Caracas, mes de julio del año 2024.`}
            </Text>
            </div>
            
          </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default Certificate
