import React, { useState, useEffect, useContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./SurveysPage.module.css";
import SettingsBar from "../components/Navbars/SettingsBar";

export const FORMS_OPTIONS = [
    {
      key: "list",
      name: "Lista",
      icon: <i className="fas fa-poll-h"></i>,
      route: "list",
    },
    {
      key: "agregar-capacitacion",
      name: "Cargar capacitación",
      icon: <i className="fas fa-file-plus"></i>,
      route: "agregar-capacitacion",
    },
    {
      key: "agregar-pregunta",
      name: "Crear pregunta",
      icon: <i class="fas fa-plus-square"></i>,
      route: "agregar-pregunta",
    },
    {
      key: "lista-preguntas",
      name: "Lista preguntas",
      icon: <i class="fas fa-list-alt"></i>,
      route: "lista-preguntas",
    },
    {
      key: "agregar-quiz",
      name: "Crear Quiz",
      icon: <i class="fas fa-plus-square"></i>,
      route: "agregar-quiz",
    },
    {
      key: "lista-quices",
      name: "Lista Quices",
      icon: <i class="fas fa-list-alt"></i>,
      route: "lista-quices",
    },
];

function CapacitacionesPageAdmin() {
    const [formsOption, setFormsOption] = useState("list");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location.pathname.split("/")[3]) {
          navigate("list", { replace: true });
        } else {
          setFormsOption(location.pathname.split("/")[3]);
        }
    }, [location.pathname]);

    useEffect(() => {
      const changeWidth = () => {
        setScreenWidth(window.innerWidth);
      }
      window.addEventListener('resize', changeWidth)
      return () => {
          window.removeEventListener('resize', changeWidth)
      }
    }, [])

  return (
    <div className={styles.content}>
      {(formsOption === "list" ||
        formsOption === "agregar-capacitacion" || 
        formsOption === "agregar-pregunta" || 
        formsOption === "lista-preguntas" || 
        formsOption === "agregar-quiz" || 
        formsOption === "lista-quices" ) && (
          <SettingsBar
          optionsList={FORMS_OPTIONS}
          option={formsOption}
          setOption={setFormsOption}
        />
      )}
      <section className={styles.leftside}>
        <Outlet />
      </section>
    </div>
  )
}

export default CapacitacionesPageAdmin
