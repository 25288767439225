import React from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function NotificationsMenu({open, anchorEl, setAnchorEl,
    filteredNotifications, readNotification}) {

    const handleClose = () => {
        setAnchorEl(null);
    };

  return (
    <Menu
    anchorEl={anchorEl}
    id="basic-menu"
    open={open}
    onClose={handleClose}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
    PaperProps={{
      style: {
        maxHeight: 150 * 4.5,
      },
    }}
      >
        {filteredNotifications?.length > 0 ?
        <>
          {filteredNotifications?.map((notification) => {
            return(
                <MenuItem key={notification.id} sx={{backgroundColor: notification.status !== 3 && "#E7F2FF"}}
                onClick={() => readNotification(notification.id, notification.url)}>
                    <Stack direction={"column"} >
                        <Typography variant='h6'
                        sx={{fontWeight: "550"}}>
                        {notification.title}
                        </Typography>
                        <Typography >
                        {notification.content}
                        </Typography>
                    </Stack>
                </MenuItem>

            )
        })}
        </>
        : 
        <Typography sx={{padding: '5px'}}>No tienes notificaciones</Typography>}
        
      </Menu>
  )
}

export default NotificationsMenu
