import React, { useState, useEffect } from "react";
import styles from "./ReferenceDetail.module.css";
import { useOutletContext, useParams } from "react-router-dom";
import { get } from "../../utils/http";
import { DATA_ENTRY_ENDPOINT } from "../../utils/constants";

const ReferenceDetail = () => {
  const {
    entry: [entry],
  } = useOutletContext();

  const [entries, setEntries] = useState([]);

  const { referenceId } = useParams();

  const fetchRelatedEntries = async () => {
    const url = DATA_ENTRY_ENDPOINT(referenceId);

    const response = await get({ url });
    if (!response.error) {
      setEntries(response.data);
    }
  };

  useEffect(() => {
    if (referenceId) {
      fetchRelatedEntries();
    }
  }, [referenceId]);

  return (
    <div className={styles["entry-detail"]}>
      <div className={styles["entry-header"]}>
        <h3>{entry?.title}</h3>
        <p>{entry?.content}</p>
      </div>
      <div className={styles.entries}>
        {entries.map((item) => (
          <div className={styles["program-entry"]} key={item.id}>
            <div className={styles["first-block"]}>
              <div className={styles.program}>{`R1000`}</div>
              <div className={styles.title}>{item.title}</div>
            </div>
            <div className={styles.description}>{item.description}</div>
            <div className={styles.image}>
              <img src={item.image} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReferenceDetail;
