export const PROGRAMS_OPTIONS = [
  {
    key: "list",
    name: "Programas",
    icon: <i className="fad fa-robot"></i>,
    route: "list",
  },
  {
    key: "lessons",
    name: "Clases",
    icon: <i className="fad fa-books"></i>,
    route: "lessons",
  },
  {
    key: "create",
    name: "Crear Programa",
    icon: <i className="fas fa-plus-square"></i>,
    route: "create",
  },
  {
    key: "licenses",
    name: "Crear Licencia",
    icon: <i className="fas fa-plus-square"></i>,
    route: "licenses",
  },
];
