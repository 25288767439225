import React, { useContext, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { get } from "../../../utils/http";
import {
  MyTextInput,
  MySubmitButton,
  MySelect,
  MyTextarea,
  MyRadio,
} from "../../FormFields";
import Container from "../../Container";
import { PROGRAMS_ENDPOINT } from "../../../utils/constants";
import { wordCapitalize } from "../../../utils/capitalize";
import styles from './CreateQuestionCap.module.css'
import { CapacitacionesContext } from "../../../context/CapacitacionesContext";

function CreateQuestionCap() {
  const [programs, setPrograms] = useState([]);
  const {createQuestion} = useContext(CapacitacionesContext)
  const [hasSingleOptions, setHasSingleOptions] = useState(false);
  const [hasMultipleOptions, setHasMultipleOptions] = useState(false);
  const [file, setFile] = useState(null);
  const [options, setOptions] = useState([{ description: "", file: null, correct_answer: false }]);

  useEffect(() => {
    if (!hasSingleOptions && !hasMultipleOptions) {
      setOptions([{ description: "",file: null, correct_answer: false }]);
    }
  }, [hasSingleOptions, hasMultipleOptions]);

  useEffect(() => {
    const fetchLicenses = async () => {
      const url = PROGRAMS_ENDPOINT;
      const response = await get({ url });

      if (!response.error) {
        setPrograms(response.data);
      }
    };
    fetchLicenses();
  }, []);

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  const navigate = useNavigate();

  const handleImageChange = (index, event) => {
    const newOptions = [...options];
    newOptions[index].file = event.target.files[0];
    setOptions(newOptions);
  };

  const handleChange = (index, event) => {
    const newOptions = [...options];
    if (event.target.name === "optionText") {
      newOptions[index].description = event.target.value;
    } else if (event.target.name === "correctAnswer") {
      newOptions[index].correct_answer = event.target.checked;
    }
    setOptions(newOptions);
  };
  
  const handleAddOption = () => {
    const newOptions = [...options, { description: "", file: null, correct_answer: false }];
    setOptions(newOptions);
  };
  
  const handleRemoveOption = (index) => () => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const createQuestionHandler = async ({ values }) => {
    const formData = new FormData();
    
    options?.forEach((option, index) => {
      if (option.file) {
        formData.append(`options[${index}][file]`, option.file);
      }
      formData.append(`options[${index}][description]`, option.description);
      formData.append(`options[${index}][correct_answer]`, option.correct_answer);
    });
    formData.append(`file`, file)
    formData.append(`title`, wordCapitalize(values.title))
    formData.append(`content`, wordCapitalize(values.content))
    formData.append(`options_type`, hasSingleOptions ? 1 : hasMultipleOptions ? 2 : 0)
    formData.append(`weight`, values.weight)
    formData.append(`program`, values.program)

    createQuestion({data: formData})
  
  };

  const handleChangeSingleValue = (e) => {
    setHasMultipleOptions(false);
    setHasSingleOptions(!hasSingleOptions);
  };

  const handleChangeMultipleValue = (e) => {
    setHasSingleOptions(false);
    setHasMultipleOptions(!hasMultipleOptions);
  };

  return (
    <Container>
      <div className={styles["create-question"]}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div style={{ overflowY: "scroll", width: '100%' }}>
          <Formik
            initialValues={{
              title: "",
              content: "",
              program: "",
              options_type: 0,
              weight: 0
            }}
            validationSchema={Yup.object({
              title: Yup.string().required("Campo requerido"),
              content: Yup.string().required("Campo requerido"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                createQuestionHandler({ values });
                setSubmitting(false);
              }, 400);
              resetForm();
            }}
          >
            <Form className={styles["form__fields"]}>
              <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
                <Grid item md={(hasSingleOptions || hasMultipleOptions) ? 7 : 12} sm={12} xs={12}
                  sx={{display:'flex', justifyContent:'center'}}>
                  <Grid container 
                  direction={"row"} 
                  rowSpacing={2} columnSpacing={2} 
                  sx={{width: isDesktop ? ((hasSingleOptions || hasMultipleOptions) ? '100%' : '50%') : '100%'}}>
                    <Grid item md={6} sm={12} xs={12}>
                      <MyTextInput
                      label={`Título`}
                      name="title"
                      type="text"
                      icon="fa fa-user"
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <MySelect label="Programa" name="program">
                        {programs?.map((program) => (
                          <MenuItem value={program.id} key={program.id}>
                            {program.name}
                          </MenuItem>
                        ))}
                      </MySelect>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <MyTextarea label={`Descripción`} name="content" />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <MyTextInput
                        label={`Valor (pts)`}
                        name="weight"
                        type="number"
                        icon="fa fa-user"
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <label htmlFor="file" style={{ fontSize: "1.5rem" }}>
                        Imagen
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="file-upload"
                        accept="image/*"
                        onChange={(e) => setFile(e.target.files[0])}
                        style={{ display: "none" }}
                      />
                    <Button
                      variant="outlined"
                      component="span" fullWidth
                      startIcon={<CloudUploadIcon />}
                      onClick={() => document.getElementById('file-upload').click()}
                      sx={{ marginTop: "3px", borderRadius: '10px' }}
                    >
                      Subir Imagen
                    </Button>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <MyRadio
                        label="Es de seleccion"
                        name="options_type"
                        checked={hasSingleOptions}
                        onClick={(e) => handleChangeSingleValue(e)}
                      >
                        {`Selección simple`}
                      </MyRadio>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <MyRadio
                        label="Es de seleccion"
                        name="options_type"
                        checked={hasMultipleOptions}
                        onClick={(e) => handleChangeMultipleValue(e)}
                      >
                        {`Selección múltiple`}
                      </MyRadio>
                    </Grid>
                  </Grid>
                </Grid>
                {(hasSingleOptions || hasMultipleOptions) &&
                  <Grid item md={5} sm={12} xs={12}>
                    <Stack direction={"column"} spacing={1}>
                      {options?.map((option, index) => (
                        <Stack direction={"column"}>
                        <Stack direction={"row"} sx={{width:'100%'}}>
                          <MyTextInput
                          name="option"
                          type="text"
                          value={option.description}
                          onChange={(event) => handleChange(index, event)}
                          icon="fa fa-user"
                          label={`Opción ${index + 1}:`}
                          width={"100%"}
                        />
                          {options?.length > 1 && (
                           <IconButton onClick={handleRemoveOption(index)} size="small"
                           sx={{height: 'fit-content', marginTop: 3, marginLeft: .7}}>
                            <RemoveCircleOutlineRoundedIcon fontSize="large"
                            sx={{color: '#cf142b', fontSize: '22px'}} />
                          </IconButton>
                          )}
                              
                        </Stack>
                          <input
                            name={`options[${index}].file`}
                            type="file"
                            id={`op-file${index+1}`}
                            onChange={(event) => handleImageChange(index, event)}
                            style={{display:'none'}}
                          />
                          <Button
                            variant="outlined"
                            component="span" 
                            startIcon={<CloudUploadIcon />}
                            onClick={() => document.getElementById(`op-file${index+1}`).click()}
                            sx={{ marginTop: "4px", borderRadius: '10px', 
                              width: 'fit-content', marginBottom: '5px' }}
                          >
                            Subir Imagen
                          </Button>
                          <div style={{display: 'flex', marginLeft: '5px',
                            alignItems: 'center', gap: '5px'}}>
                            <input
                              name="correctAnswer"
                              type="checkbox"
                              checked={option.correct_answer}
                              onChange={(event) => handleChange(index, event)}
                              style={{marginTop: '5px'}}
                            />
                            <p>Respuesta Correcta</p>
                          </div>
                        </Stack>
                        
                        ))}
                    </Stack>
                    <IconButton onClick={handleAddOption} size="small"
                     sx={{marginTop: .2}}>
                      <AddCircleOutlineRoundedIcon fontSize="large"
                      sx={{color: '#5FDBA7', fontSize: '22px'}} />
                    </IconButton>

                  </Grid>
                }
              </Grid>
              
              <MySubmitButton name="Crear" type="submit" />
            </Form>
          </Formik>
        </div>
      </div>
    </Container>
  )
}

export default CreateQuestionCap
