import React, { useState, useEffect, useContext } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/system';
import styles from "./CreateSection.module.css";
import { CoursesContext } from "../../context/CoursesContext";
import { useSections } from "../../context/SectionsContext";
import LoadSchedules from "./LoadSchedules";
import Modal from "../Modal";
import { INSTITUTIONS_ENDPOINT } from "../../utils/constants";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import { get } from "../../utils/http";
import { useOutletContext } from "react-router-dom";
import { AlertsContext } from "../../context/AlertsContext";
import { useLoading } from "../../context/LoadingContext";
import LoadingComponent from "../LoadingComponent";

const CoursesTabs = styled((props) => <Tab {...props} />)(({ theme }) => ({
  fontSize: '12px', 
  backgroundColor: 'var(--background-gray)', borderRadius: '10px',
  margin: '5px',
  '&:hover': {
    backgroundColor: "#E7E7E7",
    opacity: 1,
    borderRadius: '10px',
  },
  '&.Mui-selected': {
    boxShadow: '0 0 6px -1px var(--main-gray)',
    border: 'none',
  },
}));

function CreateSection() {
  const { levelId } = useParams();
  const {
    institutionId: [institutionId, setInstitutionId],
    currentLevel: [currentLevel, setCurrentLevel],
    showRightSide: [showRightSide, setShowRightSide]
  } = useOutletContext();

  const [sections, setSections] = useState([]);
  const [courses, setCourses] = useState([]);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [currentSectionId, setCurrentSectionId] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isSkeletonLoading, setIsSkeletonLoading] =useState(false)
  const showSnackbar = useContext(AlertsContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { markTeacher } = useContext(CoursesContext);
  const { createSection, 
    fetchSections, 
    createSchedule, 
    updateSchedule, 
    deleteSchedule } = useSections();

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  //Fetch selected level courses
  const fetchCourses = async () => {
    showLoading();
    let url = `${INSTITUTIONS_ENDPOINT}${institutionId}/courses/?level=${levelId}`;
    const response = await get({ url });
    if(!response.error){
      setCourses(response.data)
      hideLoading()
    }else{
      hideLoading()
    }
  };

  useEffect(() => {
    fetchCourses()
  }, [levelId, institutionId]);

  //Change the current course selected
  const handleChangeCourse = (event, newValue) => {
    setCurrentSection(null);
    setSections(null)
    setCurrentCourse(newValue);
  };
  
  const handleChangeCurrentSection = (event, newValue) => {
    const selectedSection = sections?.find((item) => item.id === newValue)
    setCurrentSection(selectedSection)
    setCurrentSectionId(newValue)
  };

  //Matricular a un docente al curso seleccionado
  const handleMarkTeacher = async () => {
    showLoading();
    await markTeacher(currentCourse);
    await fetchCourses();
    hideLoading();
  };

  const handleCreateSection = async () => {
    showLoading();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const nextSectionLetter = alphabet[sections?.length];

    const registerData = {
      course: currentCourse,
      name: nextSectionLetter.toUpperCase(),
    };
    const course = currentCourse
    const response = await createSection({ data: registerData });

    if (!response.error) {
      getSections();
      hideLoading();
      setCurrentCourse(course)
      showSnackbar("Seccion creada", "success");
    } else {
      hideLoading();
      showSnackbar("Hubo un error al crear la seccion", "error");
    }
  };

  sections?.sort((a, b) => a.name.localeCompare(b.name));

  const getSections = async () => {
    setIsSkeletonLoading(true)
    const response = await fetchSections(currentCourse);
    setSections(response?.data);
    setIsSkeletonLoading(false)
  };

  useEffect(() => {
    if(currentCourse){
      getSections();
    }
    setIsRegistered(isRegistered);
  }, [currentCourse]);

  const handleBackToCourses = () => {
    setShowRightSide(null);
    setCurrentLevel(null)
  };

  return (
    <div style={{width:'100%'}}>
      {!isDesktop && (
        <div
          style={{ cursor: "pointer", marginBottom: "1rem", color: "blue" }}
          onClick={handleBackToCourses}
        >
          <i className="fas fa-arrow-left"></i> Volver a cursos
        </div>
      )}
      <Box sx={{ width: '100%'}}>
        {courses?.length > 0 ? 
        <TabContext value={currentCourse}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList 
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTabs-indicator': {
              display: 'none',
            },}}
            onChange={handleChangeCourse} aria-label="lab API tabs example">
              {courses?.map((course, index) => {
                return(
                  <CoursesTabs label={course?.program} value={course?.id} key={index} />
                )
              })}
            </TabList>
          </Box>
          {courses?.map((course, index) => {
            return(
              <TabPanel value={course.id} key={index} sx={{padding: '20px 0'}}>
                {course?.teachers?.includes(currentUser?.teacher_profile?.id) ? 
                <>
                  <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                  }}
                  >
                    Secciones
                  </p> 
                  <Box>
                    {!isSkeletonLoading ? 
                      <TabContext value={currentSectionId}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                            <Button onClick={() => setModalOpen(true)}
                              variant="outlined" size="large" startIcon={<AddCircleOutlineIcon />}
                              sx={{ borderRadius: '10px', width: 'fit-content', margin: '5px 0' }}>
                              Agregar Sección
                            </Button>
                          </Box>
                          <TabList variant="scrollable"
                            scrollButtons="auto"
                          sx={{
                            '& .MuiTabs-indicator': {
                            display: 'none',
                          },}}
                          onChange={handleChangeCurrentSection} aria-label="lab API tabs example">
                            
                            {sections?.map((section) => {
                              return(
                                <CoursesTabs 
                                label={section.name.toUpperCase()} 
                                value={section?.id} 
                                key={index} />
                              )
                            })}
                          </TabList>
                        </Box>
                        {sections?.map((section) => {
                          return(
                            <TabPanel value={section.id} key={index} sx={{padding: '20px 0'}}>
                              <LoadSchedules
                                currentSection={currentSection}
                                setCurrentSection={setCurrentSection}
                                createSchedule={createSchedule}
                                updateSchedule={updateSchedule}
                                fetchCourses={fetchCourses}
                                courses={courses}
                                deleteSchedule={deleteSchedule}
                              />
                            </TabPanel>
                          )
                        })}
                      </TabContext> : 
                      <Skeleton count={1} height={'20px'}/>}
                  </Box>
                  </>: 
                  <Button variant="contained" sx={{borderRadius: '10px'}} size="large"
                  onClick={handleMarkTeacher}>
                    Matricularme como docente del curso
                  </Button>
              }
              </TabPanel>
            )
          })}
        </TabContext> : 
        <>
          {!isLoading && 
            <div className={styles.option3}>
              No hay cursos para el grado seleccionado
            </div>
          }
        </>
      }
      </Box>
      
      {modalOpen && (
        <Modal
          title={"Crear Sección"}
          modalOpen={setModalOpen}
          acceptModal={() => handleCreateSection()}
          action={"Aceptar"}
        >
          <p style={{ fontSize: "17px" }}>
            Estas seguro que quieres crear una nueva sección?
          </p>
        </Modal>
      )}
      {isLoading && <LoadingComponent />}
    </div>
  );
}

export default CreateSection;
