import { useState, createContext, useContext } from "react";
import { get, post,patch } from "../utils/http";
import { EVENTS_ENDPOINT, EVENTS_DATA_ENDPOINT } from "../utils/constants";
import { AlertsContext } from "./AlertsContext";
import { useLoading } from "./LoadingContext";
const EventsContext = createContext();

const EventsProvider = ({ children }) => {
  const { showLoading, hideLoading, isLoading } = useLoading();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onlyEvents, setOnlyEvents] = useState([]);
  const [request, setRequest] = useState([]);
  const [singleEvents, setSingleEvents] = useState([]);
  const [incidences, setIncidences] = useState([]);
  const showSnackbar = useContext(AlertsContext);
  const [dataUpdate, setDataUpdate] = useState([]);
  const [dataEvent, setDataEvent] = useState([]);
  const [dataIncidences, setDataIncidences] = useState([]);
  const [dataRequest, setDataRequest] = useState([]);
  const [pagePre, setPagePre] = useState(null);
  const [pageNext, setPageNext] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [eventInstitution, setEventInstitution] = useState([]);
  const [myEvents, setMyEvents] = useState([]);
  const [pagePreInstitution, setPagePreInstitution] = useState(null);
  const [pageNextInstitution, setPageNextInstitution] = useState(null);
  const [currentPageInstitution, setCurrentPageInstitution] = useState(1);
  const [totalPagesInstitution, setTotalPagesInstitution] = useState(0);
  const [pagePreMyEvents, setPagePreMyEvents] = useState(null);
  const [pageNextMyEvents, setPageNextMyEvents] = useState(null);
  const [currentPageMyEvents, setCurrentPageMyEvents] = useState(1);
  const [totalPagesMyEvents, setTotalPagesMyEvents] = useState(0);
  const [ allDataEventsInstitution, setAllDataEventsInstitution] = useState([]);
  const [ allDataEventsMyEvents, setAllDataEventsMyEvents] = useState([]);


  const createEvent = async ({ data }) => {
    const url = EVENTS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha creado el reporte', 'success')
    }else{
      showSnackbar('Ha ocurrido un error', 'error')
    }
  };

  const updateEvent = async ({eventId, data }) => {
    const url = `${EVENTS_ENDPOINT}${eventId}/`;
    const response = await patch({ url, data });
    if (!response.error) {
      fetchEvents('Evento editado', 'success')
      fetchEvents();
    }else{
      showSnackbar('Ha ocurrido un error', 'error')
    }
  };

  const updatePartialEvent = async ({eventId, data }) => {
    const url = `${EVENTS_ENDPOINT}${eventId}/`;
    const response = await patch({ url, data });
    if (!response.error) {
      fetchEvents('Evento editado', 'success')
      fetchEvents();
    }else{
      showSnackbar('Ha ocurrido un error', 'error')
    }
  };
  const fetchEvents = async () => {
    showLoading();
    const url = EVENTS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
        setEvents(response.data.results);
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(1);
        setDataEvent(response.data);
        setTotalPages(Math.ceil(response.data.count / 15));
        hideLoading();
    }
    else {
      hideLoading();
    }
  };

  const fetchInstitutionEvents = async () => {
    showLoading();
    const url = `${EVENTS_ENDPOINT}institutions/`;
    const response = await get({ url });
    if (!response.error) {
        setEventInstitution(response.data.results);
        setPagePreInstitution(response.data.previous);
        setPageNextInstitution(response.data.next);
        setCurrentPageInstitution(1);
        setAllDataEventsInstitution(response.data);
        setTotalPagesInstitution(Math.ceil(response.data.count / 15));
        hideLoading();
    }
    else {
      hideLoading();
    }
  };

  const fetchMyEvents = async () => {
    showLoading();
    const url = `${EVENTS_ENDPOINT}my_events/`;
    const response = await get({ url });
    if (!response.error) {
        setMyEvents(response.data.results);
        setPagePreMyEvents(response.data.previous);
        setPageNextMyEvents(response.data.next);
        setCurrentPageMyEvents(1);
        setAllDataEventsMyEvents(response.data);
        setTotalPagesMyEvents(Math.ceil(response.data.count / 15));
        hideLoading();
    }
    else {
      hideLoading();
    }
  };

  const fetchNextPage = async () => {
    if (pageNext) {
      showLoading();
      const response = await get ({ url: pageNext });
      if (!response.error) {
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(currentPage + 1);
        setEvents(response.data.results);
        setDataEvent(response.data);
        hideLoading();
      }

      else {
        hideLoading();
      }
    }
  };

  const fetchPreviousPage = async () => {
    if (pagePre) {
      showLoading();
      const response = await get ({ url: pagePre });
      if (!response.error) {
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(currentPage - 1);
        setEvents(response.data.results);
        setDataEvent(response.data);
        hideLoading();
      }
      else {
        hideLoading();
      }
    }
  };

  const fetchNextPageInstitution = async () => {
    if (pageNext) {
      showLoading();
      const response = await get ({ url: pageNext });
      if (!response.error) {
        setPagePreInstitution(response.data.previous);
        setPageNextInstitution(response.data.next);
        setCurrentPageInstitution(currentPage + 1);
        setEventInstitution(response.data.results);
        setAllDataEventsInstitution(response.data);
        hideLoading();
      }

      else {
        hideLoading();
      }
    }
  };

  const fetchPreviousPageInstitution = async () => {
    if (pagePre) {
      showLoading();
      const response = await get ({ url: pagePre });
      if (!response.error) {
        setPagePreInstitution(response.data.previous);
        setPageNextInstitution(response.data.next);
        setCurrentPageInstitution(currentPage - 1);
        setEventInstitution(response.data.results);
        setAllDataEventsInstitution(response.data);
        hideLoading();
      }
      else {
        hideLoading();
      }
    }
  };

  const fetchNextPageMyEvents = async () => {
    if (pageNext) {
      showLoading();
      const response = await get ({ url: pageNext });
      if (!response.error) {
        setPagePreMyEvents(response.data.previous);
        setPageNextMyEvents(response.data.next);
        setCurrentPageMyEvents(currentPage + 1);
        setMyEvents(response.data.results);
        setAllDataEventsMyEvents(response.data);
        hideLoading();
      }

      else {
        hideLoading();
      }
    }
  };

  const fetchPreviousPageMyEvents = async () => {
    if (pagePre) {
      showLoading();
      const response = await get ({ url: pagePre });
      if (!response.error) {
        setPagePreMyEvents(response.data.previous);
        setPageNextMyEvents(response.data.next);
        setCurrentPageMyEvents(currentPage - 1);
        setMyEvents(response.data.results);
        setAllDataEventsMyEvents(response.data);
        hideLoading();
      }
      else {
        hideLoading();
      }
    }
  };
  const fetchForUpdate = async () => {
    showLoading();
    const url = EVENTS_DATA_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
        setDataUpdate(response.data);
        hideLoading();
    }
    else {
      hideLoading();
    }
  }
  const fetchSingleEvents = async (eventId) => {
    const url = `${EVENTS_ENDPOINT}${eventId}/`;
    const response = await get({ url });

    if (!response.error) {
        setSingleEvents(response.data);
    }
  };

  const fetchByEvent = async () => {
    showLoading();
    const url = `${EVENTS_ENDPOINT}?type=Evento`;
    const response = await get({ url });
    if (!response.error) {
      hideLoading();
      setOnlyEvents(response.data.results);
      setDataEvent(response.data);
      setPagePre(response.data.previous);
      setPageNext(response.data.next);
      setCurrentPage(1);
      setTotalPages(Math.ceil(response.data.count / 15));  

    }
    else{
      hideLoading();
    }


  };

  const fetchNextPageEvent = async () => {
    if (pageNext) {
      showLoading();
      const response = await get ({ url: pageNext });
      if (!response.error) {
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(currentPage + 1);
        setOnlyEvents(response.data.results);
        setDataEvent(response.data);
        hideLoading();
      }
      else {
        hideLoading();
      }

    }
  };

  const fetchPreviousPageEvent = async () => {
    if (pagePre) {
      showLoading();
      const response = await get ({ url: pagePre });
      if (!response.error) {
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(currentPage - 1);
        setOnlyEvents(response.data.results);
        setDataEvent(response.data);
        hideLoading();
      }
      else {
        hideLoading();
      }
    }
  };
  const fetchByIncidences = async () => {
    showLoading();
    const url = `${EVENTS_ENDPOINT}?type=Incidencia`;
    const response = await get({ url });
    if (!response.error) {
        setIncidences(response.data.results);
        setDataIncidences(response.data);
        setDataEvent(response.data);
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(1);
        setTotalPages(Math.ceil(response.data.count / 15)); 
        hideLoading();
    }
    else {
      hideLoading();
    }
  }

  const fetchNextPageIncidences = async () => {
    if (pageNext) {
      showLoading();
      const response = await get({ url: pageNext });
      if (!response.error) {
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(currentPage + 1);
        setIncidences(response.data.results);
        setDataIncidences(response.data);
        hideLoading();
      }
      else {
        hideLoading();
      }
    }
  };

  const fetchPreviousPageIncidences = async () => {
    if (pagePre) {
      showLoading();
      const response = await get({ url: pagePre });
      if (!response.error) {
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(currentPage - 1);
        setIncidences(response.data.results);
        setDataIncidences(response.data);
        hideLoading();
      }
      else {
        hideLoading();
      }
    }
  };
  const fetchByRequest = async () => {
    showLoading();
    const url = `${EVENTS_ENDPOINT}?type=Solicitud`;
    const response = await get({ url });
    if (!response.error) {
        setRequest(response.data.results);
        setDataRequest(response.data);
        setDataEvent(response.data);
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(1);
        setTotalPages(Math.ceil(response.data.count / 15)); 
        hideLoading();
    }
    else {
      hideLoading();
    }
  }
  const fetchNextPageRequest = async () => {
    if (pageNext) {
      showLoading();
      const response = await get({ url: pageNext });
      if (!response.error) {
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(currentPage + 1);
        setRequest(response.data.results);
        setDataRequest(response.data);
        hideLoading();
      }
      else {
        hideLoading();
      }
    }
  };

  const fetchPreviousPageRequest = async () => {
    if (pagePre) {
      showLoading();
      const response = await get({ url: pagePre });
      if (!response.error) {
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(currentPage - 1);
        setRequest(response.data.results);
        setDataRequest(response.data);
        hideLoading();
      }
      else {
        hideLoading();
      }
    }
  };

  const fetchForSearch = async (urlsearch) => {
    showLoading();
    const url = `${EVENTS_ENDPOINT}?${urlsearch}`;
    const response = await get({ url });
    if (!response.error) {
        setEvents(response.data.results);
        setPagePre(response.data.previous);
        setPageNext(response.data.next);
        setCurrentPage(1);
        setTotalPages(Math.ceil(response.data.count / 15)); 
        hideLoading();
    }
    else {
      hideLoading();
    }
  }
  const fetchForSearchInstitution = async (urlsearch) => {
    showLoading();
    const url = `${EVENTS_ENDPOINT}institutions/?${urlsearch}`;
    const response = await get({ url });
    if (!response.error) {
        setEventInstitution(response.data.results);
        setPagePreInstitution(response.data.previous);
        setPageNextInstitution(response.data.next);
        setCurrentPageInstitution(1);
        setTotalPagesInstitution(Math.ceil(response.data.count / 15)); 
        setAllDataEventsInstitution(response.data);
        hideLoading();
    }
    else {
      hideLoading();
    }
  }
  const fetchForSearchMyEvents = async (urlsearch) => {
    showLoading();
    const url = `${EVENTS_ENDPOINT}my_events/?${urlsearch}`;
    const response = await get({ url });
    if (!response.error) {
        setMyEvents(response.data.results);
        setPagePreMyEvents(response.data.previous);
        setPageNextMyEvents(response.data.next);
        setCurrentPageMyEvents(1);
        setTotalPagesMyEvents(Math.ceil(response.data.count / 15)); 
        setAllDataEventsMyEvents(response.data);
        hideLoading();
    }
    else {
      hideLoading();
    }
  }

  const contextData = {
    createEvent,
    fetchEvents,
    fetchMyEvents,
    fetchInstitutionEvents,
    updateEvent,
    updatePartialEvent,
    events,
    myEvents,
    eventInstitution,
    incidences,
    request,
    onlyEvents,
    singleEvents,
    dataEvent,
    dataUpdate,
    fetchByEvent,
    fetchByIncidences,
    fetchByRequest,
    fetchSingleEvents,
    fetchForUpdate,
    fetchNextPage,
    fetchPreviousPage,
    fetchNextPageEvent,
    fetchPreviousPageEvent,
    fetchNextPageIncidences,
    fetchPreviousPageIncidences,
    fetchNextPageRequest,
    fetchPreviousPageRequest,
    fetchForSearch,
    fetchNextPageMyEvents,
    fetchPreviousPageMyEvents,
    fetchPreviousPageInstitution,
    fetchNextPageInstitution,
    fetchForSearchInstitution,
    fetchForSearchMyEvents,
    currentPageInstitution,
    pagePreInstitution,
    pageNextInstitution,
    totalPagesInstitution,
    currentPageMyEvents,
    pagePreMyEvents,
    pageNextMyEvents,
    totalPagesMyEvents,
    currentPage,
    pagePre,
    pageNext,
    totalPages,
    allDataEventsInstitution,
    allDataEventsMyEvents,
  };

  return (
    <EventsContext.Provider value={contextData}>
      {loading ? null : children}
    </EventsContext.Provider>
  );
};

const useEvents = () => {
  return useContext(EventsContext);
};

export { EventsContext, EventsProvider, useEvents };