import React, {useState, useEffect, useContext} from 'react'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useTheme } from "@mui/material/styles"
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import useMediaQuery from "@mui/material/useMediaQuery"
import { TextField, Box, Autocomplete, Typography } from '@mui/material';
import { AnnualTrainingsContext } from '../../../context/AnnualTrainingsContext';
import Modal from '../../Modal';
import AttendanceOverview from './AttendanceOverview';

function TrainingOverview() {
  const [currentView, setCurrentView] = useState('timeGridDay');
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const {fetchAnnualTrainings, annualTrainings} = useContext(AnnualTrainingsContext)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  useEffect(() => {
    fetchAnnualTrainings();
  }, [])

  useEffect(() => {
    // Extraer todos los teachers sin duplicados
    const allTeachers = new Map();
    annualTrainings?.forEach(training => {
      training?.sessions?.forEach(session => {
        session?.teacher?.forEach(teacher => {
          if (!allTeachers?.has(teacher?.id)) {
            allTeachers?.set(teacher.id, {
              id: teacher?.id,
              first_name: teacher?.first_name,
              last_name: teacher?.last_name,
              sessionDate: session?.date,
              sessionStartTime: session?.start_time,
              institutions: teacher?.institutions
            });
          }
        });
      });
    });
    setTeachers(Array.from(allTeachers.values()));
    setFilteredEvents(events);
  }, [annualTrainings]);

  const handleViewDidMount = (view) => {
    setCurrentView(view.view.type);
  };

  const events = annualTrainings?.flatMap(training => 
    training?.sessions?.map(session => {
      const startDate = new Date(`${session?.date.split('T')[0]}T${session?.start_time}`).toISOString();
      const endDate = new Date(`${session?.date.split('T')[0]}T${session?.end_time}`).toISOString();
      let type = ""
      switch (training.type) {
        case 'on-site':
          type = "Presencial"
          break
        case 'remote':
          type = "Remoto"
          break
        case 'hybrid':
          type = "Híbrido"
          break
      }
      return {
        id: `${session.id}`,
        title: `${session.name} - ${session.session_code}`,
        start: startDate,
        end: endDate,
        teachers: session?.teacher,
        extendedProps: {
          training: training.name,
          type: type,
          trainingId: training?.id,
          classroom: session?.classroom,
          location: session?.location,
          instructor: session?.instructor
        }
      };
    })
  );

  const handleEventClick = (clickInfo) => {
    setSelectedLesson(clickInfo.event)
    setOpenModal(true)
    // click al evento
  };

  const handleTeacherSearch = (event, value) => {
    setSelectedTeacher(value);

    if (!value) {
      setFilteredEvents(events);
      return;
    }
    const filtered = events?.filter(event => 
      event?.teachers?.some(teacher => 
        `${teacher.first_name} ${teacher.last_name}`.toLowerCase().includes(`${value.first_name} ${value.last_name}`.toLowerCase())
      )
    );
    setFilteredEvents(filtered);
  };

  const renderEventContent = (eventInfo) => {
    if (currentView === 'dayGridMonth') {
      return (
        <Stack direction={"column"} 
        spacing={0.5} 
        sx={{ maxWidth: '100%', padding: '0 2px', 
        backgroundColor: 'var(--ter-blue)', borderRadius: '5px', padding: '2px', width:'100%' }}>
        <b style={{ fontSize: '12px',
          whiteSpace: 'normal', 
          wordWrap: 'break-word', 
          overflow: 'hidden',   
          padding: '0 2px',
          color: '#fff'}}>
          {eventInfo.timeText}
        </b>
        <p style={{fontSize: '12px',
          whiteSpace: 'normal', 
          wordWrap: 'break-word', 
          overflow: 'hidden',   
          padding: '0 2px',
          color: '#fff'}}>
          {eventInfo.event.title}
        </p>
        <p style={{
          fontSize: '12px',
          whiteSpace: 'normal', 
          wordWrap: 'break-word', 
          overflow: 'hidden',   
          padding: '0 2px',
          color: '#fff'
        }}>
          {eventInfo.event.extendedProps.location}
        </p>
        <div>
          <p style={{
            fontSize: '12px',
            whiteSpace: 'normal', 
            wordWrap: 'break-word', 
            overflow: 'hidden',   
            padding: '0 2px',
            color: '#fff'
          }}>
            Instructor: 
          </p>
        {eventInfo?.event?.extendedProps?.instructor?.map((item) => (
          <p style={{
            fontSize: '12px',
            whiteSpace: 'normal', 
            wordWrap: 'break-word', 
            overflow: 'hidden',   
            padding: '0 2px',
            color: '#fff'
          }}>{`${item?.first_name} ${item?.last_name}`}</p>
        ))}
        </div>
      </Stack>
      );
    } else if (currentView === 'timeGridDay') {
      return (
        <>
          <b style={{ fontSize: '12px' }}>{eventInfo.timeText}</b>
          <p style={{ fontSize: '12px' }}>{eventInfo.event.title}</p>
          <p style={{ fontSize: '12px' }}>{`Salón: ${eventInfo.event.extendedProps.classroom || "Sin Asignar"}`}</p>
          <p style={{ fontSize: '12px',
          whiteSpace: 'normal', 
          wordWrap: 'break-word', 
          overflow: 'hidden',   
          padding: '0 2px',
          color: '#fff'}}>
          {eventInfo.event.extendedProps.location}
        </p>
        <p style={{ fontSize: '12px',
          whiteSpace: 'normal', 
          wordWrap: 'break-word', 
          overflow: 'hidden',   
          padding: '0 2px',
          color: '#fff'}}>
          Capacitación: {eventInfo.event.extendedProps.training}</p>
          <div style={{marginTop: '5px'}}>
          <p style={{
            fontSize: '12px',
            whiteSpace: 'normal', 
            wordWrap: 'break-word', 
            overflow: 'hidden',   
            padding: '0 2px',
            color: '#fff'
          }}>
            Instructor: 
          </p>
        {eventInfo?.event?.extendedProps?.instructor?.map((item) => (
          <p style={{
            fontSize: '12px',
            whiteSpace: 'normal', 
            wordWrap: 'break-word', 
            overflow: 'hidden',   
            padding: '0 2px',
            color: '#fff'
          }}>{`${item?.first_name} ${item?.last_name}`}</p>
        ))}
        </div>
        </>
      );
    }
  };

  return (
    <Box sx={{width:'100%'}}>
      <Box sx={{width:'100%', display:'flex', justifyContent:'center'}}>
      <Autocomplete
        options={teachers}
        placeholder='Buscar por nombre del docente'
        getOptionLabel={(option) => `${option.first_name} ${option.last_name} - ${option?.institutions[0] || ""}`}
        style={{ marginBottom: '20px', width: '300px', width: isDesktop ? '35%' : '100%', borderRadius: '10px'}}
        onChange={handleTeacherSearch} 
        renderInput={(params) => <TextField {...params} variant="outlined" 
        placeholder='Buscar por nombre del docente' 
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
        }}
        sx={{'& .MuiOutlinedInput-root': { borderRadius: '10px'}}}
         />}
      />
      </Box>
      {selectedTeacher && (
        <Box sx={{ marginTop: '10px', fontSize: '16px', 
        color: theme.palette.text.primary, textAlign: 'center', fontWeight: 'bold' }}>
          Capacitaciones {`${selectedTeacher.first_name} ${selectedTeacher.last_name}`}
        </Box>
      )}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridDay"
        events={filteredEvents}
        headerToolbar={{
          left: 'prev,next today',
          center: `title`,
          right: 'dayGridMonth,timeGridDay'
        }}
        eventClick={handleEventClick}
        slotMinTime={"07:00:00"}
        slotMaxTime={"17:00:00"}
        slotDuration="00:15:00"
        height={"80vh"}
        eventContent={renderEventContent}
        viewDidMount={handleViewDidMount}
      />
      {openModal && (
        <Modal title={`Asistencia: ${selectedLesson?.title}`} type={"training"}
        modalOpen={setOpenModal}>
          <AttendanceOverview trainingId={selectedLesson?.extendedProps?.trainingId}
          sessionId={selectedLesson?.id} />
        </Modal>
      )}
    </Box>
  )
}

export default TrainingOverview