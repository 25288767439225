import React, { useState } from "react";
import { get, post } from "../utils/http";
import { DATA_ENTRIES_ENDPOINT } from "../utils/constants";
import { useEffect } from "react";
import styles from "./ReferencePage.module.css";
import Card from "../components/Card/Card";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  MyTextInput,
  MySubmitButton,
  MyTextarea,
} from "../components/FormFields";
import * as Yup from "yup";

import Modal from "../components/Modal";

const ReferencePage = () => {
  const [entries, setEntries] = useState([]);
  const [activeEntry, setActiveEntry] = useState({});
  const [filterReference, setfilterReference] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const { referenceId } = useParams();

  const fetchEntries = async () => {
    const url = DATA_ENTRIES_ENDPOINT;
    const response = await get({ url });

    if (!response.error) {
      setEntries(response.data);
    }

    if (!referenceId) {
      setActiveEntry(response.data[0]);
      navigate(`${response.data[0].id}`);
    } else {
      setActiveEntry(
        response.data.filter((item) => item.id === parseInt(referenceId))[0]
      );
      navigate(`${referenceId}`);
    }
  };

  const createEntry = async (values) => {
    const url = DATA_ENTRIES_ENDPOINT;
    const response = await post({ url, data: values });

    if (!response.error) {
      fetchEntries();
    }
  };

  let filteredEntries = entries.filter((item) =>
    item.title.toLowerCase().includes(filterReference.toLowerCase())
  );

  const handleChangeFilterReference = (e) => {
    setfilterReference(e.target.value);
  };

  const handleCreateEntry = (values) => {
    createEntry(values);
    setModalOpen(false);
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  return (
    <div className={styles.databank}>
      <div className={styles.entries}>
        <div className={styles.top}>
          <div className={styles["search-bar"]}>
            <i className="far fa-search"></i>
            <input
              id="search"
              type="text"
              placeholder="Buscar"
              aria-label="Search Input"
              value={filterReference}
              onChange={(e) => handleChangeFilterReference(e)}
              className={styles.filter_text}
            />
          </div>
          <div className={styles.mid}>
            {filteredEntries?.map((item) => (
              <div
                onClick={() => {
                  setActiveEntry(item);
                }}
                key={item.id}
              >
                <Card type="databank" data={item} />
              </div>
            ))}
          </div>
          <div className={styles.bottom}>
            <Card
              type={"databank-add"}
              data={{
                title: "Agregar entrada",
                icon: "fas fa-plus",
              }}
              onClick={setModalOpen}
            />
          </div>
        </div>
      </div>
      {modalOpen && (
        <Modal
          title={"Crear entrada"}
          // action={"Crear"}
          modalOpen={setModalOpen}
          acceptModal={() => createEntry()}
        >
          <Formik
            initialValues={{
              title: "",
              content: "",
              url: "",
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .min(1, "El campo no puede estar vacío.")
                .required("Campo requerido"),
              content: Yup.string()
                .min(10, "Debe contener más de 10 caracteres.")
                .required("Campo requerido"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                handleCreateEntry(values);
                setSubmitting(false);
              }, 400);
              resetForm();
            }}
          >
            {(formProps) => (
              <Form className={styles["form__fields"]}>
                <div className={styles.inputs_wrapper}>
                  <div className={styles.inputs}>
                    <MyTextInput
                      label="Titulo"
                      name="title"
                      type="text"
                      placeholder="Título"
                    />
                    <MyTextInput
                      label="URL Referencia"
                      name="url"
                      type="url"
                      placeholder="www..."
                      icon="fa fa-user"
                    />
                    <MyTextarea label="Contenido" name="content" />
                  </div>
                  <div className={styles.cta}>
                    <MySubmitButton name="Crear" type="submit" />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}

      <Outlet context={{ entry: [activeEntry] }} />
    </div>
  );
};

export default ReferencePage;
