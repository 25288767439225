export const USERS_OPTIONS = [
  {
    key: "list",
    name: "Usuarios",
    icon: <i className="fad fa-users"></i>,
    route: "list",
  },
  {
    key: "create",
    name: "Crear usuario",
    icon: <i className="fas fa-user-plus"></i>,
    route: "create",
  },
  {
    key: "upload",
    name: "Creación masiva",
    icon: <i className="fas fa-users-medical"></i>,
    route: "upload",
  },
  {
    key: "reports",
    name: "Reportes",
    icon: <i className="fas fa-file-chart-line"></i>,
    route: "reports",
  },
  {
    key: "roles",
    name: "Roles",
    icon: <i className="fad fa-user-tag"></i>,
    route: "roles",
  },
];
