import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UsersContext } from "../../context/UsersProvider";
import styles from "./RegistroMasivoUsers.module.css";
import RegistroMasivoForm from "../Users/RegistroMasivoForm";

function RegistroMasivoUsers() {
  const navigate = useNavigate();

  const { fetchUsers, registerMultipleUsers } = useContext(UsersContext);

  return (
    <div style={{ width: "100%" }}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      ></i>
      <RegistroMasivoForm registerMultiple={registerMultipleUsers} />
    </div>
  );
}

export default RegistroMasivoUsers;
