import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import DataTable from "react-data-table-component";
import Container from "../Container";
import styles from "./UsersList.module.css";
import { useUsers } from "../../context/UsersProvider";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function UsersList() {
  const { fetchUsers, updateUser } = useUsers();
  const [filterText, setFilterText] = useState("");
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  const getUsers = async () => {
    const usersData = await fetchUsers();
    setUsers(usersData);
  };

  useEffect(() => {
    getUsers();
  }, []);

  let filteredData = users
    ? users.filter((item) =>
        item.display_name.toLowerCase().includes(filterText.toLowerCase())
      )
    : "";

  const handleChangeChecked = async (cell) => {
    const values = {
      is_active: !cell.is_active
    }
    const userId = cell.id

    setUsers(users.map(item => item.id === userId ? {...item, is_loading: true} : item))
    
    let resp =  await updateUser({userId, data: values})

    if(!resp?.error){
      setUsers(users.map(item => item.id === userId ? {...item, is_active: resp?.data?.is_active, is_loading: false} : item))
    }else{
      setUsers(users.map(item => item.id === userId ? {...item, is_loading: false} : item))
    }
  
  }

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.name,
      sortable: true,
      cell: (cell) => (
        <div
          onClick={() => handleRowClick(cell)}
          data-tag="allowRowEvents"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span className={styles.image}>
            {cell?.img_profile ? 
            <img src={cell?.img_profile} 
            style={{borderRadius: '50%', width: '32px', height: '32px'}}/> :
            <img 
            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />}
          </span>
          <p>{cell.display_name}</p>
        </div>
      ),
    },
    {
      name: "Última conexión",
      selector: (row) => row.last_login,
      sortable: true,
    },
    {
      name: "Restablecer contraseña",
      cell: (cell) => {
        return(
          <p style={{backgroundColor: 'var(--sec-purple)', 
          color: '#fff', padding: '5px 10px 5px 10px', 
          borderRadius: '15px', cursor: 'pointer', marginLeft: '10px'}}>
            Restablecer
          </p>
        )
      }
    },
    {
      name: "Usuario activo",
      selector: (row) => row?.is_active,
      sortable: true,
      cell: (cell) => {
        return(
          <Switch
          checked={cell?.is_active}
          onChange={() => handleChangeChecked(cell)}
          disabled={cell?.is_loading}
          onColor="#87be87"
          onHandleColor="#43b02a"
          handleDiameter={22}
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
          className="react-switch"
          id="material-switch"
          />
        )
      }
    }
  ];

  const handleChangeFilterText = (e) => {
    setFilterText(e.target.value);
  };

  const handleRowClick = (row) => {
    navigate(`/profile/${row.id}`, { relative: false });
  };

  return (
    <div className={styles.content}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer" }}
        onClick={() => navigate(-1)}
      ></i>
      <div className={styles["search-bar"]}>
        <input
          id="search"
          type="text"
          placeholder="Filtrar por nombre"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => handleChangeFilterText(e)}
          className={styles.filter_text}
        />
      </div>
      <Container direction={"column"}>
        <DataTable
          columns={columns}
          data={filteredData}
          customStyles={customStyles}
          sortIcon={
            <i
              className="far fa-arrow-up"
              style={{ marginLeft: "5px", fontSize: "14px" }}
            ></i>
          }
          highlightOnHover
          pointerOnHover
          fixedHeader
          fixedHeaderScrollHeight="515px"
          onRowClicked={(row) => handleRowClick(row)}
        />
      </Container>
    </div>
  );
}

export default UsersList;
