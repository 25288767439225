import React, { useState, useEffect,useContext } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { get, post } from "../../../utils/http";
import { CurrentUserContext } from "../../../context/CurrentUserProvider";
import Container from "../../Container";
import { INSTITUTIONS_ENDPOINT, 
    TEACHERS_ENDPOINT, 
    REPORTS_ENDPOINT, 
    REPORTS_GENERAL_ENDPOINT } from "../../../utils/constants";
import { useLoading } from "../../../context/LoadingContext";
import LoadingComponent from "../../LoadingComponent";
import styles from './GenerarReporte.module.css'
import Modal from '../../Modal'
import ReportePDF from "./ReportePDF";

const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#000",
        fontSize: "13px",
        fontWeight: "bold",
        borderBottom: "1px solid #E0E0E0",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f5f5f5",
        borderBottomColor: "#FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
};

function GenerarReporte() {
    const [institutions, setInstitutions] = useState([])
    const { showLoading, hideLoading, isLoading } = useLoading();
    const [filterMonitor, setFilterMonitor] = useState(0);
    const [filterText, setFilterText] = useState("");
    const [teachers, setTeachers] = useState([]);
    const { currentUser } = useContext(CurrentUserContext);
    const [currentTeacherIndex, setCurrentTeacherIndex] = useState(null);
    const [teachersFeedback, setTeachersFeedback] = useState([]);
    const [currentInstitution, setCurrentInstitution] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [openModalEdit, setOpenModalEdit] = useState(false)
    const [questions, setQuestions] = useState([])
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [reportReady, setReportReady] = useState(false)
    const [remainingCharacters, setRemainingCharacters] = useState(5000); 
    const [institutionsWithPendingReports, setInstitutionsWithPendingReports] = useState(new Set());

    const [answers, setAnswers] = useState({
      comentariosGenerales: ""
    })

    const navigate = useNavigate();


    useEffect(() => {
      showLoading()
      const loadReportsStatus = async () => {
          const newSet = new Set();
          for (let institution of institutions) {
              const hasPending = await showPorAprobar(institution);
              if (hasPending) {
                  newSet.add(institution.id);
              }
          }
          setInstitutionsWithPendingReports(newSet);
          hideLoading()
      };
      loadReportsStatus();
  }, [institutions]); 

    useEffect(() => {
      showLoading();
      const fetchInstitutions = async () => {
        const url = INSTITUTIONS_ENDPOINT;
        const response = await get({ url });
        if (!response.error) {
          setInstitutions(response.data);
          hideLoading();
        } else {
          hideLoading();
        }
      };
      fetchInstitutions();
    }, []);

    useEffect(() => {
      const fetchFeedback = async () => {
        const url = REPORTS_GENERAL_ENDPOINT;

        const response = await get({ url });
        if (!response.error) {
          setQuestions(response.data[0].questions);
        }
      };
      fetchFeedback();
    }, []);

    useEffect(() => {
      const fetchTeachers = async () => {
        const url = TEACHERS_ENDPOINT;
        const response = await get({ url });
        setTeachers(response.data);
      };
      fetchTeachers();
    }, []);

    let filteredData = institutions && institutions?.filter((item) => {
        if (filterText === "" && parseInt(filterMonitor) === 0) {
          return item;
        } else {
          let institutionMatch = true;
          if (filterText !== "") {
            institutionMatch =
              item?.name?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
              item?.short_name?.toLowerCase()?.includes(filterText?.toLowerCase());
          }
    
            let teacherMatch = true;
    
            if (filterMonitor !== 0) {
              teacherMatch = item?.monitor?.id === parseInt(filterMonitor);
            }
            if (institutionMatch && teacherMatch) {
              return item;
            }
          }
    });

    let teachersFiltered;

    if (currentUser.role !== "external_coordinator") {
        teachersFiltered =
          teachers &&
          teachers?.filter((item) => item?.role === "monitoring_teacher");
    }

    const handleChangeFilterText = (e) => {
      setFilterText(e.target.value);
    };

    const startReportDownload = (institution) => {
      setCurrentTeacherIndex(0);
      setTeachersFeedback([]);
      setCurrentInstitution(institution)
      setOpenModal(true)
    };

    const startEditReportDownload = (institution) => {
      setCurrentTeacherIndex(0);
      setTeachersFeedback([]);
      setCurrentInstitution(institution)
      setOpenModalEdit(true)
    };

    const showPorAprobar = async (institution) => {
      const fetchPorAprobar = async () => {
        const url = `${REPORTS_ENDPOINT}${institution.id}/feedbackstatus/`;
        try {
          const response = await get({ url });
          if (!response.error && response.data && response.data.length > 0) {
            return true;  // Devuelve true si hay datos y no hay error
          } else {
            return false; // Devuelve false si hay un error o no hay datos
          }
        } catch (error) {
          return false;  // Devuelve false si hay una excepción en la solicitud
        }
      };
      return await fetchPorAprobar();
    }

    const columns = [
      {
        name: "Colegio",
        selector: (row) => row?.name,
        sortable: true,
        cell: (cell) => <p>{`${cell?.name}`}</p>,
      },
      {
        name: "Seguimiento",
        selector: (row) => row?.monitor?.first_name,
        sortable: true,
        cell: (cell) => {
          return (
            <p>
              {`${cell?.monitor ? cell?.monitor?.first_name : ''} ${ cell?.monitor ? cell?.monitor?.last_name : ''}`}
            </p>
          );
        },
      },
      {
          name: "# de docentes",
          selector: (row) => row?.teachers,
          sortable: true,
          cell: (cell) => {
            return (
              <p >
                {`${cell?.teachers?.length}`}
              </p>
            );
          },
      },
      {
          name: "Reporte Trimestral",
          sortable: true,
          cell: (cell) => {
            return (
              <p onClick={() => startReportDownload(cell)}
              style={{cursor: 'pointer', color: 'var(--main-blue)', border: '1px solid var(--main-blue)', borderRadius: '10px', padding: '5px'}}>
                {`Nuevo Reporte`}
              </p>
            );
          },
      },
        // {
        //   name: "Editar Reporte",
        //   sortable: true,
        //   cell: (cell) => {
        //     return (
        //       <p onClick={() => startEditReportDownload(cell)}
        //       style={{cursor: 'pointer', color: 'var(--main-blue)', border: '1px solid var(--main-blue)', borderRadius: '10px', padding: '5px'}}> 
        //       {`Editar Reporte` }              
        //       </p>
        //     );
        //   },
        // },
        // No ha sido implementado

        // Ya se implemento la otra vista por lo que no es necesario esto aca 
        // {
        //   name: "Por Aprobar",
        //   sortable: false,
        //   cell: (cell) => {
        //     const isPending = institutionsWithPendingReports?.has(cell.id);
        //     return (
        //       <>
        //       {isPending && <p 
        //       style={{ color: '#fff', 
        //       border: 'none', 
        //       borderRadius: '20px', 
        //       backgroundColor: "var(--ter-orange)",
        //       padding: '5px'}}>
        //         {`Por Aprobar`}
        //       </p>}
        //       </>
        //     );
        //   },
        // }
    ];
    
    const closeModal = () => {
      setCurrentTeacherIndex(null);
      setOpenModal(false);
    };
    function capitalize(word) {
      if (!word) return "";
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    const handleAccept = async (formData) => {

      const stagesArray = currentInstitution?.teachers[currentTeacherIndex]?.stages;
      const uniqueStages = stagesArray.filter((value, index, self) => self.indexOf(value) === index);
      const stagesString = uniqueStages.join(', ');   

      const teacherInfo = {
        firstName: currentInstitution?.teachers[currentTeacherIndex]?.info?.first_name,
        lastName: currentInstitution?.teachers[currentTeacherIndex]?.info?.last_name,
        stages: stagesString,
        elaborado: `${currentUser?.first_name} ${currentUser?.last_name}`,
      };
    
      const filteredFormData = {};
      Object.entries(formData).forEach(([section, sectionData]) => {
        if (section === 'comentariosGenerales') {
          filteredFormData[section] = sectionData;
        } else {
          filteredFormData[section] = {};
          Object.entries(sectionData).forEach(([question, answer]) => {
            if (answer !== "") {
              filteredFormData[section][question] = answer;
            }
          });
          if (Object.keys(filteredFormData[section]).length === 0) {
            delete filteredFormData[section];
          }
        }
      });
    
      const feedbackWithTeacher = {
        teacherInfo,
        formData: filteredFormData,
      };
    
      const allQuestionsAndAnswers = [];
      Object.entries(formData).forEach(([section, sectionData]) => {
        if (section !== 'comentariosGenerales') {
          Object.entries(sectionData).forEach(([preguntaTexto, respuesta]) => {
            let preguntaId = null;
            questions[section].forEach((pregunta) => {
              if (pregunta.question === preguntaTexto) {
                preguntaId = pregunta.id;
              }
            });
            if (preguntaId !== null) {
              allQuestionsAndAnswers.push({
                pregunta_id: preguntaId,
                pregunta: preguntaTexto,
                respuesta: respuesta,
              });
            }
          });
        }
      });  
    
      const requestBody = {
        teacher: currentInstitution?.teachers[currentTeacherIndex]?.id,
        institution: currentInstitution?.id,
        comments: formData.comentariosGenerales,
        stage: stagesString,
        status : "Completado",
      };
    
      allQuestionsAndAnswers.forEach((preguntaRespuesta) => {
        const key = `answer_${preguntaRespuesta?.pregunta_id}`;
        if (preguntaRespuesta.respuesta === 'Si') {
          requestBody[key] = true;
        } else if (preguntaRespuesta.respuesta === 'No') {
          requestBody[key] = false;
        } else {
          requestBody[key] = null;
        }
      });
    
      showLoading();
    
      try {
        const url = REPORTS_ENDPOINT;
        const response = await post({ url, data: requestBody });
        if (!response.error) {
          setTeachersFeedback((prev) => [...prev, feedbackWithTeacher]);
          setAnswers({ comentariosGenerales: "" });
          setOpenCreateModal(false);
          setCurrentTeacherIndex(null);
          setReportReady(true);
        } else {
          throw new Error("Error al enviar el reporte");
        }
      } catch (error) {
        console.error(error);
        setReportReady(false);
        alert("Error al enviar el reporte");
      } finally {
        hideLoading();
      }
    };
    const handleAcceptBorrador = async (formData) => {
      const stagesArray = currentInstitution?.teachers[currentTeacherIndex]?.stages;
      const uniqueStages = stagesArray.filter((value, index, self) => self.indexOf(value) === index);
      const stagesString = uniqueStages.join(', ');

      const teacherInfo = {
        firstName: currentInstitution?.teachers[currentTeacherIndex]?.info?.first_name,
        lastName: currentInstitution?.teachers[currentTeacherIndex]?.info?.last_name,
        stages: stagesString,
        elaborado: `${currentUser?.first_name} ${currentUser?.last_name}`,
      };
    
      const filteredFormData = {};
      Object.entries(formData).forEach(([section, sectionData]) => {
        if (section === 'comentariosGenerales') {
          filteredFormData[section] = sectionData;
        } else {
          filteredFormData[section] = {};
          Object.entries(sectionData).forEach(([question, answer]) => {
            if (answer !== "") {
              filteredFormData[section][question] = answer;
            }
          });
          if (Object.keys(filteredFormData[section]).length === 0) {
            delete filteredFormData[section];
          }
        }
      });
    
      const feedbackWithTeacher = {
        teacherInfo,
        formData: filteredFormData,
      };
    
      const allQuestionsAndAnswers = [];
      Object.entries(formData).forEach(([section, sectionData]) => {
        if (section !== 'comentariosGenerales') {
          Object.entries(sectionData).forEach(([preguntaTexto, respuesta]) => {
            let preguntaId = null;
            questions[section].forEach((pregunta) => {
              if (pregunta.question === preguntaTexto) {
                preguntaId = pregunta.id;
              }
            });
            if (preguntaId !== null) {
              allQuestionsAndAnswers.push({
                pregunta_id: preguntaId,
                pregunta: preguntaTexto,
                respuesta: respuesta,
              });
            }
          });
        }
      });
    
      const requestBody = {
        teacher: currentInstitution?.teachers[currentTeacherIndex]?.id,
        institution: currentInstitution?.id,
        comments: formData.comentariosGenerales,
        stage: stagesString,
        status : "Borrador",
      };
    
      allQuestionsAndAnswers.forEach((preguntaRespuesta) => {
        const key = `answer_${preguntaRespuesta?.pregunta_id}`;
        if (preguntaRespuesta.respuesta === 'Si') {
          requestBody[key] = true;
        } else if (preguntaRespuesta.respuesta === 'No') {
          requestBody[key] = false;
        } else {
          requestBody[key] = null;
        }
      });
    
      showLoading();
    
      try {
        const url = REPORTS_ENDPOINT;
        const response = await post({ url, data: requestBody });
        if (!response.error) {
          setTeachersFeedback((prev) => [...prev, feedbackWithTeacher]);
          setAnswers({ comentariosGenerales: "" });
          setOpenCreateModal(false);
          setCurrentTeacherIndex(null);
          setReportReady(true);
        } else {
          throw new Error("Error al enviar el reporte");
        }
      } catch (error) {
        console.error(error);
        setReportReady(false);
        alert("Error al enviar el reporte");
      } finally {
        hideLoading();
      }
    };
    
    const showOpenReport = (result) => {
      const newWindow = window.open("", "_blank");
      newWindow.document.title = "Reporte trimestral";
      newWindow.document.body.innerHTML = `<div id="reporte-container"></div>`;
    
      ReactDOM.render(
        <ReportePDF teachersFeedback={teachersFeedback} institution={currentInstitution} />,
        newWindow.document.getElementById("reporte-container")
      );
    };
    
    useEffect(() => {
      if (reportReady && teachersFeedback.length > 0) {
        showOpenReport();
        setReportReady(false);
      }
    }, [reportReady, teachersFeedback]);
    
    useEffect(() => {
      if (currentTeacherIndex === null) {
        closeModal();
      }
    }, [currentTeacherIndex]);
    

    const handleCommentChange = (event) => {
      const commentText = event.target.value;
      const remaining = 5000 - commentText.length; // Adjust the limit as needed
      setRemainingCharacters(remaining);
      setAnswers({
        ...answers,
        comentariosGenerales: event.target.value
      });
    };

    const handleChange = (seccion, preguntaId, pregunta, respuesta) => {
      setAnswers(prev => ({
        ...prev,
        [seccion]: {
          ...prev[seccion],
          [pregunta]: respuesta,
        },
      }));
    };

    const handleCreateNewReport = () => {
      setOpenModal(false)
      setOpenCreateModal(true)
    }

    

  return (
    <Container>
      <div className={styles.container}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles["search-bar"]}>
          <input
            id="search"
            type="text"
            placeholder="Buscar por nombre"
            aria-label="Search Input"
            value={filterText}
            onChange={(e) => handleChangeFilterText(e)}
            className={styles.filter_text}
          />
          {(currentUser.role === "IT" ||
            currentUser.role === "monitoring_coordinator" ||
            currentUser.role === "monitoring_teacher" ||
            currentUser?.role === "monitoring_teacher_3") && (
            <select
              value={filterMonitor}
              className={styles.filter_text}
              onChange={(e) => setFilterMonitor(e.target.value)}
            >
              <option value={0}>Filtrar por docente seguimiento</option>
              {teachersFiltered?.map((teacher) => (
                <option value={teacher?.info.id}>
                  {teacher?.info?.first_name} {teacher?.info.last_name}
                </option>
              ))}
            </select>
          )}
        </div>
        <DataTable
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight="540px"
        />
      </div>
      {isLoading && <LoadingComponent />}
      {openModal &&
        <Modal title={"Nuevo Feedback Trimestral"} modalOpen={setOpenModal}>
          <div style={{height: '100%', overflowY: 'scroll'}}>
            <div style={{fontWeight: 'bold', textAlign: 'center', margin: '10px'}}>
              Selecciona el docente al que se desea generar el reporte
            </div>
            <select 
              value={currentTeacherIndex}
              className={styles.filter_textSelect}
              onChange={(e) => setCurrentTeacherIndex(e.target.value)}      
            >
              <option value = "">Selecciona un docente</option>
              {
                currentInstitution?.teachers?.map((teacher, index) => (
                  <option key={index} value={index}>
                    {`${teacher?.info?.first_name} ${teacher?.info?.last_name}`}
                  </option>
                ))
              }
            </select>
            <div style={{display: 'flex', flexDirection: 'column', 
            justifyContent:'center', width:'100%', alignItems:'center', paddingTop: '15px'}}>
              <button onClick={handleCreateNewReport} 
              style={{border: 'none', backgroundColor: 'var(--main-blue)', color: '#fff', 
              padding: '10px', borderRadius: '10px', width: 'fit-content'}}>
                Aceptar
              </button>
            </div>
          </div>
        </Modal>}
        {openCreateModal &&
        <Modal title={"Feedback Trimestral"} modalOpen={setOpenCreateModal}>
          <div style={{height: '100%', overflowY: 'scroll'}}>
          <div style={{fontWeight: 'bold', textAlign: 'center', marginTop: '10px'}}>
            {`${currentInstitution?.teachers[currentTeacherIndex]?.info?.first_name} 
            ${currentInstitution?.teachers[currentTeacherIndex]?.info?.last_name}`}
          </div>
          {Object.entries(questions).map(([section, questionsInSection]) => (
            <div key={section}>
              <h3 style={{margin: 0, paddingTop: '5px'}}>{section}</h3>
              {questionsInSection?.map(pregunta => (
                <div key={pregunta.id}>
                  <label style={{textAlign: 'start', paddingTop: '5px'}}>{pregunta.question}</label>
                  <input
                    type="radio"
                    name={pregunta.id}
                    value="Si"
                    checked={answers[section]?.[pregunta.question] === "Si"}
                    onChange={() => handleChange(section, pregunta.id, pregunta.question, "Si")}
                  /> Si
                  <input
                    type="radio"
                    name={pregunta.id}
                    value="No"
                    checked={answers[section]?.[pregunta.question] === "No"}
                    onChange={() => handleChange(section,pregunta.id ,pregunta.question, "No")}
                    style={{marginLeft: '7px'}}
                  /> No
                   <input
                      type="radio"
                      name={pregunta.id}
                      value="No aplica"
                      checked={answers[section]?.[pregunta.question] === ""}
                      onChange={() => handleChange(section, pregunta.id, pregunta.question, "")}
                      style={{ marginLeft: '7px' }}
                    /> No aplica
                </div>
              ))}
            </div>
          ))}
          <div style={{position: 'relative', paddingTop: '10px'}}>
            <label>Comentarios Generales:</label>
            <textarea
              value={answers.comentariosGenerales}
              onChange={handleCommentChange}
              maxLength={5000}
              style={{width: '100%', borderRadius: '10px', padding: '5px'}}
              rows={6}
            />
            <div style={{
              position: 'absolute',
              bottom: '5px',
              right: '10px',
              fontSize: '12px',
              color: remainingCharacters < 0 ? 'red' : 'black'
            }}>
              {remainingCharacters} / 5000 caracteres
            </div>
          </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <button onClick={() => handleAcceptBorrador(answers)} 
              className={styles.button2}>Guardar Borrador</button>

                <button onClick={() => handleAccept(answers)} 
                className={styles.button}>Aceptar</button>
            </div>
          </div>
        </Modal>}
        {/* esto se puede quitar pero lo dejare comentado por si luego necesito probar algo */}
        {/* {openModalEdit && ( // Modal para editar feedback */}
        {/* <Modal title={"Editar Feedback"} modalOpen={setOpenModalEdit}> */}
            {/* componente para editar */}
            {/* <ModalEdit teachersFeedback={teachersFeedback} institution={currentInstitution} />  */}
        {/* </Modal> */}
        {/* )} */}

    </Container>

  )
}

export default GenerarReporte;