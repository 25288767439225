export const getInitialValues = ({ values, base }) => {
  const definedValues = {};

  Object.keys(base).forEach((key) => {
    if (values[key] === null) {
      definedValues[key] = "";
    } else {
      definedValues[key] = values[key];
    }
  });
  return definedValues;
};
