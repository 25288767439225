import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Checkbox from "@mui/material/Checkbox";
import Modal from '../../Modal'
import { ATTENDANCE_INDICATORS_ENDPOINT, ANNUAL_ATTENDANCE_ENDPOINT } from '../../../utils/constants'
import { get } from '../../../utils/http'

const options = ["Descripción", "Tipo", ""]

const TableColumns = () => {
  return options.map((column) => {
    if (column === "Descripción" ) {
      return (
        <TableCell
          key={column}
          sx={{
            color: "#8C8C8C",
            fontSize: "10.4px",
            fontWeight: "bold",
            background: "#ffff",
            textTransform: 'uppercase'
          }}>
          {column}
        </TableCell>
      )
    }
    return (
      <TableCell
        key={column}
        align="center"
        sx={{
          color: "#8C8C8C",
          fontSize: "10.4px",
          fontWeight: "bold",
          background: "#ffff",
          textTransform: 'uppercase'
        }}>
        {column}
      </TableCell>
    )
  })
}

function IndicatorsModal({setOpenIndicatorsModal, teacherId, sessionId, sendAttendanceIndicators}) {
    const [indicators, setIndicators] = useState([])
    const [teacherIndicators, setTeacherIndicators] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);

    const getIndicators = async () => {
      const url = `${ATTENDANCE_INDICATORS_ENDPOINT}`;
      const response = await get({ url });
      setIndicators(response.data);
    };

    const getTeacherIndicators = async () => {
      const url = `${ANNUAL_ATTENDANCE_ENDPOINT}get_by_teacher/?session=${sessionId}&teacher=${teacherId}`;
      const response = await get({ url });
      setTeacherIndicators(response.data);
  };

    useEffect(() => {
        getIndicators()
    }, []);

    useEffect(() => {
      getTeacherIndicators()
  }, [sessionId, teacherId]);

  useEffect(() => {
    const selected = teacherIndicators[0]?.indicators?.map(indicator => indicator.id);
    setSelectedRows(selected);
  }, [teacherIndicators]);

  const handleCheckboxChange = (id) => {
    if (selectedRows?.includes(id)) {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSendSelected = () => {
    sendAttendanceIndicators(selectedRows, teacherIndicators[0].id)
    getTeacherIndicators()
    setOpenIndicatorsModal(false)
  };

  return (
    <Modal 
    modalOpen={setOpenIndicatorsModal}
    title={"Indicadores"}>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ overflow: "scroll", maxHeight: "500px", width: '100%', borderRadius: '10px' }}>
          <Table size="small" aria-label="sales order table" stickyHeader>
            <TableHead sx={{ border: "none", height: '45px'}}>
              <TableRow sx={{ border: "none" }}>
                <TableColumns />
              </TableRow>
            </TableHead>
            <TableBody>
              {indicators?.map((item, i) => {
                let type = ''
                switch (item?.type) {
                    case "commitment":
                      type = "Compromiso"
                      break
                    case "content":
                      type = "Contenido"
                      break
                    case "practice":
                      type = "Práctica"
                      break
                }
                const isChecked = selectedRows?.includes(item.id);
                return(
                  <TableRow
                  key={i}>
                     <TableCell component="th" scope="row" sx={{fontSize: '14px'}}>
                      {`${item?.description}`}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '14px'}}>
                      {type}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '14px'}}>
                      <Checkbox
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{width:'100%', display:'flex', 
        justifyContent:'center', gap: '10px', marginTop: 1}}>
          <Button variant='outlined' size='large'
          onClick={() => setOpenIndicatorsModal(false)}
          sx={{borderRadius: '10px'}}>
            Cancelar
          </Button>
          <Button variant='contained' size='large'
          onClick={handleSendSelected}
          sx={{borderRadius: '10px'}}>
            Guardar
          </Button>
        </Box>
        
    </Modal>
  )
}

export default IndicatorsModal