import React, { useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MyTextInput, MySubmitButton, MySelect } from "../FormFields";
import * as Yup from "yup";
import styles from "./CreateInstitutionForm.module.css";
import { useInstitutions } from "../../context/InstitutionsContext";
import Container from "../Container";

const CreateInstitutionForm = ({ onSuccess }) => {
  const [levels, setLevels] = useState([]);
  const [levelInfo, setLevelInfo] = useState({ stage: '', name: '', student_sections: 0, students_per_section: 0 })
  const { createInstitution } = useInstitutions();

  const navigate = useNavigate();

  const addLevel = () => {
    if (levelInfo.name !== '' && levelInfo.stage !== '' 
    && levelInfo.student_sections !== 0 
    && levelInfo.students_per_section !== 0) {
      setLevels([...levels, levelInfo]);
      setLevelInfo({ name: '', stage: '', student_sections: 0, students_per_section: 0 });
    }
  };

  const deleteLevel = (index) => {
    const newLevels = [...levels];
    newLevels.splice(index, 1);
    setLevels(newLevels);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLevelInfo((prevOpcion) => ({ ...prevOpcion, [name]: value }));
  };

  const createInstitutionHandler = async (values) => {
    const institutionData = {
      ...values,
      institution_level: levels,
    };

    if (institutionData.students_per_classroom === 0) {
      delete institutionData["students_per_classroom"];
    }
    if (institutionData.classrooms_per_level === 0) {
      delete institutionData["classrooms_per_level"];
    }
    createInstitution({ data: institutionData });
    navigate(-1);
  };

  return (
    <Container>
      <div style={{ width: "100%" }}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles["create-institution"]}>
          <Formik
            initialValues={{
              name: "",
              short_name: "",
              address: "",
              city: "",
              state: "",
              teacher_service: "",
              organization: "",
              classrooms_per_level: 1,
              students_per_classroom: 1,
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .min(1, "El campo no puede estar vacío.")
                .required("Campo requerido"),
              short_name: Yup.string()
                .min(2, "Debe contener más de 2 caracteres.")
                .required("Campo requerido"),
              city: Yup.string()
                .min(2, "Debe contener más de 2 caracteres.")
                .required("Campo requerido"),
              state: Yup.string()
                .min(2, "Debe contener más de 2 caracteres.")
                .required("Campo requerido"),
              teacher_service: Yup.string()
                .oneOf(["INT", "EXT", "MIX"])
                .required("Campo requerido"),
              address: Yup.string()
                .min(5, "Debe contener más de 5 caracteres.")
                .required("Campo requerido"),
              organization: Yup.string().min(
                4,
                "Debe contener más de 4 caracteres."
              ),
              students_per_classroom: Yup.number().min(
                0,
                "El número debe ser positivo."
              ),
              classrooms_per_level: Yup.number().min(
                0,
                "El número debe ser positivo."
              ),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                createInstitutionHandler(values);
                setSubmitting(false);
              }, 400);
              // resetForm();
            }}
          >
            <Form className={styles["form__fields"]}>
              <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                <MyTextInput
                  label="Nombre de la institucón"
                  name="name"
                  type="text"
                  placeholder="Nombre"
                  icon="fa fa-user"
                  width={'100%'}
                />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <MyTextInput
                  label="Nombre corto"
                  name="short_name"
                  type="text"
                  placeholder="ABCD"
                  icon="fa fa-key"
                  width={'100%'}
                />

                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <MyTextInput
                  label="Organización"
                  name="organization"
                  type="text"
                  placeholder="Organización"
                  icon="fa fa-user"
                  width={'100%'}
                />

                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <MyTextInput
                  label="Dirección"
                  name="address"
                  type="text"
                  placeholder="Dirección"
                  icon="fa fa-user"
                  width={'100%'}
                />

                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <MyTextInput
                  label="Ciudad"
                  name="city"
                  type="text"
                  placeholder="Ciudad"
                  icon="fa fa-key"
                  width={'100%'}
                />

                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <MyTextInput
                  label="Estado"
                  name="state"
                  type="text"
                  placeholder="Estado"
                  icon="fa fa-user"
                  width={'100%'}
                />

                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <MySelect label="Tipo de docente" name="teacher_service">
                  <MenuItem value="INT">Interno</MenuItem>
                  <MenuItem value="EXT">Externo</MenuItem>
                  <MenuItem value="MIX">Mixto</MenuItem>
                </MySelect>

                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <MyTextInput
                  label="Secciones por grado"
                  name="classrooms_per_level"
                  type="number"
                  min="1"
                  placeholder="Secciones por nivel"
                  icon="fa fa-user"
                  width={'100%'}
                />
                  
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <MyTextInput
                  label="Alumnos por sección"
                  name="students_per_classroom"
                  type="number"
                  min="1"
                  placeholder="Alumnos por sección"
                  icon="fa fa-user"
                  width={'100%'}
                />
                  
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                <Accordion sx={{borderRadius: '10px'}} elevation={0} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{fontSize: '16px'}}
                >
                  Agregar Niveles
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
                    {levels?.map((level, index) => (
                      <Grid item md={12} 
                      sx={{display:'flex', gap: '5px'}}>
                        <IconButton 
                        onClick={()=>deleteLevel(index)}>
                          <RemoveCircleOutlineIcon 
                          sx={{color: '#cf142b', fontSize: '1.8rem'}} />
                        </IconButton>
                        <p key={index} style={{color: '#000', fontSize: '1.5rem'}}>
                          {level.stage} - {level.name} - Secciones: {level.student_sections}
                        </p>
                      </Grid>

                    ))}
                     <Grid item md={3} sm={6} xs={12}>
                     <select name="stage" value={levelInfo.stage}
                      onChange={handleInputChange}>
                      <option value="">Seleccionar etapa</option>
                      <option value="Preescolar">Preescolar</option>
                      <option value="Primaria Menor">
                        Primaria Menor
                      </option>
                      <option value="Primaria Mayor">
                        Primaria Mayor
                      </option>
                      <option value="Bachillerato">Bachillerato</option>
                    </select>

                     </Grid>
                     <Grid item md={3} sm={6} xs={12}>
                     <input
                      type="text"
                      placeholder="Nombre"
                      name="name"
                      value={levelInfo.name}
                      onChange={handleInputChange}
                    />

                     </Grid>
                     <Grid item md={3} sm={6} xs={12}>
                     <input
                      type="number"
                      placeholder="Número de secciones"
                      name="student_sections"
                      value={levelInfo.student_sections}
                      onChange={handleInputChange}
                    />

                     </Grid>
                     <Grid item md={3} sm={6} xs={12}>
                     <input
                      type="number"
                      placeholder="Estudiantes por sección"
                      name="students_per_section"
                      value={levelInfo.students_per_section}
                      onChange={handleInputChange}
                    />

                     </Grid>
                     <Grid item md={12} sm={12} xs={12}>
                      <IconButton 
                        onClick={addLevel}>
                          <AddCircleOutlineIcon 
                          sx={{color: '#5FDBA7', fontSize: '1.8rem'}} />
                        </IconButton>

                     </Grid>

                  </Grid>
                </AccordionDetails>
              </Accordion>
                </Grid>
              </Grid>
              <MySubmitButton name="Crear" type="submit" />
            </Form>
          </Formik>
        </div>
      </div>
    </Container>
  );
};

export default CreateInstitutionForm;
