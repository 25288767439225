import React, {useContext, useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import DataTable from "react-data-table-component";
import Container from "../Container";
import { MyTextInput, MySubmitButton } from "../FormFields";
import { ProgramsContext } from "../../context/ProgramsContext";
import styles from "./LicensesList.module.css"
import { LICENSES_ENDPOINT } from "../../utils/constants";
import { get } from "../../utils/http";

const columns = [
  {
    name: "Licencia",
    selector: (row) => row.name,
    sortable: true,
  },
];

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};


function LicensesList() {
  const [licens, setLicens] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const navigate = useNavigate();
  const { createLicense } = useContext(ProgramsContext);

  const submitHandler = async (values) => {
    createLicense(values)
    navigate("/programs");
  };
  
  useEffect(() => {
    const fetchLessons = async () => {
      const url = LICENSES_ENDPOINT;
      const response = await get({ url });

      if (!response.error) {
        setLicens(response.data);
      }
    };
    fetchLessons();
  }, []);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
        window.removeEventListener('resize', changeWidth)
    }
  }, [])
    
  return (
    <div className={styles.create_license}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => navigate(-1)}
      ></i>
      <div className={styles.container_row}>
      {screenWidth > 950 &&
      <div className={styles.left_side}>
      <Container direction={"column"}>
        <DataTable
          columns={columns}
          data={licens}
          customStyles={customStyles}
          sortIcon={
            <i
              className="far fa-arrow-up"
              style={{ marginLeft: "5px", fontSize: "14px" }}
            ></i>
          }
          highlightOnHover
          fixedHeader
          fixedHeaderScrollHeight="515px"
        />
      </Container>
      </div>}
      <div className={styles.right_side}>
      <Formik
        initialValues={{
            name: ""
        }}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string()
            .min(1, "El campo no puede estar vacío.")
            .required("Campo requerido"),
            
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            submitHandler(values);
            setSubmitting(false);
          }, 400);
          resetForm();
        }}
      >
        <Form className={styles["form__fields"]}>
          <div className={styles.form_rows}>
            <div className={styles.inputs_left}>
              <MyTextInput
                label="Nombre"
                name="name"
                type="text"
                icon="fa fa-user"
              />
            </div>
          </div>
          <div className={styles.inputs_left}>
            <MySubmitButton
              name={"Crear"}
              type="submit"
            />
          </div>
        </Form>
      </Formik>
      </div>
      {screenWidth <= 950 &&
      <div className={styles.left_side}>
      <Container direction={"column"}>
        <DataTable
          columns={columns}
          data={licens}
          customStyles={customStyles}
          sortIcon={
            <i
              className="far fa-arrow-up"
              style={{ marginLeft: "5px", fontSize: "14px" }}
            ></i>
          }
          highlightOnHover
          fixedHeader
          fixedHeaderScrollHeight="515px"
        />
      </Container>
      </div>}
      </div>
    </div>
  )
}

export default LicensesList
