import React from 'react'
import { Grid, Stack } from '@mui/material';
import Modal from '../../Modal'

function ModalAnswers({selectedQuiz, setOpenAnswers}) {
  return (
    <Modal title={"Respuestas"} modalOpen={setOpenAnswers}>
        <div style={{height: '100%', overflowY: 'scroll', width:'100%'}}>
        {selectedQuiz ? (
              <>
              {selectedQuiz?.map((quiz) => {
            const answer = quiz?.question?.options?.filter((item) => item?.correct_answer)
            return(
              <Stack direction={"column"} > 
                <p style={{fontWeight: 'bold'}}>{quiz?.question?.content}</p>
                <Stack direction={"row"} gap={1} sx={{display: 'flex', alignItems:'center'}}>
                  <p>{quiz?.answer_option?.description}</p>
                  {quiz?.answer_option?.correct_answer ? 
                  <i class="fas fa-check" style={{color: 'var(--sec-green)'}}></i> : 
                  <i class="fas fa-times" style={{color: 'var(--sec-red)'}}></i>
                  }
                </Stack>
                {!quiz?.answer_option?.correct_answer &&
                  <p>{`Respuesta Correcta: ${answer[0]?.description}`}</p>
                }
              </Stack>
            ) 
            })}</>) : 
            <Stack
            container
            direction="column"
            sx={{
              borderRadius: "10px",
              border: "solid var(--main-blue)",
              borderWidth: 1.8,
              overflow: "hidden",
              alignItems: "center",
              padding: '10px',
            }}>
            <Grid container sx={{ justifyContent: "center" }}>
              <p style={{ fontWeight: "bold", fontSize: '14px' }}>
                Respuestas no disponibles
              </p>
            </Grid>
            <Grid container direction="row" sx={{ justifyContent: "center" }}>
              <p style={{fontSize: '13px', paddingTop: '5px'}}>
                Si el problema persiste, por favor contáctanos
              </p>
            </Grid>
          </Stack>}
          </div>
        </Modal>
  )
}

export default ModalAnswers
