import React, { createContext, useContext, useState } from "react";

const AlertsContext = createContext();

const AlertsProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');

  const showSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);

    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };

  return (
    <AlertsContext.Provider value={showSnackbar}>
      {children}
      {snackbarOpen && (
        <Snackbar message={snackbarMessage} type={snackbarType} />
      )}
    </AlertsContext.Provider>);
};

const Snackbar = ({ message, type }) => {
  const snackbarClassName = `snackbar_${type}`;

  return (
    <div 
    style={{position: 'fixed', 
    bottom: '30px', 
    right: '3%', 
    padding: '16px 27px',
    borderRadius:'10px',
    fontSize: '14px',
    color: '#fff',
    backgroundColor: type === "success"? "#4caf50" : 
    (type === "error" ? "#f44336": "#F6B317")}}>
      {message}
    </div>
  );
};

const useAlerts = () => {
  return useContext(AlertsContext);
};

export { AlertsProvider, AlertsContext, useAlerts };
