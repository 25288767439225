import { useState, createContext, useContext } from "react";
import { get, post, patch } from "../utils/http";
import {
  COURSES_ENDPOINT,
  ACADEMIC_YEAR,
  COURSE_LIST_ENDPOINT,
} from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";
import { AlertsContext } from "./AlertsContext";

const CoursesContext = createContext();

const CoursesProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [selectedCourse, setSelectedCourse] = useLocalStorage(
    "selectedCourse",
    ""
  );
  const showSnackbar = useContext(AlertsContext);

  const createCourses = async ({ data }) => {
    const url = COURSES_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha creado un nuevo curso", "success");
      fetchCourses();
    } else {
      showSnackbar("Hubo un error al crear el curso", "error");
    }
  };

  const markTeacher = async (courseId) => {
    const url = `${COURSES_ENDPOINT}${courseId}/register/`;
    const response = await patch({ url });
    if (!response.error) {
      showSnackbar("Se ha marcado como docente del curso", "success");
    } else {
      showSnackbar("Hubo un error al marcarse como docente curso", "error");
    }
  };

  // const fetchPrograms = async () => {
  //   const url = PROGRAMS_ENDPOINT;
  //   const response = await get({ url });
  //   if (!response.error) {
  //     setPrograms(response.data);
  //   }
  // };

  const fetchCourses = async () => {
    const url = COURSES_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setCourses(response.data);
    }
  };

  const fetchCourseList = async () => {
    const url = COURSE_LIST_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setCourses(response.data);
    }
  };

  const fetchYears = async () => {
    const url = ACADEMIC_YEAR;
    const response = await get({ url });
    if (!response.error) {
      setAcademicYears(response.data);
    }
  };

  const fetchSingleCourse = async (courseId) => {
    const url = `${COURSES_ENDPOINT}${courseId}/`;
    const response = await get({ url });

    if (!response.error) {
      return response.data;
    }
  };

  const updateCourse = async ({ courseId, values: data }) => {
    const url = `${COURSES_ENDPOINT}${courseId}/`;
    // const data = { ...values };
    const response = await patch({ url, data });

    if (!response.error) {
      showSnackbar("Se ha actualizado el curso", "success");
      fetchCourses();
    } else {
      showSnackbar("Hubo un error al actualizar el curso", "error");
    }
  };

  const contextData = {
    courses,
    setCourses,
    selectedCourse,
    setSelectedCourse,
    createCourses,
    fetchCourses,
    setAcademicYears,
    academicYears,
    fetchYears,
    updateCourse,
    fetchSingleCourse,
    markTeacher,
    fetchCourseList
  };

  return (
    <CoursesContext.Provider value={contextData}>
      {loading ? null : children}
    </CoursesContext.Provider>
  );
};

const useCourses = () => {
  return useContext(CoursesContext);
};

export { CoursesContext, CoursesProvider, useCourses };
