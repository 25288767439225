import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useCurrentUser } from "./CurrentUserProvider";
import { useUsers } from "./UsersProvider";
import { MESSAGE_ENDPOINT, CHAT_ROOM_ENDPOINT } from "../utils/constants";
import { get, post, patch, deleted } from "../utils/http";
import { AlertsContext } from "./AlertsContext";

const ConversationsContext = React.createContext();

const ConversationsProvider = ({ children }) => {
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState();
  const [currentConversation, setCurrentConversation] = useState();
  const [firstUnreadIndex, setFirstUnreadIndex] = useState();
  const { currentUser } = useCurrentUser();

  const showSnackbar = useContext(AlertsContext);

  const fetchRooms = async () => {
    const url = CHAT_ROOM_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      const sortedData = response.data.sort((a, b) => {
        return new Date(b.last_message?.timestamp) - new Date(a.last_message?.timestamp);
      });
      setConversations(sortedData);
    }
  };

  const fetchMessages = async () => {
    const url = `${CHAT_ROOM_ENDPOINT}${currentConversationId}/`;;
      const response = await get({ url });
      if (!response.error) {
        setMessages(response.data);
      }
  };
  const sendMessage = async ({ data }) => {
    const url = MESSAGE_ENDPOINT;
    const response = await post({ url, data });
    if(!response.error){
      fetchMessages();
      fetchRooms()
    }
  };

  const deleteMessage = async ({ messageId }) => {
    const url = `${MESSAGE_ENDPOINT}${messageId}/`;
    const response = await deleted({ url });
    if(!response.error){
      fetchMessages();
      fetchRooms()
      showSnackbar("Mensaje eliminado", "success");
    }else{
      showSnackbar("Hubo un error al eliminar el mensaje", "error");
    }
  };

  const markAsRead = async (roomId) => {
    const url = `${CHAT_ROOM_ENDPOINT}${roomId}/mark_read/`;
    const response = await patch({ url });
    if(!response.error){
      fetchRooms()
    }
  };

  const deleteRoom = async ({ roomId }) => {
    const url = `${CHAT_ROOM_ENDPOINT}${roomId}/`;
    const response = await deleted({ url });
    if (!response.error) {
      fetchRooms()
      fetchMessages()
      showSnackbar("Conversación eliminada", "success");
    } else {
      showSnackbar("Hubo un error al eliminar la conversación", "error");
    }

    return response;
  };

  useEffect(() => {
    fetchRooms()
  }, [currentUser]);

  return (
    <ConversationsContext.Provider
      value={{
        conversations,
        setConversations,
        fetchRooms,
        messages,
        setMessages,
        currentConversationId,
        setCurrentConversationId,
        setFirstUnreadIndex,
        firstUnreadIndex,
        sendMessage,
        fetchMessages,
        markAsRead,
        deleteRoom,
        currentConversation, 
        setCurrentConversation,
        deleteMessage
      }}
    >
      {children}
    </ConversationsContext.Provider>
  );
};

const useConversations = () => {
  return useContext(ConversationsContext);
};
export { ConversationsContext, ConversationsProvider, useConversations };
