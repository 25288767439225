import React, {useState, useEffect, useContext} from 'react'
import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Stack from '@mui/material/Stack';
import styles from './CalendarPage.module.css'
import { BASE_URL } from '../utils/constants';
import { get } from '../utils/http';
import { AnnualTrainingsContext } from '../context/AnnualTrainingsContext';
import { CurrentUserContext } from '../context/CurrentUserProvider';

function CalendarPage() {
  const [currentView, setCurrentView] = useState('timeGridDay');
  const { currentUser } = useContext(CurrentUserContext);
  const [userForms, setUserForms] = useState([])
  const {fetchAnnualTrainings, annualTrainings} = useContext(AnnualTrainingsContext)

  useEffect(() => {
    fetchAnnualTrainings();
  }, []);

  const navigate = useNavigate();

  const events = annualTrainings?.flatMap(training => 
    training.sessions.map((session, index) => ({
      id: training.id,
      title: `Sesión ${index + 1}: ${training.name}`,
      start: new Date(session.date.split('T')[0] + 'T' + session.start_time).toISOString(), // Formato: YYYY-MM-DDTHH:MM:SS
      end: new Date(session.date.split('T')[0] + 'T' + session.end_time),
      extendedProps: {
        training: training?.name,
        classroom: session?.classroom,
        location: session?.location
      }
    }))
  );

  const handleEventClick = (clickInfo) => {
    if(currentUser.role !== "external_teacher" || currentUser.role !== "external_coordinator" ){
      navigate(`/seguimiento/capacitaciones/anuales/${clickInfo?.event?.id}`);
    }else{
      navigate(`/capacitaciones/anuales/${clickInfo?.event?.id}`);
    }
  };
  
  useEffect(() => {
    const fetchForms = async () => {
      const url = `${BASE_URL}/accounts/forms/`;
      const response = await get({ url });

      if (!response.error) {
        setUserForms(response.data);
      }
    };
    fetchForms();
  }, []);

  const dates = []

  userForms?.forEach((form) => {
    const survey_date = new Date(form.template.start_date)
    const survey_end = new Date(form.template.end_date)
    const data={
      title: `Encuesta seguimiento: ${form?.institution?.short_name}`,
      start: survey_date.toISOString().slice(0,10),
      end: survey_end.toISOString().slice(0,10)
    }
    dates.push(data)
  })

  const renderEventContent = (eventInfo) => {
    if (currentView === 'dayGridMonth') {
      return (
        <Stack direction={"column"} 
        spacing={0.5} 
        sx={{ maxWidth: '100%', padding: '0 2px', 
        backgroundColor: 'var(--ter-blue)', borderRadius: '5px', padding: '2px', width:'100%' }}>
        <b style={{ fontSize: '12px',
          whiteSpace: 'normal', 
          wordWrap: 'break-word', 
          overflow: 'hidden',   
          padding: '0 2px',
          color: '#fff'}}>
          {eventInfo.timeText}
        </b>
        <p style={{fontSize: '12px',
          whiteSpace: 'normal', 
          wordWrap: 'break-word', 
          overflow: 'hidden',   
          padding: '0 2px',
          color: '#fff'}}>
          {eventInfo.event.title}
        </p>
        <p style={{
          fontSize: '12px',
          whiteSpace: 'normal', 
          wordWrap: 'break-word', 
          overflow: 'hidden',   
          padding: '0 2px',
          color: '#fff'
        }}>
          {eventInfo.event.extendedProps.location}
        </p>
      </Stack>
      );
    } else if (currentView === 'timeGridDay') {
      return (
        <>
          <b style={{ fontSize: '12px' }}>{eventInfo.timeText}</b>
          <p style={{ fontSize: '12px' }}>{eventInfo.event.title}</p>
          <p style={{ fontSize: '12px' }}>{`Salón: ${eventInfo.event.extendedProps.classroom || "Sin Asignar"}`}</p>
          <p style={{ fontSize: '10px', marginTop: '10px' }}>{eventInfo.event.extendedProps.training}</p>
          <p style={{ fontSize: '10px' }}>{eventInfo.event.extendedProps.type}</p>
        </>
      );
    }
  };

  const handleViewDidMount = (view) => {
    setCurrentView(view.view.type);
  };
   
  return (
        <div className={styles.calendar_container}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            weekends={true}
            events={events}
            height={"85vh"}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridDay'
            }}
            eventClick={handleEventClick}
            eventContent={renderEventContent}
            viewDidMount={handleViewDidMount}
          />
        </div>
  )
}

export default CalendarPage