import React from 'react';
import Box from "@mui/material/Box";
import { Backdrop } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import { useLoading } from '../context/LoadingContext'; 

const LoadingComponent = () => {
  const { isLoading } = useLoading();

  return (
    <Backdrop
    sx={{
      color: "#fff",
      zIndex: (theme) => theme.zIndex.drawer + 1000,
    }}
    open={isLoading}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ m: 1, position: "relative" }}>
        <img
          src="/K_neg.webp"
          alt="kurios logo"
          width={70}
          height={90}
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        />
        <CircularProgress
          size={130}
          thickness={0.5}
          color="inherit"
          sx={{
            position: "absolute",
            top: -18,
            left: -33,
            zIndex: 1,
            justifyContent: "center",
            display: "flex",
          }}
        />
      </Box>
    </Box>
    </Backdrop>
  );
};

export default LoadingComponent;
