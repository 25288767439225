/**
 * Componente AnualTrainingCertificates
 *
 * Este componente genera un certificado de las capacitaciones anales en formato PDF, mostrando el nombre de la persona, 
 * el programa, las horas asistidas y la etapa. Utiliza el paquete `@react-pdf/renderer` para generar el PDF.
 * 
 * El template del certificado se pasa en imagen utilizando un link de AWS 
 *
 * @param {Object} props - Propiedades del componente
 * @param {Object} props.person - Información de la persona (se espera que tenga propiedades `first_name` y `last_name`)
 * @param {string} props.program - Nombre del programa de capacitaciones
 * @param {number} props.hours - Total de horas asistidas
 * @param {string} props.stage - Etapa educativa (puede ser 'Bachillerato', 'Primaria Mayor', 'Primaria Menor' o 'Preescolar')
 * @returns {React.Component|null} Componente PDFViewer que contiene el documento PDF del certificado, o `null` si no se proporciona `person`
 */

import React from 'react';
import { 
    Document, 
    Page, 
    Text, 
    StyleSheet, 
    PDFViewer, 
    View, 
    Image, 
    Font 
} from '@react-pdf/renderer';
import OpenSansRegular from '../../fonts/OpenSans/OpenSans-Regular.ttf'
import { capitalizeWords } from '../../utils/capitalize';

// Registro de fuente personalizada para uso en el PDF
Font.register({ family: 'OpenSans', src: OpenSansRegular });

// Estilos para los elementos del PDF
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      position: 'relative',
    },
    backgroundImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
    },
    contentContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    title: {
        textAlign:'center', 
        marginTop: 210, 
        fontSize: '28px', 
        color: '#412b88',
        fontFamily: 'OpenSans'
    },
    text:{
        fontSize: '14px', 
        textAlign: 'center', 
        marginTop: 30, width: '70%', 
        color: '#58595b',
        fontFamily: 'OpenSans',
        flexWrap: 'wrap',   // Permite que el texto se divida en varias líneas
        overflow: 'hidden', // Permite que el texto se oculte si es demasiado extenso
    },
    text2:{
        fontSize: '14px', 
        textAlign: 'center', 
        marginTop: 15, width: '70%', 
        color: '#58595b',
        fontFamily: 'OpenSans'
    }
});

/**
 * Función principal del componente
 * @function AnualTrainingCertificates
 * @param {Object} props - Propiedades que incluyen información de la persona, programa, horas y etapa
 */
function AnualTrainingCertificates({person, program, hours, stage}) {
    // Si no se proporciona una persona, el componente no muestra nada
    if (!person) return null;

    // Traducción de etapas para mantener consistencia
    const stageTranslate = {
      'Bachillerato': 'Media General',
      'Primaria Mayor': "Primaria",
      'Primaria Menor': "Primaria",
      'Preescolar': 'Inicial',
    };

    // Asigna la etapa traducida si existe en el objeto de traducciones, o usa la etapa original
    let translateStage = stageTranslate[stage] || stage;

    // Texto descriptivo del certificado
    let text = `Por la participación en la jornada de capacitaciones de los programas de ${program}, correspondientes a la etapa de ${translateStage}, con un total de ${hours} horas de asistencia.`

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.contentContainer}>
            <Image src={"https://kuriosedu.s3.us-east-2.amazonaws.com/media/capacitacionesAnuales.png"} style={styles.backgroundImage} />
          <View style={styles.section}>
            <Text 
            style={styles.title}>
                {`${capitalizeWords(person?.first_name)} ${capitalizeWords(person?.last_name)}`}
            </Text>
            <div style={{width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center'}}>
            <Text 
            style={styles.text}>
                {`${text}`}
            </Text>
            </div>
            
          </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default AnualTrainingCertificates
