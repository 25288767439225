import React, { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import Container from "../Container";
import styles from "./ProgramsList.module.css";
import { ProgramsContext } from "../../context/ProgramsContext";
import { PROGRAMS_ENDPOINT } from "../../utils/constants";
import { get } from "../../utils/http";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import { useLoading } from "../../context/LoadingContext";
import LoadingComponent from "../LoadingComponent";
const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function ProgramsList() {
  const { setSelectedProgram } = useContext(ProgramsContext);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const {currentUser} = useContext(CurrentUserContext)
  const [programs, setPrograms] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterYear, setFilterYear] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrograms = async () => {
      showLoading();
      const url = PROGRAMS_ENDPOINT;
      const response = await get({ url });

      if (!response.error) {
        setPrograms(response.data);
        hideLoading();
      }

      hideLoading();
    };
    fetchPrograms();
  }, []);

  let filteredData = programs.filter(
    (item) =>
      (item?.name?.toLowerCase().includes(filterText?.toLowerCase()) ||
      item?.licenss?.name?.toLowerCase().includes(filterText?.toLowerCase()))  &&
      (filterYear == "" || item?.year?.id == filterYear)
  );

  const programYear = []
  programs.forEach((program) => {
        if( !programYear?.find(obj => obj?.id == program?.year?.id) && program?.year?.id != 'null' && program?.year?.id != ''){
          
          programYear.push(program?.year)
        }
  })

  const columns = [
    {
      id: "year",
      name: "Año",
      selector: (row) => row.year?.name,
      sortable: true,
      cell: (cell) => (
        <div>{cell.year?.name}</div>
      )
    },
    {
      id: "program",
      name: "Programa",
      selector: (row) => row.name,
      sortable: true,
      cell: (cell) => (
        <div>{cell.name}</div>
      )
    },
    {
      name: "Licencia",
      selector: (row) => row.licenss?.name,
      sortable: true,
    },
    {
      name: "Versión",
      selector: (row) => row.version,
      sortable: true,
    },
    {
      name: "Idioma",
      selector: (row) => row.language,
      sortable: true,
    },
    {
      name: "Descripción",
      selector: (row) => row.description,
      sortable: true,
      cell: (cell) => (
        <div>{cell.description}</div>
      )
    },
    {
      name: "Clases",
      selector: (row) => row.n_lessons,
      sortable: true,
    },
    {
      name: "Cargar",
      selector: (row) => (
        <a href={`${row.id}/upload`}>
          <Button variant="contained" sx={{borderRadius: '10px'}}>
            Cargar
          </Button>
        </a>
       
      ),
      sortable: false,
    },
  ];

  const columnsSegui = [
    {
      id: "year",
      name: "Año",
      selector: (row) => row.year?.name,
      sortable: true,
      cell: (cell) => (
        <div>{cell.year?.name}</div>
      )
    },
    {
      id: "program",
      name: "Programa",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Licencia",
      selector: (row) => row.licenss?.name,
      sortable: true,
    },
    {
      name: "Versión",
      selector: (row) => row.version,
      sortable: true,
    },
    {
      name: "Idioma",
      selector: (row) => row.language,
      sortable: true,
    },
    {
      name: "Descripción",
      selector: (row) => row.description,
      sortable: true,
    },
  ];

  const handleRowClick = (row) => {
    setSelectedProgram(programs.filter((program) => program.id === row.id)[0]);
    navigate(`/programs/${row.id}`, { relative: false });
  };

  return (
    <div className={styles.content}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer" }}
        onClick={() => navigate(-1)}
      ></i>
      <div className={styles.content2}>
          <div className={styles["search-bar"]}>
            <input
              id="search"
              type="text"
              placeholder="Buscar..."
              aria-label="Search Input"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className={styles.filter_text}
            />
          </div>
          <div className={styles["search-bartwo"]}>
            <select name="program_year" onChange={(e) => setFilterYear(e.target.value)}>
                      <option value="" >
                      ---
                      </option>
                      {programYear?.map((year)=>(
                          <option value={year?.id}>{year?.branch} - {year?.name}</option>
                      ))}
            </select>
          </div>
      </div>
      
      <Container direction={"column"}>
        {isLoading ? <LoadingComponent /> : (
          <DataTable
          columns={["IT", "monitoring_coordinator", "development_teacher"].includes(currentUser.role) ? columns : columnsSegui}
          data={filteredData}
          customStyles={customStyles}
          sortIcon={
            <i
              className="far fa-arrow-up"
              style={{ marginLeft: "5px", fontSize: "14px" }}
            ></i>
          }
          highlightOnHover
          pointerOnHover
          fixedHeader
          fixedHeaderScrollHeight="515px"
          onRowClicked={(row) => handleRowClick(row)}
          defaultSortFieldId="program"
          defaultSortAsc={true}
        />
        )}
        
      </Container>
    </div>
  );
}

export default ProgramsList;
