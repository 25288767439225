import React, {useContext} from 'react'
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Stack, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/system';
import { CurrentUserContext } from '../../../context/CurrentUserProvider';

const CardText = styled('p')({
    fontSize: '1.2rem',
    color: 'var(--ter-blue)',
    cursor:'pointer'
});
const Info = styled('p')({
    fontSize: '1.2rem',
    color: '#787878',
});
const Text = styled('p')({
    fontSize: '1.2rem',
    paddingTop: '5px'
});

function SessionsComp({sessions, handleConfirmSingleAttendance, trainingId, createAttendance}) {
    const [value, setValue] = React.useState(0);
    const { currentUser } = useContext(CurrentUserContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const formattedTime = (time) => {
    return time.slice(0, -3);
  }

  const handleCheckAttendance = (sessionId) => {
    const data = {
      session: sessionId,
      teacher: currentUser.id,
      status:"video",
      by_video: true
    }
    createAttendance(data, trainingId)
    
  }

  return (
    <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs onChange={handleChange} aria-label="visible arrows tabs example" variant="scrollable"
         value={value} >
            {sessions?.map((session, index) => {
                return(
                  <Tab label={`Sesión ${index + 1}`} value={index} sx={{fontSize: '12px'}}/>
                )
            })}
          </Tabs>
        </Box>
        {sessions?.map((session, index) => {
          const teacherAttendance = session?.teacher?.find((item) => item.id === currentUser.id)
          const startDate = new Date(session?.date).toLocaleString("es-ES", options);
          let attendanceMsg = ''
          let chipColor = ""
          let textColor = ""
          switch(teacherAttendance?.attendance_status){
            case null: 
              attendanceMsg = "Confirmar Asistencia"
              break
            case 'pending': 
              attendanceMsg = "Asistencia Confirmada"
              chipColor = "#D7EEFF"
              textColor = "#3796DF"
              break
            case 'attended': 
              attendanceMsg = "Asistente"
              chipColor = "#D7FFE0"
              textColor = "#55BB5A"
              break
            case 'video': 
              attendanceMsg = "Asistente por video"
              chipColor = "#D7FFE0"
              textColor = "#55BB5A"
              break
            case 'absent':
              attendanceMsg = "Inasistente"
              chipColor = "#FFD6CC"
              textColor = "#F0032B"
              break
          }
          return(
            <TabPanel value={index} sx={{borderRadius: '10px', padding: "10px 2px"}}>
            <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
            borderRadius: '10px'}}>
              <Grid container direction={"row"} rowGap={1} columnGap={1}>
                <Grid item md={2.9} xs={12}
                sx={{backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px'}}>
                  <Grid
                  container
                  direction={"row"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height:'100%'
                  }}>
                    <Grid
                    item
                    xs={9}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                        <Info>Fecha y Hora</Info>
                        <CardText>
                          {startDate}
                        </CardText>
                        <CardText>
                          {`${formattedTime(session?.start_time)} - ${formattedTime(session?.end_time)}`}
                        </CardText>
                      </Stack>
                    </Grid>
                    <Grid item xs={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                        <i class="fas fa-calendar-alt" style={{backgroundColor: 'var(--ter-blue)', 
                      color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                    </Grid>
                  </Grid>
                </Grid>
                {session?.instructor?.length > 0 &&
                <Grid item md={2.9} xs={5.84}
                sx={{backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px'}}>
                  <Grid
                  container
                  direction={"row"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height:'100%'
                  }}>
                    <Grid
                      item
                      xs={9}
                      sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                        <Info>Instructor</Info>
                        <CardText>
                        {`${session?.instructor[0]?.first_name} ${session?.instructor[0]?.last_name}`}
                        </CardText>
                      </Stack>
                    </Grid>
                    <Grid item xs={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                        <i class="fas fa-chalkboard-teacher" 
                        style={{backgroundColor: 'var(--ter-blue)', 
                        color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                    </Grid>
                  </Grid>
                </Grid>}
                {session?.location && 
                <Grid item md={2.9} xs={5.84}
                sx={{backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px'}}>
                  <Grid
                  container
                  direction={"row"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height:'100%'
                  }}>
                    <Grid
                      item
                      xs={9}
                      sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                        <Info>Ubicación</Info>
                        <Link to={session?.map_url} target='_blank' rel="noreferrer" style={{textDecoration:'none'}}>
                          <CardText>
                              {session?.location}
                          </CardText>
                        </Link>
                        <CardText>
                          {session?.classroom}
                        </CardText>
                      </Stack>
                    </Grid>
                    <Grid item xs={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                        <i class="fas fa-map-marker-alt" style={{backgroundColor: 'var(--ter-blue)', 
                      color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                      
                    </Grid>
                  </Grid>
                </Grid>}
                {(session?.url_zoom || session?.recording_url)&&
                  <Grid item md={2.9} xs={5.84}
                  sx={{backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px'}}>
                    <Grid
                    container
                    direction={"row"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height:'100%'
                    }}>
                      {!session?.recording_url ? 
                            <Grid
                              item
                              xs={8}
                              sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Stack direction={"column"}>
                                <Info>Zoom Info</Info>
                                {session?.meeting_id &&
                                    <div style={{display:'flex'}}>
                                        <Info>ID: </Info> <CardText>{session?.meeting_id}</CardText>
                                    </div>
                                }
                                {session?.passcode &&
                                    <div style={{display:'flex'}}>
                                        <Info>Clave: </Info> <CardText>{session?.passcode}</CardText>
                                    </div>
                                }
                                <Link to={session?.url_zoom} 
                                style={{textDecoration:'none'}}>
                                    <Button variant='outlined' size='small'
                                    sx={{borderRadius: '10px', marginTop: '5px'}}
                                    endIcon={<ArrowForwardIcon />}>
                                        {`Ingresar`}
                                    </Button>
                                </Link>
                                </Stack>
                            </Grid> : 
                            <Grid
                            item
                            xs={8}
                            sx={{ display: "flex", justifyContent: "flex-start" }}>
                              <Stack direction={"column"}>
                              <Info>Grabación</Info>
                              {session?.recording_url &&
                              <>
                                <Link to={session?.recording_url} 
                                style={{textDecoration:'none'}} target='_blank'>
                                    <Button variant='outlined'
                                    endIcon={<ArrowForwardRoundedIcon />}
                                    sx={{borderRadius: '10px'}} size='small'>
                                      {`Ver Grabación`}
                                    </Button>
                                </Link>
                                {attendanceMsg === "Inasistente" &&
                                <Button variant='outlined' color='success' onClick={() => handleCheckAttendance(session.id)}
                                endIcon={<CheckCircleOutlineRoundedIcon />}
                                sx={{borderRadius: '10px', marginTop: 1}} size='small'>
                                  {`Marcar como vista`}
                                </Button>}
                              </>
                              }
                              </Stack>
                          </Grid>}
                      <Grid item xs={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                          <i class="fas fa-video" style={{backgroundColor: 'var(--ter-blue)', 
                          color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i> 
                        
                      </Grid>
                    </Grid>
                  </Grid>
                }
                <Grid item md={2.9} xs={5.84}
                sx={{backgroundColor: '#fff', padding: '10px 15px', borderRadius: '10px'}}>
                  <Grid
                  container
                  direction={"row"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height:'100%'
                  }}>
                    <Grid
                      item
                      xs={9}
                      sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                        <Info>Asistencia</Info>
                        {attendanceMsg === "Confirmar Asistencia" ? 
                        <Button endIcon={<ArrowForwardRoundedIcon />}
                        onClick={() => handleConfirmSingleAttendance(session?.id, teacherAttendance?.attendance_status)}
                        variant='outlined' sx={{borderRadius: '10px'}}
                        size='small'>
                          {attendanceMsg}
                        </Button> :
                        <Chip
                        label={attendanceMsg}
                        sx={{
                          backgroundColor:chipColor,
                          textTransform: "capitalize",
                          width: "fit-content",
                          margin: 0,
                          height: 25,
                          borderRadius: "10px",
                          fontWeight: "bold",
                          color: textColor,
                          fontSize: '11px',
                          
                        }}
                      />
                        }
                      </Stack>
                    </Grid>
                    <Grid item xs={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                        <i class="fas fa-clipboard" style={{backgroundColor: 'var(--ter-blue)', 
                        color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {session.description &&
              <Box sx={{backgroundColor: '#fff', padding: '10px 15px', 
              borderRadius: '10px', marginTop: '15px'}}>
                <Info>Descripción</Info>
                <Text>{session.description}</Text>
              </Box>}
              {session?.materials && 
              <Box sx={{backgroundColor: '#fff', padding: '10px 15px', 
              borderRadius: '10px', marginTop: '15px'}}>
                <Info>Material Complementario</Info>
              </Box>}
            </Box>
          </TabPanel>
          )
        })}
    </TabContext>
  )
}

export default SessionsComp
