export const timestampFormatter = (userLastLogin) => {
  const now = new Date();
  const nowTimeStamp = now.getTime()
  const miliSecondsDiff = Math.abs(nowTimeStamp - userLastLogin); //obtener la diferencia del tiempo actual con el last login
  const secondsDiff = Math.round(miliSecondsDiff / 1000); //convertir la diferencia a segundos

  //cacular los dias, horas, min y seg del last login
  var days = Math.floor(secondsDiff / (3600 * 24));
  var hours = Math.floor((secondsDiff % (3600 * 24)) / 3600);
  var minutes = Math.floor((secondsDiff % 3600) / 60);
  var seconds = Math.floor(secondsDiff % 60);

  var daysDisplay = days > 0 ? days + (days === 1 ? " día, " : " días, ") : "";
  var hoursDisplay =
    hours > 0 ? hours + (hours === 1 ? " hora" : " horas") : "";
  var minutesDisplay =
    minutes > 0 ? minutes + (minutes === 1 ? " minuto" : " minutos") : "";
  var secondsDisplay =
    seconds > 0 ? seconds + (seconds === 1 ? " segundo" : " segundos") : "";

    let totalTimeDiff
      if(daysDisplay !== "" && hoursDisplay !== ""){
        totalTimeDiff = daysDisplay + hoursDisplay
      }else if(daysDisplay === "" && hoursDisplay !== ""){
        totalTimeDiff =hoursDisplay+", " + minutesDisplay
      }else if(daysDisplay !== "" && hoursDisplay === "" ){
        totalTimeDiff = daysDisplay
      }
      else if(daysDisplay === "" && hoursDisplay === "" && minutesDisplay !== ""){
        totalTimeDiff = minutesDisplay
      }else if(daysDisplay === "" && hoursDisplay === "" && minutesDisplay === "" && secondsDisplay !== ""){
        totalTimeDiff =secondsDisplay
      }
  return totalTimeDiff;
};

export const timeLeftFormatter = (event_start_date, event_end_date) => {
  const start_date = new Date();
  const start_time = start_date?.getTime();
  const end_date = new Date(event_end_date);
  const end_time = end_date?.getTime();
  const miliSecondsDiff = Math.abs(end_time - start_time);
  const secondsDiff = Math.round(miliSecondsDiff / 1000);

  var days = Math.floor(secondsDiff / (3600 * 24));
  var hours = Math.floor((secondsDiff % (3600 * 24)) / 3600);
  var minutes = Math.floor((secondsDiff % 3600) / 60);
  var seconds = Math.floor(secondsDiff % 60);

  var daysDisplay =
    days > 0 ? days + (days === 1 ? " día " : " días ") : "";
  var hoursDisplay =
    hours > 0 ? hours + (hours === 1 ? " hora" : " horas") : "";
  var minutesDisplay =
    minutes > 0
      ? minutes + (minutes === 1 ? " minuto" : " minutos")
      : "";
  var secondsDisplay =
    seconds > 0
      ? seconds + (seconds === 1 ? " segundo" : " segundos")
      : "";

  let totalTimeDiff =`${daysDisplay} ${!daysDisplay ? hoursDisplay : ''} ${(!daysDisplay && !hoursDisplay)? minutesDisplay : ''}`;

  return totalTimeDiff;
};

export const timeFormatter = (userLastLogin) => {
  const now = new Date();
  const nowTimeStamp = now.getTime();
  const miliSecondsDiff = Math.abs(nowTimeStamp - userLastLogin);
  const secondsDiff = Math.round(miliSecondsDiff / 1000);

  // Calcular los días, horas, minutos y segundos desde el último login
  var days = Math.floor(secondsDiff / (3600 * 24));
  
  if (days > 1) {
    const lastLoginDate = new Date(userLastLogin);
    const currentYear = new Date().getFullYear();
    const lastLoginYear = lastLoginDate.getFullYear();

    // Configurar las opciones de fecha con o sin el año según si es distinto al año actual
    const dateOptions = {
      weekday: 'long', // Día de la semana (Lunes, Martes, etc.)
      day: 'numeric', // Día del mes
      month: 'long', // Mes escrito completamente
      year: lastLoginYear !== currentYear ? 'numeric' : undefined // Año completo si es diferente al actual
    };

    const formattedDate = lastLoginDate.toLocaleDateString('es-ES', dateOptions);
    return formattedDate;  // Retorna la fecha en el formato deseado
  } else {
    var hours = Math.floor((secondsDiff % (3600 * 24)) / 3600);
    var minutes = Math.floor((secondsDiff % 3600) / 60);
    var seconds = secondsDiff % 60;

    var hoursDisplay = hours > 0 ? hours + (hours === 1 ? " hora, " : " horas, ") : "";
    var minutesDisplay = minutes > 0 ? minutes + (minutes === 1 ? " minuto, " : " minutos, ") : "";
    var secondsDisplay = (minutes === 0 && seconds > 0) ? seconds + (seconds === 1 ? " segun" : " segun") : "";

    // Concatenar las partes del tiempo para mostrar solo las relevantes
    return (hoursDisplay + minutesDisplay + secondsDisplay).slice(0, -2); // Elimina la última coma y espacio si está presente
  }
};

