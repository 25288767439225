import React, {useContext} from "react";
import styles from "./SettingsPage.module.css";
import CardSetting from "../components/Card/CardSetting";
import Container from "../components/Container";
import { SEGUIMIENTO_OPTIONS, COORDI_ACADEMIC_OPTIONS } from "../utils/constants/seguimientoConstants";
import { CurrentUserContext } from "../context/CurrentUserProvider";

function SeguimientoPage() {
  const { currentUser } = useContext(CurrentUserContext);
  let options = (currentUser?.role === "academic_coordination" || currentUser?.role === "academy_coordination") ? COORDI_ACADEMIC_OPTIONS : SEGUIMIENTO_OPTIONS
  
  return (
    <div style={{ marginRight: "2rem" }}>
      <Container direction={"row"}>
        <div className={styles.content}>
          {options?.map((option) => {
            return (
              <CardSetting
                className={"data-table"}
                data={option}
                key={option.name}
                usersOption={""}
                type={"settingsCard"}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
}

export default SeguimientoPage;
