import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import styles from "./ListCapacitaciones.module.css";
import { QUIZ_ENDPOINT } from "../../../utils/constants";
import { get } from "../../../utils/http";
import Container from "../../Container";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function QuicesList() {
  const [quices, setQuices] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuices = async () => {
      const url = `${QUIZ_ENDPOINT}`;
      const response = await get({ url });
      setQuices(response.data);
    };
    fetchQuices();
  }, []);

  const columns = [
    {
      name: "Descripción",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Puntuación máxima",
      selector: (row) => row.estimated_grade,
      sortable: true,
    },
    
  ];
  
  return (
    <Container>
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles.table}>
          <DataTable
            columns={columns}
            data={quices}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight="500px"
            pointerOnHover
            noDataComponent="No hay preguntas para mostrar"
          />
        </div>
      </div>
    </Container>
  )
}

export default QuicesList
