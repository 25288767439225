import React, {useState, useContext, useEffect} from 'react'
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Container from '../../Container';
import { QUESTIONS_FORM_ENDPOINT } from '../../../utils/constants';
import { get } from '../../../utils/http';
import { MonitorFormsContext } from '../../../context/MonitorFormsContext';
import styles from './Questions.module.css'
import { CurrentUserContext } from '../../../context/CurrentUserProvider';

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
  header: {
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  cells: {
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
};

function Questions() {
  const [questions, setQuestions] = useState([])
  const [filterSearch, setFilterSearch] = useState("External")
  const [filterArea, setFilterArea] = useState("")
  const {editQuestion} = useContext(MonitorFormsContext)
  const {currentUser} = useContext(CurrentUserContext)

  const filteredQuestions = questions.filter((item) => 
    item.type.includes(filterSearch) && item.area.includes(filterArea) )

  const handleChangeChecked = (value) => {
    const dataChange = {
      is_active: !value
    }
    editQuestion({data: dataChange})
  }

  const columns = [
    {
      name: "Pregunta",
      selector: (row) => row.content,
      sortable: true,
      cell: (cell) => {
        return(
          <div style={{width: '300px', height: 'fit-content', textAlign: 'start'}}>
            {cell.content}
          </div>
        )
      }
      
    },
    {
      name: "Tipo",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Peso",
      selector: (row) => row.weight,
      sortable: true,
      cell: (cell) => {
        return(
          <div style={{width: '300px', height: 'fit-content'}}>
            {`${cell.weight}%`}
          </div>
        )
      }
    },
    
  ];

  const columns_coordinador = [
    {
      name: "Pregunta",
      selector: (row) => row.content,
      sortable: true,
      cell: (cell) => {
        return(
          <div style={{width: '300px', height: 'fit-content', textAlign: 'start'}}>
            {cell.content}
          </div>
        )
      }
      
    },
    {
      name: "Tipo",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Peso",
      selector: (row) => row.weight,
      sortable: true,
      cell: (cell) => {
        return(
          <div style={{width: '300px', height: 'fit-content'}}>
            {`${cell.weight}%`}
          </div>
        )
      }
    },
    {
      name: "Estado",
      selector: (row) => row.is_active,
      cell: (cell) => {
        return(
          <Switch
          checked={cell.is_active}
          onChange={() => handleChangeChecked(cell.is_active)}
          onColor="#87be87"
          onHandleColor="#43b02a"
          handleDiameter={22}
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
          className="react-switch"
          id="material-switch"
          />
        )
      }
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      const url = QUESTIONS_FORM_ENDPOINT;
      const response = await get({ url });
  
      if (!response.error) {
        setQuestions(response.data);
      }
    };
    fetchQuestions();
  }, []);

  const sortedQuestions = filteredQuestions && filteredQuestions?.sort((a, b) => {
    return a.area.localeCompare(b.area);
  });

  const groupedData = sortedQuestions && sortedQuestions?.reduce((acc, item) => {
    if (!acc[item.area]) {
      acc[item.area] = [];
    }
    acc[item.area].push(item);
    return acc;
  }, {});

  return (
    <div style={{width: '100%'}}>
      <Container direction={"column"}>
        <div className={styles.content}>
      <i
        className="fal fa-arrow-left"
        style={{ fontSize: "12px", cursor: "pointer", 
        padding: '10px 0 0px 20px' }}
        onClick={() => navigate(-1)}
      ></i>
      <div className={styles.search_bar}>
        <select name="type" value={filterSearch}
        className={styles.filter_text}
          onChange={(e) => (setFilterSearch(e.target.value))}>
          <option value="">Filtrar por tipo de docente</option>
          <option value="Internal">Docente interno</option>
          <option value="External">Docente externo</option>
        </select>
        <select name="type" value={filterArea}
        className={styles.filter_text}
          onChange={(e) => (setFilterArea(e.target.value))}>
          <option value="">Filtrar por área</option>
          <option value="Docente">Docente</option>
          <option value="Estudiante">Estudiantes</option>
          <option value="Materiales">Materiales</option>
          <option value="Requerimientos">Requerimientos</option>
          <option value="Internos">Internos</option>
        </select>
      </div>
        {Object.entries(groupedData).map(([area, items]) => {
          let weightTotal = 0
          items.forEach((item) => {
            weightTotal += item.weight
          })
          return(
            <div style={{margin: '10px 20px 0 20px'}}>
              <p 
              style={{fontSize: '1.8rem',
              color: '#000', fontWeight: 'bold', marginBottom: '1rem'}}>
                {area}
              </p>
              <div style={{borderRadius: '10px'}}>
                <DataTable
                columns={currentUser.role === 'monitoring_coordinator' ? columns_coordinador : columns}
                data={items}
                customStyles={customStyles}
                sortIcon={
                  <i
                    className="far fa-arrow-up"
                    style={{ marginLeft: "5px", fontSize: "14px" }}
                  ></i>
                }
                highlightOnHover
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="300px"
              />
              </div>
              <div className={styles.questions_total}>
                <div style={{display: 'flex', flexDirection: 'row', 
                alignItems: 'center'}}>
                  <p style={{fontWeight: 'bold', fontSize: '12px'}}>
                    Cantidad de preguntas:
                  </p>
                  <p style={{textAlign: 'start', fontSize: '14px', paddingLeft: '5px', paddingRight: '10px'}}>
                    {items.length}
                  </p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', 
                marginLeft: 'auto', alignItems: 'center'}}>
                  <p style={{fontWeight: 'bold', fontSize: '12px'}}>
                  Peso total de las preguntas: 
                  </p>
                  <p style={{textAlign: 'start', fontSize: '14px', paddingLeft: '5px'}}>
                  {weightTotal?.toFixed(2)}%
                  </p>
                </div>
              </div>
            </div>
          )
        })}
        </div>
      </Container>
    </div>
  )
}

export default Questions
