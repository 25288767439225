import React, { useEffect, useContext } from "react";
import { Link} from 'react-router-dom'
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from "@mui/material/ListItemText"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Avatar from "@mui/material/Avatar"
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import styles from './MobileMenu.module.css'
import { DRAWER_OPTIONS,
  DRAWER_TEACHER_COORDINATOR,
  DRAWER_OPTIONS_TEACHERS,
  DRAWER_OPTIONS_TEACHER_INTERNAL,
  DRAWER_OPTIONS_SEGUIMIENTO,
  DRAWER_OPTIONS_STUDENTS, 
  DRAWER_OPTIONS_DEVELOPMENT_TEACHER_INTERNAL} from "./constants";

function MobileMenu({menuOpen, setMenuOpen, userAct}) {
    const { logoutUser, currentUser } = useContext(CurrentUserContext);

    const logOut = () => {
      setMenuOpen(false)
      logoutUser()
    }

    useEffect(() => {
      const scrollToTop = () => {
        window.scrollTo(0, 0);
      };
  
      scrollToTop();
    }, []);

    let user_drawer_options;

    if (currentUser.role === "IT") {
      user_drawer_options = DRAWER_OPTIONS;
    } 
    else if (
      currentUser.role === "monitoring_coordinator" ||
      currentUser.role === "monitoring_teacher" ||
      currentUser?.role === "monitoring_teacher_3"
    ) {
      user_drawer_options = DRAWER_OPTIONS_SEGUIMIENTO;
    } 
    
    else if (currentUser.role === "external_coordinator" ) {
      user_drawer_options = DRAWER_TEACHER_COORDINATOR;
    } 
    else if (currentUser.role === "internal_teacher" ){
      user_drawer_options = DRAWER_OPTIONS_TEACHER_INTERNAL;
    }
    else if (currentUser.role === "development_teacher" ){
      user_drawer_options = DRAWER_OPTIONS_DEVELOPMENT_TEACHER_INTERNAL;
    }
    else {
      user_drawer_options = DRAWER_OPTIONS_TEACHERS;
    }

  return (
    <Drawer
      open={menuOpen}
      anchor="right"
      onClose={() => setMenuOpen(false)}
      sx={{
        display: "block",
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: 240,
          background: "var(--main-blue)",
        },
      }}
      >
        <Link to="/profile"
        style={{textDecoration: 'none', color: '#fff'}}  onClick={() => setMenuOpen(false)}>
          <Card elevation={1}
            sx={{ maxWidth: 250, margin: "30px 15px 20px 15px", 
            borderRadius: "15px", background: 'rgb(104,91,199, .4)', 
            color: '#fff'}}
            raised>
            <CardHeader
              avatar={
                <Avatar
                  src={userAct?.img_profile || null}
                  aria-label="recipe"
                />
              }
              title={`${currentUser["last_name"]}, ${currentUser["first_name"]}`}
              titleTypographyProps={{ variant: 'h6' }}
            />
          </Card>
        </Link>
      <List >
        {user_drawer_options?.map((op, i) => (
              <Link to={op.route} key={i}
              style={{textDecoration: 'none', color: '#fff', 
              display: 'flex', alignItems: 'center'}} onClick={() => setMenuOpen(false)}>
                <ListItemButton key={op.name}>
                  <ListItemIcon sx={{justifyContent:'center', display: 'flex'}}>
                    <span className={styles.icon2}>{op.icon}</span>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="h6">
                    {`${op.name}`}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </Link>
        ))}
          <Link to="/" onClick={() => logOut()} style={{textDecoration: 'none', color: '#fff', 
              display: 'flex', alignItems: 'center'}}>
              <ListItemButton>
                <ListItemIcon sx={{justifyContent:'center', display: 'flex'}}>
                  <span className={styles.icon2}>
                    <i className="far fa-sign-out"></i>
                  </span>
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="h6">
                    {`Salir`}
                    </Typography>
                    </ListItemText>
              </ListItemButton>
            </Link>
      </List>
    </Drawer>
  )
}

export default MobileMenu
