import React, {useState, useEffect, useContext} from 'react'
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { CircularProgress, Typography } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import { AnnualTrainingsContext } from '../../../context/AnnualTrainingsContext';
import IndicatorsModal from './IndicatorsModal';

const options = ["Docente", "Institución", "Asistencia", "Comentario", "Indicadores"]

const TableColumns = () => {
  return options.map((column) => {
    if (column === "Docente" ) {
      return (
        <TableCell
          key={column}
          sx={{
            color: "#8C8C8C",
            fontSize: "10.4px",
            fontWeight: "bold",
            background: "#ffff",
            textTransform: 'uppercase'
          }}>
          {column}
        </TableCell>
      )
    }
    return (
      <TableCell
        key={column}
        align="center"
        sx={{
          color: "#8C8C8C",
          fontSize: "10.4px",
          fontWeight: "bold",
          background: "#ffff",
          textTransform: 'uppercase'
        }}>
        {column}
      </TableCell>
    )
  })
}

const CircularProgressWithLabel = ({ value, status }) => {
  const getColor = (status) => {
    switch (status) {
      case 'pending':
        return '#00a3e0'; // Azul para pending
      case 'attended':
        return '#4caf50';
      case 'video':
        return '#4caf50';
      case 'absent':
        return '#d30019'; 
      default:
        return '#330072'; // Default color
    }
  };
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress size={110} variant="determinate" value={value} sx={{ color: getColor(status),
          '& .MuiCircularProgress-circle': {
            strokeLinecap: 'round', // Curva los bordes
          },
          '& .MuiCircularProgress-root': {
            background: '#f0f0f0', 
          }
        }} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" component="div" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const calculatePercentages = (teachers) => {
  const totalTeachers = teachers.length;
  const pendingCount = teachers.filter(t => t.attendance_status === 'pending').length;
  const attendedCount = teachers.filter(t => t.attendance_status === 'attended').length;
  const absentCount = teachers.filter(t => t.attendance_status === 'absent').length;

  const pendingPercentage = (pendingCount / totalTeachers) * 100;
  const attendedPercentage = (attendedCount / totalTeachers) * 100;
  const absentPercentage = (absentCount / totalTeachers) * 100;

  return { pendingPercentage, attendedPercentage, absentPercentage };
};

function AttendanceList({ teachers, sessionId}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [markAttendance, setMarkAttendance] = useState(false)
  const [filterText, setFilterText] = useState("");
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [openIndicatorsModal, setOpenIndicatorsModal] = useState(false)
  const [teacherSelected, setTeacherSelected] = useState({})
  const {createBulkAttendance, sendAttendanceIndicators, fetchAnnualTrainingAttendance} = useContext(AnnualTrainingsContext)
  const { pendingPercentage, attendedPercentage, absentPercentage } = calculatePercentages(teachers);

  useEffect(() => {
    fetchAnnualTrainingAttendance(sessionId)
  }, [sessionId]);
  
  useEffect(() => {
    const attendedRows = teachers
      .filter(item => item.attendance_status === 'attended')
      .map(item => ({ teacher: item.id, status: 'attended', session: sessionId }));
    setSelectedRows(attendedRows);
  }, [teachers]);

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  const handleSelectRow = (id) => {
    setSelectedRows((prev) => {
      const isSelected = prev.some((row) => row.teacher === id);
      if (isSelected) {
        return prev.map((row) =>
          row.teacher === id ? { ...row, status: 'absent' } : row
        );
      } else {
        return [...prev, { teacher: id, status: 'attended', session: sessionId }];
      }
    });
  };

  const handleSendSelected = () => {
    createBulkAttendance(selectedRows, sessionId)
    handleCancelMarkAttendance()
  };

  const handleSelectTeacher = (teacherId) => {
    setTeacherSelected(teacherId)
    setOpenIndicatorsModal(!openIndicatorsModal)
  }

  const handleMarkAttendance = () => {
    setMarkAttendance(true)
  }

  const handleCancelMarkAttendance = () => {
    setMarkAttendance(false)
  }

  const showPendding = (pendingPercentage === 0 && attendedPercentage > 0) ? false : true 

  teachers?.sort((a, b) => {
    const nameA =`${a.first_name} ${a.last_name}`
    const nameB =`${b.first_name} ${b.last_name}`
    return nameA.localeCompare(nameB)
  })

  const filteredData = teachers?.filter(item => {
    const name =`${item?.first_name} ${item?.last_name}`
    const isNameMatch = name?.toLowerCase().includes(filterText?.toLowerCase());
    const isInstitutionMatch = item?.institutions[0]?.toLowerCase().includes(filterText?.toLowerCase());

    const isStatusMatch = filterStatus === "ALL" || item?.attendance_status === filterStatus;

    return (isNameMatch || isInstitutionMatch) && isStatusMatch
  });

  const opt = [
    {
      label: "Todos",
      value: "ALL",
    },
    {
      label: "Confirmado",
      value: "pending",
    },
    {
      label: "Pendiente",
      value: null,
    },
    {
      label: "Inasistente",
      value: "absent",
    },
    {
      label: "Asistente",
      value: "attended",
    },
    {
      label: "Asistente por Video",
      value: "video",
    },
  ]

  return (
      <Box sx={{minWidth: isDesktop && '500px'}}>
        <Box sx={{display: 'flex'}}>
          <h2>Asistencia</h2>
          {!markAttendance &&
          <Box sx={{ marginLeft: 'auto'}}>
            <Button variant='contained' size='large'
            onClick={handleMarkAttendance}
            sx={{borderRadius: '10px', marginTop: '10px'}}>
              Marcar Asistencia
            </Button>
          </Box>
          }
        </Box>
        <Box sx={{display:'flex', gap: '15px', marginBottom: '15px'}}>
        <Box sx={{backgroundColor: '#fff', 
        padding: '10px', display:'flex', 
        flexDirection: 'column',
           justifyContent:'center', alignItems:'center',
          borderRadius: '10px', width: '180px'}}>
            <Typography variant='h2' sx={{ marginTop: 'auto'}}>{teachers.length}</Typography>
            <p style={{fontSize: '14px', textAlign: 'center', paddingTop: '10px', marginTop: 'auto'}}>
              Docentes Inscritos
            </p>
          </Box>
          {showPendding && 
          <Box sx={{backgroundColor: '#fff', padding: '10px', display:'flex', flexDirection: 'column',
           justifyContent:'center', alignItems:'center',
          borderRadius: '10px', width: '180px'}}>
            <CircularProgressWithLabel value={pendingPercentage || 0} status={"pending"} />
            <p style={{fontSize: '14px', textAlign: 'center', paddingTop: '10px'}}>Docentes Confirmados</p>
          </Box>}
          {attendedPercentage > 0 && 
          <Box sx={{backgroundColor: '#fff', padding: '10px', display:'flex', flexDirection: 'column',
           justifyContent:'center', alignItems:'center',
            borderRadius: '10px', width: '180px'}}>
            <CircularProgressWithLabel value={attendedPercentage || 0} status={"attended"}  />
            <p style={{fontSize: '14px', textAlign: 'center', paddingTop: '10px'}}>Docentes Asistentes</p>
          </Box>}
          {absentPercentage > 0 && 
          <Box sx={{backgroundColor: '#fff', padding: '10px', display:'flex', flexDirection: 'column',
           justifyContent:'center', alignItems:'center',
            borderRadius: '10px', width: '180px'}}>
            <CircularProgressWithLabel value={absentPercentage} status={"absent"} />
            <p style={{fontSize: '14px', textAlign: 'center', paddingTop: '10px'}}>Docentes Inasistentes</p>
          </Box>}
          
        </Box>
        <Box sx={{ marginLeft: 'auto', width:'100%', display:'flex', justifyContent:'flex-end', marginBottom: '10px'}}>
          <TextField id="outlined-basic" 
          placeholder="Buscar por nombre del docente o institución"
          variant="outlined" size='small'
          sx={{width: isDesktop ? '35%' : '100%', 
            '& .MuiOutlinedInput-root': { borderRadius: '10px', backgroundColor: '#fff' }}}
          onChange={(e) => setFilterText(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon />
              </InputAdornment>
            ),
          }} />
          <FormControl 
            variant="outlined" 
            size="small" 
            sx={{
              width:'200px',
              marginLeft: '10px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                backgroundColor: '#fff',
              },
            }}
          >
            <InputLabel id="filter-status-label">Filtro por Asistencia</InputLabel>
            <Select
              labelId="filter-status-label"
              label="Estado"
              name="status"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ccc',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#999',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#333',
                },
              }}
            >
              {opt?.map((option, i) => (
                <MenuItem key={i} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ overflow: "scroll", maxHeight: "500px", width: '100%', borderRadius: '10px' }}>
          <Table size="small" aria-label="sales order table" stickyHeader>
            <TableHead sx={{ border: "none", height: '45px'}}>
              <TableRow sx={{ border: "none" }}>
                <TableColumns />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((item, i) => {
                let color = ''
                let att = ''
                switch (item?.attendance_status) {
                  case 'pending':
                    att = "Confirmado"
                    color = '#00a3e0'
                    break
                  case null:
                    att = "Pendiente"
                    color = '#fcb514'
                    break
                  case 'absent':
                    att = "Inasistente"
                    color = '#d30019'
                    break
                  case 'attended':
                    att = "Asistente"
                    color = '#43b02a'
                    break
                  case 'video':
                      att = "Asistente por Video"
                      color = '#43b02a'
                      break
                }
                const isChecked = !!selectedRows?.find(row => row.teacher === item.id && row.status === 'attended');
                return(
                  <TableRow
                  key={i}>
                    <TableCell component="th" scope="row" sx={{fontSize: '14px'}}>
                      {`${item?.first_name} ${item?.last_name}`}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '14px'}}>
                      {item?.institutions?.map((item) => (
                        <p>{item}</p>
                      ))}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '14px'}}>
                      {markAttendance ?
                        <Checkbox
                        checked={isChecked}
                        onChange={() => handleSelectRow(item.id)}
                        /> : 
                        <Chip label={att} sx={{backgroundColor: color, 
                          fontSize: '12px', color: '#fff', fontWeight: 'bold'}} />
                      }
                    </TableCell>
                    <TableCell align="center" >
                      <Button onClick={() => handleSelectTeacher(item.id)}
                      sx={{borderRadius: '10px', fontSize: '11px'}} variant='outlined'
                      size='small'>
                        Marcar Indicadores
                      </Button>
                    </TableCell>

                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
      </TableContainer>
      {markAttendance && 
        <Box sx={{width:'100%', display:'flex', 
        justifyContent:'center', gap: '10px', marginTop: 1}}>
          <Button variant='outlined' size='large'
          onClick={handleCancelMarkAttendance}
          sx={{borderRadius: '10px'}}>
            Cancelar
          </Button>
          <Button variant='contained' size='large'
          onClick={handleSendSelected}
          sx={{borderRadius: '10px'}}>
            Guardar
          </Button>
        </Box>
      }
      {openIndicatorsModal && <IndicatorsModal setOpenIndicatorsModal={setOpenIndicatorsModal}
      teacherId={teacherSelected} sessionId={sessionId} sendAttendanceIndicators={sendAttendanceIndicators} />}
      </Box>
  )
}

export default AttendanceList
