import React, { useState} from "react";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MyTextInput,
    MySubmitButton } from "../../FormFields";
import styles from './SectionsOptions.module.css'
import Alert from "../../Alert";

function SectionsOptions({ 
    userPermission, 
    createSection, sections, 
    handleChangeCurrentSection, currentSection, getSections, 
    courseId, deleteSection}) {
    const [addSection, setAddSection] = useState(false);
    const [showAlert, setShowAlter] = useState(false)

    const handleCreateSection = async (values) => {
      const registerData = {
        course: courseId,
        name: values.name.toUpperCase(),
      };
      const response = await createSection({ data: registerData });
      if (!response.erorr) {
        setAddSection(false);
        getSections();
      }
    };

    const handleDeleteSection = () => {
      const sectionId = currentSection.id
      deleteSection({ sectionId });
      getSections()
      setShowAlter(false)
    };

  return (
    <>
    <h2 style={{marginBottom: '10px', marginTop: 0}}>Secciones</h2>
      <div className={styles.options}>
        <div style={{ display: 'flex', flexDirection: 'column'}}> 
        {addSection && userPermission && (
          <div className={styles.sections}>
            <Formik
              initialValues={{
                name: "",
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .min(1, "El campo no puede estar vacío.")
                  .required(""),
              })}
              onSubmit={(values) => {
                handleCreateSection(values);
              }}
            >
              <Form className={styles["form__fields"]}>
                <div className={styles.sections}>
                  <div className={styles.input_section}>
                    <MyTextInput name="name" type="text" placeholder="Nombre" />
                  </div>
                  <div className={styles.inputs}>
                    <MySubmitButton
                      name="Aceptar"
                      type="submit"
                      icon="fas fa-check"
                    />
                    <MySubmitButton
                      name="Cancelar"
                      icon="far fa-times"
                      onClick={() => setAddSection(false)}
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        )}
        <div style={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center'}}>
        {sections.map((section) => {
          return (
            <div
              onClick={() => handleChangeCurrentSection(section)}
              className={
                currentSection?.id === section.id ? styles.option2 : styles.option
              }
              key={section.id}
            >
              <p>{section.name.toUpperCase()}</p>
            </div>
          );
        })}
        {!addSection && userPermission && (
          <div className={styles.add} onClick={() => setAddSection(true)}>
            <i
              className="far fa-plus"
              style={{ fontSize: "20px", color: "var(--main-white)" }}
            ></i>
            <p style={{color:'#fff'}}>Agregar Sección</p>
          </div>
        )}
        {userPermission && 
        <i className="fal fa-trash-alt" 
        onClick={() => setShowAlter(true)}
        style={{fontSize: '20px', 
        marginLeft: '5px', 
        color: 'var(--sec-red)', 
        cursor: 'pointer'}}></i>}
        </div>
        </div>
    </div>
    {showAlert &&
        <Alert
        cancelAlert={setShowAlter}
        acceptAlert={() => handleDeleteSection()}
        >
          <div 
          style={{display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          width: '280px', 
          textAlign: 'center'}}>
            <p style={{ fontSize: '18px'}}>
              {`Estas seguro que quieres eliminar la sección ${currentSection.name}?`}
            </p>
          </div>
    </Alert>}
    </>
  )
}

export default SectionsOptions
