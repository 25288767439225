import React from 'react'
import ReactDOM from "react-dom";
import styles from "./Alert.module.css";

const Backdrop = () => {
    return <div className={styles.backdrop}></div>;
};

const AlertOverlay = ({ children, cancelAlert, acceptAlert, }) => {
    return (
      <div className={styles.alert}>
        <div className={styles["alert-content"]}>{children}</div>
          <div className={styles["alert-action"]}>
            <div onClick={() => cancelAlert(false)}>Cancelar</div>
            <div onClick={acceptAlert}>Aceptar</div>
          </div>
      </div>
    );
};

function Alert({children, acceptAlert, cancelAlert}) {
    const portalElement = document.getElementById("overlays");
    return (
      <>
        {ReactDOM.createPortal(<Backdrop />, portalElement)}
        {ReactDOM.createPortal(
          <AlertOverlay
            cancelAlert={cancelAlert}
            acceptAlert={acceptAlert}
          >
            {children}
          </AlertOverlay>,
          portalElement
        )}
      </>
    );
}

export default Alert
