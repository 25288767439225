import React, { useState, useEffect, useContext } from 'react'
import ReactDOM from "react-dom";
import { Grid, Stack, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NextSessionComp from './NextSessionComp';
import { ANNUAL_CERTIFICATE_USER_ENDPOINT } from '../../../utils/constants';
import { get } from '../../../utils/http';
import { AnnualTrainingsContext } from '../../../context/AnnualTrainingsContext';
import AnualTrainingCertificates from '../../Certificates/AnualTrainingCertificates';

const Title2 = styled('p')({
    fontSize: '1.4rem',
    fontWeight:'bold',
    marginBottom: '5px'
});
const Info = styled('p')({
    fontSize: '1.2rem',
    color: '#787878',
});

function RightSideComponent({
    nextSessionsFilter, 
    userAnnualTraining, 
    currentUser, trainingId, userId}) {
  const {updateUserCertificate} = useContext(AnnualTrainingsContext)
  
  const [certificateStatus, setCertificateStatus] = useState(null)

  const getCertificateStatus = async () => {
    const url = `${ANNUAL_CERTIFICATE_USER_ENDPOINT}?user=${userId}&annualtraining=${trainingId}`;
    const response = await get({ url });
    if (!response.error) {
      setCertificateStatus(response.data[0]);
    }
  };

  useEffect(() => {
    getCertificateStatus();
  }, [userId,trainingId ]);

  const handleUpdateCertificate = () => {
    const certificateId = certificateStatus?.id
      const data ={
        status: "downloaded"
      }
      if(certificateStatus?.status === "approved"){
        updateUserCertificate(certificateId, data)
        getCertificateStatus()
      }
  }

  const isQuizCompleated = (certificateStatus?.quiz === "N/A" || certificateStatus?.quiz !== "N/P") ? true : false

  const showCertificateSection = userAnnualTraining?.sessions?.length > 0 &&
    userAnnualTraining.sessions[0]?.teacher?.length > 0 && userAnnualTraining?.certificated

  const openCertificateWindow = () => {
    const newWindow = window.open("", "_blank");
      newWindow.document.title = "Certificado Capacitaciones Kurios";
      newWindow.document.body.innerHTML = `<div id="certificate-container"></div>`;
      const programValues = Object.values(userAnnualTraining?.programs);
      const stringProgramValues = programValues.join(', ');
      ReactDOM.render(
        <AnualTrainingCertificates person={currentUser} 
        program={stringProgramValues} 
        hours={userAnnualTraining?.training_hours}
        stage={userAnnualTraining?.stage} />,
        newWindow.document.getElementById("certificate-container")
      );
      handleUpdateCertificate()
  };

  return (
    <>
    {nextSessionsFilter?.length > 0 ?
          <>
          <Title2>Próximas sesiones</Title2>
          {nextSessionsFilter?.map((session, index) => {
            return(
              <NextSessionComp session={session} num={index} />
            )
          })}
          </> :
          <>
           {showCertificateSection &&
           <div style={{marginTop: '10px'}}>
           <Title2>Certificado</Title2>
            <Grid
              container
              direction={"row"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                padding:'10px',
                borderRadius: '10px'
              }}>
              <Grid
              item
              xs={9}
              sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Stack direction={"column"} spacing={1}>
                  <Info>Certificado</Info>
                  {(certificateStatus?.status === "approved" || 
                  certificateStatus?.status === "downloaded") ?
                  <Button variant='outlined' size='small'
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => openCertificateWindow()}
                  sx={{borderRadius: '10px', fontSize: '11px', marginTop: '5px'}}>
                    Ver Certificado
                  </Button> : 
                  <>
                {!certificateStatus?.nda_agreement &&
                  <p style={{color: 'red'}}>Para poder acceder al certificado debes de firmar el acuerdo de confidencialidad</p>
                }
                {(certificateStatus?.survey && isQuizCompleated) ?
                  <Chip
                  label={"Esperando Aprobación"}
                  sx={{
                    backgroundColor:"#FFFBE4",
                    textTransform: "capitalize",
                    width: "fit-content",
                    margin: 0,
                    height: 25,
                    borderRadius: "10px",
                    fontWeight: "bold",
                    color: '#F09624',
                    fontSize: '12px',
                    marginTop: '5px'
                  }} /> : 
                  <>
                    {!certificateStatus?.survey &&
                    <p style={{color: 'red'}}>Para poder acceder al certificado por favor contesta la encuesta</p>
                    }
                    {!isQuizCompleated &&
                    <p style={{color: 'red'}}>Para poder acceder al certificado por favor contesta el quiz</p>
                    }
                  </>
                  }
                  </>
                  }
                </Stack>
              </Grid>
              <Grid item xs={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                  <i class="fas fa-certificate" style={{backgroundColor: 'var(--ter-blue)', 
                color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
              </Grid>
            </Grid> </div>}
          </>
          }
      
    </>
  )
}

export default RightSideComponent
