import React from "react";
import { Link } from "react-router-dom";
import {IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from "./Contact.module.css";
import { useConversations } from "../../context/ConversationsProvider";
import { useCurrentUser } from "../../context/CurrentUserProvider";

const Contact = ({
  converId,
  firstName,
  lastName,
  notifications,
  typing,
  search,
  myInfo,
  lastMessage,
  institution,
  profilePic,
  selectConversationHandler,
  conversation,
  type
}) => {
  const { currentUser } = useCurrentUser();
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const {
    deleteRoom,
    fetchRooms
  } = useConversations();

  const deleteChatHandler = (roomId) => {
    deleteRoom({roomId})
    fetchRooms()
  };

  const userPermission =
    currentUser?.role === "IT" ||
    currentUser?.role === "	monitoring_coordinator" ||
    currentUser?.role === "monitoring_teacher"||
    currentUser?.role === "monitoring_teacher_3";

  let message = lastMessage?.message

  if(lastMessage?.message.length > 20){
    message = `${lastMessage?.message.slice(0, 20)}...`
  }

  const currentUserName = `${currentUser?.first_name} ${currentUser?.last_name}`

  const createdBy = lastMessage?.created_by?.split(' ');

  const createdByName = lastMessage?.created_by === currentUserName ? "Tú" : (createdBy && createdBy[0])

  return (
    <>
      <div className={`${styles.user} ${search ? styles.search : ""}`}>
        <div className={styles["user-info"]} style={{width: type==="conversation" && "100%"}}
        onClick={() => selectConversationHandler(conversation)}>
        {profilePic ? 
          <img src={profilePic} alt="profile"
          style={{borderRadius: '50%', width: '3.7rem', height: '3.7rem'}}/> :
          <div className={styles["user-img"]}>
            <p style={{fontSize: '14px'}}>{firstName?.charAt(0)}</p>
          </div>
        }
          
          {/*{!search && (
            <div
              className={`${styles.status} ${
                status === "online" ? styles.online : status.offline
              }`}
            ></div>
            )}*/}
          <div className={styles["user-col"]}>
            <p
              className={styles["user-name"]}
            >{`${firstName} ${lastName}`}</p>
            {lastMessage && 
            <p className={lastMessage ? (notifications > 0 ? styles["user-message"] : styles["user-message2"]) : styles["user-status"]}>
              <span style={{fontWeight:'bold'}}>{`${createdByName}: `}</span>{`${message}`}
            </p>}
            <Link to={`/institutions/${institution?.id}`} 
            style={{textDecoration: 'none', color: '#858585'}}>
            <p style={{color: 'var(--ter-blue)', fontSize:'14px'}}>
              {institution?.name}
            </p>
            </Link>
          </div>
          <div className={styles.activity}>
            {notifications > 0 && (
              <div className={styles.notifications}>
                <p>{notifications}</p>
              </div>
            )}
            <div>{typing && "typing..."}</div>
          </div>
        </div>

          {(!myInfo && userPermission) && (
            <>
            <IconButton id="basic-button2"  onClick={handleClick2}
            size="small" sx={{height: '26.8px', marginLeft: 'auto'}}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu2"
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              elevation={1}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => deleteChatHandler(converId)}>
                Eliminar conversación
              </MenuItem>
            </Menu>
          </>
          )}
      </div>
    </>
  );
};

export default Contact;
