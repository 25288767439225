export const versionRules = [
    {
        program: 'B-Block',
        versions: [
            {
                number: '0.1',
                age: '9 meses - 1 año'
            },
            {
                number: '0.2',
                age: '1 - 2 años'
            }
        ]
    },
    {
        program: 'BB-Block I',
        versions: [
            {
                number: '1.1',
                age: '1 - 2 años'
            },
            {
                number: '1.2',
                age: '2 - 3 años'
            },
            {
                number: '1.3',
                age: '3 - 4 años'
            }
        ]
    },
    {
        program: 'BB-Block II',
        versions: [
            {
                number: '2.1',
                age: '3 - 4 años'
            },
            {
                number: '2.2',
                age: '4 - 5 años'
            }
        ]
    },
    {
        program: 'BB-Block III',
        versions: [
            {
                number: '3.1',
                age: '4 - 5 años'
            },
            {
                number: '3.2',
                age: '5 - 6 años'
            }
        ]
    }, 
    {
        program: 'Preescolar MOR',
        versions: [
            {
                number: 'BB-Block I 1.1',
                age: '1 - 2 años'
            },
            {
                number: 'BB-Block I 1.2',
                age: '2 - 3 años'
            },
            {
                number: 'BB-Block I 1.3',
                age: '3 - 4 años'
            },
            {
                number: 'B-Block 0.1',
                age: '9 meses - 1 año'
            },
            {
                number: 'B-Block 0.2',
                age: '1 - 2 años'
            }
        ]
    }
]