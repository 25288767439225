import React, {useContext, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { Skeleton, Button } from '@mui/material';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import { CurrentUserContext } from '../../../context/CurrentUserProvider';
import { AnnualTrainingsContext } from '../../../context/AnnualTrainingsContext';

const capacitacionesOptions = ["Capacitación", "Modalidad", "Sesiones", "Status", "Próxima Sesión", ] 

const TableColumns = () => {
  return capacitacionesOptions.map((column) => {
    if (column === "Capacitación" ) {
      return (
        <TableCell
          key={column}
          sx={{
            color: "#565656",
            fontSize: "13px",
            fontWeight: "bold",
          }}>
          {column}
        </TableCell>
      )
    }
    return (
      <TableCell
        key={column}
        align="center"
        sx={{
          color: "#565656",
          fontSize: "13px",
          fontWeight: "bold",
        }}>
        {column}
      </TableCell>
    )
  })
}

function CapacitacionesListView() {
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [nextSession, setNextSession] = useState(false)
  const {fetchAnnualTrainings, annualTrainings, createAttendance} = useContext(AnnualTrainingsContext)
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();

    useEffect(() => {
        fetchAnnualTrainings();
    }, []);

    useEffect(() => {
      fetchAnnualTrainings().finally(() => setLoading(false));
    }, []);

  const handleRowClick = (row) => {
    const hasCurrentUserAsInstructor = row?.sessions?.some(session =>
      session?.instructor?.some(instructor => instructor.id === currentUser.id)
    );
    
    if(hasCurrentUserAsInstructor){
      navigate(`/seguimiento/capacitaciones/anuales/${row.id}`, { relative: false });
    }else{
      navigate(`/capacitaciones/anuales/${row.id}`, { relative: false });
    }

  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };

  const determineStatus = (sessions) => {
    const now = new Date();
    const pastSessions = sessions?.filter(session => {
      const sessionEndDate = new Date(session.date);
      const [hours, minutes, seconds] = session?.end_time?.split(':');
    sessionEndDate.setHours(hours, minutes, seconds);
      return sessionEndDate < now;
    });
    const upcomingSessions = sessions?.filter(session => {
      const sessionEndDate = new Date(session.date);
      const [hours, minutes, seconds] = session?.start_time?.split(':');
      sessionEndDate?.setHours(hours, minutes, seconds);
  
      return sessionEndDate > now;
    });

    if (pastSessions.length === sessions.length) {
      return 'Finalizado';
    } else if (upcomingSessions.length === sessions.length) {
      return 'Próximo';
    } else {
      return 'En proceso';
    }
  };

  const getNextSessionDate = (sessions) => {
    const upcomingSessions = sessions?.filter(session => {
      const sessionEndDate = new Date(session?.date);
      const [hours, minutes, seconds] = session?.end_time?.split(':');
      sessionEndDate?.setHours(hours, minutes, seconds);
  
      return sessionEndDate > new Date();
    });
    if (upcomingSessions?.length > 0) {
      return new Date(upcomingSessions[0]?.date);
    }
    return new Date(8640000000000000); 
  };

  const sortedData = annualTrainings?.sort((a, b) => {
    const aStatus = determineStatus(a.sessions);
    const bStatus = determineStatus(b.sessions);

    if (aStatus === 'Finalizado' && bStatus !== 'Finalizado') return 1;
    if (aStatus !== 'Finalizado' && bStatus === 'Finalizado') return -1;

    const nextSessionDateA = getNextSessionDate(a.sessions);
    const nextSessionDateB = getNextSessionDate(b.sessions);

    return nextSessionDateA - nextSessionDateB;
  });

  const handleOpenDialog = (e, nextSession) => {
    e.stopPropagation();
    setNextSession(nextSession)
    setOpenDialog(true)

  }

  const handleConfirmSingleAttendance = () => {
    const data = {
      session: nextSession.id,
      teacher: currentUser.id
    }
    createAttendance(data, annualTrainings.id)
    setNextSession(null)
    setOpenDialog(false)
  }

  const getUniqueInstructors = (data) => {
    const allInstructors = data?.sessions?.flatMap(session => session?.instructor);
    
    const uniqueInstructorsMap = {};
    allInstructors?.forEach(instructor => {
      uniqueInstructorsMap[instructor?.id] = instructor;
    });
    
    return Object.values(uniqueInstructorsMap);
  };

  return (
    <div style={{borderRadius: '10px', width: '100%'}}>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ overflow: "scroll", maxHeight: "500px", width: '100%', borderRadius: '10px' }}>
        <Table size="small" aria-labelledby="tableTitle" stickyHeader>
          <TableHead sx={{ border: "none", height: '45px'}}>
              <TableRow sx={{ border: "none", backgroundColor:'#F7F8FA' }}>
                <TableColumns />
                <TableCell
                align="center"
                sx={{
                  color: "#565656",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}>
                {currentUser.role !== "external_teacher" ? 
                "Instructor" : ""}
                </TableCell>
                
              </TableRow>
          </TableHead>
          <TableBody>
            {loading ? 
            Array.from(new Array(7)).map((_, index) => (
              <TableRow key={index} sx={{ height: '50px' }}>
                {capacitacionesOptions?.map((_, i) => (
                  <TableCell key={i}>
                    <Skeleton variant="rectangular" height={20} sx={{borderRadius: '5px'}} />
                  </TableCell>
                ))}
                <TableCell>
                    <Skeleton variant="rectangular" height={20} sx={{borderRadius: '5px'}} />
                  </TableCell>
              </TableRow>
            ))
            : 
            (
              sortedData?.length > 0 ?(
          <>
            {sortedData?.map((training, i) => {
              let type = ""
              switch (training.type) {
                case 'on-site':
                  type = "Presencial"
                  break
                case 'remote':
                  type = "Remoto"
                  break
                case 'hybrid':
                  type = "Híbrido"
                  break
              }
              const nextSessionsFilter = training?.sessions?.filter((item) => new Date(item.date) > new Date())
              nextSessionsFilter?.sort((a, b) => new Date(a.date) - new Date(b.date))
              const confirmed = nextSessionsFilter[0]?.teacher?.filter(teacher => !teacher?.attendance_status).length
              const uniqueInstructors = getUniqueInstructors(training);
              
              const status = determineStatus(training.sessions);
              let chipColor = ""
              let textColor = ""
              switch (status) {
                case 'Finalizado':
                  chipColor = "#FFEDEA"
                  textColor = "#F0032B"
                  break
                case 'Próximo':
                  chipColor = "#FFFBE4"
                  textColor = "#F09624"
                  break
                case 'En proceso':
                  chipColor = "#E8FFED"
                  textColor = "#55BB5A"
                  break
              }
              const totalSessions = currentUser?.role === "external_teacher" ? training?.total_sessions : training?.sessions?.length
              return(
                <TableRow hover
                onClick={() => handleRowClick(training)} sx={{height: '50px', cursor: 'pointer'}}
                  key={i}>
                    <TableCell component="th" scope="row" sx={{fontSize: '13px'}}>
                      {`${training?.name}`}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {type}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {totalSessions}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      <Chip
                        label={status}
                        sx={{
                          backgroundColor:chipColor,
                          textTransform: "capitalize",
                          width: "fit-content",
                          margin: 0,
                          height: 25,
                          borderRadius: "10px",
                          fontWeight: "bold",
                          color: textColor,
                          fontSize: '11px',
                          
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {nextSessionsFilter?.length > 0 ? 
                      <p>{new Date(nextSessionsFilter[0]?.date).toLocaleString("es-ES", options)}</p> : 
                      <p></p>
                      }
                    </TableCell>
                    {currentUser.role === "external_teacher" &&
                    <TableCell align="center" >
                      {(confirmed >= 1 && status !== "Finalizado") &&
                      <Button startIcon={<TaskAltRoundedIcon />} color="success" 
                      onClick={(e) => handleOpenDialog(e, nextSessionsFilter[0])}
                      variant='contained' sx={{borderRadius: '10px', fontSize: '11px'}}
                      size='small'>
                        Confirmar Asistencia
                      </Button>}
                    </TableCell>}
                    {currentUser.role !== "external_teacher" &&
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {uniqueInstructors?.map((item, index) => {
                        const instructorMatch = item?.id === currentUser?.id
                        return(
                          <>
                          {instructorMatch ?
                          <Chip
                          label={`${item?.first_name} ${item?.last_name}`}
                          sx={{
                            backgroundColor:"#E8FFED",
                            textTransform: "capitalize",
                            width: "fit-content",
                            margin: 0,
                            height: 25,
                            borderRadius: "10px",
                            fontWeight: "bold",
                            color: "#55BB5A",
                            fontSize: '11px',
                            
                          }}
                        />: 
                            <p key={index}>{`${item?.first_name} ${item?.last_name}`}</p>
                          }
                          </>
                      
                      )})}
                    </TableCell>}
                </TableRow>
              )
            })}
            </>):
            (
              <TableRow>
              <TableCell colSpan={5} align="center">
                <Typography variant="body1" color="textSecondary">No hay capacitaciones disponibles</Typography>
              </TableCell>
            </TableRow>
            ))
          }
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle id="alert-dialog-title" style={{color: 'var(--main-blue)', fontSize: '18px'}}>
          {"Confirmas tu asistencia a la siguiente sesión?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{fontSize: '16px'}}>
            {`Fecha de la sesión: ${new Date(nextSession?.date).toLocaleString("es-ES", options)}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus 
          sx={{fontSize: '14px', borderRadius: '10px'}}>
            Cancelar
          </Button>
          <Button variant='outlined' onClick={handleConfirmSingleAttendance}
          autoFocus sx={{fontSize: '14px', borderRadius: '10px'}}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CapacitacionesListView
