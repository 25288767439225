import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { FORM_QUESTION_ENDPOINT } from '../../../utils/constants';
import { get } from "../../../utils/http";
import Container from "../../Container";
import styles from "./QuestionsList.module.css"

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

const columns = [
  {
    name: "Título",
    selector: (row) => row.title,
    sortable: true,
    cell: (cell) => {
      return(
      <div style={{maxWidth: '200px'}}>
        {cell.title}
      </div>
      )
    }
  },
  {
    name: "Descripción",
    selector: (row) => row.description,
    sortable: true,
    cell: (cell) => {
      return(
      <div style={{maxWidth: '300px'}}>
        {cell.description}
      </div>
      )
    }
  },
  {
    name: "Tipo",
    selector: (row) => row.options_type,
    sortable: true,
    cell: (cell) => {
      let type
      if(cell.options_type === 0 ){
        type = "Texto"
      }else if (cell.options_type === 1){
        type = "Selección Simple"
      }else if (cell.options_type === 2){
        type = "Selección multiple"
      }
      return (
        <div style={{maxWidth: '80px'}}>
          {type}
        </div>
      );
    },
  },
  {
    name: "Opciones",
    selector: (row) => row.question_options,
    sortable: true,
    cell: (cell) => {
      return (
        <div style={{display:'flex', width: '100px', flexDirection: 'column'}}>
        {cell.question_options.map((option) => (
            <p style={{ marginRight: "1rem" }}>{`${option.content}`}</p>
          
        ))}
        </div> 
      );
    },
  },
];

function QuestionsList() {
  const [questions, setQuestions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      const url = FORM_QUESTION_ENDPOINT;
      const response = await get({ url });
      if (!response.error) {
        setQuestions(response.data);
      }
    };
    fetchQuestions();
  }, []);

  return (
    <Container>
      <div className={styles.container}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles.table}>
          <DataTable
            columns={columns}
            data={questions}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight="500px"
          />
        </div>
      </div>
    </Container>
  )
}

export default QuestionsList
