import React, {useEffect, useState} from "react";
import ModalMUI from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import styles from "./Modal.module.css";
import Confetti from 'react-confetti'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  maxHeight: '95%',
  boxShadow: 24,
  borderRadius: "12px",
  alignItems: "center",
  justifyContent: 'center',
  display:'flex',
  flexDirection: 'column',
};


const Modal = ({ 
  modalOpen, 
  acceptModal, 
  children, 
  title, 
  action, 
  type, 
  showAnimation,
  width, 
  minWidth = 350, 
  maxWidth = "80%",  }) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    modalOpen(false)
    setOpen(false);
  }
  const handleAcceptModal = () => {
    acceptModal()
    modalOpen(false)
  }
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    scrollToTop();
  }, []);
  return (
    <ModalMUI
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
          ...style,
          width: width || "auto", // Si no se pasa width, se ajusta automáticamente
          minWidth: minWidth, // Valor mínimo de ancho
          maxWidth: maxWidth, // Valor máximo de ancho
        }} style={{width: type === "training" && "90%"}}>
        {showAnimation &&
        <Confetti style={{width: '100%', height: '100%'}}
        />}
        <div className={styles["modal-header"]}>
          <div className={styles.header_title}>{title}</div>
          <div className={styles.header_icon} onClick={() => modalOpen(false)}>
            <i className="fal fa-times"></i>
          </div>
        </div>
        <Stack direction={"column"} 
        sx={{display:'flex', alignItems:'center', padding: "15px",
        fontSize:'1.3rem', overflowY: 'scroll', width:'100%'}}>
          {children}
        </Stack>
          
        {action && (
          <div className={styles["modal-action"]}>
            <Button variant="contained" size="large"
            sx={{borderRadius: '10px', marginBottom: '10px'}} onClick={handleAcceptModal}>
              {action}
            </Button>
          </div>
        )}

      </Box>
      
    </ModalMUI>
  );
};

export default Modal;
