import React, { useContext, useState, useEffect, useCallback } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { CapacitacionesContext } from "../../../context/CapacitacionesContext";
import styles from './QuizView.module.css'
import Modal from "../../Modal";

export const themeJson = {
    "cssVariables": {},
    "isPanelless": false
}
  
Survey.StylesManager.applyTheme("default");
  
let defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
defaultThemeColors["$main-color"] = "var(--main-blue)";
defaultThemeColors["$main-hover-color"] = "var(--ter-blue)";

function QuizView({quiz, quizId, quizUser, quizName, getCapacitacion}) {
    const {createQuizAnsw} = useContext(CapacitacionesContext)
    const [answered, setAnswered] = useState(false)
    const [grade, setGrade] = useState(null)
    const [openAnswers, setOpenAnswers] = useState(false)
    const [jsonQuiz, setJsonQuiz] = useState({})
    const [totalWeight, setTotalWeight] = useState(0)

    useEffect(() => {
      const json = {
          elements: [],
      };
      quiz?.forEach((question) => {
        const isImageLink = question?.question?.options?.some(opt => isImageUrl(opt.file));
        let survey_question;
        
        if (question?.question?.options_type == 5) {
          survey_question = {
                              'type': 'file',
                              'allowImagesPreview': false 
                            }

        }else if(question?.question?.options_type == 4) {
          survey_question = {'type': 'text'}

        }else if(question?.question?.options_type == 3) {
          survey_question = {
                            'type': 'rating',
                            'minRateDescription': 'Malo',
                            'maxRateDescription': 'Bueno',
                            'rateValues': [1, 2, 3, 4, 5], //is related with the n_scales in backend (is 5 by default)
                          }

        } else {
          survey_question = {'type': isImageLink ? "imagepicker" : "radiogroup"};
        }

        const options = question?.question?.options?.map(opt => {
            return isImageLink ? { value: opt.file, imageLink: opt.file } : opt.file || opt.description;
        });
        if(question.question.file){
          const qst = {
            type: "html",
            html: question.question.file && `<div><img src="${question.question.file}" style="max-width:350px;height:auto;margin-bottom:10px;" /></div>`,
          };
          json?.elements?.push(qst);
        }
    
        const qst = {
          ...survey_question,
          name: question.question.id.toString(),
          title: question.question.content,
          choices: options,
          isRequired: true,
          requiredErrorText: "Value cannot be empty",
        };
    
        if (isImageLink) {
          qst.choices = question?.question?.options?.map(opt => {
              return { value: opt.file, imageLink: opt.file };
          });
          qst.imageHeight = 150; 
          qst.imageWidth = 250; 
        }
    
        json?.elements?.push(qst);
      });
      setJsonQuiz(json)
    }, [quiz]);

    useEffect(() => {
      let total = 0
      quiz?.forEach((question) => {
        total += question?.question?.weight
      });
      setTotalWeight(total)
    }, [quiz]);
    
    function isImageUrl(url) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }
    
    const survey = new Survey.Model(jsonQuiz);

    survey.completedHtml = "<p>¡Gracias por completar la encuesta!</p>";

    const alertResults = useCallback((sender) => {
      const results = sender.data;
      const answersData = {
          quiz: parseInt(quizId),
          answers: [],
      };
      Object.entries(results).map(([key, value]) => {
        quiz?.forEach((preg) => {
          if(preg.question.id === parseInt(key)){

            if ([1, 2].includes(preg.question.options_type)){

              preg.question.options.forEach((opt) => {

                if(value === opt.description || value === opt.file){
                  const data = {
                      question: key,
                      answer: opt.id,
                  };
                  answersData?.answers?.push(data);
                }
                
              })

            }else{

              const data = {
                  question: key,
                  answer: value,
              };
              answersData?.answers?.push(data);

            }
          }
        })
      });
        createQuizAnsw({ data: answersData }).then((response) => {
            if (!response.error) {
              getCapacitacion()
              setAnswered(true)
              setGrade(response.data.quiz_user.grade)
            } else {
              setAnswered(false)
              setGrade(null)
            }
        });
    }, []);
    survey.onComplete.add(alertResults);

  return (
    <div style={{width: '100%'}}>
      {(!quizUser && !answered) ?
        <div style={{ width: "100%", maxHeight: '80vh', overflowY: 'scroll' }}>
            <Survey.Survey model={survey} />
        </div> : 
        <div className={styles.task}>
        <div className={styles.task_row}>
          <div
            style={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontSize: "13px",
                textAlign: "center",
                paddingTop: "5px",
              }}
            >
              {quizName}
            </p>
          </div>
          <div
            style={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ fontSize: "11px", textAlign: "center" }}>
              Nota
            </p>
            <p
              style={{
                fontSize: "13px",
                textAlign: "center",
                paddingTop: "5px",
              }}
            >
              {`${grade || quizUser.grade}/${totalWeight}`}
            </p>
          </div>
          <div
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p className={styles.submitted}>Entregado</p>
          </div>
          {/* <div
              style={{
                width: "25%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <p
                className={styles.review}
                onClick={() => setOpenAnswers(true)}
              >
                Ver respuestas
              </p>
            </div> */}
        </div>
      </div>}
      {openAnswers && (
        <Modal title={"Respuestas"} modalOpen={setOpenAnswers}>
          <div style={{height: '100%', overflowY: 'scroll'}}>
          {quizUser?.answers?.map((answer) => {
            const questionFilter = quiz?.filter((item) => item?.question?.id === answer?.question.id)
            const answerFilter = questionFilter[0]?.question?.options?.filter((item) => item?.correct_answer)
            return(
              <div className={styles.quiz}> 
                <p style={{fontWeight: 'bold'}}>{`${answer?.question?.content} (${answer?.question?.weight}pts)`}</p>
                <div className={styles.answerComp} >
                  <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                  <p>{answer?.answer_option?.description}</p>
                  {answer?.answer_option?.correct_answer ? 
                  <i class="fas fa-check" style={{color: 'var(--sec-green)'}}></i> : 
                  <i class="fas fa-times" style={{color: 'var(--sec-red)'}}></i>
                  }
                  </div>
                  {!answer?.answer_option?.correct_answer &&
                  <p>{`Respuesta Correcta: ${answerFilter[0]?.description}`}</p>}
                </div>
              </div>
            )
          })}
          </div>
        </Modal>
      )}
    </div>
  )
}

export default QuizView
