import React, {useContext} from "react";
import styles from "./SettingsPage.module.css";
import CardSetting from "../components/Card/CardSetting";
import Container from "../components/Container";
import { SETTINGS_OPTIONS, SETTINGS_OPTIONS2 } from "../utils/constants/settingConstants";
import { CurrentUserContext } from "../context/CurrentUserProvider";

function SettingsPage() {
  const { currentUser, logoutUser } = useContext(CurrentUserContext);

  const options = (currentUser?.role === "monitoring_coordinator" ||
  currentUser?.role === "monitoring_teacher" ||
  currentUser?.role === "monitoring_teacher_3") ? SETTINGS_OPTIONS2 : SETTINGS_OPTIONS
  
  return (
    <div style={{ marginRight: "2rem" }}>
      <Container direction={"row"}>
        <div className={styles.content}>
          {options.map((option) => {
            return (
              <CardSetting
                className={"data-table"}
                data={option}
                key={option.name}
                usersOption={""}
                type={"settingsCard"}
                logoutUser={logoutUser}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
}

export default SettingsPage;
