import React, { useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styles from "./ForumMessageForm.module.css";
import { MyMessageArea, MySubmitMessageButton } from "../../FormFields";
import { useForums } from "../../../context/ForumContext";

const ForumMessageForm = ({ fetchMessages, setCommenting, topic }) => {
  const { createForumMessage } = useForums();
  const [attachment, setAttachment] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const createHandler = async (data) => {
    setIsLoading(true);
    const response = await createForumMessage({ data });
    if (!response.error) {
      fetchMessages();
      setIsLoading(false);
      setCommenting(false);
    }else{
      setIsLoading(false);
    }
    
  };

  return (
    <div className={styles.comment}>
      <Formik
        initialValues={{
          content: "",
        }}
        validationSchema={Yup.object({
          contet: Yup.string().min(1, "El campo no puede estar vacío."),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const formData = new FormData();

          for (const key in values) {
            formData.append(`${key}`, values[key]);
          }
          formData.append("topic", topic.id);
          formData.append("attachment", attachment);

          setTimeout(() => {
            createHandler(formData);
            setSubmitting(false);
          }, 400);
          resetForm();
        }}
      >
        <Form className={styles["form"]}>
          <div className={styles["form__fields"]}>
            <div className={styles.attachment}>
              <label htmlFor="file-input">
                <i className="fal fa-paperclip"></i>
              </label>
              <input
                id="file-input"
                type="file"
                onChange={(e) => setAttachment(e.target.files[0])}
              />
            </div>
            <div className={styles["message-input"]}>
              <MyMessageArea name="content" />
            </div>
            {!isLoading ? 
            <>
            <MySubmitMessageButton name="Enviar" type="submit" />
            <i
              className="fal fa-times"
              style={{ cursor: "pointer", fontSize: "30px" }}
              onClick={() => {
                setCommenting(false);
              }}
            ></i>
            </> : 
            <MoonLoader color="rgba(51, 0, 114, 1)" speedMultiplier={0.5} size={30} />}
          </div>
          {attachment && <p>{`Archivo: ${attachment?.name}`}</p>}
        </Form>
      </Formik>
    </div>
  );
};

export default ForumMessageForm;
