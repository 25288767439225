import React, {useContext, useState} from 'react'
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Container from "../Container";
import styles from "./CoursesList.module.css"
import { CoursesContext } from '../../context/CoursesContext';

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

function CoursesList() {
  const { courses, setSelectedCourse } = useContext(CoursesContext);
  const [filterText, setFilterText] = useState("");

  const navigate = useNavigate();

  let filteredData = courses?.filter((item) =>
    item.program.toLowerCase().includes(filterText.toLowerCase()) ||
    item.institution_level.toLowerCase().includes(filterText.toLowerCase()) ||
    item.institution.name.toLowerCase().includes(filterText.toLowerCase()) ||
    item.institution.short_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleEditClick = (d) => {
    setSelectedCourse(courses?.filter((course) => course.id === d.id)[0]);
    navigate(`/courses-admin/${d.id}`, { relative: false });
  };

  const columns = [
    {
      name: "Programa",
      selector: (row) => row.program,
      sortable: true,
      
    },
    {
      name: "Grado",
      selector: (row) => row.institution_level,
    },
    {
      name: "Colegio",
      selector: (row) => row.institution.name,
      sortable: true,
    },
    {
      name: "Opciones",
      sortable: false,
      selector: "null",
      cell: (d) => (
        <div onClick={()=>handleEditClick(d)}
        style={{justifyContent:'center', display:'flex', width: "60px"}}>
          <i className="fas fa-pen"></i>
        </div>
      )
    }
  ];

  const handleRowClick = (row) => {
    navigate(`/courses/${row.id}`, { relative: false });
  };

  return (
    <div className={styles.content}>
      <i className="fal fa-arrow-left" 
          style={{fontSize: '12px', cursor: 'pointer'}}
          onClick={() => navigate(-1)}></i>
      <div className={styles["search-bar"]}>
        <input
          id="search"
          type="text"
          placeholder="Buscar..."
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          className={styles.filter_text}
        />
      </div>
      <div className={styles.table_content}>
      <Container direction={"column"}>
        <DataTable
          columns={columns}
          data={filteredData}
          customStyles={customStyles}
          sortIcon={
            <i
              className="far fa-arrow-up"
              style={{ marginLeft: "5px", fontSize: "14px" }}
            ></i>
          }
          highlightOnHover
          pointerOnHover
          fixedHeader
          fixedHeaderScrollHeight="515px"
          onRowClicked={(row) => handleRowClick(row)}
        />
      </Container>
      </div>
    </div>
  )
}

export default CoursesList