import React, {useState, useContext, useEffect} from 'react'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import DataTable from "react-data-table-component";
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {
    MySubmitButton,
    MyRadio,
    MySelect,
    MyTextarea,
    MyDateTextInput
  } from "../../FormFields";
import styles from '../CourseSections.module.css'
import { get } from '../../../utils/http';
import { COURSES_ENDPOINT } from '../../../utils/constants';
import { ClassJournalContext } from '../../../context/ClassJournalContext';
import Modal from '../../Modal';

const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#000",
        fontSize: "13px",
        fontWeight: "bold",
        borderBottom: "1px solid #E0E0E0",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f5f5f5",
        borderBottomColor: "#FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
};

const INITIALVALUES = {
    lesson: "",
    date: new Date(),
    completed: false,
    notes: "",
};

function ClassJournal({currentSection, courseId, journalEntries}) {
    const [initialValues, setInitialValues] = useState(INITIALVALUES);
    const [isCreatingJournal, setIsCreatingJournal] = useState(false);
    const [lessons, setLessons] = useState({});
    const [lessonsTitles, setLessonsTitles] = useState([])
    const [classCompleated, setClassCompleated] = useState(false);
    const [journalId, setJournalId] = useState(null);
    const { fillClassJournal, updateClassJournal } = useContext(ClassJournalContext);

    useEffect(() => {
        const fetchCourseLessons = async () => {
          const url = `${COURSES_ENDPOINT}${courseId}/lessons/`;
          const response = await get({ url });
          setLessons(response.data);
        };
        fetchCourseLessons();
    }, [courseId]);

    useEffect(() => {
        const lessonsT = [];
        const lessonValues = Object.values(lessons);
        lessonValues?.forEach((value) => {
          value?.forEach((val) => {
            const data = {
              id: val.id,
              title: val.title,
            };
            lessonsT?.push(data);
          });
        });
        setLessonsTitles(lessonsT)
    }, [lessons]);

    const handleFillClassJournal = (values) => {
        const fillData = {
          date: values.date,
          lesson: parseInt(values.lesson),
          completed: classCompleated,
          section: currentSection?.id,
          notes: values.notes,
        };
        journalId
          ? updateClassJournal({ journalId, data: fillData })
          : fillClassJournal({ data: fillData });
        setJournalId(null);
        setIsCreatingJournal(false);
    };

    const handleSetEdit = (journal) => {
        setJournalId(journal.id);
        const definedInitialValues = {
          date: journal.date,
          lesson: journal.lesson_id,
          completed: journal.completed,
          notes: journal.notes,
        };
        setInitialValues(definedInitialValues);
        setClassCompleated(journal.completed);
        setIsCreatingJournal(true);
    };

    const columns = [
        {
          name: "Fecha",
          selector: (row) => row.date,
          sortable: true,
          cell: (cell) => {
            return(
              <p style={{width: '80px'}}>{cell.date}</p>
            )
          }
        },
        {
          name: "Clase",
          selector: (row) => row.lesson,
          sortable: true,
          cell: (cell) => {
            return(
              <p style={{width: '150px'}}>{cell.lesson}</p>
            )
          }
        },
        {
          name: "Completada",
          selector: (row) => row.completed,
          cell: (cell) => {
            let value;
            if (cell.completed) {
              value = "Completada";
            } else {
              value = "No completada";
            }
            return <div style={{width: '80px'}}>{value}</div>;
          },
        },
        {
          name: "Nota",
          selector: (row) => row.notes,
          sortable: true,
          cell: (cell) => {
            return(
              <p style={{width: '300px', marginTop: '10px', marginBottom: '10px'}}>{cell.notes}</p>
            )
          }
        },
        {
          name: " ",
          cell: (cell) => {
            return (
              <div style={{width: '40px'}}>
                <i
                  class="fas fa-edit"
                  style={{
                    marginLeft: "auto",
                    fontSize: "16px",
                    color: "var(--main-blue)",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSetEdit(cell)}
                ></i>
              </div>
            );
          },
        },
    ];

  return (
    <div style={{ marginBottom: "1rem" }}>
          <div className={styles.section_info}>
                <Button variant='contained' size='large'
                sx={{borderRadius: '10px', width:'fit-content'}}
                  onClick={() => setIsCreatingJournal(true)}
                >
                  Llenar día a día
                </Button>
              </div>
          <div className={styles.container}>
            <span className={styles["schedule-title2"]}> Clases Completadas: {currentSection?.completed_class_journal}</span>
          </div>
          {isCreatingJournal && (
              <Modal
                title={"Crear entrada"}
                modalOpen={setIsCreatingJournal}
                // acceptModal={() => handleFillClassJournal()}
              >
                <div className={styles.add_day}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object({
                      lesson: Yup.string().min(
                        1,
                        "El campo no puede estar vacío."
                      ),
                      date: Yup.date(),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        handleFillClassJournal(values);
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    <Form className={styles["form__fields"]}>
                      <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item md={6} sm={12} xs={12}>
                          <MySelect label="Contenido" name="lesson">
                            {lessonsTitles?.map((content) => (
                              <MenuItem
                                name="lesson"
                                value={content.id}
                                key={content.id}
                              >
                                {content.title}
                              </MenuItem>
                            ))}
                          </MySelect>

                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                        <MyDateTextInput
                            label="Fecha"
                            name="date"
                            placeholder="Título"
                            icon="fa fa-user"
                          />
                          
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                        <MyRadio
                            label="Empleado"
                            name="completed"
                            checked={classCompleated}
                            onClick={() => setClassCompleated(!classCompleated)}
                          >
                            Completado
                          </MyRadio>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <MyTextarea label="Notas" name="notes" />
                        </Grid>
                      </Grid>
                      <div className={styles.inputs}>
                        <MySubmitButton name="Aceptar" type="submit" />
                      </div>
                    </Form>
                  </Formik>
                </div>
              </Modal>
            )}
            <div className={styles.table}>
              <DataTable
                columns={columns}
                data={journalEntries}
                customStyles={customStyles}
                sortIcon={
                  <i
                    className="far fa-arrow-up"
                    style={{ marginLeft: "5px", fontSize: "14px" }}
                  ></i>
                }
                highlightOnHover
                fixedHeader
                fixedHeaderScrollHeight="500px"
              />
            </div>
        </div>
  )
}

export default ClassJournal
