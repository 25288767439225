import React, {useContext, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { Button, Skeleton, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { CurrentUserContext } from '../../../context/CurrentUserProvider';
import { AnnualTrainingsContext } from '../../../context/AnnualTrainingsContext'

const capacitacionesOptions = ["Capacitación", "Modalidad", "Sesiones", "Instructores", "Próxima Sesión", "Confirmados", "Status"]

const TableColumns = () => {
  return capacitacionesOptions.map((column) => {
    if (column === "Capacitación" ) {
      return (
        <TableCell
          key={column}
          sx={{
            color: "#565656",
            fontSize: "13px",
            fontWeight: "bold",
          }}>
          {column}
        </TableCell>
      )
    }
    return (
      <TableCell
        key={column}
        align="center"
        sx={{
          color: "#565656",
          fontSize: "13px",
          fontWeight: "bold",
        }}>
        {column}
      </TableCell>
    )
  })
}

function CapacitacionesAnualesList() {
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [filterText, setFilterText] = useState("");
  const [selectedTraining, setSelectedTraining] = useState([])
  const { currentUser } = useContext(CurrentUserContext);
  const {fetchAnnualTrainings, annualTrainings, sendReminderEmail} = useContext(AnnualTrainingsContext)

  useEffect(() => {
    fetchAnnualTrainings();
  }, []);

  useEffect(() => {
    fetchAnnualTrainings().finally(() => setLoading(false));
  }, []);

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  const navigate = useNavigate();

  const handleRowClick = (row) => {
    navigate(`/seguimiento/capacitaciones/anuales/${row.id}`, { relative: false });
  };

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const getUniqueInstructors = (data) => {
    const allInstructors = data?.sessions?.flatMap(session => session?.instructor);
    
    const uniqueInstructorsMap = {};
    allInstructors?.forEach(instructor => {
      uniqueInstructorsMap[instructor?.id] = instructor;
    });
    
    return Object.values(uniqueInstructorsMap);
  };

  const determineStatus = (sessions) => {
    const now = new Date();
    const pastSessions = sessions?.filter(session => {
      const sessionEndDate = new Date(session.date);
      const [hours, minutes, seconds] = session?.end_time?.split(':');
      sessionEndDate.setHours(hours, minutes, seconds);
        return sessionEndDate < now;
      });
    const upcomingSessions = sessions?.filter(session => {
      const sessionEndDate = new Date(session.date);
      const [hours, minutes, seconds] = session?.start_time?.split(':');
      sessionEndDate?.setHours(hours, minutes, seconds);
  
      return sessionEndDate > now;
    });

    if (pastSessions.length === sessions.length) {
      return 'Finalizado';
    } else if (upcomingSessions.length === sessions.length) {
      return 'Próximo';
    } else {
      return 'En proceso';
    }
  };

  const getNextSessionDate = (sessions) => {
    const upcomingSessions = sessions?.filter(session => {
      const sessionEndDate = new Date(session?.date);
      const [hours, minutes, seconds] = session?.end_time?.split(':');
      sessionEndDate?.setHours(hours, minutes, seconds);
  
      return sessionEndDate > new Date();
    });
    upcomingSessions?.sort((a, b) => new Date(a.date) - new Date(b.date))
    if (upcomingSessions?.length > 0) {
      return new Date(upcomingSessions[0]?.date);
    }
    return new Date(8640000000000000); // Una fecha lejana en el futuro si no hay próximas sesiones
  };

  annualTrainings?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

  const filteredData = annualTrainings?.filter(item => {
    const isNameMatch = item?.name?.toLowerCase().includes(filterText?.toLowerCase());
    const isSessionCodeMatch = item?.sessions?.some(session =>
      session?.session_code?.toLowerCase().includes(filterText?.toLowerCase())
    );

    return isNameMatch || isSessionCodeMatch;
  });

  const sortedData = filteredData?.sort((a, b) => {
    const aStatus = determineStatus(a.sessions);
    const bStatus = determineStatus(b.sessions);

    if (aStatus === 'Finalizado' && bStatus !== 'Finalizado') return 1;
    if (aStatus !== 'Finalizado' && bStatus === 'Finalizado') return -1;

    const aInstructorMatch = a?.sessions?.some(session =>
      session?.instructor?.some(instructor => instructor?.id === currentUser?.id)
    );
    const bInstructorMatch = b?.sessions?.some(session =>
      session?.instructor?.some(instructor => instructor?.id === currentUser?.id)
    );

    if (aInstructorMatch && !bInstructorMatch) return -1;
    if (!aInstructorMatch && bInstructorMatch) return 1;

    const nextSessionDateA = getNextSessionDate(a.sessions);
    const nextSessionDateB = getNextSessionDate(b.sessions);

    return nextSessionDateA - nextSessionDateB;
  });

  const handleSendRemindEmail = (sessionId) => {
    sendReminderEmail(sessionId)
    setOpenDialog(false);
  }


  const handleCheckEmailSend = (event, training) => {
    event.stopPropagation();
    setSelectedTraining(training)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
    <Box sx={{ marginLeft: 'auto', width:'100%', display:'flex', justifyContent:'flex-end', marginBottom: '10px'}}>
      <TextField id="outlined-basic" placeholder="Buscar por nombre de la capacitación o código de la sesión"
      variant="outlined" size='small'
      sx={{width: isDesktop ? '35%' : '100%', '& .MuiOutlinedInput-root': { borderRadius: '10px', backgroundColor: '#fff' }}}
      onChange={(e) => setFilterText(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRoundedIcon />
          </InputAdornment>
        ),
      }} />
    </Box>
    
    <div style={{borderRadius: '10px'}}>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ overflow: "scroll", maxHeight: "500px", width: '100%', borderRadius: '10px' }}>
        <Table size="small" aria-labelledby="tableTitle" stickyHeader>
          <TableHead sx={{ border: "none", height: '45px'}}>
              <TableRow sx={{ border: "none", backgroundColor:'#F7F8FA' }}>
                <TableColumns />
                {(currentUser?.role === "IT" || currentUser.role === "monitoring_teacher" || currentUser.role === "monitoring_coordinator" ||
    currentUser?.role === "monitoring_teacher_3") && 
                <TableCell
                  align="center"
                  sx={{
                    color: "#565656",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}>
                  Recordatorio
                </TableCell>}
              </TableRow>
          </TableHead>
          <TableBody>
            {loading ? 
            Array.from(new Array(7)).map((_, index) => (
              <TableRow key={index} sx={{ height: '50px' }}>
                {capacitacionesOptions?.map((_, i) => (
                  <TableCell key={i}>
                    <Skeleton variant="rectangular" height={20} sx={{borderRadius: '5px'}} />
                  </TableCell>
                ))}
                {(currentUser?.role === "IT" || currentUser.role === "monitoring_teacher" || currentUser.role === "monitoring_coordinator" ||
    currentUser?.role === "monitoring_teacher_3") && 
                  <TableCell >
                    <Skeleton variant="rectangular" height={20} sx={{borderRadius: '5px'}} />
                  </TableCell>
                }
              </TableRow>
            ))
            : 
          <>
            {sortedData?.map((training, i) => {
              let type = ""
              switch (training.type) {
                case 'on-site':
                  type = "Presencial"
                  break
                case 'remote':
                  type = "Remoto"
                  break
                case 'hybrid':
                  type = "Híbrido"
                  break
              }
              
              const uniqueInstructors = getUniqueInstructors(training);
              const nextSessionsFilter = training?.sessions?.filter((item) => new Date(item.date) > new Date())
              nextSessionsFilter?.sort((a, b) => new Date(a.date) - new Date(b.date))
              const confirmed = nextSessionsFilter[0]?.teacher?.filter(teacher => teacher?.attendance_status === 'pending').length
              const status = determineStatus(training?.sessions);
              let chipColor = ""
              let textColor = ""
              switch (status) {
                case 'Finalizado':
                  chipColor = "#FFEDEA"
                  textColor = "#F0032B"
                  break
                case 'Próximo':
                  chipColor = "#FFFBE4"
                  textColor = "#F09624"
                  break
                case 'En proceso':
                  chipColor = "#E8FFED"
                  textColor = "#55BB5A"
                  break
              }
              return(
                <TableRow hover
                onClick={() => handleRowClick(training)} sx={{height: '50px', cursor: 'pointer'}}
                  key={i}>
                    <TableCell component="th" scope="row" sx={{fontSize: '13px'}}>
                      {`${training?.name}`}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {type}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {training.total_sessions}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {uniqueInstructors?.map((item, index) => {
                        const instructorMatch = item?.id === currentUser?.id
                        return(
                          <>
                          {instructorMatch ?
                          <Chip
                          label={`${item?.first_name} ${item?.last_name}`}
                          sx={{
                            backgroundColor:"#E8FFED",
                            textTransform: "capitalize",
                            width: "fit-content",
                            margin: 0,
                            height: 25,
                            borderRadius: "10px",
                            fontWeight: "bold",
                            color: "#55BB5A",
                            fontSize: '11px',
                            
                          }}
                        />: 
                            <p key={index}>{`${item?.first_name} ${item?.last_name}`}</p>
                          }
                          </>
                      
                      )})}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {nextSessionsFilter?.length > 0 ? 
                      <p>{new Date(nextSessionsFilter[0]?.date).toLocaleString("es-ES", options)}</p> : 
                      <p></p>
                      }
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {nextSessionsFilter?.length > 0 ? 
                        <p>{`${confirmed}/${nextSessionsFilter[0]?.teacher?.length}`}</p> : 
                        <></>
                      }
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      <Chip
                        label={status}
                        sx={{
                          backgroundColor:chipColor,
                          textTransform: "capitalize",
                          width: "fit-content",
                          margin: 0,
                          height: 25,
                          borderRadius: "10px",
                          fontWeight: "bold",
                          color: textColor,
                          fontSize: '11px',
                          
                        }}
                      />
                    </TableCell>
                    {(currentUser.role === "IT" || currentUser.role === "monitoring_teacher" || 
                    currentUser.role === "monitoring_coordinator" ||
                    currentUser?.role === "monitoring_teacher_3")  &&
                    <TableCell align="center" >
                      {(status !== "Finalizado") && 
                      <Button sx={{borderRadius: '10px', fontSize: '11px'}} variant='outlined'
                      size='small' onClick={(event) => handleCheckEmailSend(event, training?.sessions)}
                      startIcon={<NotificationsActiveOutlinedIcon />}>
                        Enviar
                      </Button>}
                    </TableCell>}
                </TableRow>
              )
            })}
            </>
          }
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle id="alert-dialog-title" style={{color: 'var(--main-blue)', fontSize: '18px'}}>
          {"Selecciona la Sesión a la que deseas enviar el recordatorio"}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {selectedTraining && selectedTraining?.map((item, i) => {
              const lastEmailSent = item?.last_email_sent_date ? new Date(item?.last_email_sent_date).toLocaleString("es-ES", options) : "N/A"
              return(
                <Stack direction={"row"} key={i}>
                  <Stack direction={"column"}>
                  <Typography variant='h6'>
                    {`${item?.name} - ${item?.session_code}`}
                  </Typography>
                  <Typography>{`Último recordatorio: ${lastEmailSent}`}</Typography>
                  </Stack>
                  <Button variant='outlined' onClick={() => handleSendRemindEmail(item.id)}
                   sx={{borderRadius: '10px', marginLeft: 'auto'}}>
                    Seleccionar 
                  </Button>
                </Stack>
              )
            })}
            </Stack>
            </DialogContent>
      </Dialog>
    </div>
    </>
  )
}

export default CapacitacionesAnualesList