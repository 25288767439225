import { useState, createContext, useContext, useEffect } from "react";
import { get, post, deleted, patch } from "../utils/http";
import {
  SECTIONS_ENDPOINT,
  COURSE_SECTIONS_ENDPOINT,
  CLASS_SCHEDULES_ENDPOINT,
} from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";
import { AlertsContext } from "./AlertsContext";

const SectionsContext = createContext();

const SectionsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useLocalStorage(
    "selectedSection",
    ""
  );
  const showSnackbar = useContext(AlertsContext);

  const createSection = async ({ data }) => {
    const url = SECTIONS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha creado la sección", "success");
      return response;
    } else {
      showSnackbar("Hubo un error al crear la sección", "error");
    }
  };

  const fetchSections = async (courseId) => {
    const url = COURSE_SECTIONS_ENDPOINT(courseId);
    const response = await get({ url });

    if (!response.error) {
      return response;
    }
  };

  const fetchSingleSection = async () => {
    const url = SECTIONS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setSections(response.data);
    }
  };

  const createSchedule = async ({ data }) => {
    const url = CLASS_SCHEDULES_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha agregado el bloque horario", "success");
      return response;
    } else {
      showSnackbar("Hubo un error al agregar el bloque horario", "error");
    }
  };

  const updateSchedule = async ({ scheduleId, data }) => {
    const url = `${CLASS_SCHEDULES_ENDPOINT}${scheduleId}/`;

    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar("Se ha editado el horario", "success");
      fetchSections();
    } else {
      showSnackbar("Hubo un error al editar el horario", "error");
    }
  };

  const deleteSchedule = async ({ scheduleId }) => {
    const url = `${CLASS_SCHEDULES_ENDPOINT}${scheduleId}/`;

    const response = await deleted({ url });
    if (!response.error) {
      showSnackbar("Se ha eliminado el horario", "success");
      fetchSections();
    } else {
      showSnackbar("Hubo un error al eliminar el horario", "error");
    }
  };

  const deleteSection = async ({ sectionId }) => {
    const url = `${SECTIONS_ENDPOINT}${sectionId}/`;
    const response = await deleted({ url });
    if (!response.error) {
      showSnackbar("Sección eliminada", "success");
    } else {
      showSnackbar("Hubo un error al eliminar la sección", "error");
    }

    return response;
  };

  useEffect(() => {
    // fetcSections();
  }, []);

  const contextData = {
    sections,
    setSections,
    selectedSection,
    setSelectedSection,
    createSection,
    fetchSections,
    createSchedule,
    deleteSection,
    updateSchedule,
    deleteSchedule
  };

  return (
    <SectionsContext.Provider value={contextData}>
      {loading ? null : children}
    </SectionsContext.Provider>
  );
};

const useSections = () => {
  return useContext(SectionsContext);
};

export { SectionsContext, SectionsProvider, useSections };
