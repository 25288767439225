import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function CapacitacionesPage() {
    const [formsOption, setFormsOption] = useState("list");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!location.pathname.split("/")[2]) {
        navigate("list", { replace: true });
      } else {
        setFormsOption(location.pathname.split("/")[2]);
      }
    }, [location.pathname]);

  return (
    <div>
      <Outlet />
    </div>
  )
}

export default CapacitacionesPage
