export const BASIC_INFO = [
  { key: "username", value: "Nombre de usuario" },
  { key: "first_name", value: "Primer Nombre" },
  { key: "last_name", value: "Apellido" },
  { key: "email", value: "Correo Electronico" },
  { key: "last_login", value: "Último inicio de sesión" },
];

export const EMPLOYEE_INFO = [
  { key: "phone", value: "Numero de teléfono" },
  { key: "address", value: "Dirección" },
  { key: "role", value: "Cargo" },
  { key: "branch", value: "Sede" },
];

export const DATE_FIELDS = ["last_login"];
