import React, {useState, useEffect, useContext} from 'react'
import Box from '@mui/material/Box';
import { Skeleton, Button, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import { AnnualTrainingsContext } from '../../../context/AnnualTrainingsContext';

const certificatesOptions = ["Docente", "Acuerdo de Confidencialidad", "Encuesta", "Asistencia", "Quiz", "Status", "Descargar Certificado", "Por Aprobar"]

const TableColumns = () => {
  return certificatesOptions.map((column) => {
    if (column === "Docente" ) {
      return (
        <TableCell
          key={column}
          sx={{
            color: "#565656",
            fontSize: "13px",
            fontWeight: "bold",
          }}>
          {column}
        </TableCell>
      )
    }
    return (
      <TableCell
        key={column}
        align="center"
        sx={{
          color: "#565656",
          fontSize: "13px",
          fontWeight: "bold",
        }}>
        {column}
      </TableCell>
    )
  })
}

function CertificatesDataView({trainingId}) {
  const [loading, setLoading] = useState(true);
  const [approveUser, setAprroveUser] = useState({})
  const [openDialog, setOpenDialog] = React.useState(false);
  const {fetchAnnualTrainingCertificates, trainingCertificates, updateCertificate} = useContext(AnnualTrainingsContext)
  
  useEffect(() => {
    if(trainingId){
      fetchAnnualTrainingCertificates(trainingId);
    }
  }, [trainingId]);

  useEffect(() => {
    fetchAnnualTrainingCertificates().finally(() => setLoading(false));
  }, []);

  const handleClickOpenDialog = (row) => {
    setAprroveUser(row)
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setAprroveUser({})
    setOpenDialog(false);
  };

  const handleAcceptCertificate = () => {
    const data = {
      status: "approved"
    }
    updateCertificate(approveUser.id, data, trainingId)
    setAprroveUser({})
    setOpenDialog(false);
  }

  let statusMsg
  switch (approveUser?.status) {
    case 'pending':
      statusMsg = 'las asistencias y la nota del quiz'; 
      break
    case 'pending attended':
      statusMsg = 'las asistencias'; 
      break
    case 'pending quiz':
      statusMsg = 'la nota del quiz'; 
      break
  }
  return (
    <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
      borderRadius: '10px'}}>
        <h2 style={{marginTop: '0', marginBottom: '10px'}}>Certificados</h2>
        <TableContainer
        component={Paper}
        elevation={0}
        sx={{ overflow: "scroll", maxHeight: "500px", width: '100%', borderRadius: '10px' }}>
          <Table size="small" aria-labelledby="tableTitle" stickyHeader>
            <TableHead sx={{ border: "none", height: '45px'}}>
              <TableRow sx={{ border: "none", backgroundColor:'#F7F8FA' }}>
                <TableColumns />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? 
              Array.from(new Array(5)).map((_, index) => (
                <TableRow key={index} sx={{ height: '50px' }}>
                  {certificatesOptions?.map((_, i) => (
                    <TableCell key={i}>
                      <Skeleton variant="rectangular" height={20} sx={{borderRadius: '5px'}} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
              : (
                trainingCertificates?.length > 0 ?(
              <>
              {trainingCertificates?.map((certificate) => {
                let status
                let statusMsg
                let chipColor = ""
                let textColor = ""
                switch (certificate?.status) {
                  case 'pending':
                    status = 'Pendiente aprobación'; 
                    statusMsg = 'las asistencias y la nota del quiz'; 
                    chipColor = "#FFFBE4"
                    textColor = "#F09624"
                    break
                  case 'pending attended':
                    status = 'Pendiente aprobación asistencia'; 
                    statusMsg = 'las asistencias'; 
                    chipColor = "#FFFBE4"
                    textColor = "#F09624"
                    break
                  case 'pending quiz':
                    status = 'Pendiente aprobación quiz'; 
                    statusMsg = 'la nota del quiz'; 
                    chipColor = "#FFFBE4"
                    textColor = "#F09624"
                    break
                  case 'approved':
                    status = 'Por descargar';
                    chipColor = "#EDF7FF"
                    textColor = "#43A4EF"
                    break
                  case 'downloaded':
                    status = 'Descargado'; 
                    chipColor = "#E8FFED"
                    textColor = "#55BB5A"
                    break
                }
                return(
                  <TableRow hover sx={{height: '50px'}} >
                    <TableCell component="th" scope="row" sx={{fontSize: '13px'}}>
                      {`${certificate?.user?.first_name} ${certificate?.user?.last_name}`}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      
                      <Chip
                        label={certificate?.nda_agreement ? 'Si' : 'No'}
                        sx={{
                          backgroundColor: certificate?.nda_agreement ? "var(--sec-green)" : "var(--sec-red)",
                          textTransform: "capitalize",
                          width: "fit-content",
                          margin: 0,
                          height: 25,
                          borderRadius: "10px",
                          fontWeight: "bold",
                          color: 'var(--main-white)',
                          fontSize: '11px',
                          
                        }}
                      />
                      
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      
                      <Chip
                        label={certificate?.survey ? 'Si' : 'No'}
                        sx={{
                          backgroundColor: certificate?.survey ? "var(--sec-green)" : "var(--sec-red)",
                          textTransform: "capitalize",
                          width: "fit-content",
                          margin: 0,
                          height: 25,
                          borderRadius: "10px",
                          fontWeight: "bold",
                          color: 'var(--main-white)',
                          fontSize: '11px',
                          
                        }}
                      />
                      
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {certificate?.attendance + ' %'}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {['N/P', 'N/A'].includes(certificate?.quiz) ? certificate?.quiz : certificate?.quiz  + ' %'}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {certificate?.status !== "approved" && certificate?.status !== "downloaded" ?
                      <Tooltip title={
                        <Typography sx={{ fontSize: '11px' }}>
                          {`Está pendiente por ${statusMsg}`}
                        </Typography>
                      }>
                      <Chip
                        label={status}
                        sx={{
                          backgroundColor:chipColor,
                          textTransform: "capitalize",
                          width: "fit-content",
                          margin: 0,
                          height: 25,
                          borderRadius: "10px",
                          fontWeight: "bold",
                          color: textColor,
                          fontSize: '11px',
                          
                        }}
                      />
                      </Tooltip> : 
                      <Chip
                      label={status}
                      sx={{
                        backgroundColor:chipColor,
                        textTransform: "capitalize",
                        width: "fit-content",
                        margin: 0,
                        height: 25,
                        borderRadius: "10px",
                        fontWeight: "bold",
                        color: textColor,
                        fontSize: '11px',
                      }}
                    />}
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      <Button variant='outlined' sx={{borderRadius: '10px'}}
                      startIcon={<DownloadRoundedIcon />}>
                        Descargar
                      </Button>
                    </TableCell>
                    <TableCell align="center" sx={{fontSize: '13px'}}>
                      {certificate?.status !== "approved" && certificate?.status !== "downloaded" &&
                      <Button startIcon={<TaskAltRoundedIcon />} color="success"
                      variant='contained' sx={{borderRadius: '10px'}} onClick={() => handleClickOpenDialog(certificate)}>
                        Aprobar
                      </Button>}
                    </TableCell>
                  </TableRow>
                )
              })}
              </>) : (
                <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body1" color="textSecondary">No hay datos disponibles</Typography>
                </TableCell>
              </TableRow>
              ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle id="alert-dialog-title" style={{fontSize: '18px'}}>
          {`Seguro que quieres aprobar el certificado de ${approveUser?.user?.first_name} ${approveUser?.user?.last_name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{fontSize: '14px'}}>
            {`El docente esta pendiente por ${statusMsg}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant='outlined' size='large' sx={{borderRadius: '10px'}}>
            Cancelar</Button>
          <Button onClick={() => handleAcceptCertificate()} sx={{borderRadius: '10px'}} size='large'
          autoFocus variant='contained' color="success">
            Aprobar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default CertificatesDataView
