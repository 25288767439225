import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import linkifyHtml from 'linkify-html';
import Modal from '../Modal';
import extractVideoId from '../../utils/extractVideoId';
import YoutubeEmbed from '../../utils/YoutubePreview';
import HomeCardComponent from '../Card/HomeCardComponent';
import { useAnnouncements } from '../../context/AnnouncementsContext';

const Cont = styled('div')({
    fontStyle: 'italic',
  fontSize: '14px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'var(--background-gray)',
  padding: '5px 15px',
  borderRadius: 'var(--regular-border-radius)',
  height: '60px',
  alignItems: 'center',
});

function AnnouncementHomeComp() {
    const { userAnnouncements, markAnnouncementAsRead } = useAnnouncements();
    const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
    const [announcementText, setAnnouncementText] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const handleSelectedAnnouncement = (item) => {
        setSelectedAnnouncement(item);
        const contenidoSinComillas = item?.data?.content?.replace(/^"|"$/g, "");
        setAnnouncementText(contenidoSinComillas);
        setModalOpen(true);
    };
    
    const handleAcceptAnnouncement = () => {
      markAnnouncementAsRead(selectedAnnouncement.id);
      setModalOpen(false);
      setSelectedAnnouncement(null);
    };

    const filteredAnnouncements = userAnnouncements?.filter(
      (ann) => !ann.read_at
    );

  return (
    <Box>
        <h1 style={{margin: '10px 0'}}>Anuncios</h1>
        <Box sx={{ maxHeight: '150px', overflowY: 'auto' }}>
        <Stack direction={"column"}
          spacing={1}>
            {modalOpen && (
                <Modal
                  title={selectedAnnouncement.data.title}
                  action={"Aceptar"}
                  modalOpen={setModalOpen}
                  acceptModal={handleAcceptAnnouncement}
                  type={"announcement"}
                >
                    <div
                        style={{padding: "5px 15px"}}
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtml(announcementText, {
                            attributes: {
                              target: "_blank",
                            },
                          }),
                        }}
                    />
                    {selectedAnnouncement?.data?.image && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img alt='selectedAnnouncement'
                            src={selectedAnnouncement?.data?.image}
                            width={350}
                            style={{ padding: "20px 10px" }}
                          />
                        </div>
                    )}
                    {selectedAnnouncement?.data?.url && (
                        <>
                          {selectedAnnouncement?.data?.url?.includes(
                            "youtu"
                          ) ? (
                            <div style={{ marginTop: "20px" }}>
                              <YoutubeEmbed
                                url={selectedAnnouncement?.data?.url}
                                embedId={extractVideoId(
                                  selectedAnnouncement?.data?.url
                                )}
                              />
                            </div>
                          ) : (
                            <div style={{ padding: "20px 15px" }}>
                              <p style={{ fontWeight: "bold" }}>
                                Accede al enlace aquí:{" "}
                              </p>
                              <Link
                                target="_blank" rel="noreferrer"
                                to={selectedAnnouncement?.data?.url}
                              >
                                {selectedAnnouncement?.data?.url}
                              </Link>
                            </div>
                          )}
                        </>
                    )}
                  </Modal>
                )}
                {filteredAnnouncements?.length > 0 ? (
                  filteredAnnouncements?.map((ann) => {
                    return (
                      <HomeCardComponent  title={ann.data.title} color={'var(--sec-yellow)'}
                      handleClick={() => handleSelectedAnnouncement(ann)}/>
                      
                    );
                })
            ) : (
                <Cont >
                  <p>{"No hay anuncios nuevos"}</p>
                </Cont>
            )}
        </Stack>
        </Box>
    </Box>
  )
}

export default AnnouncementHomeComp
