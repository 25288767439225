import React, { useContext, useState } from "react";

const SocketContext = React.createContext();

const SocketProvider = ({ chatroomId, children }) => {
  const [sockets, setSockets] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);

  {/*useEffect(() => {
    let newSockets = [];

    const createSocket = (conversation) => {
      const newSocket = io(WS_BASE_URL, {
        query: { roomId: `${conversation["id"]}` },
        path: `/socket.io/${conversation["id"]}`,
      });

      if (
        unreadMessages.length === 0 ||
        findUnreadMessage(conversation["id"])
      ) {
        setUnreadMessages((prevUnreadMessages) => [
          ...prevUnreadMessages,
          {
            chatRoomId: conversation["id"],
            notifications: 0,
          },
        ]);
      }

      newSocket.on("connect", () => {
        newSockets.push(newSocket);
      });

      newSocket.on("new message", (message) => {
        if (message.message.postedByUser !== currentUser["id"]) {
          if (message.message.chatRoomId === currentConversationId) {
            const newMessage = message.message;
            const sender = users.filter(
              (user) => user["id"] === newMessage.postedByUser
            );
            const formattedMessage = {
              ...newMessage,
              postedByUser: sender,
            };
            setMessages((prevMessages) => [...prevMessages, formattedMessage]);
            return;
          } else {
            setUnreadMessages((prevUnreadMessages) => {
              const conversationId = message.message.chatRoomId;
              const updatedMessages = [...prevUnreadMessages];

              const index = updatedMessages.findIndex(
                (item) => item.chatRoomId === conversationId
              );
              const prevNotifications = updatedMessages[index].notifications;

              updatedMessages.splice(index, 1, {
                chatRoomId: conversationId,
                notifications: prevNotifications + 1,
              });

              return updatedMessages;
            });
          }
        }
      });
    };

    conversations.forEach((conversation) => {
      createSocket(conversation);
      setSockets(newSockets);
    });

    return () => {
      setSockets((prevSockets) => {
        prevSockets.forEach((socket) => {
          socket.close();
        });
        return [];
      });
      setUnreadMessages([]);
    };
  }, [conversations]);*/}

  return (
    <SocketContext.Provider
      value={{ sockets, setSockets, unreadMessages, setUnreadMessages }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  return useContext(SocketContext);
};

export { SocketContext, SocketProvider, useSocket };
