// Habilidades docentes que se mostrarán en la sección de análisis de acompañamiento
const skills =['Conocimiento del contenido del programa', 
    'Hace uso de la planificación de Kurios',
     'Cumple con el objetivo de aprendizaje de la clase',
    'Se evidencia el inicio, desarrollo y cierre de la clase',
      'Cumple con el tiempo establecido para la clase',
    'Atiende a las dudas de los estudiantes', 
    'Utiliza el vocabulario técnico según el programa',
    'Cumplimiento de los acuerdos de convivencia',
  ]

  
  const yearStageImages = {
    'Año 1': {
      'Preescolar': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano1-1.png'
      ],
      'Primaria': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano1-2.png',
      ],
      'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano1-3.png',
      ]
    },
    'Año 2': {
      'Preescolar': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano2-1.png',
      ],
      'Primaria': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano2-2.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano2-3.png'
      ],
      'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano2-4.png'
      ]
    },
    'Año 3': {
      'Preescolar': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano3-1.png',
      ],
      'Primaria': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano3-2.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano3-3.png'
      ],
      'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano3-4.png'
      ]
    },
    'Año 4+': {
      'Preescolar': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano3-1.png',
      ],
      'Primaria': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano4-1.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano4-2.png',
      ],
      'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ano3-4.png'
      ]
    },
    'Digital Skills - Marketplace': { 
        'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/digitalskillsmarketplace.png'
        ]
    },
    'Digital Skills I y II - Marketplace': { 
        'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/digitalskillsIyIImarketplace.png'
        ]
    },
    'Digital Skills II - Marketplace': { 
        'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/digitalskillsIImarketplace.png'
        ]
    },
  };

export { skills, yearStageImages };