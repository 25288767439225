import { useState, createContext, useContext, useEffect } from "react";
import { get, patch, post } from "../utils/http";
import useLocalStorage from "../hooks/useLocalStorage";
import {
    ANNUAL_TRAININGS_ENDPOINT,
    ANNUAL_TRAININGS_ENDPOINT_REMINDERS,
    ANNUAL_ATTENDANCE_ENDPOINT,
    ANNUAL_BULK_ATTENDANCE_ENDPOINT,
    ANNUAL_SESSIONS_ENDPOINT,
    ANNUAL_CERTIFICATE_ENDPOINT,
    QUIZ_ANSWERS_ENDPOINT
} from "../utils/constants";
import { useCurrentUser } from "./CurrentUserProvider";
import { AlertsContext } from "./AlertsContext";
import { useLoading } from "./LoadingContext";
import LoadingComponent from "../components/LoadingComponent";

const AnnualTrainingsContext = createContext();

const AnnualTrainingsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [trainingCertificates, setTrainingCertificates] = useState([])
  const [annualTrainings, setAnnualTrainings] = useState([]);
  const [annualTrainingsInitial, setAnnualTrainingsInitial] = useState([]);
  const [userAnnualTraining, setUserAnnualTraining] = useState([]);
  const [sessionAttendance, setSessionAttendance] = useState([]);
  const { showLoading, hideLoading, isLoading } = useLoading();

  const { currentUser } = useCurrentUser();
  const showSnackbar = useContext(AlertsContext);

  const createAnnualTraining = async (data) => {
    showLoading()
    const url = ANNUAL_TRAININGS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha creado la capacitación anual', 'success')
      fetchAnnualTrainings();
      hideLoading()
    }else{
      showSnackbar('Hubo un error al crear la capacitación anual', 'error');
      hideLoading()
    }
  };

  const fetchAnnualTrainings = async () => {
    showLoading()
    const url = ANNUAL_TRAININGS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
        setAnnualTrainings(response.data);
        hideLoading()
    }else{
      hideLoading()
    }
  };

  const fetchAnnualTrainingsReminders = async () => {
    showLoading()
    const url = ANNUAL_TRAININGS_ENDPOINT_REMINDERS;
    const response = await get({ url });
    if (!response.error) {
        setAnnualTrainingsInitial(response.data);
        hideLoading()
    }else{
      hideLoading()
    }
  };
  const fetchSingleAnnualTrainings = async (trainingId) => {
    if(trainingId){
      showLoading()
      const url = `${ANNUAL_TRAININGS_ENDPOINT}${trainingId}/sessions/`;
      const response = await get({ url });
      if (!response.error) {
        setUserAnnualTraining(response.data);
        hideLoading()
      }else{
        hideLoading()
      }
    }
  };

  const fetchAnnualTrainingAttendance = async (sessionId) => {
    if(sessionId){
      showLoading()
      const url = `${ANNUAL_SESSIONS_ENDPOINT}${sessionId}/attendance/`;
      const response = await get({ url });
      if (!response.error) {
        setSessionAttendance(response.data);
        hideLoading()
      }else{
        hideLoading()
      }
    }
  };

  const updateAnnualTraining = async ({trainingId, data}) => {
    showLoading()
    const url = `${ANNUAL_TRAININGS_ENDPOINT}${trainingId}/`;
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar('Capacitación anual editada', 'success')
      fetchSingleAnnualTrainings(trainingId);
      hideLoading()
    }else{
      showSnackbar('Hubo un error al editar la capacitación anual', 'error');
      hideLoading()
    }
  };

  const updateSession = async ({sessionId, data, trainingId}) => {
    showLoading()
    const url = `${ANNUAL_SESSIONS_ENDPOINT}${sessionId}/`;
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar('Sesión editada', 'success')
      fetchSingleAnnualTrainings(trainingId);
      hideLoading()
    }else{
      showSnackbar('Hubo un error al editar la Sesión', 'error');
      hideLoading()
    }
  };

  const createAttendance = async (data, trainingId) => {
    showLoading()
    const url = ANNUAL_ATTENDANCE_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha confirmado tu asistencia', 'success')
      fetchSingleAnnualTrainings(trainingId)
      fetchAnnualTrainings()
      hideLoading()
    }else{
      showSnackbar('Hubo un error al confirmar la asistencia', 'error');
      hideLoading()
    }
  };

  const createBulkAttendance = async (data, trainingId) => {
    showLoading()
    const url = ANNUAL_BULK_ATTENDANCE_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha guardado la asistencia', 'success')
      fetchSingleAnnualTrainings(trainingId)
      fetchAnnualTrainings()
      hideLoading()
    }else{
      showSnackbar('Hubo un error al guardar la asistencia', 'error');
      hideLoading()
    }
  };

  const sendReminderEmail = async (sessionId) => {
    showLoading()
    const url = `${ANNUAL_SESSIONS_ENDPOINT}${sessionId}/send_session_email/`;
    const response = await get({ url });
    if (!response.error) {
      showSnackbar('Se han enviado los recordatorios para la próxima sesión', 'success')
      hideLoading()
    }else{
      showSnackbar('Hubo un problema al enviar los recordatorios', 'success')
      hideLoading()
    }
  };

  const fetchAnnualTrainingCertificates = async (trainingId) => {
    showLoading()
    const url = `${ANNUAL_CERTIFICATE_ENDPOINT}${trainingId}/list_by_annualtraining/`;
    const response = await get({ url });
    if (!response.error) {
      setTrainingCertificates(response.data);
      hideLoading()
    }else{
      hideLoading()
    }
  };

  const updateCertificate = async (certificateId, data, trainingId) => {
    showLoading()
    const url = `${ANNUAL_CERTIFICATE_ENDPOINT}${certificateId}/`;
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar('Se ha aprobado el certificado', 'success')
      fetchAnnualTrainingCertificates(trainingId);
      hideLoading()
    }else{
      showSnackbar('Hubo un error al aprobar el certificado', 'error');
      hideLoading()
    }
  };

  const updateUserCertificate = async (certificateId, data) => {
    showLoading()
    const url = `${ANNUAL_CERTIFICATE_ENDPOINT}${certificateId}/`;
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar('Se ha editado el certificado', 'success')
      hideLoading()
    }else{
      showSnackbar('Hubo un error al editar el certificado', 'error');
      hideLoading()
    }
  };

  const createQuizAnswer = async ({ data, trainingId }) => {
    const url = QUIZ_ANSWERS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha guardado la respuesta", "success");
      fetchSingleAnnualTrainings(trainingId)
    } else {
      showSnackbar("Hubo un error al guardar la respuesta", "error");
    }
    return response;
  };

  const sendAttendanceIndicators = async (data, attendanceId) => {
    showLoading()
    const url = `${ANNUAL_ATTENDANCE_ENDPOINT}${attendanceId}/`;
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar('Se han guardado los indicadores', 'success')
      hideLoading()
    }else{
      showSnackbar('Hubo un error al guardar los indicadores', 'error');
      hideLoading()
    }
  };


  useEffect(() => {
  }, []);

  const contextData = {
    createAnnualTraining,
    updateAnnualTraining,
    fetchAnnualTrainings,
    fetchAnnualTrainingsReminders,
    annualTrainings,
    fetchSingleAnnualTrainings,
    userAnnualTraining,
    createAttendance,
    createBulkAttendance,
    updateSession,
    sendReminderEmail,
    fetchAnnualTrainingCertificates,
    trainingCertificates,
    updateCertificate,
    createQuizAnswer,
    updateUserCertificate,
    sendAttendanceIndicators,
    fetchAnnualTrainingAttendance,
    sessionAttendance
  };

  return (
    <AnnualTrainingsContext.Provider value={contextData}>
      {loading ? null : children}
      {isLoading && <LoadingComponent />}
    </AnnualTrainingsContext.Provider>
  );
};

const useAnnualTrainings = () => {
  return useContext(AnnualTrainingsContext);
};

export { AnnualTrainingsContext, AnnualTrainingsProvider, useAnnualTrainings };
