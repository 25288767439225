import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./SurveysPage.module.css";
import SettingsBar from "../components/Navbars/SettingsBar";

export const FORMS_OPTIONS = [
  {
    key: "llenar",
    name: "Llenar Planilla",
    icon: <i className="fas fa-poll-h"></i>,
    route: "llenar",
  },
  {
    key: "list",
    name: "Planillas",
    icon: <i className="fas fa-list-alt"></i>,
    route: "list",
  },
  {
    key: "crear-template",
    name: "Crear Template",
    icon: <i className="fas fa-folder-plus"></i>,
    route: "crear-template",
  },
  {
    key: "crear-pregunta",
    name: "Crear pregunta",
    icon: <i className="fas fa-file-plus"></i>,
    route: "crear-pregunta",
  },

  {
    key: "preguntas",
    name: "Preguntas",
    icon: <i className="fad fa-list-ul"></i>,
    route: "preguntas",
  },
];

function PlanillasPage() {
  const [formsOption, setFormsOption] = useState("llenar");
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if (!location.pathname.split("/")[3]) {
      navigate("llenar", { replace: true });
    } else {
      setFormsOption(location.pathname.split("/")[3]);
    }
  }, [location.pathname]);

  return (
    <div className={styles.content}>
      { (formsOption === "llenar" ||
        formsOption === "crear-pregunta" ||
        formsOption === "preguntas" ||
        formsOption === "list" || 
        formsOption === "crear-template") && (
        <SettingsBar
          optionsList={FORMS_OPTIONS}
          option={formsOption}
          setOption={setFormsOption}
        />
      )}
      <section className={styles.leftside}>
        <Outlet />
      </section>
    </div>
  );
}

export default PlanillasPage;
