export const DEVELOPMENT_OPTIONS = [
    {
      name: "Desarrollo",
      icon: <i className="fad fa-users-cog"></i>,
      route: "",
    },
    {
        name: "Instituciones",
        icon: <i className="fas fa-school" aria-hidden="true"></i>,
        route: "/institutions/list",
    },
    {
      name: "Capacitación",
      icon: <i className="fad fa-chalkboard-teacher"></i>,
      route: "/capacitaciones",
    },

    {
      name: "Programas",
      icon: <i className="fad fa-robot"></i>,
      route: "/programs/list",
    },
    {
      name: "Panel de actividades",
      icon: <i className="fas fa-exclamation-triangle"></i>,
      route: "/events",
    },
  ];
  