import { createTheme, responsiveFontSizes } from "@mui/material/styles"

let mainTheme = createTheme({
  typography: {
    fontFamily: "Open Sans, sans-serif",
  },
  palette: {
    primary: { main: "#330072" }, 
    gray: { main: "#989a9e" },
    white: { main: "#fff" },
  },
})

mainTheme = responsiveFontSizes(mainTheme)

export default mainTheme
