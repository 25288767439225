import React, {useContext, useEffect, useState} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {useEditComponent} from './editComponentAux';
import { Grid, Stack, Box, MenuItem, FormControl, Select, InputLabel, TextField, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import * as Yup from 'yup';
import { dateFormatter } from '../../../utils/dateFormatter';
import { EventsContext } from '../../../context/EventsProvider';
import { CurrentUserContext } from "../../../context/CurrentUserProvider";
import { AlertsContext } from '../../../context/AlertsContext';
import { priority, priorityChoices, priorityChoicesColor, 
      getStatusIcon, typeofEvents,  areaResponsible, areaChoices, 
      stageChoices, statusChoices, isExternal, typeInformationChoices, 
      typeofInformationChoices, typeOfChoices, eventTypeMapOptions, typeChoices } from '../constants';
import styles from '../Events.module.css';

const Title = styled('p')({
  fontSize: '1.6rem',
  fontWeight:'bold',
  marginBottom: '5px'
});
const Info = styled('p')({
  fontSize: '1.2rem',
  color: '#787878',
});
const Text = styled('p')({
  fontSize: '1.2rem',
  paddingTop: '5px'
});
const Text2 = styled('p')({
  fontSize: '1.3rem',
  paddingTop: '5px',
  color: 'var(--main-blue)',
  fontWeight:'bold',
});

const validationSchema = Yup.object().shape({
  status: Yup.string().required('El estado es requerido'),
  designated_person: Yup.number().nullable().when('status', {
    is: (val) => val === 'En Proceso' || val === 'Resuelto' || val === 'Cerrado',
    then: schema => schema.required('Required'),
    otherwise: schema => schema,    
  }),
  description: Yup.string().required('Required')
    .max(3000, 'El texto no debe exceder los 3000 caracteres'),
  text_solution: Yup.string().when('status', {
    is: (val) => val === 'Resuelto' || val === 'Cerrado',
    then: schema => schema.required('Debe contener la solución').max(3000, 'El texto no debe exceder los 3000 caracteres'),
    otherwise: schema => schema.max(3000, 'Debe tener menos de 3000 caracteres'),
  }),
});
function InformationAdmin() {
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({text_solution: '', status: ''});
  const { currentUser } = useContext(CurrentUserContext);
  const {fetchSingleEvents, singleEvents, fetchForUpdate, dataUpdate, updatePartialEvent} = useContext(EventsContext)
  const showSnackbar = useContext(AlertsContext);
  const [error, setError] = useState({});
  const priorityIcon = priorityChoicesColor(singleEvents?.priority);
  const {selectedType, setSelectedType, options} = useEditComponent('Information');
  const { eventId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setFormData(singleEvents);
  }, [singleEvents]);

    useEffect(() => {
        const event = parseInt(eventId);
        fetchSingleEvents(event);  
        fetchForUpdate();
    }, [eventId]);

    const handleNavigateInstitution = () => {
      if (singleEvents?.institution) {
        navigate(`/institutions/${singleEvents?.institution?.id}`);
      }
      else {
        showSnackbar('No se pudo encontrar la institución', 'error');
        navigate(`/institutions/list`);
      }
    };    

    const handleNavigateProgram = () => {
      if (singleEvents?.program) {
        navigate(`/programs/${singleEvents?.program?.id}`);
      }
      else {
        showSnackbar('No se pudo encontrar el programa', 'error');
        navigate(`/programs/list`);
      }
    }
    const toggleEditMode = () => {
        setEditMode(!editMode);
      };
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'program') {
          const selectedProgram = dataUpdate.programs.find(program => program?.id === value);
          setFormData({ ...formData, [name]: selectedProgram });
        } 
        else if (name === 'term') {
          const selectedTerm = dataUpdate.terms.find(term => term?.id === value);
          setFormData({ ...formData, [name]: selectedTerm });
        }
        else if (name === 'designated_person'){
          const selectedPerson = dataUpdate?.internal_users.find(user => user?.id === value);
          setFormData({ ...formData, [name]: selectedPerson });
        }
        else if (name === 'external_teacher'){
          const selectedPersonExternal = dataUpdate?.internal_users.find(user => user?.id === value);
          setFormData({ ...formData, [name]: selectedPersonExternal });
        }
        else if (name === 'type_event'){
          setSelectedType(value);
          setFormData({ ...formData, [name]: value });
        }
        else {
          setFormData({ ...formData, [name]: value });

          setError({...error, [name]: ''});
        }
      };
    
      const handleSave = async () => {
        try {
          // Enviar los datos actualizados al servidor
          const event = parseInt(eventId);
          const data = {
            ...formData,
            status : formData.status,
            term: formData?.term?.id || null,
            program: formData?.program?.id || null,
            designated_person: formData?.designated_person?.id || null,
            area_responsible: formData?.area_responsible || null,
            external_teacher: formData?.external_teacher?.id || null,
          }
          await validationSchema.validate(data, { abortEarly: false });

          await updatePartialEvent({eventId: event, data: data});
          setEditMode(false);
          fetchSingleEvents(event); 
        } catch (err) {
          if (err.name === 'ValidationError') {
            const validationErrors = {};
            err.inner.forEach(error => {
              validationErrors[error.path] = error.message;
            });
            setError(validationErrors); 
          } else {
            console.error('Error updating incidence:', err);
          }
        }
      };

    const formatDuration = (duration) => {
      if (!duration) return "";
      const days = String(duration).split(" ")[0];
      return `${days} Días`;
    };

    
    const icon = getStatusIcon(singleEvents?.status);
    
    const fullAccessRoles = ['IT', 'post_sales', 'monitoring_teacher', 'monitoring_coordinator',
      'monitoring_teacher_3', 'purchases_and_inventory'];

    const UserFullAccess = fullAccessRoles.includes(currentUser.role);
  return (
    <Stack direction={"column"}>
      <i
      className="fal fa-arrow-left"
      style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
      onClick={() => navigate(-1)}
      ></i>
          <Grid container direction={"row"} columnGap={2} rowGap={2}>
            <Grid item md={3.5} xs={7} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Title>{"Reportado Por"}</Title>
              <Info>{singleEvents.report_person?.first_name } {singleEvents?.report_person?.last_name}</Info>
              <Stack direction={"row"} spacing={1}
              sx={{display:'flex', alignItems:'center', marginTop: '2px'}}>
                <i class="fas fa-calendar" style={{color: "#787878", fontSize:'1.2rem'}}></i>
                <Info>Fecha de Inicio: {dateFormatter(singleEvents?.event_date)}</Info>
              </Stack>
            </Grid>
            <Grid item md={3.5} xs={7}
              sx={{ backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px' }}>
              <Title>{"Personal Asignado"}</Title>
              <Stack direction={"column"} spacing={1} sx={{ display: 'flex', alignItems: 'flex-start', marginTop: '2px' }}>
                {editMode && UserFullAccess ? (
                  <FormControl fullWidth>
                    <InputLabel>Personal Asignado</InputLabel>
                    <Select name="designated_person" value={formData?.designated_person?.id || ''} 
                    onChange={handleInputChange} sx = {{borderRadius: '10px'}}>
                      {dataUpdate?.internal_users?.map((user) => (
                        <MenuItem key={user?.id} value={user?.id}>
                          {user?.first_name} {user?.last_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <>
                    <Info>  {singleEvents?.designated_person?.first_name && singleEvents?.designated_person?.last_name
                      ? `${singleEvents.designated_person.first_name} ${singleEvents.designated_person.last_name}`
                      : "Sin personal asignado"}
                      
                    </Info>
                    <Stack direction={"row"} spacing={1} alignItems="center" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      <i className="fas fa-calendar" style={{ color: "#787878", fontSize: '1.2rem' }}></i>
                      <Info>Fecha de Fin:</Info>
                      <Info>{dateFormatter(singleEvents?.update_to_solution) || "Sin Asignar"}</Info>
                    </Stack>
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item md={2} xs={4.5} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Estado</Info>
                      {editMode  && UserFullAccess ? (
                        <FormControl fullWidth>
                            <InputLabel>Estado</InputLabel>
                            <Select name="status" value={formData.status} 
                            onChange={handleInputChange} sx = {{borderRadius: '10px'}}>
                              {statusChoices.map((status) => (
                                  <MenuItem key={status.value} value={status.value}>
                                    {status.label}
                                  </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        ) : (
                        <Text2>{singleEvents?.status}</Text2>
                        )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                     <i className={icon.className} style={icon.style}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            
            <Grid item md={1.4} xs={2.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Editar</Info>
                        <Stack direction={"row"} spacing={2} alignItems="center">
                          <IconButton onClick={toggleEditMode} sx={{ marginTop: '15px' }}>
                            <i className={`fa ${editMode ? 'fa-times' : 'fas fa-edit'}`} style={{backgroundColor: 'var(--main-blue)', 
                                color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                          </IconButton>
                          {editMode && (
                            <IconButton onClick={handleSave} sx={{ marginTop: '15px' }}>
                              <i className="fa fa-save" style={{backgroundColor: 'var(--sec-green)', 
                                color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                            </IconButton>
                          )}
                        </Stack>
                      </Stack>
                  </Grid>
              </Grid>
              
            </Grid>
            {!isExternal(currentUser) && 
            <Grid item md={3.5} xs={7}
              sx={{ backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px' }}>
              <Title>{"Persona Afectada"}</Title>
              <Stack direction={"column"} spacing={1} sx={{ display: 'flex', alignItems: 'flex-start', marginTop: '2px' }}>
                {editMode && UserFullAccess ? (
                  <FormControl fullWidth>
                    <InputLabel>Persona Afectada</InputLabel>
                    <Select name="external_teacher" value={formData?.external_teacher?.id || ''} 
                    onChange={handleInputChange} sx = {{borderRadius: '10px'}}>
                      {dataUpdate?.internal_users?.map((person) => (
                        <MenuItem key={person?.id} value={person?.id}>
                          {person?.first_name} {person?.last_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <>
                    <Info>  {singleEvents?.external_teacher?.first_name && singleEvents?.external_teacher?.last_name
                      ? `${singleEvents.external_teacher.first_name} ${singleEvents.external_teacher.last_name}`
                      : "Información no disponible"}
                      
                    </Info>
                  </>
                )}
              </Stack>
            </Grid>
            }
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}
                onClick={() => handleNavigateInstitution()}
                style={{cursor: 'pointer'}}
                >
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                      <Info>Institución</Info>
                      <Text2>{singleEvents?.institution?.name}</Text2>
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fas fa-university" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                      <Info>Tipo</Info>
                      {editMode && UserFullAccess ? (
                        <FormControl fullWidth>
                          <InputLabel>Seleccione un tipo</InputLabel>
                          <Select name="type_event" value={selectedType || ''} 
                          onChange={handleInputChange} sx = {{borderRadius: '10px'}}>
                            {typeChoices?.map((type) => (
                              <MenuItem key={type?.value} value={type?.value}>
                                {type?.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ):(
                        <Text2>{typeOfChoices(singleEvents?.type_event)}</Text2>
                      )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fas fa-list-ul" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"}>
                      <Info>{`Tipo de ${typeOfChoices(selectedType)}`}</Info>
                      {editMode && UserFullAccess?  (
                          <FormControl fullWidth>
                            <InputLabel>Seleccione un tipo</InputLabel>
                            <Select name="incident_type" value={formData?.incident_type || ''} 
                            onChange={handleInputChange} sx = {{borderRadius: '10px'}}>
                              {options?.map((type) => (
                                <MenuItem key={type?.value} value={type?.value}>
                                  {type?.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ):(
                         <Text2> {eventTypeMapOptions[selectedType]
                          ? eventTypeMapOptions[selectedType](singleEvents?.incident_type)
                          : 'No definido'}</Text2>
                         )
                      }
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fas fa-list-ul" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            {!isExternal(currentUser) && 
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Prioridad</Info>
                      <Stack direction={"row"} spacing={1}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '2px'
                          }}
                        >
                          {editMode && UserFullAccess ? (
                            <FormControl fullWidth>
                            <InputLabel>Prioridad</InputLabel>
                            <Select name="priority" value={formData?.priority}
                             onChange={handleInputChange}  sx = {{borderRadius: '10px'}}>
                            {priorityChoices.map((priority) => (
                                <MenuItem key={priority.value} value={priority.value}>
                                {`${priority.label}`}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                          ) : (
                            <Text2>{priority(singleEvents?.priority)}</Text2>
                          )}
                        </Stack>
                        
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i className={priorityIcon.className} style={priorityIcon.style}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            }
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Tiempo Estimado De Solución</Info>
                      <Stack direction={"row"} spacing={1}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '2px'
                          }}
                        >
                          {editMode && UserFullAccess ? (
                            <TextField
                              fullWidth
                              name="estimated_resolution_time"
                              label="Tiempo Estimado de Resolución (Días)"
                              value={formData.estimated_resolution_time || ''}
                              onChange={handleInputChange}
                              placeholder="3"
                              InputProps={{ inputProps: { min: 1, max: 60 } }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "10px", 
                                }
                              }}
                              
                            />
                          ) : (
                            <Info>{formatDuration(singleEvents?.estimated_resolution_time)}</Info>
                          )}
                        </Stack>
                        
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fas fa-clock" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Área Responsable</Info>
                      {editMode && UserFullAccess ? (
                        <FormControl fullWidth>
                            <InputLabel>Área Responsable</InputLabel>
                            <Select name="area_responsible" value={formData?.area_responsible} 
                            onChange={handleInputChange} sx = {{borderRadius: '10px'}}>
                              {areaChoices.map((area) => (
                                  <MenuItem key={area.value} value={area.value}>
                                  {`${area.label}`}
                                  </MenuItem>
                              ))}
                            </Select>
                        </FormControl>                        
                        ) : (
                        <Text2>{areaResponsible(singleEvents?.area_responsible)}</Text2>
                        )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                     <i class = "fas fa-users" style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}
                onClick={()=> {
                  if (!editMode) {
                  handleNavigateProgram()}}}
                style={{cursor: 'pointer'}}
                >
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Programa</Info>
                      {editMode ? (
                        <FormControl fullWidth>
                            <InputLabel>Programa</InputLabel>
                            <Select name="program" value={formData?.program?.id}
                             onChange={handleInputChange} sx = {{borderRadius: '10px'}}>
                            {dataUpdate?.programs?.map((program) => (
                                <MenuItem key={program?.id} value={program?.id}>
                                {`${program?.year} - ${program?.name} - ${program?.version} - ${program?.language}`}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        ) : (
                        <Text2>{`${singleEvents?.program?.year || ''} - ${singleEvents?.program?.name || ''} - 
                        ${singleEvents?.program?.version || ''} - ${singleEvents?.program?.language || ''}` }</Text2>
                        )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fa fa-book" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
            </Grid>
            {singleEvents?.lesson !== null && (
              <Grid item md={3.5} xs={5.8} 
              sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
                <Grid
                  container
                  direction={"row"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height:'100%'
                  }}
                  onClick={()=> {
                    if (!editMode) {
                    handleNavigateProgram()}}}
                  style={{cursor: 'pointer'}}
                  >
                    <Grid
                      item
                      xs={8}
                      sx={{ display: "flex", justifyContent: "flex-start" }}>
                        <Stack direction={"column"} sx = {{flexGrow: 1}}>
                        <Info>Clase</Info>
                          <Text2>{`N° ${singleEvents?.lesson_number || ''} - ${singleEvents?.lesson_title || ''}` }</Text2>
                        </Stack>
                    </Grid>
                    <Grid item sx={{display:'flex', alignItems:'center'}}>
                      <i class="fas fa-book-reader" 
                      style={{backgroundColor: 'var(--main-blue)', 
                      color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                    </Grid>
                </Grid>
                
              </Grid>
            )}
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Etapa</Info>
                      {editMode ? (
                        <FormControl fullWidth>
                          <InputLabel>Etapa</InputLabel>
                            <Select name="stage" value={formData?.stage || ''} 
                            onChange={handleInputChange} sx = {{borderRadius: '10px'}}>
                              {stageChoices.map((stage) => (
                                  <MenuItem key={stage.value} value={stage.value}>
                                  {`${stage.label}`}
                                  </MenuItem>
                              ))}
                            </Select>
                        </FormControl>
                        ) : (
                        <>
                         <Text2>{singleEvents?.stage}</Text2>

                        </>
                      )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                  <i class="fas fa-bookmark" style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
            </Grid>
            <Grid item md={3.5} xs={5.8} 
            sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', borderRadius: '10px'}}>
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height:'100%'
                }}>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Stack direction={"column"} sx = {{flexGrow: 1}}>
                      <Info>Lapso</Info>
                      {editMode ? (
                        <FormControl fullWidth>
                            <InputLabel>Lapso</InputLabel>
                            <Select name="term" value={formData?.term?.id} 
                            onChange={handleInputChange} sx = {{borderRadius: '10px'}}>
                              {dataUpdate?.terms?.map((term) => (
                                <MenuItem value={term?.id}>{term?.name}</MenuItem>

                              ))}
                            </Select>
                        </FormControl>
                        ) : (
                        <Text2>{singleEvents?.term?.name}</Text2>
                      )}
                      </Stack>
                  </Grid>
                  <Grid item sx={{display:'flex', alignItems:'center'}}>
                    <i class="fas fa-calendar-alt" 
                    style={{backgroundColor: 'var(--main-blue)', 
                    color: '#fff', padding: '7px', borderRadius: '7px', fontSize: '16px'}}></i>
                  </Grid>
              </Grid>
              
            </Grid>
          </Grid>
          {singleEvents?.text_message && (
             <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
              borderRadius: '10px', marginTop:'15px'}}>
                <Info>Mensaje</Info>
                  <Text>{singleEvents?.text_message}</Text>
              </Box>
          )}
          <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
          borderRadius: '10px', marginTop:'15px'}}>
            <Info>Descripción</Info>
            {editMode  ? (
                <TextField name="description" value={formData.description} 
                onChange={handleInputChange} fullWidth multiline rows={4}
                error={Boolean(error.description)}
                helperText={error.description}
                FormHelperTextProps={{
                  sx: {
                    fontSize: '1rem', 
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px", 
                  }
                }} />
                ) : (
                <Text>{singleEvents?.description}</Text>
            )}
          </Box>
          <Box sx={{backgroundColor: 'var(--background-gray)', padding: '10px 15px', 
              borderRadius: '10px', marginTop:'15px'}}>

                <Info>Solución</Info>
                {!isExternal(currentUser) ? (
                  <>
                    {editMode && UserFullAccess? (
                      <TextField 
                      name="text_solution" value={formData.text_solution} onChange={handleInputChange} 
                      fullWidth multiline rows={4}
                      error={Boolean(error.text_solution)} 
                      helperText={error.text_solution}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px", 
                        }
                      }}
                      FormHelperTextProps={{
                        sx: {
                          fontSize: '1rem', 
                        },
                      }}/>
                      
                  ) : (
                  <Text>{singleEvents?.text_solution}</Text>
                  )}
                  </>
            
              ): (
                <>
               {singleEvents?.text_solution != null ? (<Text2>Solventado</Text2>):(<Text2>En espera</Text2>)}
               </>
          )}
        </Box>
    </Stack>
  )
}

export default InformationAdmin
