import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./InstitutionsList.module.css";
import { InstitutionsContext } from "../../context/InstitutionsContext";
import Container from "../Container";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import {
  TEACHERS_ENDPOINT,
  INSTITUTIONS_LIST_ENDPOINT,
} from "../../utils/constants";
import { get } from "../../utils/http";
import LoadingComponent from "../LoadingComponent";
import { useLoading } from "../../context/LoadingContext";

function InstitutionsList() {
  const [filterText, setFilterText] = useState("");
  const [filterMonitor, setFilterMonitor] = useState(0);
  const [institutions, setInstitutions] = useState([]);
  const { setSelectedInstitution } = useContext(InstitutionsContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const [teachers, setTeachers] = useState([]);
  const navigate = useNavigate();

  const internalTeachers = institutions?.filter((item) => item.teacher_service !== "EXT")

  const finalInstitutions = (currentUser.role === "academic_coordination") ? internalTeachers : institutions

  useEffect(() => {
    showLoading();
    const fetchInstitutions = async () => {
      const url = INSTITUTIONS_LIST_ENDPOINT;
      const response = await get({ url });
      if (!response.error) {
        response.data?.sort((a, b) => a?.name?.localeCompare(b?.name));
        setInstitutions(response.data);
        hideLoading();
      } else {
        hideLoading();
      }
    };
    fetchInstitutions();
  }, []);

  useEffect(() => {
    const fetchTeachers = async () => {
      const url = TEACHERS_ENDPOINT;
      const response = await get({ url });
      setTeachers(response.data);
    };
    fetchTeachers();
  }, []);

  let teachersFiltered;

  if (currentUser.role !== "external_coordinator") {
    teachersFiltered =
      teachers &&
      teachers?.filter((item) => item?.role === "monitoring_teacher");
  }

  let filteredData = finalInstitutions && finalInstitutions?.filter((item) => {
    if (filterText === "" && parseInt(filterMonitor) === 0) {
      return item;
    } else {
      let institutionMatch = true;
      if (filterText !== "") {
        institutionMatch =
          item?.name?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
          item?.short_name?.toLowerCase()?.includes(filterText?.toLowerCase());
      }

        let teacherMatch = true;

        if (filterMonitor !== 0) {
          teacherMatch = item?.monitor?.id === parseInt(filterMonitor);
        }
        if (institutionMatch && teacherMatch) {
          return item;
        }
      }
  });

  const handleChangeFilterText = (e) => {
    setFilterText(e.target.value);
  };

  return (
    <Container type={"institutions"}>
      <div className={styles.container}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div className={styles["search-bar"]}>
          <input
            id="search"
            type="text"
            placeholder="Buscar por nombre"
            aria-label="Search Input"
            value={filterText}
            onChange={(e) => handleChangeFilterText(e)}
            className={styles.filter_text}
          />
          {(currentUser.role === "IT" ||
            currentUser.role === "monitoring_coordinator" ||
            currentUser.role === "monitoring_teacher" ||
            currentUser?.role === "monitoring_teacher_3") && (
            <select
              value={filterMonitor}
              className={styles.filter_text}
              onChange={(e) => setFilterMonitor(e.target.value)}
            >
              <option value={0}>Filtrar por docente seguimiento</option>
              {teachersFiltered?.map((teacher, index) => (
                <option value={teacher?.info.id} key={index}>
                  {teacher?.info.first_name} {teacher?.info.last_name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className={styles.content}>
          {filteredData?.map((inst) => {
            return (
              <Link
                to={`/institutions/${inst.id}`}
                style={{ textDecoration: "none", color: "#000" }}
                key={inst.id}
                onClick={() => setSelectedInstitution(inst)}
              >
                <div className={styles.course}>
                  <div className={styles.logos}>
                    <div className={styles["logo-img"]}>
                      <img alt="institut"
                        src={inst?.logo ?? "/IsotipoK.png"}
                      />
                    </div>
                  </div>
                  <div className={styles.info}>
                    <h2>{`${inst?.name}`}</h2>
                    {/* <h3>{`${inst.short_name.toUpperCase()}`}</h3> */}
                    {/* <p>{`Año TK: 3`}</p> */}
                    <p>{`Servicio docente: ${inst?.teacher_service}`}</p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      {isLoading && <LoadingComponent />}
    </Container>
  );
}

export default InstitutionsList;